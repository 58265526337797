export class AuthenticationBuilder {
    components = []
    constructor(template, formData) {
        this.template = template;
        this.formData = formData;
    }
    withBody() {

        this.components.push({
            type: "body",
            add_security_recommendation: this.formData.security ? this.formData.security : false
        })
        return this;
    }

    withFooter() {
        if (this.formData.codeExpiry) {
            this.components.push({
                type: "footer",
                code_expiration_minutes: this.formData.codeExpiry
            })
        }
        return this;
    }

    withButtons() {
        if (this.formData.copyButton) {
            this.components.push(
                {
                    type: "buttons",
                    buttons: [
                        {
                            type: "otp",
                            otp_type: "copy_code",
                            text: this.formData.copyButton ?? ""
                        }
                    ]
                }
            )
        }
        return this;
    }

    build() {
        return { components: this.components };
    }
}
export class RequestBuilder {
    components = []
    constructor(template, formData) {
        this.template = template;
        this.formData = formData;
    }

    withHeader(type, fileType, doesHeaderHasVars, value) {
        if (type != "none") {
            let textHeaderData = {}
            if (doesHeaderHasVars) {
                textHeaderData = {
                    type: "HEADER",
                    format: "text",
                    text: this.formData.header,
                    example: {
                        header_text: value
                    }
                }
            } else {
                textHeaderData = { type: "HEADER", format: "text", text: this.formData.header }
            }

            let header = type == "text" ?
                textHeaderData :
                type == "media" ?
                    fileType == "image" ?
                        { type: "HEADER", format: "image", example: { header_handle: ["4::aW..."] } } :
                        fileType == "video" ?
                            { type: "HEADER", format: "video", example: { header_handle: ["4::aW..."] } } :
                            fileType == "document" ?
                                { type: "HEADER", format: "document", example: { header_handle: ["4::aW..."] } } :
                                { type: "HEADER", format: "location" } : {}

            this.components.push(header)
        }


        return this;
    }

    withBody(doesBodyHasVars, bodySample) {
        let bodyData = {}
        if (doesBodyHasVars) {
            bodyData = {
                type: "BODY",
                text: this.formData.body,
                example: {
                    body_text: bodySample
                }
            }
        } else {
            bodyData = { type: "BODY", text: this.formData.body }
        }

        this.components.push(bodyData)

        // this.components.push({
        //     type: "BODY",
        //     text: this.formData.body
        // })
        return this;
    }

    withFooter() {
        if (this.formData.footer !== "") {
            this.components.push({ type: "FOOTER", text: this.formData.footer })
        }
        return this;
    }

    withButtons(buttonType, data) {
        if (!data) return this;
        switch (buttonType) {
            case "calltoaction":
                this.components.push({
                    type: "BUTTONS",
                    buttons: data.map(item => {
                        if (item.action === "PHONE_NUMBER") {
                            return {
                                type: "PHONE_NUMBER",
                                text: item.text,
                                phone_number: item.number
                            };
                        } else if (item.action === "visitwebsite") {
                            return {
                                type: "url",
                                text: item.text,
                                url: item.websiteurl
                            };
                        }
                    })
                })
                break;
            case "quickreply":
                this.components.push({
                    type: "BUTTONS",
                    buttons: data.map(item => ({
                        type: "QUICK_REPLY",
                        text: item.data
                    }))
                })
                break;
        }

        return this;
    }

    build() {
        return { components: this.components };
    }
}