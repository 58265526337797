
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useState, useRef, useEffect, useContext } from 'react'
import useDeviceWidthChecker from "../components/customHooks/DeviceWidthDetector"
import { Table } from 'antd';
import Companyactionbtn from '../components/company-listing/compnay-actionbtn';

import readmessage from "../images/icons/read.svg";
import Pending from "../images/icons/pending.svg";
import Send_message from "../images/icons/send.svg";
import Expired from "../images/icons/expired.svg";
import Calendar from "../images/company/calendar.svg";
import restoreIcon from "../images/icons/ic_restore.svg";
import restoreIcon1 from "../images/icons/ic_restore1.svg";
import Broadcastcard from "../components/broadcast/broadcast-card";
import Companytable from "../components/company-listing/companytable";
import { getAllUsers, reverseDeleteUser, deleteUser, editApproval, getAllMessagesSent } from "../services/User";


import "../components/company-listing/companylisting.css";
import { Spinner } from "react-bootstrap";

const Companylisting = () => {
    const width = useDeviceWidthChecker()
    const [ctshow, setCtShow] = useState(false);
    const [cvsshow, setCvsShow] = useState(false);
    const [cvsshow1, setCvsShow1] = useState(false);
    const [expiredhow, setExpiredShow] = useState(false);
    const [updateUi, setUpdateUi] = useState(false)
    const [allUsers, setAllUsers] = useState([])
    const [allUsers1, setAllUsers1] = useState([])
    const [loading, setLoading] = useState(false)
    const [date, setDate] = useState(null)
    const [allMessagesSent, setAllmessagesSent] = useState(0)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [confirmationMessage, setConfirmationMessage] = useState("")
    const [restore_icon, setRestoreIcon] = useState(-1);
    const [active, setActive] = useState(0);
    const [_id, set_id] = useState(null);

    const [companiesInfo, setCompaniesInfo] = useState({
        active: 0,
        pending: 0,
        expired: 0
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resp = await getAllUsers()
                setLoading(true)
                if (resp.success) {

                    setAllUsers(resp.users)
                    setAllUsers1(handleDataForTable(resp.users))
                    console.log(loading)
                }
                else
                    throw new Error(resp.error)
                setLoading(false)
            } catch (error) {
                console.log(error.message)
                console.log('Failed to fetch Users!')
            }
        }

        fetchData()
    }, [updateUi])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resp = await getAllMessagesSent()
                setLoading(true)
                if (resp.success) {
                    setAllmessagesSent(resp.messageCount)
                    console.log(loading)
                }
                else
                    throw new Error(resp.error)
                setLoading(false)
            } catch (error) {
                console.log(error.message)
                console.log('Failed to fetch Messages!')
            }
        }

        fetchData()
    }, [])

    console.log(allMessagesSent)
    useEffect(() => {
        const today = new Date();
        const day = today.getDate().toString().padStart(2, "0");
        const month = (today.getMonth() + 1).toString().padStart(2, "0"); // January is 0!
        const year = today.getFullYear().toString();
        const formattedDate = `${day}/${month}/${year}`;
        console.log(formattedDate); // outputs "03/02/2023"
        setDate(formattedDate)
    }, [])

    useEffect(() => {
        let activeCount = 0;
        let pendingCount = 0;
        let expiredCount = 0;

        allUsers.forEach((company) => {
            if (company.isdeleted) {
                expiredCount++;
            }
            else {
                if (company.isapproved) {
                    activeCount++;
                } else {
                    pendingCount++;
                }
            }
        });

        setCompaniesInfo({
            active: activeCount,
            pending: pendingCount,
            expired: expiredCount,
        });
    }, [allUsers]);

    const getCompaniesCount = () => {
        let activeCount = 0;
        let pendingCount = 0;
        let expiredCount = 0;

        allUsers.forEach((company) => {
            if (company.isdeleted) {
                expiredCount++;
            }
            else {
                if (company.isapproved) {
                    activeCount++;
                } else {
                    pendingCount++;
                }
            }
        });

        setCompaniesInfo({
            active: activeCount,
            pending: pendingCount,
            expired: expiredCount,
        });
    }

    function handleUnDelete(event) {
        setLoading(true)
        const userID = event.target.closest('tr').dataset.userId;
        // Retrieve user using userID and update isdeleted flag
        console.log(userID)
        reverseDeleteUser(userID)
            .then(response => {

                console.log(response);
                setUpdateUi((prevData) => {
                    return !prevData
                })
                getCompaniesCount()
                setLoading(false)
            })
            .catch(error => {
                console.error(error);
            });
    }

    const submitDelete = async (_id) => {
        try {
            setLoading(true);
            const response = await deleteUser(_id);
            console.log(response);
            setUpdateUi((prevData) => !prevData);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const submitEdit = async (data) => {
        try {
            // Display confirmation modal
            setShowConfirmation(true);
            console.log(data)
            setConfirmationMessage(`Are you sure you want to change the approval status of '${data.companyName}' to ${data.isapproved ? "Inactive" : "Active"}.`)
            set_id(data._id)
        } catch (error) {
            console.error(error);
        }
    };

    const handleProceed = async () => {
        try {
            setLoading(true);
            setShowConfirmation(false); // Close the modal after processing
            const response = await editApproval(_id); // Assuming _id is defined somewhere
            console.log(response);
            setUpdateUi((prevData) => !prevData);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            set_id(null)
            setConfirmationMessage(false)
        }
    };

    const handleCancel = () => {
        // Close the modal without proceeding
        setShowConfirmation(false);
        set_id(null)
        setConfirmationMessage(false)
    };

    const columns = [
        {
            title: 'Company Name',
            dataIndex: 'CompanyName',
        },
        {
            title: 'Company Admin',
            dataIndex: 'CompanyAdmin',
        },
        {
            title: 'Email',
            dataIndex: 'Email',
        },
        {
            title: 'Status',
            dataIndex: 'Status',
        },
        {
            title: 'Actions',
            dataIndex: 'Actions',
        },
    ];

    // making data for showing rows in table
    const handleDataForTable = (data) => {
        let finalData = []
        for (let i = 0; i != data.length; i++) {
            if (!data[i]?.isdeleted) {
                finalData.push({
                    key: i,
                    CompanyName: data[i]?.companyName,
                    CompanyAdmin: data[i]?.firstname + " " + data[i]?.firstname,
                    Email: data[i]?.email, Status: data[i]?.isapproved ? <span><span className="dot active"></span>Active</span> : <span><span className="dot inactive"></span>Inactive</span>,
                    Actions: <Companyactionbtn data={data[i]} submitDelete={submitDelete} submitEdit={submitEdit} />
                })
            }
        }
        return finalData
    }

    return (
        <>
            <section className="main inner-main broadcast-sec">

                <section className="contact-group-top">
                    <Container fluid >
                        <Row style={{ height: width < 600 ? "50px" : "" }} >
                            <Col sm={12} className="mb-3">
                                <div className="overview-heading">
                                    Overview
                                </div>
                            </Col>
                            <Col sm={12} className="mb-3 d-md-none">
                                {/* <div className="overview-header bg-white rounded-pill">
                                    <Row className="align-items-center">
                                        <Col xs={12}>
                                            {<ul className="nav">
                                                {items.map((item, index) => (
                                                    <li key={index} className={active === index ? "active" : "nav-item rounded-pill"}
                                                        onClick={() => handleClick(index)}>
                                                        {item}
                                                    </li>
                                                ))}
                                            </ul>}
                                        </Col>
                                    </Row>
                                </div> */}
                            </Col>
                            <Col sm={12} className="mb-4 mb-lg-5">
                                <div className="overview-header rounded-pill">
                                    <Row className="align-items-center">
                                        <Col md="4">
                                            {/* <div className = "date-side">
                                            <img src = {Calendar} alt = "calendar-icon"/> {date}
                                        </div> */}
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>

                        <Row className="pb-3">
                            <Col md="4" lg={3} xl={3}>
                                <div className="delivered-message company-card">
                                    <Broadcastcard title={'All companies'} imageicon={Send_message} number={companiesInfo.active + companiesInfo.pending + companiesInfo.expired} loading={loading} />
                                </div>
                            </Col>
                            <Col md="4" lg={3} xl={3}>
                                <div className="message-send company-card" onClick={() => setCvsShow(true)}>
                                    <Broadcastcard title={'Active Companies'} imageicon={readmessage} number={companiesInfo.active} loading={loading} />
                                </div>
                            </Col>

                            <Col md="4" lg={3} xl={3} onClick={() => setCvsShow1(true)}>
                                <div className="pending company-card">
                                    <Broadcastcard title={'Pending Companies'} imageicon={Pending} number={companiesInfo.pending} loading={loading} />
                                </div>
                            </Col>
                            <Col md="4" lg={3} xl={3}>
                                <div className="failed company-card" onClick={() => setExpiredShow(true)}>
                                    <Broadcastcard title={'Deleted Companies'} imageicon={Expired} number={companiesInfo.expired} loading={loading} />
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </section>
                <section className="contact-group-table">
                    <Container fluid>
                        <Row className="py-3 align-items-center">
                            <Col xs={6}>
                                <div className="overview-heading">
                                    Companies Listing
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                {/* <Companytable data={allUsers} submitDelete={submitDelete} submitEdit={submitEdit} loading={loading} /> */}
                                <Table
                                    columns={columns}
                                    dataSource={allUsers1}
                                    scroll={{
                                        x: width < 600 && 1500,
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>

            </section>


            <Modal show={cvsshow} onHide={() => setCvsShow(false)} className="whatsapp-modal modal-csv" centered>
                <Modal.Header className="p-0" closeButton>
                    <Modal.Title>
                        Active Companies ({companiesInfo.active})
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <Row>
                        <Col sm={12}>
                            <div className="modal-table">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allUsers.filter(users => users.isapproved).map(users => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            {users.companyName}
                                                        </td>
                                                        <td>
                                                            {users.email}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal show={cvsshow1} onHide={() => setCvsShow1(false)} className="whatsapp-modal modal-csv" centered>
                <Modal.Header className="p-0" closeButton>
                    <Modal.Title>
                        Pending Companies ({companiesInfo.pending})
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <Row>
                        <Col sm={12}>
                            <div className="modal-table">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allUsers.filter(users => !users.isapproved && !users.isdeleted).map(users => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            {users.companyName}
                                                        </td>
                                                        <td>
                                                            {users.email}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal show={expiredhow} onHide={() => setExpiredShow(false)} className="whatsapp-modal modal-csv" centered>
                {loading ? <div className="spinnerStyle">
                    <Spinner size="large" />
                </div> : null}
                <Modal.Header className="p-0" closeButton>
                    <Modal.Title>
                        Deleted Companies ({companiesInfo.expired})
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <Row>
                        <Col sm={12}>
                            <div className="modal-table company-listing-table">
                                <div className="table-responsive">
                                    <>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allUsers.filter(users => users.isdeleted).map(users => {
                                                    return (
                                                        <tr key={users._id} data-user-id={users._id}>
                                                            <td>
                                                                {users.companyName}
                                                            </td>
                                                            <td>
                                                                {users.email}
                                                            </td>
                                                            <td>
                                                                Deleted
                                                            </td>
                                                            <td>
                                                                <Button onMouseLeave={() => { setRestoreIcon(-1) }} onMouseEnter={() => { setRestoreIcon(users._id) }} className="btn-main responsive-btn"
                                                                    id={`custom-switch-${users._id}`}
                                                                    onClick={handleUnDelete} >
                                                                    <img src={restore_icon == users._id ? restoreIcon : restoreIcon1} /> Restore
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                        <>{
                                            companiesInfo.expired == 0 &&
                                            <div style={{ width: "100%", textAlign: "center" }}>
                                                No data to show
                                            </div>}
                                        </>
                                    </>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal show={showConfirmation} onHide={handleCancel} className="whatsapp-modal modal-csv" centered>
                <Modal.Header className="p-0" closeButton>
                    <Modal.Title>Confirm Approval Change</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0 pb-2">
                    <Row>
                        <Col sm={10}>
                            <p style={{ margin: '0', padding: '0', fontSize: '1em' }}>{confirmationMessage}</p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="px-0">
                    <Button className="btn-main-default" onClick={handleCancel} disabled={loading}>
                        Cancel
                    </Button>
                    <Button className="btn-main" onClick={handleProceed} disabled={loading}>
                        Proceed
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Companylisting;