import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, Spinner } from "react-bootstrap";
import { useState, useEffect, useContext, useRef } from "react";
import { useLocation } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";

import getconfig from "../config";

import {
  updateUser,
  updatePfp,
  getUserDetails,
  getPfp,
} from "../services/User";

import Forminput from "../components/forms/input";
import UserContext from "../store/user-context";
import { toast } from "react-toastify";

const Editprofile = () => {
  const { userState, setUserState } = useContext(UserContext);
  const { userId } = userState;
  const [updated, setUpdated] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [isShown, setIsShown] = useState(false);
  const [isHide, setIsHide] = useState(true);
  const location = useLocation();
  const [userData, setUserData] = useState({});
  const [password, setPassword] = useState({ old: "", new1: "", new2: "" });
  const [pfpExists, setPfpExists] = useState(false);
  const [userErrors, setUserErrors] = useState({
    firstname: { hasError: false, errorMessage: "" },
    lastname: { hasError: false, errorMessage: "" },
  });
  const [passwordErrors, setPasswordErrors] = useState({
    old: { hasError: false, errorMessage: "" },
    new1: { hasError: false, errorMessage: "" },
    new2: { hasError: false, errorMessage: "" },
  });
  const backendUrl = getconfig?.backend;
  // const backendUrl='http://localhost:5002/'

  const navigate = useNavigate();

  const validateInput = (name, value) => {
    // perform validation and return error message if there's an error, otherwise return null

    if (name === "firstname" && value !== "" && !/^[a-zA-Z ]+$/.test(value)) {
      return "First name should only contain letters and spaces.";
    }
    if (name === "firstname" && value == "") {
      return "First name is required";
    }

    if (name === "lastname" && value !== "" && !/^[a-zA-Z ]+$/.test(value)) {
      return "Last name should only contain letters and spaces.";
    }
    if (name === "lastname" && value == "") {
      return "Last name is required";
    }

    if (name === "old" && value.trim() === "") {
      return "Password is required.";
    }

    if (name === "new1" && value.length > 0 && value.length < 8) {
      return "Password should be at least 8 characters long.";
    }

    if (name === "new2" && value.length === 0 && password.new1.length > 0) {
      return "Confirm Password is required.";
    }

    if (name === "new2" && value !== password.new1) {
      return "Confirm Password does not match Password.";
    }
    // add more validation checks for other fields here

    return null;
  };

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
      setIsHide(false);
      setIsShown(true);
    } else if (!selectedImage && !imageUrl) {
      setIsHide(true);
      setIsShown(false);
    }
  }, [selectedImage]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await getUserDetails(userId);
        if (resp.success) {
          setUserData(resp.data);
        } else throw new Error(resp.error);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [updated]);

  const checkFileExists = async () => {
    try {
      const response = await getPfp();
      console.log(response);
      const companyId = localStorage.getItem("companyId");
      console.log(
        "url of image : ",
        `${backendUrl}uploads/${companyId}/pfp/${userId}/${response.filename}`
      );
      if (response.success) {
        setImageUrl(
          `${backendUrl}uploads/${companyId}/pfp/${userId}/${response.filename}`
        );
        setPfpExists(true);
        setIsShown(true);
        setIsHide(false);
      } else {
        console.log("File does not exist");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkFileExists();
  }, []);

  const removeDp = () => {
    if (selectedImage) {
      setSelectedImage(null);
      setImageUrl(null);
      checkFileExists();
    } else {
      setImageUrl(null);
      setIsShown(false);
      setIsHide(true);
    }
  };

  const inputRef = useRef(null);

  const handleEditClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  // console.log(userId)

  // console.log(imageUrl)
  // console.log("selected",selectedImage)
  // console.log("exists",pfpExists)
  // console.log("url",imageUrl)
  // console.log("uploadbtn",isHide)
  // console.log("editbtn",isShown)

  useEffect(() => {
    if (password.new1 == "" && password.new2 == "") {
      setPasswordErrors((prevState) => ({
        ...prevState,
        new1: { hasError: false, errorMessage: "" },
        new2: { hasError: false, errorMessage: "" },
      }));
    }
  }, [password]);

  const handleChange = (e) => {
    setUpdated(false);
    const { name, value } = e.target;
    const errorMessage = validateInput(name, value);
    setUserErrors((prevState) => ({
      ...prevState,
      [name]: {
        hasError: Boolean(errorMessage),
        errorMessage: errorMessage || "",
      },
    }));

    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePasswordChange = (e) => {
    setUpdated(false);
    const { name, value } = e.target;
    const errorMessage = validateInput(name, value);
    setPasswordErrors((prevState) => ({
      ...prevState,
      [name]: {
        hasError: Boolean(errorMessage),
        errorMessage: errorMessage || "",
      },
    }));

    setPassword((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const submit = async () => {
    // setIsShown(true);
    // setIsHide(false);
    setIsUpdating(true);
    console.log(userData);
    if (!password.old || password.old.length === 0) {
      setPasswordErrors((prevState) => ({
        ...prevState,
        ["old"]: {
          hasError: true,
          errorMessage: "Password is required" || "",
        },
      }));
      setIsUpdating(false);

      return;
    }

    let data = new FormData();
    if (selectedImage) {
      data.append("pfp", selectedImage);
    } else {
      if (imageUrl) {
        data.append("pfp", "unchanged");
      } else {
        data.append("pfp", "removed");
      }
    }
    data.append("userId", userId);
    //data.append("oldPassword", password.old)

    const newUpdatedData = {
      firstname: userData.firstname,
      lastname: userData.lastname,
      oldPassword: password.old,
      passwordChange: false,
      newPassword: "",
    };

    const errors = {};
    Object.keys(userData).forEach((name) => {
      const error = validateInput(name, userData[name]);
      if (error) {
        errors[name] = { hasError: true, errorMessage: error };
      } else {
        errors[name] = { hasError: false, errorMessage: "" };
      }
    });
    setUserErrors(errors);
    let hasError = false;
    Object.keys(errors).forEach((name) => {
      if (errors[name].hasError) {
        hasError = true;
      }
    });

    const errors1 = {};
    Object.keys(password).forEach((name) => {
      const error = validateInput(name, password[name]);
      if (error) {
        errors1[name] = { hasError: true, errorMessage: error };
      } else {
        errors1[name] = { hasError: false, errorMessage: "" };
      }
    });

    setPasswordErrors(errors1);
    let hasError1 = false;
    Object.keys(errors1).forEach((name) => {
      if (errors1[name].hasError) {
        hasError1 = true;
      }
    });

    if (hasError) {
      setIsUpdating(false);

      return;
    }

    if (password.new1 !== "" && password.new1 === password.new2) {
      newUpdatedData.passwordChange = true;
      newUpdatedData.newPassword = password.new1;
    }
    try {
      if (password.new1 === password.new2) {
        // const response = await updateUser({ userId, updatedData: newUpdatedData });
        // console.log(userData)
        // console.log(newUpdatedData)
        //data.append("userId", userId)
        data.append("firstname", newUpdatedData?.firstname);
        data.append("lastname", newUpdatedData?.lastname);
        data.append("oldPassword", newUpdatedData?.oldPassword);
        data.append(
          "passwordChange",
          newUpdatedData?.passwordChange ? "true" : "false"
        );
        data.append("newPassword", newUpdatedData?.newPassword);

        const response2 = await updatePfp(data);
        console.log("resp of user prof update : ", response2);
        checkFileExists();
        if (response2) {
          // navigate('/')
          toast.success("Profile Updated Successfully");
          setUserState((prevState) => ({
            ...prevState,
            updateUser: true,
          }));
          setUpdated(true);
        }
        // else if (response2.message == "Password not correct") {
        //     setPasswordErrors(prevState => ({
        //         ...prevState,
        //         old: {
        //             ...prevState.old,
        //             hasError: true,
        //             errorMessage: "Old password is incorrect"
        //         }
        //     }))
        // }
      }
    } catch (error) {
      setIsUpdating(false);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <section className="main edit-profile">
      <Container>
        <Row>
          <Col sm="12">
            <div className="form-container">
              <div className="form-header">
                <ul className="nav align-items-center">
                  <li className="nav-item">
                    <div
                      className="profile-img"
                      style={{ backgroundImage: imageUrl ? "none" : "" }}
                    >
                      {imageUrl && <img src={imageUrl} />}
                    </div>
                  </li>

                  {isHide && (
                    <li className="nav-item">
                      <div className="profile-img-upload">
                        <label>Upload Photo</label>
                        <input
                          accept="image/*"
                          type="file"
                          id="select-image"
                          onChange={(e) => setSelectedImage(e.target.files[0])}
                        />
                      </div>
                    </li>
                  )}

                  {isShown && (
                    <li className="nav-item">
                      <div className="action-list">
                        <div className="action-btn" onClick={handleEditClick}>
                          <i className="bi bi-pencil"></i>
                        </div>
                        <input
                          accept="image/*"
                          type="file"
                          id="select-image"
                          onChange={handleImageChange}
                          ref={inputRef}
                          style={{ display: "none" }}
                        />
                        <div
                          className="action-btn"
                          onClick={() => {
                            removeDp();
                          }}
                        >
                          <i className="bi bi-trash3"></i>
                        </div>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
              <div className="form-body">
                <div className="form-title">
                  <h1>Basic information</h1>
                </div>
                <Row className="mb-3 mb-lg-4">
                  <Col md={6}>
                    {/* <Forminput label={'First Name'} type='text' name="firstname" value={userData.firstname} onChange={handleChange} /> */}
                    <Form.Group className="form-group">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={userData.firstname}
                        name="firstname"
                        value={userData.firstname}
                        onChange={handleChange}
                        className={
                          userErrors.firstname.hasError ? "is-invalid" : ""
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {userErrors.firstname.hasError &&
                          userErrors.firstname.errorMessage}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    {/* <Forminput label={'Last Name'} type={'text'} name="lastname" value={userData.lastname} onChange={handleChange} /> */}
                    <Form.Group className="form-group">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={userData.lastname}
                        name="lastname"
                        value={userData.lastname}
                        onChange={handleChange}
                        className={
                          userErrors.lastname.hasError ? "is-invalid" : ""
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {userErrors.lastname.hasError &&
                          userErrors.lastname.errorMessage}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Forminput
                      label={"Company Name"}
                      type={"email"}
                      name="companyname"
                      value={userData.companyname}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col md={6}>
                    <Forminput
                      label={"Email"}
                      type={"email"}
                      name="email"
                      value={userData.email}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <div className="form-title">
                  <h1>Change Password</h1>
                  <p>
                    Leave "new password" and "confirm password" fields empty if
                    you are not updating password
                  </p>
                </div>
                <Row>
                  <Col md={6}>
                    {/* <Forminput label = {'Old Password *'} type = {'password'} /> */}
                    <Form.Group className="form-group">
                      <Form.Label>Old Password *</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter Old Password"
                        name="old"
                        value={password.old}
                        onChange={handlePasswordChange}
                        className={
                          passwordErrors.old.hasError ? "is-invalid" : ""
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {passwordErrors.old.hasError &&
                          passwordErrors.old.errorMessage}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    {/* <Forminput label = {'New Password *'} type = {'password'} /> */}
                    <Form.Group className="form-group">
                      <Form.Label>New Password </Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter New Password"
                        name="new1"
                        value={password.new1}
                        onChange={handlePasswordChange}
                        className={
                          passwordErrors.new1.hasError ? "is-invalid" : ""
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {passwordErrors.new1.hasError &&
                          passwordErrors.new1.errorMessage}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    {/* <Forminput label = {'Confirm Password *'} type = {'password'}/> */}
                    <Form.Group className="form-group">
                      <Form.Label>Confirm Password </Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Confirm new Password"
                        name="new2"
                        value={password.new2}
                        onChange={handlePasswordChange}
                        className={
                          passwordErrors.new2.hasError ? "is-invalid" : ""
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {passwordErrors.new2.hasError &&
                          passwordErrors.new2.errorMessage}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    {updated ? (
                      isUpdating ? (
                        <div
                          style={{ marginTop: "30px" }}
                          variant="main"
                          className="w-100"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{ color: "rgba(22, 94, 91, 1)" }}
                          />
                        </div>
                      ) : (
                        <Button
                          style={{ marginTop: "30px" }}
                          variant="main"
                          className="w-100"
                          onClick={() => {
                            submit();
                          }}
                        >
                          Update
                        </Button>
                      )
                    ) : isUpdating ? (
                      <div
                        style={{ marginTop: "30px" }}
                        variant="main"
                        className="w-100"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          style={{ color: "rgba(22, 94, 91, 1)" }}
                        />
                      </div>
                    ) : (
                      <Button
                        variant="main"
                        className="w-100"
                        onClick={() => {
                          submit();
                        }}
                      >
                        Update
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Editprofile;
