import axios from 'axios'
import config from '../../config'

const baseUrl = `${config.backend}contact/`

export const getAllContacts = async(data) => {
    const response = await axios.get(`${baseUrl}all`)
    return response.data
}

export const getSpecificContacts = async(id) => {
    const response = await axios.get(`${baseUrl}${id}`)
    return response.data
}

export const deleteContacts = async(id) => {
    const response = await axios.delete(`${baseUrl}${id}`)
    return response.data
}

export const updatetheContact = async(data) => {
    const response = await axios.put(`${baseUrl}updateContact`,data)
    return response.data
}

export const addContact = async(data) => {
    const response = await axios.post(`${baseUrl}add`, data)
    return response.data
}

export const addMultipleContacts = async(data) => {
    const response = await axios.post(`${baseUrl}add-multiple`, data)
    return response.data
}