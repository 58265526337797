import React , {ChangeEvent } from 'react'
import {Box} from '@mui/material';
import "./styles.css"

interface propsTypes {
    width?: string,
    height?: string,
    backgroundColor?: string,
    placeholder?: string,
    value?: string,
    disabled?:boolean,
    isTextBold?: Boolean,
    handleOnChange?: (val : string) => void
}

const InputField: React.FC<propsTypes> = (props) => {
    const handleChangeText = (event: ChangeEvent<HTMLInputElement>) => {
        console.log("(event.target.value", event.target.value)
        if(props?.handleOnChange != undefined){ // call function only if it is not undefined
            props?.handleOnChange(event.target.value);
        }
    };
  return (
    <>
       <Box
            sx={{
                height: props?.height ?? "38px",
                display : "flex",
                justifyContent : "center",
                alignItems : "center",
                borderRadius : "15px",
                width : props?.width ?? "300px",
                padding : 0,
                margin: 0,
            }}
            className="custom-input-container"
        >
            <input
                placeholder={props?.placeholder ?? "Placeholder ...."}
                value={props?.value}
                onChange={handleChangeText}
                className="custom-input-field"
                disabled={props?.disabled}
                style={{
                    backgroundColor : props?.backgroundColor ?? "#ffffff",
                    fontWeight : props?.isTextBold ? 600 : ""
                }}
            />
        </Box>
    </>
  )
}

export default InputField
