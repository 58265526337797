
import React from "react";

const TableRow = ({ style, data, clickRow, rowid, isCsvTable }) => {
    return (
        <tr style={style} className="broadcast-table-row" onClick={() => { clickRow(rowid) }}>
            {
                data?.map((item, index) => {
                    return <td>{item}</td>
                })

            }
        </tr>
    );
};

export default TableRow;