


const TemplateHeader = ({ TemplateName, TemplateDate, Badgetext, TemplateCreated, statusClass }) => {
    console.log("Badgetext", Badgetext)
    let finalClass = Badgetext == "PENDING" ? "in-review" : Badgetext == "APPROVED" ? "approved" : Badgetext == "REJECTED" ? "rejected" : Badgetext == "REJECTED" ? "rejected" : null
    return (
        <>
            <div className="template-header-mob">
                <div className="templete-name mb-1">
                    {TemplateName}
                </div>
                <div className="templated-created-date" style={{ fontSize: "14px" }} >
                    {TemplateDate}
                </div>
                <div className="d-flex align-items-center justify-content-between mt-3" style={{ width: "100%", justifyContent: "space-between", columnGap: "10px" }} >
                    <div className="status-show">
                        <span className={`status-badge ${finalClass}`} style={{ fontSize: "13px" }} >
                            {Badgetext}
                        </span>
                    </div>
                    <p className="templated-createdby" style={{ textAlign: "right", fontSize: "14px" }} >
                        Created By {TemplateCreated}
                    </p>
                </div>
            </div>
        </>
    );
};

export default TemplateHeader;