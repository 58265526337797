
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useState, useEffect, ReactNode, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Reciver from "../../images/icons/reciver.svg";
import Linkforward from "../../images/icons/link-forward.svg";
import { toast } from 'react-toastify'
import { createTemplate, updateTemplate } from "../../services/WhatsappAPI";
import { Spinner } from "react-bootstrap";
import { BasicTemplate } from "../../components/template-creation/BasicTemplate";
import { HeaderTemplate } from "../../components/template-creation/HeaderTemplate";
import { BodyTemplate } from "../../components/template-creation/BodyTemplate";
import { FooterTemplate } from "../../components/template-creation/FooterTemplate";
import { ButtonTemplate } from "../../components/template-creation/ButtonTemplate";
import { AuthenticationTemplate } from "../../components/template-creation/AuthenticationTemplate";
import { AuthenticationPreview } from "../../components/template-preview/AuthenticationPreview";
import { AuthenticationBuilder, RequestBuilder } from "../../utils/templateUtil";
import bgImg from "../../images/cover-bg.svg"
import {Box, IconButton, Typography, Button as MuiButton} from "@mui/material"
import {TemplateWithVariablesInHeader, TemplateWithHeaderImage, TemplateWithHeaderDocument, TemplateWithHeaderLocation, TemplateSimple , TemplateWithHeaderVideo} from "./components/templates.tsx"
import {TemplateCategory, TemplateNameSection , TemplateHeader , TemplateBody , TemplateVariables , TemplateFooter , TemplateActionButtons } from "./components/TemplateComponents.tsx"
import "./styles.css"

const Createtemplate = () => {
    const [headerData , setHeaderData] = useState({
        type : "none",
        fileType : "image",
        file : null,
        locationName : "",
        locLongitude : "",
        locLatitude : ""
    })
    const [ tempImgUrl , setTempImageUrl ] = useState(null)
    const [quickReply, setQuickReply] = useState(false);
    const [templateType, setTemplateType ] = useState("simple"); //  simple || location || document || image || simpleWIthTime
    const [calltoaction, setCalltoaction] = useState(false);
    const [success, setSuccess] = useState(false)
    const navigate = useNavigate()
    const location = useLocation();
    const [sending, setSending] = useState(false)
    const [formData, setFormData] = useState({
        name: "",
        header: "",
        body: "",
        footer: "",
        category: "UTILITY",
        language: "en_US"
    })
    const [callToActionData, setCallToActionData] = useState([{
        key: 1,
        action: "PHONE_NUMBER",
        text: "",
        country: "+92",
        number: "",
        urltype: "",
        websiteurl: ""
    }])
    const temp = {
        name: "",
        category: "",
        language: "en_US",
        allow_category_change: true
    }
    const [quickReplyData, setQuickReplyData] = useState([{ key: 1, data: "" }]);
    const [numQuickReplyButtons, setNumQuickReplyButtons] = useState(1)
    const [numcallToActionButtons, setNumcallToActionButtons] = useState(1)
    const [buttonType, setButtonType] = useState("None")
    const [tempData, setTempData] = useState({ language: null, button: { "value": "None", "label": "None" } });
    const [bodyVarCount, setbodyVarCount] = useState(0)
    const [headerVarCount, setHeaderVarCount] = useState(0)
    const [headerSample, setHeaderSample] = useState('')
    const [bodySample, setBodySample] = useState([])
    const [isEdit, setIsEdit] = useState(false);
    const authenticationPreviewForEdit = (template) => {
        const data = {
            id: template.id,
            isApproved: template.status == "APPROVED" ? true : false,
            category: template.category,
            codeExpiry: "",
            copyButton: "",
            footer: "",
            header: "",
            language: template.language,
            name: template.name,
            security: false
        }
        template.components.map(component => {
            switch (component.type) {
                case "BODY":
                    if (component.text.includes("For your security, do not share this code."))
                        data.security = true;
                    break;
                case "FOOTER":
                    const inputString = component.text;
                    var matchResult = inputString.match(/\b(\d+)\b/);
                    if (matchResult && matchResult.length > 0)
                        data.codeExpiry = matchResult[1];
                    break;
                case "BUTTONS":
                    component.buttons.map(btn => {
                        switch (btn.type) {
                            case "URL":
                                data.copyButton = btn.text
                                break;
                        }
                    })
            }
        })
        window.history.replaceState({}, document.title)
        setFormData(data);
    }
    // handle edit workflow
    useEffect(() => {
        if (location.state && location.state.isEdit) {

            setIsEdit(true)
            const template = location.state.template
            tempData.language = { label: template.language == 'en_US' ? "English (US)" : "English (UK)", value: template.language };
            setTempData(tempData)
            if (template.category == "AUTHENTICATION")
                return authenticationPreviewForEdit(template);
            const data = {
                id: template.id,
                isApproved: template.status == "APPROVED" ? true : false,
                name: template.name,
                category: template.category,
                language: template.language,
                header: "",
                body: "",
                footer: "",
            }
            const quickReplyForEdit = [];
            const callToActionArray = [];
            template.components.map(component => {
                switch (component.type) {
                    case "HEADER":
                        data.header = component.text;
                        break;
                    case "BODY":
                        data.body = component.text;
                        break;
                    case "FOOTER":
                        data.footer = component.text;
                        break;
                    case "BUTTONS":
                        component.buttons.map(btn => {
                            const callToAction = {
                                key: 1,
                                action: "PHONE_NUMBER",
                                text: "",
                                country: "+92",
                                number: "",
                                urltype: "",
                                websiteurl: ""
                            };
                            switch (btn.type) {
                                case "QUICK_REPLY":
                                    quickReplyForEdit.push({ key: quickReplyForEdit.length + 1, data: btn.text })
                                    break;
                                case "PHONE_NUMBER":
                                    callToAction.key = callToActionArray.length + 1;
                                    callToAction.action = "PHONE_NUMBER"
                                    callToAction.text = btn.text
                                    callToAction.number = btn.phone_number
                                    callToActionArray.push(callToAction);
                                    break;
                                case "URL":
                                    callToAction.key = callToActionArray.length + 1;
                                    callToAction.action = "visitwebsite"
                                    callToAction.urltype = btn.type
                                    callToAction.text = btn.text
                                    callToAction.websiteurl = btn.url
                                    callToActionArray.push(callToAction);
                                    break;
                            }
                        })
                        break;
                }
            })
            window.history.replaceState({}, document.title)
            if (quickReplyForEdit.length > 0) {
                setButtonType("quickreply");
                setQuickReplyData(quickReplyForEdit);
                setNumQuickReplyButtons(quickReplyForEdit.length)
                tempData.button = { value: 'quickreply', label: 'Quick Reply' }
                setTempData(tempData);

            } else if (callToActionArray.length > 0) {
                setButtonType("calltoaction");
                setCallToActionData(callToActionArray);
                setNumcallToActionButtons(callToActionArray.length);
                tempData.button = { value: 'calltoaction', label: 'Call To Action' }
                setTempData(tempData);
            }

            setFormData(data);
        }
    }, [])

    useEffect(() => {
        if (formData.header.indexOf("{{1}}") === -1) {
            setHeaderVarCount(0);
            setHeaderSample('')
        }
        if (bodyVarCount > 0 && formData.body.indexOf("{{" + bodyVarCount + "}}") === -1) {
            const updatedBodyVars = [...bodySample];
            updatedBodyVars.pop();
            setBodySample(updatedBodyVars);
            setbodyVarCount(prevbodyVarCount => prevbodyVarCount - 1)
        }
    }, [formData]);

    useEffect(() => {
        if (buttonType !== "quickreply" && !(location.state && location.state.isEdit)) {
            setQuickReplyData([{ key: 1, data: "" }]);
            setNumQuickReplyButtons(1)
        }
    }, [buttonType]);

    useEffect(() => {
        if (buttonType !== "calltoaction" && !(location.state && location.state.isEdit)) {
            setCallToActionData([{
                key: 1,
                action: "PHONE_NUMBER",
                text: "",
                country: "+92",
                number: "",
                urltype: "",
                websiteurl: ""
            }]);
            setNumcallToActionButtons(1)
        }
    }, [buttonType]);

    function handleChange(event) {
        if (event.target.name == "security") {
            setFormData(prevFormData => {
                return {
                    ...prevFormData,
                    [event.target.name]: event.target.checked
                }
            })
        } else {
            setFormData(prevFormData => {
                return {
                    ...prevFormData,
                    [event.target.name]: event.target.value
                }
            })
        }
    }

    function handleLanguage(selectedOption) {
        setTempData({ ...tempData, language: selectedOption });
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                language: selectedOption.value
            }
        })
    }

    function handleButton(selectedOption) {
        setTempData({ ...tempData, button: selectedOption });
        setButtonType(selectedOption.value)
    }

    const handleHeaderVarCount = () => {
        setHeaderVarCount(prevHeaderVarCount => prevHeaderVarCount + 1)
        setFormData(prevFormData => ({
            ...prevFormData,
            header: prevFormData.header + "{{" + (headerVarCount + 1) + "}}"
        }))
        return
    }

    const handlebodyVarCount = () => {
        setbodyVarCount(prevbodyVarCount => prevbodyVarCount + 1)
        setFormData(prevFormData => ({
            ...prevFormData,
            body: prevFormData.body + "{{" + (bodyVarCount + 1) + "}}"
        }))
        setBodySample(prevBodyVars => [...prevBodyVars, '']);
    }

    const handleCallToAction = (event, dataKey) => {
        setCallToActionData(prevData => {
            return prevData.map(item => {
                if (item.key === dataKey) {
                    return {
                        ...item,
                        [event.target.name]: event.target.value
                    };
                }
                return item;
            });
        })
    }

    const toggleAction = (dataKey) => {
        if (numcallToActionButtons > 1) {
            setCallToActionData(prevData => {
                const data = [...prevData];
                const action1 = data.find(d => d.key === 1).action;
                const action2 = data.find(d => d.key === 2).action;
                if (dataKey === 2) {
                    data.find(d => d.key === 1).action = action2 === "PHONE_NUMBER" ? "visitwebsite" : "PHONE_NUMBER";
                }
                if (dataKey === 1) {
                    data.find(d => d.key === 2).action = action1 === "PHONE_NUMBER" ? "visitwebsite" : "PHONE_NUMBER";
                }
                return data;
            });
        }
    };

    const AddCallToActionButton = () => {
        if (numcallToActionButtons < 2) {
            setNumcallToActionButtons(prevNumcallToActionButtons => prevNumcallToActionButtons + 1);
            setCallToActionData([...callToActionData, {
                key: numcallToActionButtons + 1,
                action: callToActionData[callToActionData.length - 1].action === "PHONE_NUMBER" ? "visitwebsite" : "PHONE_NUMBER",
                text: "",
                country: "+92",
                number: "",
                urltype: "",
                websiteurl: ""
            }]);
        }
    };

    const deleteCallToAction = (dataKey) => {
        if (numcallToActionButtons > 1) {
            setCallToActionData((prevState) =>
                prevState.filter((item) => item.key !== dataKey)
            );

            setCallToActionData((prevData) => prevData.map((item) => {
                if (item.key > dataKey) {
                    return { ...item, key: item.key - 1 }
                }
                return item
            }))
            setNumcallToActionButtons(prevNumcallToActionButtons => prevNumcallToActionButtons - 1)
        }
    };

    const handleQuickReply = (event, dataKey) => {
        setQuickReplyData(prevData => {
            return prevData.map(item => {
                if (item.key === dataKey) {
                    return { ...item, data: event.target.value };
                }
                return item;
            });
        });
    };

    const AddQuickReplyButton = () => {
        if (numQuickReplyButtons < 3) {
            setNumQuickReplyButtons(prevNumQuickReplyButtons => prevNumQuickReplyButtons + 1);
            setQuickReplyData([...quickReplyData, { key: numQuickReplyButtons + 1, data: "" }]);
        }
    };

    const deleteQuickReply = (dataKey) => {
        if (numQuickReplyButtons > 1) {
            setQuickReplyData((prevState) =>
                prevState.filter((item) => item.key !== dataKey)
            );

            setQuickReplyData((prevData) => prevData.map((item) => {
                if (item.key > dataKey) {
                    return { ...item, key: item.key - 1 }
                }
                return item
            }))

            setNumQuickReplyButtons(prevNumQuickReplyButtons => prevNumQuickReplyButtons - 1)
        }
    };

    function isLowerCaseAndUnderscores(str) {
        return /^[a-z_]+$/.test(str);
    }
    function isNumber(str) {
        return /^\d+$/.test(str);
    }

    const validateCharacterLimit = () => {
        if ((formData.name && formData.name.length > 60) ||
            (formData.header && formData.header.length > 60) ||
            (formData.body && formData.body.length > 1024) ||
            (formData.footer && formData.footer.length > 60)
        ) {
            toast.warning("Address the issue before proceeding further", { autoClose: 1500 })
            return false;
        }

        return true;
    }

    const validateForm = () => {
        let error = 0
        let errormessage = ""
        if (error === 0 && formData.name === "") {
            errormessage = "Name cannot be empty"
            error = 1
        }
        let x = isLowerCaseAndUnderscores(formData.name)
        if (error === 0 && x === false) {
            errormessage = "Name can only have lowercase and underscores"
            error = 1
        }
        if (error === 0 && (formData.header.startsWith("{{1}}") || formData.header.endsWith("{{1}}"))) {
            errormessage = "Variables cannot be at the start or end of header"
            error = 1
        } if (error === 0 && (formData.body.startsWith("{{1}}") || formData.body.startsWith("{{" + bodyVarCount + "}}") || formData.body.endsWith("{{" + bodyVarCount + "}}"))) {
            errormessage = "Variables cannot be at the start or end of body"
            error = 1
        }
        if (error === 0 && formData.body === "" && formData.category !== "AUTHENTICATION") {
            errormessage = "Body cannot be empty"
            error = 1
        }
        if (buttonType === "quickreply") {
            quickReplyData.map((item) => {
                if (item.data === "") {
                    errormessage = "Button text cannot be empty"
                    error = 1
                }
            })
        }
        if (buttonType === "calltoaction") {
            callToActionData.map((item) => {
                if (item.text === "") {
                    errormessage = "Button text cannot be empty"
                    error = 1
                }
                if (item.action === "PHONE_NUMBER") {
                    if (error === 0 && item.number === "") {
                        errormessage = "Phone number cannot be empty"
                        error = 1
                    }
                    let y = isNumber(item.number.substring(1, item.number.length))
                    if (error === 0 && y === false) {
                        errormessage = "Phone number can only be numeric"
                        error = 1
                    }
                }
                if (item.action === "visitwebsite") {
                    if (error === 0 && item.websiteurl === "") {
                        errormessage = "URL cannot be empty"
                        error = 1
                    }
                }
            })
        }


        if (error === 1) {
            toast.error(errormessage, { autoClose: 1500 })
            return false;
        }
        console.log('Template Submitted Sucessfully');
        return true;
    }

    const authenticationRequest = () => {
        const request = {
            ...temp,
            components: [
                {
                    type: "body",
                    add_security_recommendation: formData.security ? formData.security : false
                },
                {
                    type: "footer",
                    code_expiration_minutes: formData.codeExpiry
                },
                {
                    type: "buttons",
                    buttons: [
                        {
                            type: "otp",
                            otp_type: "copy_code",
                            text: formData.copyButton ?? ""
                        }
                    ]
                }
            ]
        }
        return request;
    }
    const updateRequest = async () => {

        if (!validateForm() || !validateCharacterLimit()) {
            return;
        }
        setSending(true)
        let request = {};
        if (formData.category === "AUTHENTICATION") {
            request = new AuthenticationBuilder(location.state.template, formData).withBody().withFooter().withButtons().build();
        } else {
            request = new RequestBuilder(location.state.template, formData).withHeader().withBody().withFooter().withButtons(buttonType, buttonType === "calltoaction" ? callToActionData : quickReplyData).build();
        }
        request.id = location.state.template.id;
        if (location.state.template.status !== "APPROVED") {
            request.category = formData.category;
            request.allow_category_change = true;

        }
        console.log(request)
        const res = await updateTemplate(request)
        if (res.success) {
            setSuccess(true)
            toast.success('Template Updated')
            setTimeout(() => {
                navigate('/template')
            }, 2000);
        }
        else {
            toast.error(res.error)
        }
        setSending(false)
        console.log(res)

    }

    const createRequest = async () => {

        if (!validateForm() || !validateCharacterLimit()) {
            return;
        }
        setSending(true)
        temp.name = formData.name
        temp.category = formData.category
        temp.language = formData.language;
        let request;
        if (formData.category == "AUTHENTICATION")
            request = authenticationRequest(temp);
        else {
            request = {
                ...temp,
                components: [
                    { type: "BODY", text: formData.body },
                    formData.header !== "" ? { type: "HEADER", format: "TEXT", text: formData.header } : {},
                    formData.footer !== "" ? { type: "FOOTER", text: formData.footer } : {},
                    buttonType === "calltoaction" ? {
                        type: "BUTTONS",
                        buttons: callToActionData.map(item => {
                            if (item.action === "PHONE_NUMBER") {
                                return {
                                    type: "PHONE_NUMBER",
                                    text: item.text,
                                    phone_number: item.number
                                };
                            } else if (item.action === "visitwebsite") {
                                return {
                                    type: "url",
                                    text: item.text,
                                    url: item.websiteurl
                                };
                            }
                        })
                    }
                        : {},
                    buttonType === "quickreply" ? {
                        type: "BUTTONS",
                        buttons: quickReplyData.map(item => ({
                            type: "QUICK_REPLY",
                            text: item.data
                        }))
                    }
                        : {}
                ]
                    .filter(c => Object.keys(c).length !== 0)
            }
        }
        console.log(request)
        const res = await createTemplate(request)
        if (res.success) {
            setSuccess(true)
            toast.success('Template created')
            setTimeout(() => {
                navigate('/template')
            }, 2000);
        }
        else {
            toast.error(res.error)
        }
        setSending(false)
        console.log(res)

    }

    const handleHeaderSample = (e) => {
        setHeaderSample(e.target.value)
    }

    const handleBodySample = (event, index) => {
        const updatedBodyVars = [...bodySample];
        updatedBodyVars[index] = event.target.value;
        setBodySample(updatedBodyVars);
    };

    // get specific template
    const getSpecificTemplate = (value: string ): ReactNode => {
        // switch(value){
        //     case "location":
        //         return <TemplateWithHeaderLocation />
        //     case "simpleWIthTime":
        //         return <TemplateWithVariablesInHeader  />
        //     case "image":
        //         return <TemplateWithHeaderImage formData={formData} headerData={headerData} image={tempImgUrl} />
        //     case "document":
        //         return <TemplateWithHeaderDocument />
        //     case "video":
        //         return <TemplateWithHeaderVideo />
        //     default:
        //         return <TemplateWithHeaderAndText />
        // }

        switch(value){
            case "text":
                return <TemplateSimple formData={formData} />
            case "image":
                return <TemplateWithHeaderImage buttonType={buttonType} quickReply={quickReply} formData={formData} headerData={headerData} image={tempImgUrl} />
            case "video":
                return <TemplateWithHeaderVideo formData={formData}  />
            case "document":
                return <TemplateWithHeaderDocument formData={formData}  />
            case "location":
                return <TemplateWithHeaderLocation formData={formData}  />
            default:
                return <TemplateWithVariablesInHeader formData={formData}  />
        }
    }

    // type of header selected
    const getHeaderType = (value) => {
        switch(value){
            case "text":
                return "text"
            case "media":
                if(headerData?.fileType == "image")
                    return "image"
                else if (headerData?.fileType == "video")
                    return "video"
                else if (headerData?.fileType == "document")
                    return "document"
                return "location"
            default:
                return "simple"
        }
    }

    useMemo(() => {
        getSpecificTemplate(getHeaderType(headerData?.type))
    },[formData,headerData])

    console.log("formdata")

    return (
        <>
            <Box className="bg-[#ecf1f9] flex justify-start align-items-start w-100 flex-column pt-[18px] h-[auto] overflow-auto" sx={{ paddingBottom : "50px"}}  >
                <Box className="flex justify-start align-items-start w-100 "  >
                    <Container>
                        <Row>
                            <Col md={8} className="mb-3 mb-md-0">
                                <TemplateCategory formData={formData} handleChange={handleChange} />
                                <TemplateNameSection  isEdit={isEdit} handleChange={handleChange} formData={formData} handleLanguage={handleLanguage} tempData={tempData} />
                                <TemplateHeader setTempImageUrl={setTempImageUrl} isEdit={isEdit} formData={formData} handleChange={handleChange} headerData={headerData} setHeaderData={setHeaderData} />
                                <TemplateBody handleChange={handleChange} formData={formData} handlebodyVarCount={handlebodyVarCount} bodySample={bodySample} bodyVarCount={bodyVarCount} handleBodySample={handleBodySample} />
                                {/* <TemplateVariables /> */}
                                <TemplateFooter formData={formData} handleChange={handleChange} />
                                <TemplateActionButtons tempData={tempData} handleButton={handleButton} buttonType={buttonType} quickReplyData={quickReplyData} handleQuickReply={handleQuickReply} handleCallToAction={handleCallToAction} deleteQuickReply={deleteQuickReply} numQuickReplyButtons={numQuickReplyButtons} numcallToActionButtons={numcallToActionButtons} AddQuickReplyButton={AddQuickReplyButton} AddCallToActionButton={AddCallToActionButton} callToActionData={callToActionData} deleteCallToAction={deleteCallToAction} toggleAction={toggleAction} />

                                <Box className="flex justify-start items-start mt-[18px] w-100 d-none d-md-block ">
                                    <MuiButton variant="contained" disableRipple sx={{background : "#165E5B", color : "#FFFFFF", borderRadius : "5px", width : "100%", height : "45px", textTransform : "none", boxShadow: 'none', fontWeight : 600, fontSize : "16px", fontFamily : "Segoe UI" , '&:hover' : {backgroundColor : "#165E5B"} }} >Create Template</MuiButton>
                                </Box>
                            </Col>

                            <Col md={4} className="mb-3 mb-md-0">
                                <Box className={`flex justify-center items-center flex-column w-100 rounded-lg p-[16px] bg-[#EFEAE2]`} sx={{background : `url(${bgImg})`}} >
                                    <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[14px] lg:text-[16px] font-medium leading-[22px] text-[#000000]" >Message preview on WhatsApps</Typography>
                                    {getSpecificTemplate(getHeaderType(headerData?.type))}

                                    {/* <TemplateSimple />
                                    <TemplateWithHeaderImage formData={formData} headerData={headerData} image={tempImgUrl} />
                                    <TemplateWithHeaderVideo />
                                    <TemplateWithHeaderDocument />
                                    <TemplateWithHeaderLocation />
                                    <TemplateWithVariablesInHeader  formData={formData} headerData={headerData} image={tempImgUrl} /> */}
                                </Box>

                                <Box className="flex justify-start items-start mt-[18px] w-100 d-block d-md-none ">
                                    <MuiButton variant="contained" disableRipple sx={{background : "#165E5B", color : "#FFFFFF", borderRadius : "5px", width : "100%", height : "45px", textTransform : "none", boxShadow: 'none', fontWeight : 600, fontSize : "16px", fontFamily : "Segoe UI" , '&:hover' : {backgroundColor : "#165E5B"} }} >Create Template</MuiButton>
                                </Box>
                            </Col>
                        </Row>
                    </Container>
                </Box>
            </Box>
        </>
    )

    return (
        <>
            <section className="main inner-main">
                <section className="contact-group-top">
                    <Container>
                        <Row>
                            <Col md={7} lg={8} className="mb-3 mb-md-0">
                                <div className="template-header mb-3">
                                    <div className="template-heading">
                                        <Link to="/template"><i className="bi bi-caret-left-fill"></i>Templates </Link> / Create new template
                                    </div>
                                </div>

                                <div className="create-template-form">
                                    <BasicTemplate isEdit={isEdit} handleChange={handleChange} formData={formData} handleLanguage={handleLanguage} tempData={tempData} />
                                    {formData.category !== "AUTHENTICATION" && <HeaderTemplate formData={formData} handleChange={handleChange} handleHeaderSample={handleHeaderSample} handleHeaderVarCount={handleHeaderVarCount} headerSample={headerSample} headerVarCount={headerVarCount} />}
                                    {formData.category !== "AUTHENTICATION" && <BodyTemplate handleChange={handleChange} formData={formData} handlebodyVarCount={handlebodyVarCount} bodySample={bodySample} bodyVarCount={bodyVarCount} handleBodySample={handleBodySample} />}
                                    {formData.category !== "AUTHENTICATION" && <FooterTemplate handleChange={handleChange} formData={formData} />}
                                    {formData.category !== "AUTHENTICATION" && <ButtonTemplate tempData={tempData} handleButton={handleButton} buttonType={buttonType} quickReplyData={quickReplyData} handleQuickReply={handleQuickReply} handleCallToAction={handleCallToAction} deleteQuickReply={deleteQuickReply} numQuickReplyButtons={numQuickReplyButtons} numcallToActionButtons={numcallToActionButtons} AddQuickReplyButton={AddQuickReplyButton} AddCallToActionButton={AddCallToActionButton} callToActionData={callToActionData} deleteCallToAction={deleteCallToAction} toggleAction={toggleAction} />}
                                    {formData.category == "AUTHENTICATION" && <AuthenticationTemplate formData={formData} handleChange={handleChange} />}
                                    {success && (
                                        <Row>
                                            <Col sm={12}>
                                                <div style={{ margin: '20px 5px', color: '#165E5B', fontWeight: '500' }}>
                                                    Template created successfully ✅
                                                </div>
                                            </Col>
                                        </Row>
                                    )}


                                </div>
                                <Row className="pt-3">
                                    <Col sm={12} className="text-end">
                                        {isEdit ?
                                            <button className="btn btn-main" onClick={updateRequest} disabled={sending}>
                                                {sending ? <Spinner size="sm" /> : "Update Template"}
                                            </button> :
                                            <button className="btn btn-main" onClick={createRequest} disabled={sending}>
                                                {sending ? <Spinner size="sm" /> : "Create Template"}
                                            </button>
                                        }
                                    </Col>
                                </Row>

                            </Col>
                            <Col md={5} lg={4}>
                                <div className="template-header mb-3">
                                    <div className="template-heading">
                                        Preview
                                    </div>
                                </div>
                                {formData.category == "AUTHENTICATION" && <AuthenticationPreview formData={formData} />}
                                {formData.category !== "AUTHENTICATION" && <div className="preview-sec">
                                    <div className="preview-body">
                                        <div className="preview-screen">
                                            {formData.header !== '' && (<div className="heading">
                                                {formData.header}
                                            </div>)}
                                            {formData.body !== '' && (<div className="content">
                                                {formData.body}
                                            </div>)}
                                            {formData.footer !== '' && (<div className="content" style={{ opacity: '70%', fontSize: '12px' }} >
                                                {formData.footer}
                                            </div>)}
                                        </div>
                                        {buttonType == "calltoaction" && (<div className="link-footer px-0">


                                            {callToActionData[0].action === "PHONE_NUMBER" && (
                                                <div>
                                                    {callToActionData[0].text !== '' && <a href="#"><img src={Reciver} alt="icon" />{callToActionData[0].text}</a>}
                                                    {numcallToActionButtons > 1 && callToActionData[1].text !== '' && (<a href="#"><img src={Linkforward} alt="icon" />{callToActionData[1].text}</a>)}
                                                </div>
                                            )}

                                        </div>)}
                                    </div>


                                    {buttonType == "quickreply" && (<Row className="event-time">
                                        {quickReplyData[0].data !== "" && <Col xs={6} className="pe-1">
                                            <div className="e-time start-time">
                                                {quickReplyData[0].data}
                                            </div>
                                        </Col>}

                                        {numQuickReplyButtons > 1 && quickReplyData[1].data !== "" && <Col xs={6} className="ps-1">
                                            <div className="e-time end-time">
                                                {quickReplyData[1].data}
                                            </div>
                                        </Col>}

                                        {numQuickReplyButtons > 2 && quickReplyData[2].data !== "" && <div className="event-time">
                                            <Col xs={12} className="pe-0">
                                                <div className="e-time">
                                                    {quickReplyData[2].data}
                                                </div>
                                            </Col>
                                        </div>}

                                    </Row>)}
                                </div>}
                            </Col>
                        </Row>
                    </Container>
                </section>
            </section>
        </>
    )
}

export default Createtemplate;