

import Table from "react-bootstrap/Table";
import TemplateAction from "./template-action";
import TemplateDetails from "./template-details";
import TemplateStatus from "./template-status";
import './css/template-listing-table.css';
import moment from "moment";

const Listingtable = ({ Templates, onChildClick, templates, onClickDelete, showDeleteModal, setShowDeletModal, isDeleting, editTemplate, setSelIndex, selIndex, activeTempIcon, templateData }) => {
    console.log("templates", templates)
    return (
        <>
            <div className="table-responsive template-recordtable">
                <Table hover className="listing-table mb-0">
                    <thead>
                        <tr>
                            <th style={{ fontSize: "14px", fontWeight: 500, fontFamily: "Segoe UI" }} >Template Name</th>
                            <th style={{ fontSize: "14px", fontWeight: 500, fontFamily: "Segoe UI" }} >Status</th>
                            <th style={{ fontSize: "14px", fontWeight: 500, fontFamily: "Segoe UI" }} >Language</th>
                            <th style={{ fontSize: "14px", fontWeight: 500, fontFamily: "Segoe UI" }} >Category</th>
                            <th style={{ fontSize: "14px", fontWeight: 500, fontFamily: "Segoe UI" }} >Updated Date</th>
                            <th style={{ fontSize: "14px", fontWeight: 500, fontFamily: "Segoe UI" }} >Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {templates.map((template, index) => (
                            <tr key={index}>
                                <td>
                                    <TemplateDetails Eventname={template.name} EventCreatedBy={template.createdBy} />
                                </td>
                                <td><TemplateStatus statustext={template.status} statusClass={template.statusclass} /></td>
                                <td style={{ fontSize: "14px" }} > {template.language.toUpperCase()}</td>
                                <td style={{ fontSize: "14px" }}>{template.category.charAt(0).toUpperCase() + template.category.slice(1).toLowerCase()}</td>
                                <td style={{ fontSize: "14px" }}>{moment(template.updatedAt).format("MMM-DD-YYYY")}</td>
                                <td>
                                    <TemplateAction templates={templates} templateData={templateData} activeTempIcon={activeTempIcon} editTemplate={() => editTemplate(template)} isDeleting={isDeleting} onChildC={() => onChildClick(template)} selIndex={selIndex} showDeleteModal={showDeleteModal} setShowDeletModal={setShowDeletModal} setSelIndex={(ind) => setSelIndex(ind)} onClickDelete={(name) => onClickDelete(name)} index={index} template={templates[selIndex]} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </>
    );
};

export default Listingtable;