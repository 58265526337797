import React, { Fragment } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useContext } from "react";

import UserContext from "./store/user-context";

import Header from "./components/header/Header";
import Login from "./pages/login";
import Reset from "./pages/resetpassword";
import Signup from "./pages/signup";
import Contact from "./pages/contact";
import Group from "./pages/group";
import Faq from "./pages/faq";
import Subscription from "./pages/subscription";
import Editprofile from "./pages/editprofile";
import Broadcast from "./pages/broadcast";
import Inbox from "./pages/Inbox";
import Template from "./pages/template";
import Createtemplate from "./pages/createTemplate";
import Companylisting from "./pages/company_listing";
import Emptychat from "./pages/empty-chat";
import Userlisting from "./pages/user_listing";
import Resetpass from "./pages/reset-password";
import FacebookSignup from "./pages/facebook-signup";
import Do2FA from "./pages/do2FA";
import Register2FA from "./pages/register2FA";
import NotFound from "./pages/unauthorized";
import Tags from "./pages/tags/index.tsx";
import CreateTemplateNew from "./pages/templates/index.tsx";
import TemplateListing from "./pages/template-listing.js";
import UsersListing from "./pages/users-listing.js";
import Widgets from "./pages/widgets.js";
import WidgetCreation from "./pages/widget-creation.js";

import { DataContextProvider } from "./store/data-context";
import { toast } from "react-toastify";
import { Button, Container } from "react-bootstrap";
import { PrivacyPolicy } from "./pages/privacy_policy";
import { TermsAndCondition } from "./pages/terms_and_condition";
import { RegisterBusinessOnMeta } from "./pages/register_business_on_meta";
import { logOutUser } from "./services/User";
import DataContext from "./store/data-context";

function Router({ socket, setSokectConnection, setSocket }) {
  const {
    userState: {
      isAuth,
      superAdmin,
      companyAdmin,
      companyId,
      mfaCompleted,
      mfaDone,
    },
    setUserState,
  } = useContext(UserContext);
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  console.log("params", pathname);
  const logOutBtnHandler = async (showToast = false) => {
    const token = localStorage.getItem("token");

    const response = await fetch(
      "https://utter-api.mwancloud.com/notification/unregister",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Replace axios header with fetch equivalent
        },
      }
    );
    const resp = await logOutUser({ userId: localStorage.getItem("userId") });
    window.location.href = "/login";
    if (showToast)
      // toast.success('User logged out successfully')

      socket?.emit("userDisconnect", companyId);
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("companyId");
    setSocket(null);

    setUserState({
      isAuth: false,
      token: null,
      userId: null,
      superAdmin: false,
      companyAdmin: false,
      companyId: null,
      mfaCompleted: false,
      mfaDone: false,
    });
  };

  useEffect(() => {
    if (companyId && socket == null && mfaDone) {
      console.log("mfaDone", mfaDone);
      setSokectConnection();
    }
  }, [socket, companyId, setSokectConnection, mfaDone]);

  useEffect(() => {
    setIsLoaded(false);
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const superadmin = localStorage.getItem("superAdmin");
    const companyadmin = localStorage.getItem("superAdmin");
    const companyid = localStorage.getItem("companyId");
    const mfaCompleted = localStorage.getItem("mfaCompleted");
    const mfaDone = localStorage.getItem("mfaDone");
    if (!token || !userId) {
      logOutBtnHandler();
      setIsLoaded(true);
      return;
    }
    setUserState({
      isAuth: true,
      token: token,
      userId: userId,
      superAdmin: superadmin,
      companyAdmin: companyadmin,
      companyId: companyid,
      updateUser: false,
      mfaCompleted: mfaCompleted,
      mfaDone: mfaDone,
    });
    setIsLoaded(true);
  }, [setUserState]);

  const isSuperAdmin = localStorage.getItem("superAdmin");
  const isCompanyAdmin = localStorage.getItem("companyAdmin");

  const validRoutes = [
    "/inbox",
    "/contact",
    "/group",
    "/faq",
    "/subscription",
    "/editprofile",
    "/broadcast",
    "/template",
    "/templateNew",
    "/createTemplate",
    "/createTemplateNew",
    "/privacy-policy",
    "/terms-and-condition",
    "/tags",
    "/users",
    "/widgets",
    "/widget-creation",
  ];

  return (
    <Fragment>
      {isLoaded ? (
        <Routes>
          {isAuth ? (
            localStorage.getItem("mfaCompleted") == "false" ? (
              <Route
                path="/"
                element={
                  <DataContextProvider>
                    <Header
                      logOutBtnHandler={logOutBtnHandler}
                      loggedout={true}
                      admin={true}
                      authScreen={true}
                    />
                  </DataContextProvider>
                }
              >
                <Route path="/" element={<Register2FA />} />
              </Route>
            ) : localStorage.getItem("mfaDone") == "false" ? (
              <Route
                path="/"
                element={
                  <DataContextProvider>
                    <Header
                      logOutBtnHandler={logOutBtnHandler}
                      loggedout={true}
                      admin={true}
                      authScreen={true}
                    />
                  </DataContextProvider>
                }
              >
                <Route path="/" element={<Do2FA />} />
              </Route>
            ) : localStorage.getItem("superAdmin") == "false" ? (
              localStorage.getItem("companyAdmin") == "false" ? (
                <Route
                  path="/"
                  element={
                    <DataContextProvider>
                      <Header logOutBtnHandler={logOutBtnHandler} />
                    </DataContextProvider>
                  }
                >
                  {/* <Route path='/' element={isSuperAdmin == "true" || isCompanyAdmin == "true" ? <Userlisting /> : <Inbox />} /> */}
                  <Route path="/" element={<Inbox />} />
                  <Route path="/inbox" element={<Inbox />} />
                  <Route path="/inbox/:id" element={<Inbox />} />

                  <Route path="/contact" element={<Contact />} />
                  <Route path="/group" element={<Group />} />
                  <Route path="/faq" element={<Faq />} />
                  <Route path="/subscription" element={<Subscription />} />
                  <Route path="/editprofile" element={<Editprofile />} />
                  <Route path="/broadcast" element={<Broadcast />} />
                  <Route path="/template" element={<TemplateListing />} />
                  <Route path="/templateNew" element={<TemplateListing />} />
                  <Route path="/createTemplate" element={<Createtemplate />} />
                  <Route
                    path="/createTemplateNew"
                    element={<CreateTemplateNew />}
                  />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route
                    path="/terms-and-condition"
                    element={<TermsAndCondition />}
                  />
                  <Route path="/tags" element={<Tags />} />
                  <Route
                    path="/users"
                    element={
                      <UsersListing logOutBtnHandler={logOutBtnHandler} />
                    }
                  />
                  <Route path="/widgets" element={<Widgets />} />
                  <Route path="/widget-creation" element={<WidgetCreation />} />
                </Route>
              ) : (
                <Route
                  path="/"
                  element={
                    <DataContextProvider>
                      <Header
                        logOutBtnHandler={logOutBtnHandler}
                        admin={true}
                        cAdmin={true}
                      />
                    </DataContextProvider>
                  }
                >
                  {/* <Route path='/' element={isSuperAdmin == "true" || isCompanyAdmin == "true" ? <Userlisting /> : <Inbox />} /> */}
                  <Route path="/" element={<Inbox />} />
                  <Route path="/inbox" element={<Inbox />} />
                  <Route path="/inbox/:id" element={<Inbox />} />

                  <Route path="/contact" element={<Contact />} />
                  <Route path="/group" element={<Group />} />
                  <Route path="/faq" element={<Faq />} />
                  <Route path="/subscription" element={<Subscription />} />
                  <Route path="/editprofile" element={<Editprofile />} />
                  <Route path="/broadcast" element={<Broadcast />} />
                  <Route path="/template" element={<TemplateListing />} />
                  <Route path="/templateNew" element={<TemplateListing />} />
                  <Route path="/createTemplate" element={<Createtemplate />} />
                  <Route
                    path="/createTemplateNew"
                    element={<CreateTemplateNew />}
                  />
                  <Route path="/user_listing" element={<Userlisting />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route
                    path="/terms-and-condition"
                    element={<TermsAndCondition />}
                  />
                  <Route path="/tags" element={<Tags />} />
                  <Route
                    path="/users"
                    element={
                      <UsersListing logOutBtnHandler={logOutBtnHandler} />
                    }
                  />
                  <Route path="/widgets" element={<Widgets />} />
                  <Route path="/widget-creation" element={<WidgetCreation />} />
                </Route>
              )
            ) : (
              <Route
                path="/"
                element={
                  <DataContextProvider>
                    <Header
                      logOutBtnHandler={logOutBtnHandler}
                      loggedout={true}
                      admin={true}
                    />
                  </DataContextProvider>
                }
              >
                <Route
                  path="/"
                  element={
                    isSuperAdmin == "true" || isCompanyAdmin == "true" ? (
                      <Companylisting />
                    ) : (
                      <Inbox />
                    )
                  }
                ></Route>
                <Route path="/company_listing" element={<Companylisting />} />
                <Route path="/editprofile" element={<Editprofile />} />
                <Route path="/faq" element={<Faq />} />
              </Route>
            )
          ) : (
            <Route path="/" element={<Header loggedout={true} />}>
              <Route path="/" element={<Login />}></Route>
              <Route path="/login" element={<Login />} />
              <Route path="/resetpassword" element={<Reset />} />
              <Route path="/reset-password" element={<Resetpass />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/facebook-signup" element={<FacebookSignup />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/terms-and-condition"
                element={<TermsAndCondition />}
              />
              {/* <Route path="/register_business_on_meta" element={<RegisterBusinessOnMeta />} /> */}
            </Route>
          )}
          {isAuth ? (
            <Route path="*" element={<NotFound />} />
          ) : validRoutes.includes(pathname) ? (
            navigate("/login")
          ) : (
            <Route path="*" element={<NotFound />} />
          )}
        </Routes>
      ) : (
        <></>
      )}
    </Fragment>
  );
}

export default Router;
