import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Decimal from "decimal.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Spinner } from "react-bootstrap";
import { Box, IconButton, Pagination } from "@mui/material";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Modaltable from "../components/modal-table/mTable";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import { TbArrowBarToDown } from "react-icons/tb";
import { Table } from "antd";
import {
  getAllContacts,
  addContact,
  addMultipleContacts,
  deleteContacts,
  searchContact,
} from "../services/Contacts/index";
import { updatetheContact } from "../services/Contacts/index";
import Companyactionbtn from "../components/company-listing/compnay-actionbtn";
import Papa from "papaparse";
import WindowWidthDetector from "../components/customHooks/DeviceWidthDetector";
import moment from "moment";
import ContactgroupTable from "../components/contact-table/c-table";
import Search from "../components/search/search";
import { getUserDetails } from "../services/User";
import { toast } from "react-toastify";
import Select from "react-select";
import { getAllGroups } from "../services/Group";
import { exportToExcel } from "../utils/exportToCsv";
import { countryCodeList } from "../utils/countryCodeList";
import TableSkeleton from "../components/skeletons/TableLoader";
import { Typography } from "@mui/material";
import ContactIcon from "../images/contact-icon.svg";
import UploadIcon from "../images/upload-icon.svg";
import InvalidIcon from "../images/invalid-contact.svg";
import AddIcon from "../images/contact-mobile-add-icon.svg";

import Wtable from "../components/table/table";
import ContactMobile from "../components/contact-table/ContactMobile";
import { setContactsData } from "../reduxSlice/contacts";

const Contact = () => {
  const { allContacts, totalRecords } = useSelector(
    (state) => state.ContactsReducer
  );
  const dispatch = useDispatch();
  console.log("allContacts", allContacts);
  const width = WindowWidthDetector();
  const [ctshow, setCtShow] = useState(false);
  const [cvsshow, setCvsShow] = useState(false);
  const [inValidContacs, setAllInValidContacts] = useState([]);
  const [isInvalidRecords, setIsInvalidRecords] = useState(false);
  const [csvError, setCsvError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [newContactData, setNewContactData] = useState({
    contactName: "",
    number: "",
    canMessage: true,
    canBroadcast: true,
  });
  const [fileName, setFileName] = useState("");
  const [fileData, setFileData] = useState(null);

  const [fileUploaded, setFileUploaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({
    totalPages: totalRecords ? totalRecords : 1,
    currPage: 1,
  });
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [contactsGroup, setContactsGroup] = useState([]);
  const [isNewContactAdded, setIsNewContactAdded] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [duplicateNames, setDuplicateNames] = useState([]);
  const [invalidRecordsLength, setInvalidRecordsLength] = useState(0);
  const [duplicateNumbers, setDuplicateNumbers] = useState([]);
  const [inserted, setInserted] = useState([]);
  const [allOrgnlRecords, setOrgRecords] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [updateContacts, setUpdateContacts] = useState(false);
  const [editContact, setEditContact] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [searchText, setSearchText] = useState(""); // Filter by search text

  const [inputErrors, setInputErrors] = useState({
    contactName: { hasError: false, errorMessage: "" },
    number: { hasError: false, errorMessage: "" },
  });
  const numericRegex = /^[0-9]+$/;
  // const nameRegex = /^[A-Za-z0-9]+(?:[-' ][A-Za-z0-9]+)*$/;
  const nameRegex = /^(?!\s*$).+/;
  const [searchQuery, setSearchQuery] = useState("");
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const inputRef = useRef(null);
  const [currPage, setCurrPage] = useState(1);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to update screenWidth state
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", updateScreenWidth);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  const handleClick = () => {
    inputRef.current.click();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // setLoading(true)
        const resp = await getAllGroups(1, 1000);
        const groupData = [];
        if (resp.success) {
          resp.data.map((data) => {
            groupData.push({ value: data._id, label: data.groupName });
          });
        }
        setGroups(groupData);
      } catch (error) {
        console.log("Failed to fetch Groups!");
      }
    };
    fetchData();
  }, []);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputErrors((prevState) => ({
      ...prevState,
      [name]: { hasError: false, errorMessage: "" },
    }));
    setNewContactData((prevValues) => ({ ...prevValues, [name]: value }));

    setSelectedContact((prevValues) => ({ ...prevValues, [name]: value }));
  };

  useEffect(() => {
    if (!ctshow) {
      setNewContactData({
        contactName: "",
        number: "",
        canMessage: true,
        canBroadcast: true,
      });
      setInputErrors({
        contactName: { hasError: false, errorMessage: "" },
        number: { hasError: false, errorMessage: "" },
      });
    }
  }, [ctshow]);

  useEffect(() => {
    if (!isInvalidRecords) {
      setIsAdding(false);
      setFileData(null);
      setFileName("");
    }
  }, [isInvalidRecords]);

  useEffect(() => {
    if (!cvsshow) {
      setContactsGroup([]);
    }
  }, [cvsshow]);

  // setting data of contacts
  const setAllContactsData = (contactsData, records = totalRecords) => {
    console.log("data of contacts got :", contactsData);

    // Set original data (raw contacts)
    setOrgData(contactsData);

    // Transform the data for the table and update contacts
    const transformedData = handleDataForTable(contactsData);
    setContacts(transformedData);
    setOrgRecords(transformedData);

    // Dispatch the contacts data along with total records to Redux store
    dispatch(setContactsData({ data: contactsData, totalRecords: records }));
  };

  // const fetchAllContacts = async (page = 1, pageSize = 10) => {
  //   setLoading(true);
  //   try {
  //     let contactsData = [],
  //       newTotalRecords = 0;
  //     let finalPageSize = pageSize ? pageSize : paginationData?.currPage;
  //     let totalPages =
  //       paginationData?.totalPages > 0 ? paginationData?.totalPages : 10000000;
  //     let diff = finalPageSize > 1 ? finalPageSize * 10 - totalPages : 0;
  //     finalPageSize =
  //       finalPageSize * 10 > totalPages
  //         ? finalPageSize * 10 - diff
  //         : finalPageSize * 10;

  //     if (
  //       allContacts?.length >= 10
  //         ? allContacts?.length >= finalPageSize
  //         : allContacts?.length > 0
  //     ) {
  //       contactsData = Object.values(allContacts);
  //       newTotalRecords = totalRecords;
  //       setPaginationData((prev) => ({
  //         currPage: pageSize,
  //         pageSize: prev.pageSize + 1,
  //       }));
  //     } else {
  //       const resp = await getAllContacts(
  //         finalPageSize > 0 ? Math.ceil(finalPageSize / 10) : 1
  //       );
  //       if (resp.success) {
  //         newTotalRecords = resp?.totalRecords;
  //         contactsData = [...allContacts, ...resp.data];
  //         setPaginationData((prev) => ({
  //           currPage: prev.currPage + 1,
  //           totalPages: resp?.totalRecords,
  //         }));

  //         // console.log(resp.data)
  //         // setOrgData(resp.data);
  //         // setContacts(handleDataForTable(resp.data));
  //         // setOrgRecords(handleDataForTable(resp.data));
  //         // setPaginationData(prev => ({ pageSize: prev.pageSize + 1, totalPages: resp?.totalPages }))

  //         // dispatch(setContactsData(resp.data))
  //       } else throw new Error(resp.error);
  //     }

  //     console.log("contactsData", contactsData);

  //     setAllContactsData(contactsData, newTotalRecords);
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   setLoading(false);
  // };
  const fetchAllContacts = async (page = 1, pageSize = 10, search = "") => {
    console.log("called", page);
    setLoading(true);
    try {
      let contactsData = [];
      // const resp = await getAllContacts(page, pageSize);
      const resp = await searchContact(page, pageSize, search);

      if (resp.success) {
        const { data, totalRecords, totalPages } = resp;

        contactsData = [...data]; // Append new data

        setAllContactsData(contactsData, totalRecords);
        setPaginationData({
          currPage: page,
          totalPages: totalPages,
        });
        setCurrentPage(page);
      } else {
        throw new Error(resp.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  // const fetchAllContacts = async () => {
  //   setLoading(true);
  //   try {
  //     let contactsData = [],
  //       newTotalRecords = 0;
  //     let finalPageSize = pageSize ? pageSize : paginationData?.currPage;
  //     let totalPages =
  //       paginationData?.totalPages > 0 ? paginationData?.totalPages : 10000000;
  //     let diff = finalPageSize > 1 ? finalPageSize * 10 - totalPages : 0;
  //     finalPageSize =
  //       finalPageSize * 10 > totalPages
  //         ? finalPageSize * 10 - diff
  //         : finalPageSize * 10;
  //     console.log(
  //       "pageSize",
  //       ":",
  //       diff,
  //       ":",
  //       finalPageSize,
  //       ":",
  //       totalPages,
  //       ":",
  //       allContacts?.length
  //     );

  //     if (
  //       allContacts?.length >= 10
  //         ? allContacts?.length >= finalPageSize
  //         : allContacts?.length > 0
  //     ) {
  //       contactsData = Object.values(allContacts);
  //       newTotalRecords = totalRecords;
  //       setPaginationData((prev) => ({ ...prev, pageSize: prev.pageSize + 1 }));
  //     } else {
  //       const resp = await getAllContacts(finalPageSize / 10);
  //       if (resp.success) {
  //         newTotalRecords = resp?.totalRecords;
  //         contactsData = resp.data;
  //         setPaginationData((prev) => ({
  //           pageSize: prev.pageSize + 1,
  //           totalPages: resp?.totalRecords,
  //         }));

  //         // console.log(resp.data)
  //         // setOrgData(resp.data);
  //         // setContacts(handleDataForTable(resp.data));
  //         // setOrgRecords(handleDataForTable(resp.data));
  //         // setPaginationData(prev => ({ pageSize: prev.pageSize + 1, totalPages: resp?.totalPages }))

  //         // dispatch(setContactsData(resp.data))
  //       } else throw new Error(resp.error);
  //     }

  //     console.log("contactsData", contactsData);

  //     setAllContactsData(contactsData, newTotalRecords);
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   setLoading(false);
  // };

  useEffect(() => {
    if (!cvsshow) setFileUploaded(false);
  }, [cvsshow]);

  const handleClose = () => {
    setContactsGroup([]);
    setInserted([]);
    setSelectedContacts([]);
    setDuplicateNumbers([]);
    setDuplicateNames([]);
    setCtShow(false);
  };

  // useEffect(() => {
  //   setPaginationData({
  //     currPage:
  //       paginationData.currPage > paginationData.totalPages
  //         ? paginationData.currPage - 1
  //         : paginationData.currPage,
  //     totalPages: paginationData.totalPages,
  //   });
  // }, [paginationData]);

  const handleDelete = async (id) => {
    const isDeleted = await deleteContacts(id);
    if (isDeleted?.success) {
      // const remainingContacts = allContacts.filter((item) => item._id !== id);
      // const totalRecordsAfterDelete = remainingContacts.length;
      // const totalPagesAfterDelete = Math.ceil(totalRecordsAfterDelete / 10);

      // // Update contacts in state
      // setAllContactsData(remainingContacts, totalRecordsAfterDelete);

      // Stay on the same page, even if there are no records left
      // fetchAllContacts(currentPage, 10);

      toast.success("Contact Deleted Successfully!");
      console.log("new pagination", paginationData);
      // setPaginationData({
      //   currPage:
      //     paginationData.currPage > paginationData.totalPages
      //       ? paginationData.currPage - 1
      //       : paginationData.currPage,
      //   totalPages: paginationData.totalPages,
      // });
      fetchAllContacts(paginationData.currPage, 10, searchText);

      // fetchAllContacts();
    } else {
      console.log("Something went wrong");
    }
  };
  // console.log("new pagination1", paginationData);
  const isPhoneNumValid = () => {
    const num = newContactData.number;
    const name = newContactData.contactName;
    if (!nameRegex.test(name)) {
      setInputErrors((prevState) => ({
        ...prevState,
        contactName: {
          ...prevState.contactName,
          hasError: true,
          errorMessage: "Enter a valid name",
        },
      }));
      return false;
    } else if (!numericRegex.test(num)) {
      setInputErrors((prevState) => ({
        ...prevState,
        number: {
          ...prevState.number,
          hasError: true,
          errorMessage: "Enter a valid contact number",
        },
      }));
      return false;
    } else {
      const countryNumberDetail = countryCodeList.filter((code) => {
        if (num.startsWith(code?.phone)) return code;
      });
      console.log("countryNumberDetail", countryNumberDetail);
      if (countryNumberDetail.length <= 0) {
        setInputErrors((prevState) => ({
          ...prevState,
          number: {
            ...prevState.number,
            hasError: true,
            errorMessage: `Invalid country code.`,
          },
        }));
        return false;
      }
      const totalLength =
        countryNumberDetail[0]?.phone.length +
        countryNumberDetail[0]?.phoneLength;
      if (countryNumberDetail?.length > 0) {
        console.log("thiss");

        if (num.length === totalLength) {
          return true;
        }
      }
      setInputErrors((prevState) => ({
        ...prevState,
        number: {
          ...prevState.number,
          hasError: true,
          errorMessage: `Phone number length for ${countryNumberDetail[0]?.label} is ${totalLength} `,
        },
      }));
      return false;
    }
    // else if (num.length < 7) {
    //     setInputErrors(prevState => ({
    //         ...prevState,
    //         number: {
    //             ...prevState.number,
    //             hasError: true,
    //             errorMessage: "Phone number cannot be less than 7 digits"
    //         }
    //     }));
    //     return false
    // }
    // else if (num.length > 15) {
    //     setInputErrors(prevState => ({
    //         ...prevState,
    //         number: {
    //             ...prevState.number,
    //             hasError: true,
    //             errorMessage: "Phone number cannot be greater than 15 digits"
    //         }
    //     }));
    //     return false
    // }
    // return true
  };

  const isPhoneNumValidForUpdate = () => {
    console.log("selectedContact", selectedContact);
    const num = selectedContact.number;
    const name = selectedContact.contactName;
    if (!nameRegex.test(name)) {
      setInputErrors((prevState) => ({
        ...prevState,
        contactName: {
          ...prevState.contactName,
          hasError: true,
          errorMessage: "Enter a valid name",
        },
      }));
      return false;
    } else if (!numericRegex.test(num)) {
      setInputErrors((prevState) => ({
        ...prevState,
        number: {
          ...prevState.number,
          hasError: true,
          errorMessage: "Enter a valid contact number",
        },
      }));
      return false;
    } else {
      const countryNumberDetail = countryCodeList.filter((code) => {
        if (num.startsWith(code.phone)) return code;
      });
      const totalLength =
        countryNumberDetail[0].phone.length +
        countryNumberDetail[0].phoneLength;
      if (countryNumberDetail.length > 0) {
        if (num.length === totalLength) {
          return true;
        }
      }
      setInputErrors((prevState) => ({
        ...prevState,
        number: {
          ...prevState.number,
          hasError: true,
          errorMessage: `Phone number length for ${countryNumberDetail[0].label} is ${totalLength} `,
        },
      }));
      return false;
    }
  };

  // const handleAddContacts = async (e) => {
  //   e?.preventDefault();
  //   console.log(fileData, "fileData");
  //   if (!fileData) {
  //     setCsvError(true);
  //     return;
  //   }
  //   setIsAdding(true);
  //   // if (fileData.type !== "text/csv" && !fileData.name.endsWith(".csv")) {
  //   //   toast.error("Please upload a CSV file.");
  //   //   return;
  //   // }
  //   let contacts = [],
  //     inValidContacts = [];
  //   setContactsGroup([]);
  //   setIsInvalidRecords(false);
  //   setAllInValidContacts([]);
  //   Papa.parse(fileData, {
  //     header: false,
  //     skipEmptyLines: true,
  //     complete: function (results) {
  //       console.log("headers L ", results.data[0]);
  //       if (
  //         !results.data[0][0] ||
  //         !results.data[0][1] ||
  //         results.data[0]?.length < 2 ||
  //         results.data[0][0] !== "name" ||
  //         results.data[0][1] != "number"
  //       ) {
  //         toast.error("The headers are incorrect or missing");
  //         return;
  //       }

  //       console.log("results : ", results);
  //       setFileUploaded(true);

  //       console.log(
  //         "results.data",
  //         results.data.slice(1, results.data?.length),
  //         ":"
  //       );

  //       results.data.slice(1, results.data?.length).map((item) => {
  //         let name = item[0];
  //         let number = item[1];
  //         console.log("number1", number);
  //         // if (typeof number === "string" && number.includes("E+")) {
  //         //   // Convert scientific notation to a full number
  //         //   number = Number(number).toLocaleString("fullwide", {
  //         //     useGrouping: false,
  //         //   });
  //         // }

  //         let element = {
  //           contactName: name,
  //           number,
  //           invalid: false,
  //         };

  //         console.log(
  //           "data of contact : ",
  //           name,
  //           "::",
  //           number,
  //           ":",
  //           typeof number,
  //           ":",
  //           numericRegex.test(number)
  //         );
  //         const num = element.number;
  //         console.log(
  //           "reges res : ",
  //           element.contactName,
  //           ":::",
  //           nameRegex.test(element.contactName),
  //           ":::",
  //           element.number,
  //           ":::",
  //           ":",
  //           numericRegex.test(element.number),
  //           "final : ",
  //           !numericRegex.test(element.number),
  //           ":::",
  //           !nameRegex.test(element.contactName)
  //         );
  //         if (
  //           !numericRegex.test(element.number) ||
  //           !nameRegex.test(element.contactName)
  //         ) {
  //           element.invalid = true;
  //         }
  //         console.log("number2", element);

  //         const countryNumberDetail = countryCodeList.filter((code) => {
  //           if (num?.startsWith(code.phone)) return code;
  //         });
  //         if (countryNumberDetail.length === 0) element.invalid = true;
  //         else {
  //           const totalLength =
  //             countryNumberDetail[0].phone.length +
  //             countryNumberDetail[0].phoneLength;
  //           if (countryNumberDetail.length > 0) {
  //             if (num.length !== totalLength) {
  //               element.invalid = true;
  //             }
  //           }
  //         }

  //         console.log("element", element);
  //         if (element?.invalid) {
  //           inValidContacts.push(element);
  //         } else {
  //           contacts.push(element);
  //         }
  //       });
  //       console.log("sending data : ", contacts, "::", inValidContacs);
  //       setAllInValidContacts(inValidContacts);
  //       // inValidContacts?.map((c) => {
  //       //   if (c?.contactName != "" || c?.number != 0 || c?.number != "") {
  //       //     console.log("isInvalidLength", inValidContacts.length);
  //       //     setIsInvalidRecords(true);
  //       //   }
  //       // });
  //       setContactsGroup(contacts);
  //       if (inValidContacts.length > 0) {
  //         setIsInvalidRecords(true);
  //         const allNonEmptyRecords = inValidContacts.filter(
  //           (item) => item.contactName.trim() !== "" && item.number !== 0
  //         );
  //         setInvalidRecordsLength(allNonEmptyRecords.length);
  //         setCvsShow(false);
  //       } else {
  //         setIsInvalidRecords(false);
  //       }
  //     },
  //   });

  //   console.log("true dewd", contactsGroup);
  //   if (contactsGroup.length > 0) {
  //     setIsAdding(true);
  //     const allNonEmptyRecords = contactsGroup.filter(
  //       (item) => item.contactName.trim() !== "" && item.number !== 0
  //     );
  //     try {
  //       // for (let index = 0; index < allNonEmptyRecords.length; index++) {
  //       //   const element = allNonEmptyRecords[index];
  //       //   console.log("element3", element);
  //       //   if (
  //       //     !numericRegex.test(element.number) ||
  //       //     !nameRegex.test(element.contactName) ||
  //       //     element.invalid
  //       //   ) {
  //       //     console.log("inside invalid if", element);
  //       //     // toast.error(
  //       //     //   `Invalid contact ${element.contactName} | ${element.number} `
  //       //     // );
  //       //     // toast.error(
  //       //     //   "Please address the highlighted contacts or deselect them to proceed."
  //       //     // );
  //       //     setIsInvalidRecords(true);

  //       //     setCvsShow(false);
  //       //     return;
  //       //   }
  //       // }
  //       console.log("hello", contactsGroup);

  //       // console.log("hello", contactsGroup);
  //       const resp = await addMultipleContacts({
  //         contacts: allNonEmptyRecords,
  //         groups: selectedGroups,
  //       });
  //       console.log("resp ===", resp);
  //       if (resp.success) {
  //         setFileData(null);
  //         setFileName("");
  //         setIsInvalidRecords(false);
  //         setCvsShow(false);
  //         handleClose();
  //         setContacts((prevContact) => [...resp.data.inserted, ...prevContact]);
  //         fetchAllContacts();
  //         setUpdateContacts((prev) => !prev);
  //         toast.success(
  //           `${
  //             resp.data.inserted.length + resp.data.updateContacts.length
  //           } new contacts added & ${
  //             resp.data.duplicateNumbers.length
  //           } duplicate contacts found`
  //         );
  //         setIsAdding(false);
  //         // if (resp.data.duplicateNames.length > 0 || resp.data.duplicateNumbers.length > 0) {
  //         //     console.log('Some Contacts Were not Added Successfully')
  //         //     setDuplicateNumbers(resp.data.duplicateNumbers)
  //         //     setDuplicateNames(resp.data.duplicateNames)
  //         //     setInserted(resp.data.inserted)
  //         //     if (resp.data.inserted.length === 0) {
  //         //         if (resp.data.duplicateNames.length === 0) {
  //         //             toast.error("None of the selected contacts could be added as those numbers already exist in contacts")
  //         //         }
  //         //         else if (resp.data.duplicateNumbers.length === 0) {
  //         //             toast.error("None of the selected contacts could be added as those names already exist in contacts")
  //         //         }
  //         //         else {
  //         //             toast.error("None of the selected contacts could be added as those names and numbers already exist in contacts")
  //         //         }
  //         //     }
  //         //     else {
  //         //         if (resp.data.duplicateNames.length === 0) {
  //         //             toast.error("Some of the selected contacts could not be added as those numbers already exist in contacts")
  //         //         }
  //         //         else if (resp.data.duplicateNumbers.length === 0) {
  //         //             toast.error("Some of the selected contacts could not be added as those names already exist in contacts")
  //         //         }
  //         //         else {
  //         //             toast.error("Some of the selected contacts could not be added as those names and numbers already exist in contacts")
  //         //         }
  //         //     }
  //         // }
  //         // else {
  //         //     console.log('Contacts Added successfully')
  //         //     handleClose()
  //         // }
  //         setUpdateContacts((prevUpdateContacts) => !prevUpdateContacts);
  //       } else {
  //         setIsAdding(false);

  //         toast.error(resp.error);
  //         throw new Error(resp.error);
  //       }
  //     } catch (error) {
  //       // toast.error("Somthing went wrong. Please fix contact from csv file");
  //       console.log(error.message);
  //       setIsAdding(false);
  //     }
  //   }

  //   setIsAdding(false);
  // };

  const handleAddContacts = async (e) => {
    e?.preventDefault();

    if (!fileData) {
      setCsvError(true);
      return;
    }

    setIsAdding(true);
    let contacts = [],
      inValidContacts = [];

    // Clear previous states
    setContactsGroup([]);
    setIsInvalidRecords(false);
    setAllInValidContacts([]);

    // Parse the CSV file
    Papa.parse(fileData, {
      header: false,
      skipEmptyLines: true,
      complete: function (results) {
        if (
          !results.data[0][0] ||
          !results.data[0][1] ||
          results.data[0]?.length < 2 ||
          results.data[0][0] !== "name" ||
          results.data[0][1] !== "number"
        ) {
          toast.error("The headers are incorrect or missing");
          setIsAdding(false);
          return;
        }

        results.data.slice(1).forEach((item) => {
          let name = item[0];
          let number = item[1];
          if (!name?.trim() && !number?.trim()) {
            return; // Skip empty rows
          }
          let element = {
            contactName: name,
            number,
            invalid: false,
          };

          // Validate name and number
          if (
            !numericRegex.test(element.number) ||
            !nameRegex.test(element.contactName)
          ) {
            element.invalid = true;
          }

          // Country code validation
          const countryNumberDetail = countryCodeList.filter((code) => {
            return number.startsWith(code.phone);
          });

          if (
            countryNumberDetail.length === 0 ||
            number.length !==
              countryNumberDetail[0].phone.length +
                countryNumberDetail[0].phoneLength
          ) {
            element.invalid = true;
          }

          // Separate valid and invalid contacts
          if (element.invalid) {
            inValidContacts.push(element);
          } else {
            contacts.push(element);
          }
        });
        setAllInValidContacts(inValidContacts);
        setIsInvalidRecords(inValidContacts.length > 0);
        setInvalidRecordsLength(inValidContacts.length);

        if (inValidContacts.length > 0) {
          setCvsShow(false);
          return;
        }

        setContactsGroup(contacts); // This will trigger the useEffect below
      },
    });

    setIsAdding(false);
  };

  // Add useEffect to trigger API call when contactsGroup is updated
  useEffect(() => {
    const addContacts = async () => {
      if (contactsGroup.length > 0) {
        setIsAdding(true);

        const allNonEmptyRecords = contactsGroup.filter(
          (item) => item.contactName.trim() !== "" && item.number !== 0
        );

        try {
          const resp = await addMultipleContacts({
            contacts: allNonEmptyRecords,
            groups: selectedGroups,
          });

          if (resp.success) {
            setFileData(null);
            setFileName("");
            setIsInvalidRecords(false);
            setCvsShow(false);
            handleClose();
            setContacts((prevContact) => [
              ...resp.data.inserted,
              ...prevContact,
            ]);
            fetchAllContacts();
            setUpdateContacts((prev) => !prev);
            toast.success(
              `${
                resp.data.inserted.length + resp.data.updateContacts.length
              } new contacts added & ${
                resp.data.duplicateNumbers.length
              } duplicate contacts found`
            );
          } else {
            throw new Error(resp.error);
          }
        } catch (error) {
          toast.error(
            "Something went wrong. Please fix contacts in the CSV file."
          );
          console.log(error.message);
        } finally {
          setIsAdding(false);
        }
      }
    };

    addContacts();
  }, [contactsGroup]); // Trigger when contactsGroup changes

  const handleAddContact = async (e) => {
    e.preventDefault();

    try {
      console.log("selectedGroups", selectedGroups);
      const check = isPhoneNumValid();
      if (!check) return;
      setIsAdding(true);

      if (selectedGroups?.length > 0) {
        newContactData.groups = selectedGroups;
      }
      const resp = await addContact(newContactData);
      if (resp.success) {
        console.log("Contact Added successfully");
        //setContacts(prevContact => [resp.data, ...prevContact])
        handleClose();
        toast.success("Contact Added successfully");
        console.log("currentPAge", currentPage);
        // fetchAllContacts(currentPage, 10, searchText);
        fetchAllContacts(
          paginationData.currPage > paginationData.totalPages
            ? paginationData.currPage - 1
            : paginationData.currPage,
          10,
          searchText
        );
        setPaginationData({
          currPage:
            paginationData.currPage > paginationData.totalPages
              ? paginationData.currPage - 1
              : paginationData.currPage,
          totalPages: paginationData.totalPages,
        });
      } else {
        // toast.error(resp.error);
        throw new Error(resp.error);
      }
    } catch (error) {
      setIsAdding(false);
      toast.error(error.message);
      handleClose();

      console.log(error.message);
      if (error.message.includes("Enter a valid contact name")) {
        setIsAdding(false);

        setInputErrors((prevState) => ({
          ...prevState,
          contactName: {
            ...prevState.contactName,
            hasError: true,
            errorMessage: "Enter a valid contact name",
          },
        }));
      }
      if (error.message.includes("A contact with this name already exists")) {
        setIsAdding(false);

        setInputErrors((prevState) => ({
          ...prevState,
          contactName: {
            ...prevState.contactName,
            hasError: true,
            errorMessage: "A contact with this name already exists",
          },
        }));
      }
      if (error.message.includes("A contact with this number already exists")) {
        setIsAdding(false);

        setInputErrors((prevState) => ({
          ...prevState,
          number: {
            ...prevState.number,
            hasError: true,
            errorMessage: "A contact with this number already exists",
          },
        }));
      }
      if (error.message.includes("Enter a valid phone number")) {
        setIsAdding(false);

        setInputErrors((prevState) => ({
          ...prevState,
          number: {
            ...prevState.number,
            hasError: true,
            errorMessage: "Enter a valid contact number",
          },
        }));
      }
    }
    setIsAdding(false);
  };

  const handleEdit = async () => {
    const check = isPhoneNumValidForUpdate();
    console.log("updatedContact: ", selectedContact);
    console.log("check", check);
    if (!check) return;

    const isContactUpdated = await updatetheContact(selectedContact);
    if (isContactUpdated?.success) {
      console.log("Contact Successfully Updated!");
      setUpdateContacts((prevUpdateContacts) => !prevUpdateContacts);

      setEditContact(false);
      setSelectedContact(null);

      toast.success("Contact Updated Successfully");
      fetchAllContacts(
        paginationData.currPage > paginationData.totalPages
          ? paginationData.currPage - 1
          : paginationData.currPage,
        10,
        searchText
      );
      setPaginationData({
        currPage:
          paginationData.currPage > paginationData.totalPages
            ? paginationData.currPage - 1
            : paginationData.currPage,
        totalPages: paginationData.totalPages,
      });
    } else {
      console.log("Something went wrong");
      toast.error(isContactUpdated?.error);
      return isContactUpdated;
    }
    console.log(isContactUpdated);
  };

  let isHeaderWrong = true;

  const changeHandler = (event) => {
    const file = event.target.files[0];
    console.log("renderrrrrr", event.target.files[0]);
    console.log(
      "invalidRecordsss",
      invalidRecordsLength,
      "::",
      isInvalidRecords
    );

    if (file) {
      setCsvError(false);
    }
    if (file.type !== "text/csv" && !file.name.endsWith(".csv")) {
      toast.error("Please upload a CSV file.");
      return;
    }
    setFileData(file);
    setFileName(file.name);

    // Papa.parse(file, {
    //   header: false,
    //   skipEmptyLines: true,
    //   complete: function (results) {
    //     console.log("headers L ", results.data[0]);
    //     if (
    //       !results.data[0][0] ||
    //       !results.data[0][1] ||
    //       results.data[0]?.length < 2 ||
    //       results.data[0][0] !== "name" ||
    //       results.data[0][1] != "number"
    //     ) {
    //       toast.error("The headers are incorrect or missing");
    //       return;
    //     }

    //     console.log("results : ", results);
    //     setFileUploaded(true);
    //     let contacts = [],
    //       inValidContacts = [];
    //     console.log(
    //       "results.data",
    //       results.data.slice(1, results.data?.length),
    //       ":"
    //     );

    //     results.data.slice(1, results.data?.length).map((item) => {
    //       let name = item[0];
    //       let number = item[1];
    //       let element = {
    //         contactName: name,
    //         number: Number(number),
    //         invalid: false,
    //       };
    //       console.log(
    //         "data of contact : ",
    //         name,
    //         "::",
    //         number,
    //         ":",
    //         typeof number,
    //         ":",
    //         numericRegex.test(number)
    //       );
    //       const num = element.number?.toString();
    //       console.log(
    //         "reges res : ",
    //         element.contactName,
    //         ":::",
    //         nameRegex.test(element.contactName),
    //         ":::",
    //         element.number,
    //         ":::",
    //         ":",
    //         numericRegex.test(element.number),
    //         "final : ",
    //         !numericRegex.test(element.number),
    //         ":::",
    //         !nameRegex.test(element.contactName)
    //       );
    //       if (
    //         !numericRegex.test(element.number) ||
    //         !nameRegex.test(element.contactName)
    //       ) {
    //         element.invalid = true;
    //       }
    //       const countryNumberDetail = countryCodeList.filter((code) => {
    //         if (num?.startsWith(code.phone)) return code;
    //       });
    //       if (countryNumberDetail.length === 0) element.invalid = true;
    //       else {
    //         const totalLength =
    //           countryNumberDetail[0].phone.length +
    //           countryNumberDetail[0].phoneLength;
    //         if (countryNumberDetail.length > 0) {
    //           if (num.length !== totalLength) {
    //             element.invalid = true;
    //           }
    //         }
    //       }
    //       console.log("element", element);
    //       if (element?.invalid) {
    //         inValidContacts.push(element);
    //       }
    //       contacts.push(element);
    //     });
    //     console.log("sending data : ", contacts, "::", inValidContacs);
    //     setAllInValidContacts(inValidContacts);
    //     setContactsGroup(contacts);
    //   },
    // });

    // Papa.parse(event.target.files[0], {
    //   header: false,
    //   skipEmptyLines: true,
    //   complete: function (results) {
    //     console.log("considtion", results.data[0])
    //     if (!results.data[0][0] || !results.data[0][1] || results.data[0]?.length < 2 || results.data[0][0] !== "name" || results.data[0][1] != "number") {
    //       console.log("inside of ididididi")
    //       toast.error("Headers of file mismatch with specified")
    //     } else {
    //       isHeaderWrong = false
    //       console.log("inside of elslslsl", isHeaderWrong)
    //     }
    //   },
    // });

    //console.log("isHeaderWrong : ", isHeaderWrong)
    //if (isHeaderWrong == false) {

    //}
  };

  const exportContact = async () => {
    const resp = await getAllContacts(1, 10000);
    console.log("ssssss", resp.data);
    const result = resp?.data.map((contact) => ({
      name: contact.contactName,
      number: contact.number,
    }));
    exportToExcel(result);
    toast.success("Contacts Exported Sucessfully");
  };
  useEffect(() => {
    console.log("effect runs");
    fetchAllContacts(currentPage, 10, searchText);

    setCurrentPage(currentPage);
  }, [currentPage]);

  // useEffect(() => {
  //   fetchAllContacts();
  // }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
    },
    {
      title: "Actions",
      dataIndex: "Actions",
    },
  ];
  console.log("isInvalidRecords", isInvalidRecords);

  const getRandomColor = (index) => {
    // Calculate hue value based on the index to get distinct colors
    const hue = (index * 50) % 360; // You can adjust the multiplier (50) to change the color range

    // Set constant saturation and lightness values
    const saturation = 50;
    const lightness = 50;

    // Convert HSL to RGB values
    const hslToRgb = (h, s, l) => {
      h /= 360;
      s /= 100;
      l /= 100;
      let r, g, b;

      if (s === 0) {
        r = g = b = l; // Achromatic
      } else {
        const hue2rgb = (p, q, t) => {
          if (t < 0) t += 1;
          if (t > 1) t -= 1;
          if (t < 1 / 6) return p + (q - p) * 6 * t;
          if (t < 1 / 2) return q;
          if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
          return p;
        };

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
      }

      const toHex = (x) => {
        const hex = Math.round(x * 255).toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      };

      return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    };

    return hslToRgb(hue, saturation, lightness);
  };

  // making data for showing rows in table
  const handleDataForTable = (data) => {
    // return []
    let finalData = [];
    for (let i = 0; i != data.length; i++) {
      const color = getRandomColor(i);
      finalData.push({
        key: data[i]._id,
        name: data[i]?.contactName,
        number: data[i]?.number,
        Name: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              className="random-clr"
              style={{ background: color, marginRight: "10px" }}
            >
              {data[i]?.contactName?.substring(0, 1)}
            </div>
            <p
              style={{
                fontFamily: "Segoe UI",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "22px",
              }}
            >
              {data[i]?.contactName}
            </p>
          </div>
        ),
        createdAt: (
          <p
            style={{
              fontFamily: "Segoe UI",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "22px",
            }}
          >
            {moment(data[i]?.createdAt).format("DD MMM, YYYY")}
          </p>
        ),
        phone: (
          <p
            style={{
              fontFamily: "Segoe UI",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "22px",
            }}
          >
            {"+" + data[i]?.number}
          </p>
        ),
        Actions: (
          <Companyactionbtn
            data={data[i]}
            isComingFromContact
            submitDelete={handleDelete}
            fetchAllContacts={fetchAllContacts}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            submitEdit={() => {
              setEditContact(true);
              setSelectedContact(data[i]);
              setInputErrors({
                contactName: { hasError: false, errorMessage: "" },
                number: { hasError: false, errorMessage: "" },
              });
            }}
          />
        ),
      });
    }

    return finalData;
  };

  console.log("contacts", contacts);
  // Function to download the sample CSV
  const downloadSampleCSV = () => {
    // Define CSV content with headers "name" and "number"
    const csvContent = "name,number\n" + "Contact name,971661111111\n"; // You can add more rows here if needed

    // Create a blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a link element and trigger the download
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", "sample.csv"); // The downloaded file will be named 'sample.csv'

    // Trigger the download by clicking the link programmatically
    document.body.appendChild(link);
    link.click();

    // Clean up the DOM by removing the link element
    document.body.removeChild(link);
  };

  // search any record
  const getSearchedRecords = async (value) => {
    setSearchText(value);
    await fetchAllContacts(1, 10, value);
    // If the search value is empty, reset the table with original records
    // if (!value) {
    //   setAllContactsData(allOrgnlRecords); // Reset to all original records
    //   return;
    // }

    // try {
    //   // Make the API call to fetch the search results
    //   const resp = await searchContact(value);
    //   // Parse the response data

    //   if (resp.success) {
    //     // If the search is successful, set the contacts with the search results
    //     const { data } = resp;
    //     console.log("data", data);
    //     setAllContactsData(data); // Set the fetched search result to contacts
    //   } else {
    //     // Handle search error (no results or API error)
    //     console.log("Search failed:", resp.error);
    //     setAllContactsData([]); // Set to empty if no matches or error
    //   }
    // } catch (error) {
    //   console.error("Error while searching:", error);
    //   setAllContactsData([]); // Set to empty if there's an error
    // }
  };

  // if (loading) {
  //   return <TableSkeleton />;
  // }
  // const handlePaginationChange = (event, value) => {
  //   console.log("value of paginatipn ", value);
  //   setCurrPage(value);
  //   if (value == 1) {
  //     // makeTableData(0, 10)
  //     fetchAllContacts(1);
  //   } else {
  //     // props?.fetchData(value)
  //     fetchAllContacts(value);
  //     // makeTableData((value - 1) * 10, (value * 10))
  //   }
  // };
  const handleTryAgain = () => {
    setCvsShow(true);
    setIsInvalidRecords(false);
    setFileName("");
    setFileData(null);
    setIsAdding(false);
    setAllInValidContacts([]);
    setInvalidRecordsLength(0);
  };

  const handlePaginationChange = (event, value) => {
    console.log("valiue", value);
    fetchAllContacts(value, 10, searchText);
    setCurrentPage(value); // Fetch new data for the selected page
    setPaginationData({
      currPage: value,
      totalPages: paginationData.totalPages,
    });
  };
  console.log(
    "invalidRecordsLength",
    invalidRecordsLength,
    "::",
    isInvalidRecords,
    "::",
    inValidContacs
  );
  return (
    <section className="main inner-main">
      {screenWidth >= 1024 ? (
        <section className="contact-group-top">
          <Container>
            <Row className="flex-column-reverse flex-md-row">
              <Col xs={2} className="">
                <h2
                  className="mt-1 flex gap-2"
                  style={{
                    fontWeight: "600",
                    fontSize: "22px",
                    fontFamily: "Segoe UI",
                  }}
                >
                  <img src={ContactIcon} />
                  Contacts
                </h2>
              </Col>

              <Col xs={4} className="mb-3 mb-sm-0 mt-md-0 mt-3">
                <Form.Group className="input-search">
                  <Form.Control
                    type="text"
                    placeholder="Search contact"
                    // onChange={(e) =>  setSearchQuery(e.target.value.trimStart())}
                    onChange={(e) =>
                      getSearchedRecords(e.target.value.trimStart())
                    }
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <div className="d-grid  gap-2 d-md-flex justify-content-md-end">
                  <Button
                    className="btn-main flex"
                    onClick={exportContact}
                    style={{ minWidth: "230px" }}
                  >
                    {/* <img src={UploadIcon} className="export-all-icon" style={{ marginRight: "10px" }} /> */}
                    <TbArrowBarToDown
                      size="24"
                      style={{ marginRight: "10px" }}
                    />
                    Export All Contacts
                  </Button>
                  <Button className="btn-main" onClick={() => setCtShow(true)}>
                    <i className="bi bi-plus"></i> Add Contact
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      ) : (
        <section className="contact-group-top">
          <Container className="d-flex justify-between">
            <div>
              <h2
                className="mt-1 flex gap-2"
                style={{
                  fontWeight: "600",
                  fontSize: "22px",
                  fontFamily: "Segoe UI",
                }}
              >
                <img src={ContactIcon} />
                Contacts
              </h2>
            </div>
            <div className="mb-3 gap-2 d-flex justify-content-end">
              <Button className="btn-main " onClick={exportContact}>
                <img
                  src={UploadIcon}
                  style={{
                    height: "21px",
                    minWidth: "21px",
                  }}
                />
              </Button>
              <Button className="btn-main" onClick={() => setCtShow(true)}>
                <i
                  className="bi bi-plus"
                  style={{ fontSize: "26px", fontWeight: "400" }}
                ></i>
              </Button>
            </div>
          </Container>
        </section>
      )}
      {screenWidth < 1024 && (
        <Container>
          <Row>
            <Col xs={12} className=" ">
              <Form.Group className="input-search ">
                <Form.Control
                  className="py-4"
                  type="text"
                  placeholder="Search contact"
                  // onChange={(e) =>  setSearchQuery(e.target.value.trimStart())}
                  onChange={(e) =>
                    getSearchedRecords(e.target.value.trimStart())
                  }
                  style={{ minWidth: "100%" }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Container>
      )}
      {loading ? (
        <TableSkeleton />
      ) : (
        <Box className="bg-[#ecf1f9] flex justify-start align-items-start w-100 flex-column h-100">
          {screenWidth >= 1023 ? (
            <Box
              className="flex justify-start align-items-start w-100 mt-[30px] rounded-md container all-tags mb-8 testing"
              sx={{
                padding: 0,
                height: "calc(100vh  - 300px)",
                marginBottom: "100px",
              }}
            >
              <Table
                columns={columns}
                dataSource={contacts} // Use full contacts data, not sliced
                pagination={
                  false // Disable changing pageSize (optional)
                }
                style={{ width: "100%", background: "#FFFFFF" }}
                size="middle"
              />
            </Box>
          ) : (
            <section className=" pt-3 d-lg-none w-100">
              <ContactMobile contacts={contacts} />
            </section>
          )}

          <Box
            className="bg-[#FFFFFF] flex justify-center align-items-center w-100 all-tags "
            sx={{
              height: "10%",
              position: "fixed",
              bottom: "0",
              boxShadow: "0 -4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            {/* <Pagination
                count={Math.ceil((totalRecords / 10) % 10)}
                //count={Math.ceil((props?.totalRecords / 10) % 10)}
                variant="outlined"
                shape="rounded"
                siblingCount={0}
                page={paginationData?.currPage}
                //page={props?.currPage == 0 ? 1 : props?.currPage}
                onChange={handlePaginationChange}
              />
               */}
            <Pagination
              count={paginationData.totalPages} // Total number of pages
              variant="outlined"
              shape="rounded"
              siblingCount={2}
              page={paginationData.currPage} // Current page
              onChange={handlePaginationChange} // Page change handler
            />
          </Box>
        </Box>
      )}

      <Modal
        show={ctshow}
        onHide={() => setCtShow(false)}
        className="whatsapp-modal modal-contact"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title style={{ marginBottom: "8px" }}>Add Contact</Modal.Title>
          <Modal.Title
            onClick={() => {
              setCtShow(false);
              setCvsShow(true);
            }}
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#165E5B",
              fontFamily: "Segoe UI",
              marginBottom: "8px",
              cursor: "pointer",
            }}
          >
            Import Contacts
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Name*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  name="contactName"
                  value={newContactData.contactName}
                  onChange={handleInputChange}
                  className={
                    inputErrors.contactName.hasError ? "is-invalid" : ""
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {inputErrors.contactName.hasError &&
                    inputErrors.contactName.errorMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Phone No*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ex: 447700900123 "
                  name="number"
                  value={newContactData.number}
                  onChange={handleInputChange}
                  className={inputErrors.number.hasError ? "is-invalid" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  {inputErrors.number.hasError &&
                    inputErrors.number.errorMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group className="contact-m-form">
                <Form.Label>Select Group</Form.Label>
                <Select
                  isMulti
                  className="c-select basic-multi-select"
                  options={groups}
                  placeholder={"Select Group(s)"}
                  classNamePrefix="select"
                  onChange={(e) => {
                    setSelectedGroups(e);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button className="btn-main-default" onClick={() => setCtShow(false)}>
            Cancel
          </Button>
          {isAdding ? (
            <div
              style={{
                display: "flex",
                minWidth: "150px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ color: "rgba(22, 94, 91, 1)" }}
              />
            </div>
          ) : (
            <Button className="btn-main" onClick={handleAddContact}>
              Add Contact
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={isInvalidRecords}
        onHide={() => setIsInvalidRecords(false)}
        className="whatsapp-modal modal-invalid"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title className="d-flex align-items-center justify-content-between w-100">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0 50px",
              }}
            >
              <img src={InvalidIcon} className="mb-2" />
              <span className="mb-2">Failed to Upload</span>
              <p className="mb-2 text-center">
                We have found{" "}
                <span style={{ color: "#e94b42" }}>{invalidRecordsLength}</span>{" "}
                invalid Contacts. Update the Contacts in the CSV file and
                re-upload.
              </p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          {inValidContacs.length > 0 && (
            <Row>
              <Col sm={12}>
                <div className="modal-table">
                  <Modaltable
                    contacts={inValidContacs}
                    setSelectedContacts={setSelectedContacts}
                    fileUploaded={fileUploaded}
                    setInvalidRecordsLength={setInvalidRecordsLength}
                  />
                </div>
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button className="btn-main-default w-100" onClick={handleTryAgain}>
            Try Again
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={cvsshow}
        onHide={() => setCvsShow(false)}
        className="whatsapp-modal modal-csv"
        centered
      >
        <Modal.Header
          className="p-0"
          style={{ borderBottom: "2px solid #0000001A" }}
        >
          <Modal.Title className="d-flex align-items-center justify-content-between w-100">
            <span style={{ fontSize: "20px" }}>Import Contacts</span>
            <Button
              className="import-cvs"
              onClick={() => {
                setCtShow(true);
                setCvsShow(false);
              }}
            >
              Add Contact
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-4 pb-0">
          <Row>
            <Col sm={12}>
              <div className="download-template">
                <p>
                  Download the “CSV Template”, fill in the data, and then upload
                  the file to the system.
                </p>
                <Button className="btn btn-main" onClick={downloadSampleCSV}>
                  <svg
                    className="me-1"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.5 10.5L10.5 14.732L14.5 10.541"
                      stroke="#165E5B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.5 3.5V14.5"
                      stroke="#165E5B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.5 17.5H16.5"
                      stroke="#165E5B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Download Sample
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <div className="Upload-cvs-file">
                  <input type="file" onChange={changeHandler} accept=".csv" />
                  <div className="file-body">
                    <i
                      class="bi bi-file-earmark-text me-2 "
                      style={{ fontSize: "20px" }}
                    ></i>
                    {fileName ? fileName : "Upload a CSV file - up to 16 MB"}
                  </div>
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            {csvError && (
              <Row>
                <Col sm={12}>
                  <div
                    className="error-message"
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginTop: "-15px",
                    }}
                  >
                    CSV file is required
                  </div>
                </Col>
              </Row>
            )}
            <Col sm={12}>
              <Form.Group className="contact-m-form">
                <Form.Label>Add to Groups (Optional)</Form.Label>
                <Select
                  isMulti
                  className="c-select basic-multi-select"
                  options={groups}
                  placeholder={"Select Group(s)"}
                  classNamePrefix="select"
                  onChange={(e) => {
                    setSelectedGroups(e);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          {/* {contactsGroup.length > 0 && (
            <Row>
              <Col sm={12}>
                <div className="modal-table">
                  <Modaltable
                    contacts={contactsGroup}
                    setSelectedContacts={setSelectedContacts}
                    fileUploaded={fileUploaded}
                    inValidContacs={inValidContacs}
                  />
                </div>
              </Col>
            </Row>
          )} */}
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button
            className="btn-main-default"
            onClick={() => {
              setCvsShow(false);
              setFileData(null);
              setFileName("");
            }}
          >
            Cancel
          </Button>
          {isAdding ? (
            <div
              style={{
                display: "flex",
                minWidth: "114.94px",
                maxWidth: "114.94px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ color: "rgba(22, 94, 91, 1)" }}
              />
            </div>
          ) : (
            <Button className="btn-main" onClick={() => handleAddContacts()}>
              Import
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={editContact}
        onHide={() => {
          setEditContact(false);
          setSelectedContact(null);
        }}
        className="whatsapp-modal modal-contact"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title>Edit Contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={selectedContact?.contactName}
                  name="contactName"
                  value={selectedContact?.contactName}
                  onChange={handleInputChange}
                  className={
                    inputErrors.contactName.hasError ? "is-invalid" : ""
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {inputErrors.contactName.hasError &&
                    inputErrors.contactName.errorMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Phone No</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={selectedContact?.number}
                  name="number"
                  value={selectedContact?.number}
                  onChange={handleInputChange}
                  className={inputErrors.number.hasError ? "is-invalid" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  {inputErrors.number.hasError &&
                    inputErrors.number.errorMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button
            className="btn-main-default"
            onClick={() => {
              setEditContact(false);
              setSelectedContact(null);
            }}
          >
            Cancel
          </Button>
          <Button className="btn-main" onClick={handleEdit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default Contact;
