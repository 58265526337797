import Inboxchat from "../components/chat/NewChatFile.tsx";

// import Inboxchat from "../components/chat/inboxchat";

const Inbox = () => {
  return (
    <section className="main">
      <Inboxchat />
      {/* <Inboxchat /> */}
    </section>
  );
};

export default Inbox;
