import Container from "react-bootstrap/Container";
import { useState, useEffect, useContext, useMemo, useRef } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Button, NavDropdown, Spinner } from "react-bootstrap";
import useDeviceWidthDetector from "../customHooks/DeviceWidthDetector";
import Logo from "../../images/logos/Utter-logo.svg";
import Inbox_icon from "../../images/icons/inbox_ic.svg";
import Contact_icon from "../../images/icons/contact_ic.svg";
import BroadCast_icon from "../../images/icons/broadcast_ic.svg";
import Manage_icon from "../../images/icons/manage_ic.svg";
import template_iconNew from "../../images/icons/templates_icon.svg";
import tags_icon from "../../images/icons/tag_icons.svg";
import Users_icon from "../../images/icons/users_icon.svg";
import integrationsIcon from "../../images/icons/setting-icon.svg";
import NotificationIcon from "../../images/notification-icon.svg";
import NoNotification from "../../images/no-notification.svg";

import Template_icon from "../../images/icons/template_ic.svg";
import admin_icon from "../../images/icons/admin_icon.svg";
import Group_icon from "../../images/icons/group_ic.svg";
import Menu_ic from "../../images/menu-ic.svg";
import Tags_icon from "../../images/icons/tag_icons.svg";
import Empty_notification from "../../images/icons/Bell-icon.svg";
import Dashboad_icon from "../../images/icons/dashboard_ic.svg";
import Bell_icon from "../../images/bell-icon.svg";

import Dropdown from "react-bootstrap/Dropdown";
import "./header.css";
import { io } from "socket.io-client";
import config from "../../config";
import DataContext from "../../store/data-context";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import {
  checkValidToken,
  getAllNotifications,
  getPfp,
  getUserDetails,
  readChat,
  readNotification,
  unreadNotificationCount,
} from "../../services/User";
import {
  removeCurrentChat,
  clearNotificationDetail,
  setNotificationDetail,
  setUnreadCount,
} from "../../reduxSlice/messageNotificationSlice";
import UserContext from "../../store/user-context";
import { updateUser } from "../../services/User";
import { Footer } from "../../pages/footer";
import getconfig from "../../config";
import { useSelector, useDispatch } from "react-redux";

const Header = ({
  logOutBtnHandler,
  loggedout = false,
  admin = false,
  cAdmin = false,
  authScreen = false,
  setNotificationData,
  notificationData,
}) => {
  const width = useDeviceWidthDetector();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  console.log("pathname===", pathname);
  const [currentLink, setCurrentLink] = useState(null);
  const [companyBtn, setCompanyBtn] = useState(true);
  const [profileBtn, setProfileBtn] = useState(false);
  const [userData, setUserData] = useState({});
  const { userState, setUserState } = useContext(UserContext);
  const { userId, updateUser } = userState;

  const [getData, setGetData] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [pfpExists, setPfpExists] = useState(false);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  //const backendUrl = " https://utter-api.mwancloud.com/";
  const backendUrl = getconfig?.backend;
  const [updateInbox, setUpdateInbox] = useState(0);
  const { newMessage, unReadChats, notificationDetail, unReadCount } =
    useSelector((state) => state.MessageNotification);
  const { allConversations: storedConversations } = useSelector(
    (state) => state.ChatsReducer
  );
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to update screenWidth state
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", updateScreenWidth);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);
  console.log("notificationDetail", notificationDetail);
  // useEffect(() => {
  //     if (updateInbox !== 0 && currentLink === '/inbox')
  //         setUpdateInbox(0)
  // }, [updateInbox, currentLink])

  // useEffect(() => {
  //   if (notificationDetail) {
  //     // Display the notification, for example with an alert
  //     // alert(`New message: ${notificationDetail.notification.title}`);
  //     // setNotificationData(notificationDetail);
  //     // Clear the notification after it's displayed
  //     setTimeout(() => {
  //       dispatch(clearNotificationDetail());
  //     }, 3000);
  //   }
  // }, [notificationDetail, dispatch]);

  const [menushow, setMenuShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  // const handleClick = (event) => {
  //   setActive(event.target.id);
  // };
  // useEffect(() => {
  //   switch (location.pathname) {
  //     case "/inbox":
  //       setActive("inbox");
  //       break;
  //     case "/contact":
  //       setActive("contact");
  //       break;
  //     case "/emptyContact":
  //       setActive("emptyContact");
  //       break;
  //     case "/group":
  //       setActive("group");
  //       break;
  //     case "/emptyGroup":
  //       setActive("group");
  //       break;
  //     case "/emptyBroadcast":
  //       setActive("emptyBroadcast");
  //       break;
  //     case "/broadcast":
  //       setActive("broadcast");
  //       break;
  //     case "/broadcastListing":
  //       setActive("broadcastListing");
  //       break;
  //     case "/templatelisting":
  //       setActive("templatelisting");
  //       break;
  //     case "/manageuser":
  //       setActive("manageuser");
  //       break;
  //     case "/integrations":
  //       setActive("integrations");
  //       break;
  //     case "/tags":
  //       setActive("tags");
  //       break;
  //     case "/emptytags":
  //       setActive("emptytags");
  //       break;
  //     default:
  //       setActive("");
  //       break;
  //   }
  // }, [location]);

  const handleSeeNotifications = async () => {
    setNotificationShow(true);
    setLoading(true);
    const response = await getAllNotifications();
    console.log("allNotifications", response);
    // setNotificationData(response?.data);
    dispatch(setNotificationDetail(response?.data));
    setLoading(false);
  };

  const handleNotificationClick = async (notification) => {
    const chatId = notification?.notification?.webpush?.data?.chatId;
    if (chatId) {
      await readChat({ inboxId: chatId });
    }

    const unReadNotification = await unreadNotificationCount();
    console.log("unReadNotification", notification);
    dispatch(setUnreadCount(unReadNotification?.unreadMessage));
    if (!chatId) {
      console.log("unReadNotification coming");
      await readNotification({ id: notification?._id });
      setNotificationShow(false);
      return;
    }

    navigate("/inbox", { state: notification });
    updateLink("/inbox");

    setNotificationShow(false);
  };

  const handleMarkAllRead = async () => {
    try {
      // Call the API to mark all notifications as read
      await readNotification();
      const unReadNotification = await unreadNotificationCount();
      console.log("unReadNotification", unReadNotification);
      dispatch(setUnreadCount(unReadNotification?.unreadMessage));
      // After successfully marking notifications as read, update the state
      const updatedNotifications = notificationDetail.map((data) => {
        const updatedUsers = data.users.map((user) => {
          // Mark each user's notification as read by setting isRead to true
          return { ...user, isRead: true };
        });

        // Return the updated notification object
        return { ...data, users: updatedUsers };
      });

      // Update the local state to reflect the changes
      dispatch(setNotificationDetail(updatedNotifications));
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  const handleMenu = () => {
    setMenuShow(false);
  };

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const closeDropdown = () => setIsOpen(false);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  // Handle link click: close dropdown and call another function
  const handleLinkClick = (callback) => (event) => {
    //event.preventDefault(); // Prevent default link behavior
    closeDropdown();
    callback();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  //     if (updateInbox !== 0 && currentLink === '/inbox')
  //         setUpdateInbox(0)
  // }, [updateInbox, currentLink])

  useEffect(() => {
    const checkTokenValidation = async () => {
      try {
        const resp = await checkValidToken(userId);
        console.log("response of valid token", resp);
        if (resp.success) {
          console.log("Valid token");
        } else if (
          resp.error == "Invalid token" ||
          resp.error == "jwt malformed"
        ) {
          logOutHandler();
        }
      } catch (error) {
        console.log(error);
      }
    };
    checkTokenValidation();
  }, [location]);
  useEffect(() => {
    if (updateUser) {
      checkFileExists();
      setGetData((prevData) => !prevData);
      setUserState((prevState) => ({
        ...prevState,
        updateUser: false,
      }));
    }
  }, [updateUser]);

  useEffect(() => {
    setCurrentLink(location.pathname);
  }, []);

  useEffect(() => {
    console.log("unReadChats ===", unReadChats);
    //if (currentLink != "/inbox") {
    //setUpdateInbox(unReadChats?.length);
    //}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unReadChats]);

  const checkFileExists = async () => {
    try {
      const response = await getPfp();
      console.log(response);
      if (response.success) {
        const companyId = localStorage.getItem("companyId");
        setImageUrl(
          `${backendUrl}uploads/${companyId}/pfp/${userId}/${response.filename}`
        );
        setPfpExists(true);
      } else {
        console.log("File does not exist");
        setPfpExists(false);
      }
    } catch (error) {
      console.log(error);
      setPfpExists(false);
    }
  };
  useEffect(() => {
    //if (currentLink != "/inbox") {
    //if (storedConversations && storedConversations?.length > 0) {
    let allConvs = [...storedConversations];
    let count = 0;
    for (let i = 0; i != allConvs?.length; i++) {
      if (allConvs[i].unreadMessageCount > 0) {
        count += 1;
      }
    }
    console.log("unread chats ", count);
    setUpdateInbox(count);
    // } else {
    //     setUpdateInbox(unReadChats);
    // }
    //}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedConversations]);

  useEffect(() => {
    if (!loggedout) {
      checkFileExists();
    }
  }, []);

  useEffect(() => {
    let links = [
      "/login",
      "/signup",
      "/resetpassword",
      "/reset-password",
      "/facebook-signup",
    ];
    links?.includes(window.location.pathname) == true
      ? setCompanyBtn(true)
      : setCompanyBtn(false);
  }, []);

  useEffect(() => {
    let links = [
      "/login",
      "/signup",
      "/resetpassword",
      "/reset-password",
      "/facebook-signup",
    ];
    links?.includes(window.location.pathname) == true
      ? setProfileBtn(false)
      : setProfileBtn(true);
  }, []);

  const updateLink = (link) => {
    setCurrentLink(link);
    setExpanded(false);
    console.log("link ===", link);
    if (link != "/inbox") {
      dispatch(removeCurrentChat());
    }
  };
  const [profileshow, setProfileShow] = useState(false);
  const [notificationShow, setNotificationShow] = useState(false);
  const [btnClicked, setBtnClicked] = useState(false);
  const navigate = useNavigate();

  const logOutHandler = () => {
    if (btnClicked) return;
    setBtnClicked(true);
    logOutBtnHandler(true);
    setBtnClicked(false);
    // navigate("/login");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await getUserDetails(userId);
        if (resp.success) {
          setUserData(resp.data);
        } else throw new Error(resp.error);
      } catch (error) {
        console.log(error);
      }
    };

    if (!loggedout || admin) {
      fetchData();
    }
  }, [getData]);

  const submit = async (userData, password) => {
    const newUpdatedData = {
      firstname: userData.firstname,
      lastname: userData.lastname,
      oldPassword: password.old,
      passwordChange: false,
      newPassword: "",
    };

    if (password.new1 !== "" && password.new1 === password.new2) {
      newUpdatedData.passwordChange = true;
      newUpdatedData.newPassword = password.new1;
    }

    console.log("Submitting updated data:", newUpdatedData);

    if (password.new1 === password.new2) {
      const response = await updateUser({
        userId,
        updatedData: newUpdatedData,
      });
      console.log("Update response:", response);
    }
  };

  const handleEmailClick = () => {
    const emailAddress = "support@mwanmobile.org"; // Replace with your actual email address
    const mailtoLink = `mailto:${emailAddress}`;
    window.location.href = mailtoLink;
  };

  const [expanded, setExpanded] = useState(false);
  const onLinkClick = (e) => {
    navigate(e);
    setProfileShow(false);
  };

  const isSuperAdmin = localStorage.getItem("superAdmin");
  const isCompanyAdmin = localStorage.getItem("companyAdmin");

  return (
    <>
      <Navbar expand="lg" className="Navbar-top" expanded={expanded}>
        <Container fluid>
          <div
            className={`position-relative d-flex align-items-center  justify-content-between w-100 d-lg-none`}
          >
            {/* {
                            !loggedout && !isSuperAdmin ?
                                profileBtn && (admin || !loggedout) ?
                                    <ul className="nav align-items-center">
                                        <li className="nav-item">
                                            <Navbar.Toggle aria-controls='basic-nav-dropdown' onClick={() => setExpanded(expanded ? false : "expanded")}>
                                                <i className="bi bi-list"></i>
                                            </Navbar.Toggle>
                                        </li>
                                    </ul> : null
                                : null
                        } */}
            {isSuperAdmin == "true" ? null : (
              <ul className="nav align-items-center">
                {!loggedout ? (
                  <li className="nav-item">
                    {/* <Navbar.Toggle
                      aria-controls="basic-nav-dropdown"
                      onClick={() => setExpanded(expanded ? false : "expanded")}
                    >
                      <i className="bi bi-list"></i>
                    </Navbar.Toggle> */}
                    <Button
                      className="navbar-mob-open btn"
                      onClick={() => setMenuShow(true)}
                    >
                      <img src={Menu_ic} alt="menu_icon" />
                    </Button>
                  </li>
                ) : null}
              </ul>
            )}
            <Navbar.Brand
              onClick={() => {
                {
                  navigate("/");
                  updateLink("/");
                }
              }}
              style={{ cursor: "pointer" }}
              className="py-0 me-auto"
            >
              <img
                src={Logo}
                style={{
                  marginLeft: width < 600 && !admin ? "calc(1vw + 15px)" : "",
                }}
                alt="logo-main"
              />
            </Navbar.Brand>
            {profileBtn && (admin || !loggedout) ? (
              <ul className="nav align-items-center">
                <li className="nav-item">
                  {authScreen ? (
                    <div
                      onClick={() => {
                        logOutHandler();
                      }}
                      style={{ gap: "4px" }}
                      className="profile-btn"
                    >
                      <span>Logout</span>
                    </div>
                  ) : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        className="notification-container"
                        onClick={handleSeeNotifications}
                      >
                        <img src={Bell_icon} alt="bell-icon" />
                        {unReadCount > 0 && (
                          <p
                            className="notification-count"
                            style={{
                              fontSize: unReadCount > 99 ? "10px" : "12px",
                            }}
                          >
                            {" "}
                            {unReadCount > 99 ? "99+" : unReadCount}
                          </p>
                        )}
                      </div>

                      <div
                        onClick={() => setProfileShow(true)}
                        className="profile-btn"
                      >
                        <span className="p-img"></span> <span>Profile</span>{" "}
                        <span className="creat-dn"></span>
                      </div>
                    </div>
                  )}
                </li>
              </ul>
            ) : null}
          </div>
          <Navbar.Brand
            onClick={() => {
              {
                navigate("/");
                updateLink("/");
              }
            }}
            style={{ cursor: "pointer" }}
            className="d-none d-lg-block"
          >
            <img src={Logo} alt="logo-main" />
          </Navbar.Brand>
          <Navbar.Collapse id="whatsapp-bot-nav" className="mt-4 mt-lg-0">
            <Nav
              whatsapp-bot-nav
              className={`${
                window.location.pathname.includes("/login")
                  ? "d-none"
                  : "mx-auto"
              } ${
                window.location.pathname.includes("signup")
                  ? "d-none"
                  : "mx-auto"
              } 
                            ${
                              window.location.pathname.includes("/") &&
                              loggedout === true
                                ? "d-none"
                                : "mx-auto"
                            } ${
                window.location.pathname.includes("resetpassword")
                  ? "d-none"
                  : "mx-auto"
              } ${
                window.location.pathname.includes("facebook-signup")
                  ? "d-none"
                  : "mx-auto"
              }`}
            >
              {/* {cAdmin && (
                <li className="Nav-item">
                  <Link
                    to="/"
                    className={`${currentLink == "/" ? "active" : ""}`}
                    onClick={() => {
                      updateLink("/");
                    }}
                  >
                    <img
                      src={admin_icon}
                      style={{
                        width: "21px",
                        transform: "scale(1.15)",
                        height: "21px",
                      }}
                      alt="nav-icon"
                    />
                    <p className="header-items-text">DASHBOARD</p>
                  </Link>
                </li>
              )} */}
              <li className="Nav-item">
                <Link
                  to="/inbox"
                  className={`${
                    currentLink == "/inbox" ||
                    currentLink == "/inbox" ||
                    currentLink == "/"
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    updateLink("/inbox");
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: width > 1024 ? "100px" : "",
                      gap: "5px",
                    }}
                  >
                    <img
                      src={Inbox_icon}
                      style={{
                        width: "21px",
                        transform: "scale(1.15)",
                        height: "21px",
                      }}
                      alt="nav-icon"
                    />
                    <p className="header-items-text">INBOX</p>
                    <div
                      className="time-sec1"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      {updateInbox > 0 && (
                        <Button
                          style={{
                            fontWeight: "bold",
                            color: "white",
                            backgroundColor: "rgba(233, 75, 66, 1)",
                            fontSize: "12px",
                            padding: "0",
                            height: "27px",
                            width: "27px",
                            border: "0px",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {updateInbox > 99 ? "99+" : updateInbox}
                        </Button>
                      )}
                    </div>
                  </div>
                </Link>
              </li>
              <li className="Nav-item">
                <Link
                  to="/contact"
                  className={`${currentLink == "/contact" ? "active" : ""}`}
                  onClick={() => {
                    updateLink("/contact");
                  }}
                >
                  <img
                    src={Contact_icon}
                    style={{
                      width: "21px",
                      transform: "scale(1.15)",
                      height: "21px",
                    }}
                    alt="nav-icon"
                  />
                  <p className="header-items-text">CONTACTS</p>
                </Link>
              </li>
              <li className="Nav-item">
                <Link
                  to="/group"
                  className={`${currentLink == "/group" ? "active" : ""}`}
                  onClick={() => {
                    updateLink("/group");
                  }}
                >
                  <img
                    src={Group_icon}
                    style={{
                      width: "21px",
                      transform: "scale(1.15)",
                      height: "21px",
                    }}
                    alt="nav-icon"
                  />
                  <p className="header-items-text">GROUPS</p>
                </Link>
              </li>
              <li className="Nav-item">
                <Link
                  to="/broadcast"
                  className={`${currentLink == "/broadcast" ? "active" : ""}`}
                  onClick={() => {
                    updateLink("/broadcast");
                  }}
                >
                  <img
                    src={BroadCast_icon}
                    style={{
                      width: "21px",
                      transform: "scale(1.15)",
                      height: "21px",
                    }}
                    alt="nav-icon"
                  />
                  <p className="header-items-text">BROADCAST</p>
                </Link>
              </li>

              {width < 1024 ? (
                <>
                  <li className="Nav-item">
                    <Link
                      to="/template"
                      className={`${
                        currentLink == "/template" ? "active" : ""
                      }`}
                      onClick={() => {
                        updateLink("/template");
                      }}
                    >
                      <img
                        src={template_iconNew}
                        style={{
                          width: "21px",
                          transform: "scale(1.15)",
                          height: "21px",
                        }}
                        alt="nav-icon"
                      />
                      <p className="header-items-text">MESSAGE TEMPLATES</p>
                    </Link>
                  </li>
                  {isCompanyAdmin == "true" ? (
                    <li className="Nav-item">
                      <Link
                        to="/users"
                        className={`${currentLink == "/users" ? "active" : ""}`}
                        onClick={() => {
                          updateLink("/users");
                        }}
                      >
                        <img
                          src={Users_icon}
                          style={{
                            width: "21px",
                            transform: "scale(1.15)",
                            height: "21px",
                          }}
                          alt="nav-icon"
                        />
                        <p className="header-items-text">USERS</p>
                      </Link>
                    </li>
                  ) : null}
                  {isCompanyAdmin == "true" ? (
                    <li className="Nav-item">
                      <Link
                        to="/tags"
                        className={`${currentLink == "/tags" ? "active" : ""}`}
                        onClick={() => {
                          updateLink("/tags");
                        }}
                      >
                        <img
                          src={tags_icon}
                          style={{
                            width: "21px",
                            transform: "scale(1.15)",
                            height: "21px",
                          }}
                          alt="nav-icon"
                        />
                        <p className="header-items-text">TAGS</p>
                      </Link>
                    </li>
                  ) : null}
                  {/* {isCompanyAdmin == "true" ? ( */}
                  <li className="Nav-item">
                    <Link
                      to="/widgets"
                      className={`${currentLink == "/widgets" ? "active" : ""}`}
                      onClick={() => {
                        updateLink("/widgets");
                      }}
                    >
                      <img
                        src={integrationsIcon}
                        style={{
                          width: "21px",
                          transform: "scale(1.15)",
                          height: "21px",
                        }}
                        alt="nav-icon"
                      />
                      <p className="header-items-text">INTEGRATION</p>
                    </Link>
                  </li>
                  {/* ) : null} */}
                </>
              ) : (
                <Dropdown
                  style={{
                    background:
                      currentLink == "/template" ||
                      currentLink == "/createTemplate" ||
                      currentLink == "/tags" ||
                      currentLink == "/widgets" ||
                      currentLink == "/users"
                        ? "rgba(255, 255, 255, 0.06)"
                        : "",
                    display: "flex",
                  }}
                  className={`Nav-item`}
                >
                  <Dropdown.Toggle
                    className="header-items-text"
                    id="dropdown-autoclose-true"
                    style={{
                      border: "none",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "5px",
                      background:
                        currentLink == "/tags"
                          ? "rgba(255, 255, 255, 0.06)"
                          : "transparent",
                      fontWeight: 100,
                    }}
                  >
                    <img
                      src={Manage_icon}
                      style={{
                        width: "21px",
                        transform: "scale(1.15)",
                        height: "21px",
                      }}
                      alt="nav-icon"
                    />{" "}
                    MANAGE
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      href=""
                      className="Nav-item"
                      style={{
                        height: "50px",
                        borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        updateLink("/template");
                        navigate("/template");
                      }}
                    >
                      <Link
                        to="/template"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <img
                          src={template_iconNew}
                          style={{
                            width: "21px",
                            transform: "scale(1.15)",
                            height: "21px",
                          }}
                          alt="nav-icon"
                        />
                        <p className="header-items-text">TEMPLATES</p>
                      </Link>
                    </Dropdown.Item>
                    {isCompanyAdmin == "true" ? (
                      <Dropdown.Item
                        href=""
                        className="Nav-item"
                        style={{
                          height: "50px",
                          borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          updateLink("/users");
                          navigate("/users");
                        }}
                      >
                        <Link
                          to="/users"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <img
                            src={Users_icon}
                            style={{
                              width: "21px",
                              transform: "scale(1.15)",
                              height: "21px",
                            }}
                            alt="nav-icon"
                          />
                          <p className="header-items-text">USERS</p>
                        </Link>
                      </Dropdown.Item>
                    ) : null}
                    {isCompanyAdmin == "true" ? (
                      <Dropdown.Item
                        href=""
                        className="Nav-item"
                        style={{
                          height: "50px",
                          display: "flex",
                          justifyContent: "flex-start",
                          borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          updateLink("/tags");
                          navigate("/tags");
                        }}
                      >
                        <Link
                          to="/tags"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <img
                            src={tags_icon}
                            style={{
                              width: "21px",
                              transform: "scale(1.15)",
                              height: "21px",
                            }}
                            alt="nav-icon"
                          />
                          <p className="header-items-text">TAGS</p>
                        </Link>
                      </Dropdown.Item>
                    ) : null}
                    {/* {isCompanyAdmin == "true" ? ( */}
                    <Dropdown.Item
                      href=""
                      className="Nav-item"
                      style={{
                        height: "50px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        updateLink("/widgets");
                        navigate("/widgets");
                      }}
                    >
                      <Link
                        to="/widgets"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <img
                          src={integrationsIcon}
                          style={{
                            width: "21px",
                            transform: "scale(1.15)",
                            height: "21px",
                          }}
                          alt="nav-icon"
                        />
                        <p className="header-items-text">INTEGRATION</p>
                      </Link>
                    </Dropdown.Item>
                    {/* ) : null} */}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Nav>
          </Navbar.Collapse>
          {profileBtn && (admin || !loggedout) ? (
            authScreen ? (
              <div
                onClick={() => {
                  logOutHandler();
                }}
                style={{ gap: "4px" }}
                className="profile-btn desktop-btn"
              >
                <span>Logout</span>{" "}
                <span
                  style={{ transform: "rotate(-90deg)", paddingBottom: "7px" }}
                  className="creat-dn"
                ></span>
              </div>
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* {notificationData && (
                  <h1>New message: {notificationData.notification.title}</h1>
                )} */}
                <div
                  className="notification-container"
                  onClick={handleSeeNotifications}
                  style={{ display: screenWidth < 1023 && "none" }}
                >
                  <img src={Bell_icon} alt="bell-icon" />
                  {unReadCount > 0 && (
                    <p
                      className="notification-count"
                      style={{ fontSize: unReadCount > 99 ? "10px" : "12px" }}
                    >
                      {unReadCount > 99 ? "99+" : unReadCount}
                    </p>
                  )}
                </div>
                <div
                  onClick={() => {
                    setProfileShow(true);
                  }}
                  className="profile-btn desktop-btn"
                >
                  <span className="p-img"></span> <span>Profile</span>{" "}
                  <span className="creat-dn"></span>
                </div>
              </div>
            )
          ) : null}
        </Container>
      </Navbar>
      <Offcanvas
        show={menushow}
        onHide={() => setMenuShow(false)}
        backdrop="static"
        placement="start"
        className="mobile-navbar"
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body className="mobile-nav inner">
          <ul className="nav flex-column">
            <li className="nav-item">
              <Link
                to="/inbox"
                className="d-flex align-items-center gap-3"
                onClick={() => {
                  handleMenu();
                  updateLink("/inbox");
                }}
              >
                <img src={Inbox_icon} alt="nav-icon" /> inbox
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/contact"
                className="d-flex align-items-center gap-3"
                onClick={() => {
                  handleMenu();
                  updateLink("/contact");
                }}
              >
                <img src={Contact_icon} alt="nav-icon" /> Contact
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/group"
                className="d-flex align-items-center gap-3"
                onClick={() => {
                  handleMenu();
                  updateLink("/group");
                }}
              >
                <img src={Group_icon} alt="nav-icon" /> Groups
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/broadcast"
                className="d-flex align-items-center gap-3"
                onClick={() => {
                  handleMenu();
                  updateLink("/broadcast");
                }}
              >
                <img src={BroadCast_icon} alt="nav-icon" /> Broadcast
              </Link>
            </li>
            <li className="nav-item">
              <div className="dropdown-cotainer py-3" ref={dropdownRef}>
                <Button
                  variant="dropdown"
                  className="d-flex align-items-center gap-3"
                  onClick={toggleDropdown}
                >
                  <img src={Manage_icon} alt="nav-icon" /> Manage
                </Button>

                <div className={`dropdown-content ${isOpen ? "show" : ""}`}>
                  <ul className="flex-column nav">
                    <li className="nav-item">
                      <Link
                        to="/template"
                        className="d-flex align-items-center gap-3"
                        onClick={() => {
                          handleLinkClick(handleMenu());
                          updateLink("/template");
                        }}
                      >
                        <img src={Manage_icon} alt="nav-icon" /> Message
                        Template
                      </Link>
                    </li>
                    {isCompanyAdmin === true ? (
                      <li className="nav-item">
                        <Link
                          to="/users"
                          className="d-flex align-items-center gap-3"
                          onClick={() => {
                            handleLinkClick(handleMenu());
                            updateLink("/users");
                          }}
                        >
                          <img src={Manage_icon} alt="nav-icon" /> Users
                        </Link>
                      </li>
                    ) : null}
                    {isCompanyAdmin === true ? (
                      <li className="nav-item">
                        <Link
                          to="/tags"
                          className="d-flex align-items-center gap-3"
                          onClick={() => {
                            handleLinkClick(handleMenu());
                            updateLink("/tags");
                          }}
                        >
                          <img src={Manage_icon} alt="nav-icon" /> Tags
                        </Link>
                      </li>
                    ) : null}

                    {
                      <li className="nav-item">
                        <Link
                          to="/widgets"
                          className="d-flex align-items-center gap-3"
                          onClick={() => {
                            handleLinkClick(handleMenu());
                            updateLink("/widgets");
                          }}
                        >
                          <img src={Manage_icon} alt="nav-icon" /> Integration
                        </Link>
                      </li>
                    }
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={profileshow}
        onHide={() => setProfileShow(false)}
        style={{ width: width < 600 ? "100%" : "" }}
        placement="end"
        className="profile-offcanvas"
      >
        <Offcanvas.Header closeButton>
          <div className="profile-info">
            <ul className="nav">
              <li className="nav-item">
                {pfpExists ? (
                  <div
                    className="profile-member"
                    style={{ backgroundImage: imageUrl ? "none" : "" }}
                  >
                    <img src={imageUrl} alt="" />
                  </div>
                ) : (
                  <div className="profile-member">
                    {/* <img src = {} alt={}/> */}
                  </div>
                )}
              </li>
              <li className="nav-item">
                <div className="profile-member-details">
                  <h1>
                    {userData.firstname} {userData.lastname}
                  </h1>
                  <p>{userData.email}</p>
                  <Link
                    to="/editprofile"
                    state={{ userData: userData }}
                    className="btn btn-main-default"
                    onClick={() => {
                      setProfileShow(false);
                      updateLink("none");
                    }}
                  >
                    Edit Profile
                  </Link>
                  {/* <br/> */}
                  {/* {cAdmin && <Link to='/user_listing' className = "btn btn-main-default" onClick = {()=>setProfileShow(false)}>Admin Dashboard</Link>} */}
                  {/* <Button className = "btn btn-main-default" onClick = {()=>{setProfileShow(false) 
                                        setEditprofileShow(true)}}>
                                        Edit Profile
                                    </Button> */}
                </div>
              </li>
            </ul>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!loggedout && (
            <div className="subcription-card">
              <h2>Subscription Plan</h2>
              <h3>
                Expires on <span>09 Feb 2023</span>
              </h3>
              <Row>
                <Col sm={12} className="text-end">
                  <Link
                    to="/subscription"
                    className="btn upgrade-btn"
                    onClick={() => {
                      setProfileShow(false);
                      updateLink("none");
                    }}
                  >
                    Upgrate Now
                  </Link>
                </Col>
              </Row>
            </div>
          )}
          <Row className="py-3">
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Language</Form.Label>
                <Form.Select aria-label="English">
                  <option value="1">English</option>
                  {/* <option value="2">Urdu</option> */}
                  {/* <option value="3">Punjabi</option> */}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          {(!admin || cAdmin) && (
            <Row className="py-3">
              <Col sm={6} className="mb-3 mb-sm-0">
                <Link
                  to="/faq"
                  className="btn btn-main-default w-100 d-flex justify-content-center flex-row "
                  style={{ alignItems: "center" }}
                  onClick={() => {
                    setProfileShow(false);
                    updateLink("none");
                  }}
                >
                  <i className="bi bi-question-circle"></i>
                  <span
                    style={{
                      lineHeight: "0px",
                      marginLeft: "5px",
                      fontSize: "15px",
                    }}
                  >
                    Help Center
                  </span>
                </Link>
              </Col>
              <Col sm={6}>
                <Button
                  onClick={handleEmailClick}
                  className="btn btn-main-default w-100 d-flex justify-content-center flex-row"
                  style={{ alignItems: "center" }}
                >
                  <i className="bi bi-envelope"></i>
                  <span
                    style={{
                      lineHeight: "0px",
                      marginLeft: "5px",
                      fontSize: "15px",
                    }}
                  >
                    Email Us
                  </span>
                </Button>
              </Col>
            </Row>
          )}
          <div className="logout-btn">
            <button
              className="btn btn-main-default w-100"
              onClick={logOutHandler}
            >
              Sign Out
            </button>
          </div>
          <div style={{ textAlign: "center" }}>
            <Row>
              <Col sm={6} className="mb-3 mb-sm-0">
                <a
                  className="policy"
                  onClick={() => {
                    onLinkClick("privacy-policy");
                  }}
                >
                  Privacy Policy
                </a>
              </Col>
              <Col sm={6}>
                <a
                  className="policy"
                  onClick={() => {
                    onLinkClick("terms-and-condition");
                  }}
                >
                  Terms & Conditions
                </a>
              </Col>
            </Row>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={notificationShow}
        onHide={() => setNotificationShow(false)}
        style={{ width: width < 600 ? "100%" : "440px" }}
        placement="end"
        className="profile-offcanvas notification-offcanvas"
      >
        <Offcanvas.Header closeButton style={{ maxHeight: "75px" }}>
          <div className="notification-info">
            <ul className="nav notification-header ">
              <li className="nav-item ">All Notifications</li>
              {notificationDetail?.length > 0 && (
                <li className="nav-item" onClick={handleMarkAllRead}>
                  Mark all as read
                </li>
              )}
            </ul>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {loading ? (
            <div
              style={{
                display: "flex",
                minWidth: "150px",
                justifyContent: "center",
                alignItems: "center",
                height: "60%",
              }}
            >
              <Spinner
                as="span"
                animation="border"
                size="xl"
                role="status"
                aria-hidden="true"
                style={{ color: "rgba(22, 94, 91, 1)" }}
              />
            </div>
          ) : (
            <div className="notification-body">
              {notificationDetail?.length > 0 ? (
                notificationDetail?.map((data) => {
                  const userId = localStorage.getItem("userId"); // Get the userId from localStorage
                  const user = data?.users?.find((u) => u.user === userId); // Find the user object that matches the userId

                  const isUnread = user && !user.isRead;
                  const timeAgo = moment(data.createdAt).fromNow();
                  console.log("Loading", isUnread);
                  if (!data) return "Loading...";
                  return (
                    <>
                      <div
                        className={`single-notification ${
                          isUnread && "single-Notification-unread"
                        }`}
                        onClick={() => handleNotificationClick(data)}
                      >
                        {isUnread ? (
                          <span
                            className="unread-dot mb-3"
                            style={{
                              color: "#F94F4F",
                              width: "10px",
                              height: "10px",
                              marginRight: "5px",
                            }}
                          >
                            ●
                          </span>
                        ) : (
                          <span
                            className="unread-dot"
                            style={{
                              visibility: "hidden",
                              width: "10px",
                              height: "10px",
                            }}
                          >
                            ●
                          </span>
                        )}
                        {/* <div className="notification-profile">RM</div> */}
                        <img
                          src={NotificationIcon}
                          alt=""
                          style={{
                            width: "40px",
                            height: "40px",
                            marginRight: "12px",
                          }}
                        />
                        <div className="notification-content">
                          {data?.notification?.webpush?.notification?.body}
                          <div className="notification-time">
                            {timeAgo}
                          </div>{" "}
                          {/* Time ago display */}
                        </div>
                      </div>
                      <hr />
                    </>
                  );
                })
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "70vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={NoNotification} className="mb-4" />
                  <p
                    style={{ fontSize: "16px", fontWeight: "600" }}
                    className="mb-2"
                  >
                    No notifications yet
                  </p>
                  <p style={{ fontSize: "14px", fontWeight: "400" }}>
                    We'll let you know when updates arrive!
                  </p>
                </div>
              )}
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
      <div>
        <Outlet />
      </div>
    </>
  );
};

export default Header;
