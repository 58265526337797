import React, { useState } from 'react'
import { Tooltip, Zoom } from '@mui/material';
import InfoIcon from "../../images/icons/infoIcon.svg"
import "./styles.css"

const ToolTipComp = (props) => {
    const [show, setShow] = useState(false);
    const handleClick = () => {
        if (show) {
            setShow(false);
        } else {
            setShow(true);
        }
    };
    return (
        <>
            <div
                onMouseOver={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
            >
                <Tooltip
                    arrow
                    TransitionComponent={Zoom}
                    title={props?.text}
                    placement={props?.placement ? props?.placement : "top"}
                    sx={{ cursor: "pointer" }}
                    open={show}
                    onClick={handleClick}
                >
                    <img src={InfoIcon} style={{ cursor: "pointer", marginBottom: "5px" }} />
                </Tooltip>
            </div>
        </>
    )
}

export default ToolTipComp
