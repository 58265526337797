import copy from "../../images/icons/copy.svg";

export const AuthTemplate = ({ components, isInbox, isSending }) => {
  return (
    <>
      {components.map((component, index) => {
        if (component.type === "HEADER") {
          return (
            <div className="heading" key={index}>
              {isSending && component?.text?.length > 100
                ? component?.text?.substring(0, 100) + "..."
                : component?.text}
            </div>
          );
        } else if (component.type === "BODY") {
          return (
            <div className="content" key={index}>
              {isSending && component?.text?.length > 100
                ? component?.text?.substring(0, 100) + "..."
                : component?.text}
            </div>
          );
        } else if (component.type === "FOOTER") {
          return (
            <div
              className="content"
              style={{ opacity: "60%", fontSize: "12px" }}
              key={index}
            >
              {isSending && component?.text?.length > 100
                ? component?.text?.substring(0, 100) + "..."
                : component?.text}
            </div>
          );
        } else if (component.buttons[0].type === "URL") {
          return (
            <div className="link-footer1 px-0">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={copy} alt="icon" style={{ width: "15px" }} />

                <p
                  style={{
                    paddingLeft: "5px",
                    color: "#0FA6E0",
                    fontWeight: "600",
                  }}
                >
                  {component.buttons[0].text}
                </p>
              </div>
            </div>
          );
        }
      })}
    </>
  );
};
