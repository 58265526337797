
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import moment from "moment"

import TemplateHeader from "./accordion-header";
import TemplateBodymob from "./template-accordion-body";

import './css/table-listing-mob.css';

const TemplateListingMob = ({ Templates, onChildClick, templates, onClickDelete, showDeleteModal, setShowDeletModal, isDeleting, editTemplate, setSelIndex, selIndex, activeTempIcon, templateData }) => {
    console.log("templates in mobile : ", templates)
    return (
        <>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="template-listing-collapse">
                            <div className="tlc-header">
                                Templates Name
                            </div>
                            <div className="tlc-body">
                                <Accordion defaultActiveKey={templates[0]?.id} >
                                    {
                                        templates?.map((template, index) => (
                                            <Accordion.Item eventKey={template?.id}  >
                                                <Accordion.Header>
                                                    <TemplateHeader TemplateName={template?.name} TemplateDate={moment(template.updatedAt).format("MMM-DD-YYYY")} Badgetext={template?.status} TemplateCreated={template?.createdBy} />
                                                </Accordion.Header>
                                                <Accordion.Body className="pt-0">
                                                    <TemplateBodymob templateDataNew={templateData} activeTempIcon={activeTempIcon} templates={templates} showDeleteModal={showDeleteModal} setShowDeletModal={setShowDeletModal} isDeleting={isDeleting} editTemplate={() => editTemplate(template)} CategoryTitle={template.category.charAt(0).toUpperCase() + template.category.slice(1).toLowerCase()} LanguageTitle={template?.language} onChildC={() => onChildClick(template)} selIndex={selIndex} setSelIndex={(ind) => setSelIndex(ind)} onClickDelete={(name) => onClickDelete(name)} index={index} template={templates[selIndex]} />
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))
                                    }
                                </Accordion>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default TemplateListingMob;