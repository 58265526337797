import React from "react";
import Skeletonn from "react-loading-skeleton";
import Skeleton from "@mui/material/Skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../group-table/group-contact.css";

const TableSkeleton = ({ width, marginTop }) => {
  const renderSkeleton = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "71px",
          backgroundColor: "white",
          borderRadius: "4px",
          padding: "0 20px",
        }}
        className="flex justify-between mb-2"
      >
        <div className="flex align-items-center gap-3">
          <Skeleton width={55} height={90} style={{ borderRadius: "50%" }} />
          <div className="flex flex-col gap-2">
            <Skeleton width={121} height={12} />
            <Skeleton width={112} height={12} />
          </div>
        </div>
        <Skeleton
          width={54}
          height={12}
          className="mt-3"
          style={{ alignSelf: "flex-start" }}
        />
      </div>
    );
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "0",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            marginTop: marginTop || "0px",
            width: width || "83%",
          }}
        >
          {/* <Skeletonn variant="circular" width={40} height={40} /> */}
          {renderSkeleton()}
          {renderSkeleton()}
          {renderSkeleton()}
          {renderSkeleton()}
        </div>
      </div>
    </>
  );
};

export default TableSkeleton;
