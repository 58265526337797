
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import moment from 'moment/moment';

import Username from "./userName/username";
import { generateColorHash } from ".././../utils/utils"
import UsertableStatus from "./user-status";
import Useraction from "./useraction";

import './css/usermob.css';

const ManageUsermob = (props) => {
    console.log("props?.allUsers", props?.allUsers)
    const badgeStyelmob = {
        //backgroundColor: '#FBE2FF',
        color: '#000',
        fontWeight: '600',
        lineHeight: '16px',
        fontSize: '12px',
        borderRadius: '5px',
        padding: '5px 6px',
        margin: '2px',
    }

    // get selected tags values
    const getSelectedTagsValues = (tags) => {
        if (!tags) {
            return []
        }
        let temp = []
        for (let i = 0; i != tags?.length; i++) {
            temp.push({ label: tags[i]?.title, value: tags[i]?._id })
        }
        return temp
    }

    return (
        <>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="template-listing-collapse">
                            <div className="tlc-header">
                                Name
                            </div>
                            <div className="tlc-body">
                                <Accordion defaultActiveKey={props?.allUsers[0]?._id}>
                                    {
                                        props?.allUsers?.map((item, index) => (
                                            <Accordion.Item eventKey={item?._id}>
                                                <Accordion.Header>
                                                    <Username name={item?.firstname + " " + item?.lastname} Useremail={item?.email} />
                                                </Accordion.Header>
                                                <Accordion.Body className="pt-0">
                                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                                        <div className="ub-title">Tags</div>
                                                        <>
                                                            {
                                                                getSelectedTagsValues(item?.tags)?.map((item) => (
                                                                    <span style={{ ...badgeStyelmob, backgroundColor: generateColorHash(item?.value?.substring(0, 10)) }} >{item?.label}</span>
                                                                ))
                                                            }
                                                        </>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                                        <div className="ub-title">Role</div>
                                                        <div className="Role">
                                                            {item?.role}
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                                        <div className="ub-title">Status</div>
                                                        <div className="status-info">
                                                            <UsertableStatus statusTitle={item?.isapproved ? "Active" : "Inactive"} statusClass={item?.isapproved ? 'active' : 'disactivated'} />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                                        <div className="ub-title">Created At</div>
                                                        <div className="cb-date">{moment(item?.createdAt).format("MMM-DD-YYYY")}</div>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <Useraction handleClickEditUser={() => props?.handleClickEditUser(item)} handleClickDeleteUser={() => props?.handleClickDeleteUser(item)} />
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))
                                    }

                                </Accordion>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ManageUsermob;