

import { Link } from "react-router-dom";
import { Form, Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

import './form.css';
import { useState } from "react";

const Authform = ({ goback, checkAuth, successMessage, errorMessage, setErrorMessage, showback, isSendingReq }) => {

    const [code, setCode] = useState("")


    function isStringOnlyNumbers(str) {
        return /^\d+$/.test(str);
    }

    function handleChange(e) {
        setCode(e.target.value)

        if (e.target.value.length !== 6) {
            setErrorMessage("Auth code can only be of 6 digits")
        }
        else if (!isStringOnlyNumbers(e.target.value)) {
            setErrorMessage("Auth code can only consist of numbers")
        }
        else {
            setErrorMessage("")
        }
    }

    function submit(e) {
        e?.preventDefault()
        if (code !== '') { checkAuth(code) }
    }

    const disabled = code.length == 0 || errorMessage.length > 0

    return (
        <div className="form-sec">
            <Form>
                <div className="form-heading text-center">
                    Enter Authentication Code
                </div>
                <Form.Group className="form-group" style={{ marginBottom: '10px' }}>
                    <Form.Label style={{ fontSize: '14px' }}>Get code from your authentication app</Form.Label>
                    <Form.Control type="string" placeholder="xxxxxx" onChange={handleChange} required={true} />
                </Form.Group>
                {errorMessage.length > 0 && <Form.Group className="text-center last-pra" style={{ marginBottom: '10px', color: 'red', fontWeight: '500' }}>
                    {errorMessage}
                </Form.Group>}

                {successMessage.length > 0 && <Form.Group className="text-center last-pra" style={{ marginBottom: '10px', color: 'green', fontWeight: '500' }}>
                    {successMessage}
                </Form.Group>}
                <Form.Group className="form-group">
                    {
                        isSendingReq ?
                            <Button className="btn btn-main" style={disabled ? { backgroundColor: '#165E5B', border: 'none' } : {}}>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ color: "#FFFFFF" }}
                                />
                            </Button>
                            :
                            <Button className="btn btn-main" type="submit" onClick={submit} disabled={disabled} style={disabled ? { backgroundColor: '#165E5B', border: 'none' } : {}}>
                                Authenticate
                            </Button>
                    }
                </Form.Group>
                {showback && <Form.Group className="text-center last-pra">
                    <Link onClick={goback}>Get Secret Key/QR Code</Link>
                </Form.Group>}
            </Form>
        </div>
    );
}

export default Authform;