import React , {useState, useEffect} from 'react'
import Header from "./header.tsx"
import NoTagsFound from './NoTagsFound.tsx'
import AllTags from './AllTags.tsx'
import { Box } from '@mui/material'
import CustomModal from "../../components/popup/index.tsx"
import axios from 'axios'
import { toast } from "react-toastify";
import config from '../../config.js'
import TableSkeleton from '../../components/skeletons/TableLoader'

const baseUrl = `${config.backend}tags/`

interface TagType {
    _id: string;
    title: string;
    tagKey : string,
    company: {
        _id: string;
        firstname: string;
        lastname: string;
        email: string;
    }
}

interface tagData  {
  title : string
  id?: string
  tagKey : string
}

const Tags = () => {
  const [ showUpdatePopup , setShowUpdatePopup ] = useState<Boolean>(false)
  const [ showDeletePopup , setShowDeletePopup ] = useState<Boolean>(false)
  const [ showCreatePopup , setShowCreatePopup ] = useState<Boolean>(false)
  const [ isCreatingTag , setIsTagCreating ] = useState<Boolean>(false)
  const [ isUpdatingTag , setIsUpdatingTag ] = useState<Boolean>(false)
  const [ isDeletingTag , setIsDeletingTag ] = useState<Boolean>(false)
  const [ errMsg , setErrMsg ] = useState<String>("")

  const [newTagData , setNewTagData ] = useState<tagData>({title : "", tagKey : ""})
  const [editTagData , setEditTagData ] = useState<tagData>({title : "", tagKey : "", id: ""})
  const [deleteTagData , setdeleteTagData ] = useState<tagData>({title : "", tagKey : "", id: ""})

  const [ allTags , setAllTags ] = useState([])
  const [ allOrgTags , setAllOrgTags ] = useState([])
  const [ isAllTagsFetching , setAllTagsFetching ] = useState<Boolean>(false)

    // START ****** create new tag functions
    const handleClickAddNewTag = () => {
        setErrMsg("")
        setNewTagData({title : ""})
        setShowCreatePopup(true)
    }

    // function for api call create
    const handleOnClickCreateTag = async () => {
      console.log("newTagData?.tagKey", newTagData?.tagKey)
      if(newTagData?.title == ""){
        setErrMsg("Tag Name is required")
        return false
      }
      if(newTagData?.tagKey == "" || newTagData?.tagKey == undefined){
        setErrMsg("Tag key is required")
        return false
      }
      setIsTagCreating(true)
      try{
        const {data} = await axios.post(`${baseUrl}/addNew`, newTagData)
        console.log("new tag sent data", data)
        if(data?.success){
          setIsTagCreating(false)
          setShowCreatePopup(false)
          setNewTagData({title : ""})
          getAllTags()
        }else{
          setErrMsg(data?.message)
        }
      }catch(err){
        setIsTagCreating(false)
        toast.error("something went wrong, Please try again")
      }
      setIsTagCreating(false)
    }

    const handleOnClickCancel = () => {
        setShowCreatePopup(false)
        setErrMsg("")
        setIsTagCreating(false)
    }
    // END ****** create new tag functions

    // START *******  edit tag functions
    const handleClickEditTag = (itemData: TagType) => {
        console.log("itemData",itemData)
        setErrMsg("")
        setShowUpdatePopup(true)
        setEditTagData({title : itemData?.item?.title, tagKey : itemData?.item?.tagKey , id : itemData?.item?._id})
    }

    // function for api call edit
    const handleOnClickEditTag = async () => {
      if(editTagData?.title == ""){
        setErrMsg("Tag Name is required")
        return false
      }
      if(editTagData?.tagKey == ""){
        setErrMsg("Tag key is required")
        return false
      }
      setIsUpdatingTag(true)
      setErrMsg("")
      try{
        let sendingData = {
          title : editTagData?.title,
          tagKey : editTagData?.tagKey,
        }
        const {data} = await axios.put(`${baseUrl}update/${editTagData?.id}`, sendingData)
        if(data?.success){
          setIsUpdatingTag(false)
          setShowUpdatePopup(false)
          setEditTagData({title : "", id : ""})
          getAllTags()
        }else{
          setErrMsg(data?.message)
        }
      }catch(err){
        setIsUpdatingTag(false)
        toast.error("something went wrong, Please try again")
      }
      setIsUpdatingTag(false)
    }

    const handleOnClickCancelEditTag = () => {
        setEditTagData({title : "", id : ""})
        setShowUpdatePopup(false)
        setIsUpdatingTag(false)
    }
    // END ******** edit tag functions


    // START ***** delete tag functions
    const handleClickDeleteTag = (itemData: TagType) => {
      console.log("itemData",itemData)
      setErrMsg("")
      setdeleteTagData({title : itemData?.item?.title, id : itemData?.item?._id})
      setShowDeletePopup(true)
    }

    const handleOnClickCancelDeleteTag = () => {
        setShowDeletePopup(false)
        setdeleteTagData({title : "", id : ""})
        setIsDeletingTag(false)
    }
    // function for handling api
    const handleOnClickDeleteTag = async () => {
        setIsDeletingTag(true)
        setErrMsg("")
        try{
          const {data} = await axios.delete(`${baseUrl}delete/${deleteTagData?.id}`)
          if(data?.success){
            setIsDeletingTag(false)
            setShowDeletePopup(false)
            setdeleteTagData({title : "", id : ""})
            getAllTags()
          }else{
            setErrMsg(data?.message)
          toast.error(data?.message)
          setShowDeletePopup(false)

          }
        }catch(err){
          setIsDeletingTag(false)
          toast.error("something went wrong, Please try again")
        }
        setIsDeletingTag(false)
    }
    // END ******* delete tag functions

    // get all tags
    const getAllTags = async () => {
      setAllTagsFetching(true)
      try{
        const {data} = await axios.get(`${baseUrl}`)
        console.log("all tags found====", data)
        if(data?.success){
          setAllTagsFetching(false)
          setAllTags(data?.allTags)
          setAllOrgTags(data?.allTags)
        }
      }catch(err){
        setAllTagsFetching(false)
      }
    }

    useEffect(() => {
      getAllTags()
    },[])

    // get searched tags
    const getSearchedTags = async (value: string) => {
      if(allOrgTags?.length > 0){
        if(value?.length > 0){
            let matchedRecords = allOrgTags?.filter(item => item?.title?.toLowerCase()?.includes(value?.toLowerCase()));
            setAllTags(matchedRecords)
        }else{
          setAllTags(allOrgTags)
        }
      }
    }

  return (
    <>
      <Box className="bg-[#ecf1f9] flex flex-column justify-start align-items-start" sx={{height : "calc(100vh - 70px)"}} >
        <Header getSearchedTags={getSearchedTags} handleClickAddNewTag={handleClickAddNewTag} />
            {
              isAllTagsFetching ?
              <TableSkeleton marginTop="30px"/>
                :
              allOrgTags?.length == 0 ?
                <NoTagsFound handleClickAddNewTag={handleClickAddNewTag} />
                :
                <AllTags
                      handleClickEditTag={handleClickEditTag}
                      handleClickDeleteTag={handleClickDeleteTag}
                      allTags={allTags}
                    />
            }
      </Box>

      {/* Create Tag */}
        <CustomModal
          isOpen={showCreatePopup}
          modalTitle="Create Tag"
          isCreateNewTag
          isTextBold
          errMsg={errMsg}
          isSending={isCreatingTag}
          tagTitle={newTagData?.title}
          onChangeData={(val: string, key : string) => setNewTagData(prev => ({...prev , [key] : val}) )}
          onOkClick={handleOnClickCreateTag}
          onCancelClick={handleOnClickCancel}
        />

      {/* Edit Tag */}
      <CustomModal
          isOpen={showUpdatePopup}
          modalTitle="Edit Tag"
          isEditTag
          errMsg={errMsg}
          isTextBold
          isSending={isUpdatingTag}
          tagTitle={editTagData?.title}
          tagKey={editTagData?.tagKey}
          onChangeData={(val: string, key : string) => setEditTagData(prev => ({...prev , [key] : val}) )}
          onOkClick={handleOnClickEditTag}
          onCancelClick={handleOnClickCancelEditTag}
      />

      {/* Delete Tag */}
      <CustomModal
          isOpen={showDeletePopup}
          modalTitle="Confirm"
          isHideCrossSign
          isDeleteTag
          isTextBold
          isSending={isDeletingTag}
          OKText="Yes, Delete it"
          cancelText='Cancel'
          contentText={`<p>You are about to delete Tag <span style="fontWeight : 700" >"${deleteTagData?.title}" </span> from your Tags. This process cannot be reversed.</p>`}
          onCancelClick={handleOnClickCancelDeleteTag}
          onOkClick={handleOnClickDeleteTag}
      />
    </>
  )
}

export default Tags
