
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect, useContext, useRef } from 'react';
import WindowWidthDetector from "../components/customHooks/DeviceWidthDetector"
import readmessage from "../images/icons/read.svg";
import Pending from "../images/icons/pending.svg";
import Send_message from "../images/icons/send.svg";
import Expired from "../images/icons/expired.svg";
import NoDataFound from "./template-listing-empty.js"
import Calendar from "../images/company/calendar.svg";

import Broadcastcard from "../components/broadcast/broadcast-card";
import Usertable from "../components/user-listing/usertable";

import "../components/user-listing/userlisting.css";
import restoreIcon from "../images/icons/ic_restore.svg"
import restoreIcon1 from "../images/icons/ic_restore1.svg"
import UserContext from '../store/user-context'
import { getAllCompanyUsers, reverseDeleteUser, createUser, deleteUser, editApproval, getCompanyMessages } from "../services/User";
import { getAllChats } from "../services/Inbox";
import { getAllBroadcasts } from "../services/Broadcast";
import { getPhoneNumber } from "../services/Company";
import { Spinner } from "react-bootstrap";

const Userlisting = () => {

    return (
        <>
            <NoDataFound isEmpty={true} emptyText="Coming Soon" />
        </>
    )

    const width = WindowWidthDetector()
    const [ctshow, setCtShow] = useState(false);
    const [cbshow, setCbShow] = useState(false);
    const [cvsshow, setCvsShow] = useState(false);
    const [cvsshow1, setCvsShow1] = useState(false);
    const [expiredhow, setExpiredShow] = useState(false);
    const [loading, setLoading] = useState(false)
    const [broadcasts, setBroadcasts] = useState([])
    const [chats, setChats] = useState([])
    const btn = useRef(null);

    const { userState: { userId, companyId }, setUserState } = useContext(UserContext)

    const [active, setActive] = useState(0);

    const [company, setCompany] = useState([{}])
    const [allUsers, setAllUsers] = useState([])
    const [updateUi, setUpdateUi] = useState(false)
    const [usersInfo, setUsersInfo] = useState({
        active: 0,
        pending: 0,
        expired: 0
    })
    const [inputValues, setInputValues] = useState({
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        confirmPassword: '',
        companyId: ''
    })

    const [inputErrors, setInputErrors] = useState({
        firstname: { hasError: false, errorMessage: '' },
        lastname: { hasError: false, errorMessage: '' },
        email: { hasError: false, errorMessage: '' },
        password: { hasError: false, errorMessage: '' },
        confirmPassword: { hasError: false, errorMessage: '' }
    });

    const [date, setDate] = useState(null)

    const [messageCount, setMessageCount] = useState(0)
    const [phoneNum, setPhoneNum] = useState('')
    const [showCode, setShowCode] = useState('react');
    const [_id, set_id] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [confirmationMessage, setConfirmationMessage] = useState("");
    const [restore_icon, setRestoreIcon] = useState(-1);


    const toggleCode = (codeType) => {
        setShowCode(codeType);
    };

    function validateForm() {
        console.log(inputValues)
        if (inputValues.firstname === '') {
            console.log('First name cannot be empty.')
            return false
        }
        if (inputValues.lastname === '') {
            console.log('last name cannot be empty.')
            return false
        }
        if (inputValues.email === '') {
            console.log('Email cannot be empty.')
            return false
        }
        if (inputValues.password === '') {
            console.log('passwords cannot be empty.')
            return false
        }
        if (inputValues.password !== inputValues.confirmPassword) {
            console.log('passwords do not match.')
            return false
        }
        return true
    }

    const validateInput = (name, value) => {
        // perform validation and return error message if there's an error, otherwise return null
        console.log(name, value)

        if (name === 'firstname' && value !== '' && !/^[a-zA-Z ]+$/.test(value)) {
            return 'First name should only contain letters and spaces.';
        }
        if (name === 'firstname' && value == '') {
            return 'First name is required';
        }

        if (name === 'lastname' && value !== '' && !/^[a-zA-Z ]+$/.test(value)) {
            return 'Last name should only contain letters and spaces.';
        }
        if (name === 'lastname' && value == '') {
            return 'Last name is required';
        }
        if (name === 'email') {
            if (value.trim() === '') {
                return 'Email is required.';
            }
            else if (!/^\S+@\S+\.\S+$/.test(value)) {
                return 'Email is invalid.';
            }
        }

        if (name === 'password' && value.trim() === '') {
            return 'Password is required.';
        }

        if (name === 'password' && value.length < 8) {
            return 'Password should be at least 8 characters long.';
        }

        if (name === 'confirmPassword' && value.trim() === '') {
            return 'Confirm Password is required.';
        }

        if (name === 'confirmPassword' && value !== inputValues.password) {
            return 'Confirm Password does not match Password.';
        }

        // add more validation checks for other fields here

        return null;
    }

    useEffect(() => {
        if (!ctshow) {
            setInputValues({ firstname: '', lastname: '', email: '', password: '', confirmPassword: '', companyId: '' })
        }
    }, [ctshow])

    const handleClick = (index) => {
        setActive(index);
    }

    useEffect(() => {

        const fetchData = async () => {
            setLoading(true)
            try {
                const resp = await getAllCompanyUsers()

                if (resp.success) {
                    setAllUsers(resp.users)
                    setCompany(resp.company)
                }
                else
                    throw new Error(resp.error)

            } catch (error) {
                console.log(error.message)
                console.log('Failed to fetch Users!')
            }
            setLoading(false)
        }

        fetchData()
    }, [updateUi])


    useEffect(() => {
        const fetchData = async () => {
            try {
                const resp = await getCompanyMessages()
                setLoading(true)
                if (resp.success) {
                    setMessageCount(resp.messageCount)
                }
                else
                    throw new Error(resp.error)
                setLoading(false)
            } catch (error) {
                console.log(error.message)
                console.log('Failed to fetch Messages!')
            }
        }

        fetchData()
    }, [])

    useEffect(() => {
        const getSupportBotCode = async () => {
            try {
                const resp = await getPhoneNumber(companyId)
                if (resp.success) {
                    const cleanedNumber = resp.number.replace(/\D/g, '');
                    setPhoneNum(cleanedNumber)
                }
            }
            catch (e) {
                console.log(e)
            }
        }

        if (cbshow)
            getSupportBotCode()

    }, [cbshow])



    console.log(chats)
    console.log(broadcasts)
    console.log(messageCount)


    useEffect(() => {
        const today = new Date();
        const day = today.getDate().toString().padStart(2, "0");
        const month = (today.getMonth() + 1).toString().padStart(2, "0"); // January is 0!
        const year = today.getFullYear().toString();
        const formattedDate = `${day}/${month}/${year}`;
        console.log(formattedDate); // outputs "03/02/2023"
        setDate(formattedDate)
    }, [])

    useEffect(() => {
        let activeCount = 0;
        let pendingCount = 0;
        let expiredCount = 0;

        allUsers.forEach((user) => {
            if (user.isdeleted) {
                expiredCount++;
            }
            else {
                if (user.isapproved) {
                    activeCount++;
                } else {
                    pendingCount++;
                }
            }
        });

        setUsersInfo({
            active: activeCount,
            pending: pendingCount,
            expired: expiredCount,
        });
    }, [allUsers]);

    function handleUnDelete(event) {
        const userID = event.target.closest('tr').dataset.userId;
        // Retrieve user using userID and update isdeleted flag
        console.log(userID)
        setLoading(true);
        reverseDeleteUser(userID)
            .then(response => {
                console.log(response);
                setUpdateUi((prevData) => {
                    return !prevData
                })
                // setExpiredShow(false);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
    }

    const inputChangeHandler = (e) => {
        const { name, value } = e.target;

        const errorMessage = validateInput(name, value);
        setInputErrors(prevState => ({
            ...prevState,
            [name]: {
                hasError: Boolean(errorMessage),
                errorMessage: errorMessage || ''
            }
        }));

        setInputValues({
            ...inputValues,
            [e.target.name]: e.target.value.trimStart()
        });
    }
    console.log(inputErrors)

    const addUser = async (e) => {
        inputValues.companyId = companyId

        const errors = {};
        Object.keys(inputValues).forEach((name) => {
            const error = validateInput(name, inputValues[name]);
            if (error) {
                errors[name] = { hasError: true, errorMessage: error };
            } else {
                errors[name] = { hasError: false, errorMessage: '' };
            }
        });
        setInputErrors(errors);

        let hasError = false;
        Object.keys(errors).forEach((name) => {
            if (errors[name].hasError) {
                hasError = true;
            }
        });

        if (hasError) {
            // There is at least one error
            return;
        }

        if (validateForm() === true) {
            console.log(true)
            try {
                const resp = await createUser(inputValues)
                if (resp.success) {
                    setCtShow(false)
                    console.log('User registered successfully')
                    setUpdateUi((prevData) => {
                        return !prevData
                    })
                }
                else
                    throw new Error(resp.error)
            } catch (error) {
                console.log('Error Signing Up. Please try again.')
                console.log(error.message)
                if (error.message.includes("A user with this email already exists")) {
                    setInputErrors(prevState => ({
                        ...prevState,
                        email: {
                            ...prevState.email,
                            hasError: true,
                            errorMessage: error.message
                        }
                    }))
                }
                else if (error.message.includes("E11000 duplicate key error collection:")) {
                    setInputErrors(prevState => ({
                        ...prevState,
                        email: {
                            ...prevState.email,
                            hasError: true,
                            errorMessage: "A user with this email already exists"
                        }
                    }))
                }
            }
        }
    }

    const submitDelete = (_id) => {
        deleteUser(_id)
            .then(response => {
                console.log(response);
                setUpdateUi((prevData) => {
                    return !prevData
                })
            })
            .catch(error => {
                console.error(error);
            });

        return
    }

    const submitEdit = async (data) => {
        try {
            // Display confirmation modal
            setShowConfirmation(true);
            console.log(data)
            setConfirmationMessage(`Are you sure you want to change the approval status of '${data.firstname} ${data.lastname}' to ${data.isapproved ? "Inactive" : "Active"}.`)
            set_id(data._id)
        } catch (error) {
            console.error(error);
        }
    };

    const handleProceed = async () => {
        try {
            setLoading(true);
            setShowConfirmation(false); // Close the modal after processing
            const response = await editApproval(_id); // Assuming _id is defined somewhere
            console.log(response);
            setUpdateUi((prevData) => !prevData);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            set_id(null)
            setConfirmationMessage(false)
        }
    };

    const handleCancel = () => {
        // Close the modal without proceeding
        setShowConfirmation(false);
        set_id(null)
        setConfirmationMessage(false)
    };


    const reactCode =
        `<div id="gb-widget-3951" style={{ bottom: '14px', right: '16px', opacity: 1, transition: 'opacity 0.5s ease 0s', boxSizing: 'border-box', direction: 'ltr', textAlign: 'right', position: 'fixed', zIndex: '16000160' }}>
    <div className="q8c6tt-2 eiGYSu" style={{ display: 'flex', margin: '7px', position: 'relative', WebkitBoxPack: 'end', justifyContent: 'flex-end', maxHeight: '50px' }}>
        <a size="50" href=https://wa.me/${phoneNum} target="_blank" color="#4dc247" id="getbutton-whatsapp" className="q8c6tt-0 jWcIXO" style={{ flexShrink: 0, width: '50px', height: '50px', order: 2, padding: '5px', boxSizing: 'border-box', borderRadius: '50%', cursor: 'pointer', overflow: 'hidden', boxShadow: 'rgba(0, 0, 0, 0.4) 2px 2px 6px', transition: 'all 0.5s ease 0s', position: 'relative', zIndex: 200, display: 'block', border: '0px', backgroundColor: 'rgb(77, 194, 71)' }}>
            <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{ width: '100%', height: '100%', fill: 'rgb(255, 255, 255)', stroke: 'none' }}>
                <path d="M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"></path>
            </svg>
        </a>
    </div>
</div>`;

    const htmlCode =
        `<div id="gb-widget-3951" style="bottom: 14px; right: 16px; opacity: 1; transition: opacity 0.5s ease 0s; box-sizing: border-box; direction: ltr; text-align: right; position: fixed; z-index: 16000160;">
    <div class="q8c6tt-2 eiGYSu" style="display: flex; margin: 7px; position: relative; -webkit-box-pack: end; justify-content: flex-end; max-height: 50px;">
        <a size="50" href="https://wa.me/${phoneNum}" target="_blank" color="#4dc247" id="getbutton-whatsapp" class="q8c6tt-0 jWcIXO" style="flex-shrink: 0; width: 50px; height: 50px; order: 2; padding: 5px; box-sizing: border-box; border-radius: 50%; cursor: pointer; overflow: hidden; box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 6px; transition: all 0.5s ease 0s; position: relative; z-index: 200; display: block; border: 0px; background-color: rgb(77, 194, 71);">
            <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style="width: 100%; height: 100%; fill: rgb(255, 255, 255); stroke: none;">
                <path d="M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"></path>
            </svg>
        </a>
    </div>
</div>`

    return (
        <>
            <section className="main inner-main broadcast-sec">
                <section className="contact-group-top">
                    <Container fluid>

                        <Row>
                            <Col sm={12} className="mb-3">
                                <div className="overview-heading">
                                    Overview
                                </div>
                            </Col>

                            <Col sm={12} className="mb-4 mb-lg-5">
                                <div className="overview-header rounded-pill">
                                    <Row className="align-items-center">
                                        <Col md="4">
                                            {/* <div className = "date-side">
                                            <img src = {Calendar} alt = "calendar-icon"/> {date}
                                        </div> */}
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>

                        <Row className="pb-3">
                            <Col md="4" lg={3} xl={3}>
                                <div className="delivered-message company-card">
                                    <Broadcastcard title={'All Users'} imageicon={Send_message} number={usersInfo.active + usersInfo.pending + usersInfo.expired} loading={loading} />
                                </div>
                            </Col>
                            <Col md="4" lg={3} xl={3}>
                                <div className="message-send company-card" onClick={() => setCvsShow(true)}>
                                    <Broadcastcard title={'Active users'} imageicon={readmessage} number={usersInfo.active} loading={loading} />
                                </div>
                            </Col>

                            <Col md="4" lg={3} xl={3}>
                                <div className="pending company-card" onClick={() => setCvsShow1(true)}>
                                    <Broadcastcard title={'Pending users'} imageicon={Pending} number={usersInfo.pending} loading={loading} />
                                </div>
                            </Col>
                            <Col md="4" lg={3} xl={3}>
                                <div className="failed company-card" onClick={() => setExpiredShow(true)}>
                                    <Broadcastcard title={'Deleted users'} imageicon={Expired} number={usersInfo.expired} loading={loading} />
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </section>
                <section className="contact-group-table">
                    <Container fluid>
                        <Row className="py-3 pb-0 align-items-center flex-md-row flex-column justify-content-between mb-4 " style={{ rowGap: "15px" }} >
                            <div style={{ width: width < 600 ? "100%" : "30%" }}>
                                <p className="overview-heading  " style={{ textAlign: width < 600 ? "center" : "" }} >
                                    {company[0].companyName} users
                                </p>
                            </div>
                            <div style={{ width: width < 600 ? "100%" : "60%", }}>
                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: "100%", }} >
                                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <div style={{ width: "100%", display: "flex", justifyContent: width < 600 ? "center" : "flex-end", alignItems: "center", gap: "10px" }}>
                                            <Button className="btn-main add-company" onClick={() => setCbShow(true)}>
                                                Get Chatbot Code
                                            </Button>
                                            <Button className="btn-main add-company" onClick={() => setCtShow(true)}>
                                                <i className="bi bi-plus"></i> Add User
                                            </Button>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Usertable data={allUsers} submitDelete={submitDelete} submitEdit={submitEdit} loading={loading} />
                            </Col>
                        </Row>
                    </Container>
                </section>

            </section>


            <Modal show={ctshow} onHide={() => setCtShow(false)} className="whatsapp-modal modal-contact" centered>
                <Modal.Header className="p-0" closeButton>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            <Form.Group>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" name='firstname' placeholder='Enter First Name' onChange={inputChangeHandler} className={inputErrors.firstname.hasError ? "is-invalid" : ""} required={true} />
                                <Form.Control.Feedback type="invalid">
                                    {inputErrors.firstname.hasError && inputErrors.firstname.errorMessage}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Form.Group>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" name='lastname' placeholder='Enter Last Name' onChange={inputChangeHandler} className={inputErrors.lastname.hasError ? "is-invalid" : ""} required={true} />
                                <Form.Control.Feedback type="invalid">
                                    {inputErrors.lastname.hasError && inputErrors.lastname.errorMessage}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control name='email' type='email' placeholder='Enter Email' onChange={inputChangeHandler} className={inputErrors.email.hasError ? "is-invalid" : ""} required={true} />
                                <Form.Control.Feedback type="invalid">
                                    {inputErrors.email.hasError && inputErrors.email.errorMessage}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Form.Group>
                                <Form.Label>Password</Form.Label>
                                <Form.Control name='password' placeholder='Enter Password' type="password" onChange={inputChangeHandler} className={inputErrors.password.hasError ? "is-invalid" : ""} required={true} />
                                <Form.Control.Feedback type="invalid">
                                    {inputErrors.password.hasError && inputErrors.password.errorMessage}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Form.Group>
                                <Form.Label>Confirm password</Form.Label>
                                <Form.Control name='confirmPassword' placeholder='Confirm Password' type="password" onChange={inputChangeHandler} className={inputErrors.confirmPassword.hasError ? "is-invalid" : ""} required={true} />
                                <Form.Control.Feedback type="invalid">
                                    {inputErrors.confirmPassword.hasError && inputErrors.confirmPassword.errorMessage}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="px-0">
                    <Button className="btn-main-default" onClick={() => setCtShow(false)}>
                        Cancel
                    </Button>
                    <Button className="btn-main" onClick={() => addUser()}>
                        Add User
                    </Button>

                </Modal.Footer>
            </Modal>

            <Modal show={cbshow} onHide={() => setCbShow(false)} className="whatsapp-modal modal-contact" centered>
                <Modal.Header className="p-0" closeButton>
                    <Modal.Title>Chatbot code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="code-buttons" style={{ marginBottom: '10px' }}>
                        <Button className="code-select-btn" style={{ borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', backgroundColor: showCode == 'react' && '#165E5B' }} variant='secondary' onClick={() => toggleCode('react')}>
                            React Code
                        </Button>
                        <Button className="code-select-btn" style={{ borderTopRightRadius: '5px', borderBottomRightRadius: '5px', backgroundColor: showCode == 'html' && '#165E5B' }} variant='secondary' onClick={() => toggleCode('html')}>
                            HTML Code
                        </Button>
                    </div>
                    <h5>Code:</h5>
                    {phoneNum.length > 0 ?
                        <pre style={{ backgroundColor: '#f4f4f4', padding: '10px', borderRadius: '4px' }}>
                            <code>{showCode === 'react' ? reactCode : htmlCode}</code>
                        </pre> :
                        <div style={{ height: '225px' }}>
                            <div className="placeholder-glow row msg-row mx-0 h-100">
                                <span className="col-12 placeholder h-100"></span>
                            </div>
                        </div>}
                </Modal.Body>
            </Modal>

            <Modal show={cvsshow} onHide={() => setCvsShow(false)} className="whatsapp-modal modal-csv" centered>
                <Modal.Header className="p-0" closeButton>
                    <Modal.Title>
                        Active Users ({usersInfo.active})
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <Row>
                        <Col sm={12}>
                            <div className="modal-table">
                                <div className="table-responsive">
                                    {
                                        allUsers.filter(users => users.isapproved)?.length == 0 ?
                                            <div style={{ width: "100%", textAlign: "center", marginTop: "20px" }}>
                                                No data to show
                                            </div>
                                            :
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allUsers.filter(users => users.isapproved).map(users => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    {users.firstname + " " + users.lastname}
                                                                </td>
                                                                <td>
                                                                    {users.email}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal show={cvsshow1} onHide={() => setCvsShow1(false)} className="whatsapp-modal modal-csv" centered>
                <Modal.Header className="p-0" closeButton>
                    <Modal.Title>
                        Pending Users ({usersInfo.pending})
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <Row>
                        <Col sm={12}>
                            <div className="modal-table">
                                <div className="table-responsive">
                                    {
                                        allUsers.filter(users => !users.isapproved && !users.isdeleted)?.length == 0 ?
                                            <div style={{ width: "100%", textAlign: "center", marginTop: "20px" }}>
                                                No data to show
                                            </div>
                                            :
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allUsers.filter(users => !users.isapproved && !users.isdeleted).map(users => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    {users.firstname + " " + users.lastname}
                                                                </td>
                                                                <td>
                                                                    {users.email}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal show={expiredhow} onHide={() => setExpiredShow(false)} className="whatsapp-modal modal-csv" centered>
                {loading ? <div className="spinnerStyle">
                    <Spinner size="large" />
                </div> : null}
                <Modal.Header className="p-0" closeButton>
                    <Modal.Title>
                        Deleted Users ({usersInfo.expired})
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <Row>
                        <Col sm={12}>
                            <div className="modal-table company-listing-table">
                                <div className="table-responsive">
                                    {
                                        allUsers.filter(users => users.isdeleted)?.length == 0 ?
                                            <div style={{ width: "100%", textAlign: "center", marginTop: "20px" }}>
                                                No data to show
                                            </div>
                                            :
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allUsers.filter(users => users.isdeleted).map(users => {
                                                        return (
                                                            <tr key={users._id} data-user-id={users._id}>
                                                                <td>
                                                                    {users.firstname + " " + users.lastname}
                                                                </td>
                                                                <td>
                                                                    {users.email}
                                                                </td>
                                                                <td>
                                                                    Deleted
                                                                </td>
                                                                <td>
                                                                    <Button onMouseLeave={() => { setRestoreIcon(-1) }} onMouseEnter={() => { setRestoreIcon(users._id) }} style={{ display: "flex", justifyContent: 'center', alignItems: "center", columnGap: "5px" }} className="btn-main responsive-btn  "
                                                                        id={`custom-switch-${users._id}`}
                                                                        onClick={handleUnDelete} >
                                                                        <img src={restore_icon == users._id ? restoreIcon : restoreIcon1} /> Restore
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal show={showConfirmation} onHide={handleCancel} className="whatsapp-modal modal-csv" centered>
                <Modal.Header className="p-0" closeButton>
                    <Modal.Title>Confirm Approval Change</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0 pb-2">
                    <Row>
                        <Col sm={10}>
                            <p style={{ margin: '0', padding: '0', fontSize: '1em' }}>{confirmationMessage}</p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="px-0">
                    <Button className="btn-main-default" onClick={handleCancel} disabled={loading}>
                        Cancel
                    </Button>
                    <Button className="btn-main" onClick={handleProceed} disabled={loading}>
                        Proceed
                    </Button>
                </Modal.Footer>
            </Modal>



        </>
    )
}

export default Userlisting;