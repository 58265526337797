import React, { useState, useRef, useEffect, useMemo } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Grouptable from "../components/group-table/g-table";
import Search from "../components/search/search";
import Modaltable from "../components/modal-table/mTable";
import Companyactionbtn from "../components/company-listing/compnay-actionbtn";
import Actionbutton from "../components/tableAction/tableactionBtn1";
import {
  getAllGroups,
  createGroup,
  deleteGroup,
  editGroup1,
} from "../services/Group/index";
import { getAllContacts } from "../services/Contacts";
import moment from "moment";
import { Table } from "antd";
import "./../components/group-table/group-contact.css";
import Allcontacts from "../components/group-table/all-contacts";
import { toast } from "react-toastify";
import WindowWidthDetector from "../components/customHooks/DeviceWidthDetector";
import TableSkelton from "../components/skeletons/TableLoader";

const Contact = () => {
  const width = WindowWidthDetector();
  const [cgshow, setCgShow] = useState(false);
  const [cgtshow, setCgtShow] = useState(false);
  const [cvsshow, setCvsShow] = useState(false);
  const [newgroupshow, setNewgroupShow] = useState(false);
  const [groups, setGroups] = useState([]);
  const [groupsOrgData, setGroupsOrgData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const inputRef = useRef(null);
  const [selectedGroupId, setSelectedGrpId] = useState(null);
  const [allContacts, setAllContacts] = useState([]);
  const [isContactsFetching, setIsContactsFetching] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showError1, setShowError1] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [updateScreen, setUpdateScreen] = useState(false);
  const [groupContacts, setGroupContacts] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleInputChange = (e) => {
    setGroupName(e.target.value);
    if (e.target.value.length > 0) {
      setShowError1(false);
    }
  };

  const handleSelected = (contacts) => {
    setSelectedContacts(contacts);
    if (contacts.length > 0) {
      setShowError(false);
    }
  };

  const handleGroupContacts = (contacts) => {
    console.log("contacts in func", contacts);
    setGroupContacts(contacts);
  };

  const fetchContacts = async () => {
    setIsContactsFetching(true);
    try {
      const resp = await getAllContacts();
      console.log("resp of contacts : ", resp);
      if (resp.success) {
        setAllContacts(resp.data);
        setIsContactsFetching(false);

        fetchData(resp.data);
      } else throw new Error(resp.error);
    } catch (error) {
      console.log(error);
    }
  };

  const addGroup = () => {
    setCgtShow(true);
    if (allContacts?.length < 1) {
      fetchContacts();
    }
  };

  // useEffect(() => {
  //     fetchData()
  // }, [updateScreen])

  useEffect(() => {
    fetchContacts();
  }, []);

  useEffect(() => {
    if (!cgtshow && !newgroupshow) {
      setSelectedContacts([]);
      setGroupName("");
      setShowError(false);
      setSearchQuery1("");
    }
  }, [newgroupshow, cgtshow]);

  const fetchData = async (contacts) => {
    try {
      setLoading(true);
      const resp = await getAllGroups();
      if (resp.success) {
        setGroupsOrgData(
          handleDataForTable(resp.data, contacts || allContacts)
        );
        setGroups(handleDataForTable(resp.data, contacts || allContacts));
        //setGroups(resp.data)
      } else throw new Error(resp.error);
      setLoading(false);
    } catch (error) {
      console.log("Failed to fetch Groups!");
    }
  };

  const createNewGroup = async () => {
    const groupData = {
      groupName: groupName,
      contacts: selectedContacts,
    };
    console.log(groupData);

    try {
      console.log(groupData);
      const resp = await createGroup(groupData);
      if (resp.success) {
        console.log("Group Created successfully");
        setUpdateScreen((prevUpdateScreen) => !prevUpdateScreen);
        fetchData(allContacts);
      } else throw new Error(resp.error);
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    }
  };

  const handleDelete = async (id) => {
    console.log(id);
    const isDeleted = await deleteGroup(id);
    if (isDeleted?.success) {
      setUpdateScreen((prevUpdateScreen) => !prevUpdateScreen);
      fetchData(allContacts);
      console.log("Group Deleted Successfully!");
    } else {
      console.log("Something went wrong");
    }
  };

  const editGroup = async (id, newName, newContacts) => {
    console.log(id, newName, newContacts);
    const resp = await editGroup1({
      id: selectedGroupId,
      newName,
      newContacts,
    });
    if (resp?.success) {
      //setUpdateScreen(prevUpdateScreen => !prevUpdateScreen)
      fetchData(allContacts);
      console.log("Group updated Successfully!");
    } else {
      toast.error(resp.error);
      console.log(resp.error);
    }
  };

  const columns = [
    {
      title: "No#",
      dataIndex: "No",
    },
    {
      title: "Group Name",
      dataIndex: "groupName",
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
    },
    {
      title: "Members",
      dataIndex: "members",
    },
    {
      title: "Actions",
      dataIndex: "Actions",
    },
  ];

  console.log("groupContacts ===", groupContacts);

  // making data for showing rows in table
  const handleDataForTable = (data, contacts) => {
    console.log("allContacts in function", contacts);
    let finalData = [];
    for (let i = 0; i != data.length; i++) {
      finalData.push({
        key: i,
        No: i + 1,
        groupName: data[i]?.groupName,
        createdAt: moment(data[i]?.createdAt).format("DD MMM, YY"),
        members: data[i]?.contacts?.length,
        Actions: (
          <Actionbutton
            onClick={() => setSelectedGrpId(data[i]?._id)}
            handleDelete={handleDelete}
            group={data[i]}
            Contactlist={contacts}
            handleSelected={handleGroupContacts}
            selectedContacts={groupContacts}
            editGroup={editGroup}
          />
        ),
      });
    }

    return finalData;
  };

  useMemo(() => {
    setGroups(handleDataForTable(groupsOrgData, allContacts));
  }, [groupContacts]);

  // search any record
  const getSearchedRecords = (value) => {
    if (value == "" || !value) {
      setGroups(groupsOrgData);
    } else {
      let matched = groupsOrgData.filter((item) =>
        item?.groupName?.toLowerCase()?.includes(value.toLowerCase())
      );

      setGroups(matched);
    }
  };

  // if (loading) {
  //     return (
  //         <TableSkelton />
  //     )
  // }

  return (
    <section className="main inner-main">
      <section className="contact-group-top">
        <Container>
          <Row>
            <Col sm={6} className="mb-3 mb-sm-0">
              <Form.Group className="input-search">
                <Form.Control
                  type="text"
                  placeholder="Search...."
                  onChange={(e) =>
                    getSearchedRecords(e.target.value.trimStart())
                  }
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <div className="btn-sec text-sm-end">
                <Button
                  className="btn-main"
                  onClick={() => addGroup()}
                  style={{ width: width < 600 ? "100%" : "" }}
                >
                  <i className="bi bi-plus"></i> Create Group
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {loading ? (
        <TableSkelton />
      ) : (
        <section className="contact-group-table">
          <Container>
            <Row>
              <Col xs={12}>
                {/* <Grouptable groups={groups} searchQuery={searchQuery} handleDelete={handleDelete} Contactlist={allContacts} handleSelected={handleGroupContacts} selectedContacts={groupContacts} editGroup={editGroup} loading={loading} /> */}
                <Table
                  columns={columns}
                  dataSource={groups}
                  scroll={{
                    x: width < 600 && 1200,
                  }}
                />
              </Col>
            </Row>
          </Container>
        </section>
      )}

      <Modal
        show={cgshow}
        onHide={() => setCgShow(false)}
        className="whatsapp-modal modal-contact"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title>Create Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Group Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Set Group Name i.e Coworkers, Employees"
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button className="btn-main-default" onClick={() => setCgShow(false)}>
            Cancel
          </Button>
          <Button
            className="btn-main"
            onClick={() => {
              setCgShow(false);
              setCgtShow(true);
            }}
          >
            Next
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={cgtshow && allContacts.length > 0}
        onHide={() => setCgtShow(false)}
        className="whatsapp-modal modal-g-contact"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title className="mb-1 w-100">
            Add Group Members
            <span
              className="finish-btn"
              onClick={() => {
                //if (selectedContacts.length > 0) {
                setCgtShow(false);
                setNewgroupShow(true);
                setShowError(false);
                // }
                // else {
                //     setShowError(true)
                // }
              }}
            >
              Next <i className="bi bi-chevron-right"></i>
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Row>
            <Col sm={12}>
              {/* <Button className = "import-cvs" onClick={()=>{setCgtShow(false) 
                                setCvsShow(true)}}>
                                Import Contacts From CSV File
                            </Button> */}
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Search contact to add in group"
                  onChange={(e) => setSearchQuery1(e.target.value.trimStart())}
                />
              </Form.Group>
            </Col>
          </Row>
          {showError && (
            <span style={{ color: "red", fontWeight: "500" }}>
              Select atleast 1 contact *
            </span>
          )}
          <Row>
            <div
              className="col-12"
              style={{
                maxHeight: width < 600 ? "300px" : "",
                height: width < 600 ? "100%" : "",
                overflow: width < 600 ? "auto" : "",
              }}
            >
              {isContactsFetching ? (
                <p>Fetching Contacts please wait...</p>
              ) : (
                <Allcontacts
                  Contactlist={allContacts}
                  handleSelected={handleSelected}
                  selectedContacts={selectedContacts}
                  searchQuery={searchQuery1}
                />
              )}
            </div>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={newgroupshow}
        onHide={() => setNewgroupShow(false)}
        className="whatsapp-modal modal-contact"
        centered
      >
        <Modal.Header className="p-0" closeButton>
          <Modal.Title className="text-center w-100">
            <div
              className="back-btn"
              onClick={() => {
                setNewgroupShow(false);
                setCgtShow(true);
              }}
            >
              <i className="bi bi-chevron-left"></i>
            </div>
            New Group
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Group Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Set Group Name i.e Coworkers, Employees"
                  onChange={handleInputChange}
                  value={groupName}
                  required
                />
                {showError1 && (
                  <span style={{ color: "red", fontWeight: "500" }}>
                    Group name cannot be empty *
                  </span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>
                  Participants: {selectedContacts.length} of{" "}
                  {allContacts.length}
                </Form.Label>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button
            className="btn-main"
            onClick={() => {
              if (groupName.length > 0) {
                setNewgroupShow(false);
                setShowError1(false);
                createNewGroup();
              } else {
                setShowError1(true);
              }
            }}
          >
            Create Group
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={cvsshow}
        onHide={() => setCvsShow(false)}
        className="whatsapp-modal modal-csv"
        centered
      >
        <Modal.Header className="p-0 border-0" closeButton>
          <Modal.Title className="w-100 text-center">
            <div
              className="back-btn"
              onClick={() => {
                setCvsShow(false);
                setNewgroupShow(true);
              }}
            >
              <i className="bi bi-chevron-left"></i>
            </div>
            Import contacts from CSV File
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Row className="mb-1">
            <Col sm={12}>
              <Form.Group controlId="formFile" className="position-relative">
                <Form.Control className="file-up" type="file" ref={inputRef} />
                <div className="input-label" onClick={handleClick}>
                  Upload
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="modal-table">
                <Modaltable />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Button className="import-cvs">
                Import Contacts From CSV File
              </Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button
            className="btn-main-default"
            onClick={() => setCvsShow(false)}
          >
            Cancel
          </Button>
          <Button className="btn-main" onClick={() => setCvsShow(false)}>
            Import Contact
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default Contact;
