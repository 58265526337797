import React, { ReactNode } from 'react'
import {Button} from '@mui/material';
import "./styles.css"

interface propsTypes {
    disablePadding?: boolean,
    text: string,
    backgroundColor?: String,
    height?: String,
    color?:string,
    width?: string,
    fontSize?: String,
    children?: ReactNode,
    handleOnClick: () => void,
}

const CustomButton: React.FC<propsTypes> = (props) => {
    const handleClickPress = () => {
        if(props?.handleOnClick != undefined){
            props?.handleOnClick()
        }
    }
    const Children = props?.children

    const buttonStyle = {
        width : props?.width ? props?.width : "auto",
        backgroundColor : props?.backgroundColor ?  props?.backgroundColor :  "#165e5b",
        height : props?.height ?  props?.height :  "38px",
        "&:hover": {
            backgroundColor: props?.backgroundColor ?  props?.backgroundColor :  "#165e5b"
        }
    }
  return (
    <>
        <Button
                variant="contained"
                className="mui-btn"
                disableRipple
                sx={buttonStyle}
                onClick={handleClickPress}
            >
            {Children}
            <p className="mui-btn-text" style={{
                    paddingRight : Children ? "" : "0px",
                    paddingLeft : !Children ? "10px" : "",
                    paddingRight : !Children ? "10px" : "",
                    fontSize : props?.fontSize ? props?.fontSize : "",
                    color : props?.color ? props?.color : "",
                }} >{props?.text}</p>
        </Button>
    </>
  )
}

export default CustomButton
