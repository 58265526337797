
import Form from "react-bootstrap/Form";

const Forminput = ({label,type,value}) => (
    <>
        <Form.Group className = "form-group">
            <Form.Label>
                {label}
            </Form.Label>
            <input className = "form-control" type = {type} placeholder = {value} />
        </Form.Group>
    </>
)

export default Forminput;