
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './footer.css'
import { useNavigate } from 'react-router-dom';

export const FooterComponent = () => {

    const navigate = useNavigate();
    const onClick = (e) => {
        navigate(e);
    }
    return (
        <div style={{ bottom: 0, position: "fixed", height: "50px", width: "100%" }}  >
            <div className='text-center p-3' style={{ backgroundColor: 'var(--bg-primary)' }}>
                <a className='footer-comp' style={{ color: 'white ', marginRight: "100px", fontSize: "12px" }} onClick={() => {
                    onClick("/privacy-policy")
                }} >
                    Privacy Policy
                </a>
                <a className='footer-comp' style={{ color: 'white ', fontSize: "12px" }} onClick={() => {
                    onClick("/terms-and-condition")
                }}
                >
                    Terms & Condition
                </a>
            </div>
        </div>
    );
}