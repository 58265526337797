import React from "react";

const Username = ({nameletter,index, name}) => {

    const getRandomColor = (index) => {
        // Calculate hue value based on the index to get distinct colors
        const hue = (index * 50) % 360; // You can adjust the multiplier (50) to change the color range
        
        // Set constant saturation and lightness values
        const saturation = 50;
        const lightness = 50;
      
        // Convert HSL to RGB values
        const hslToRgb = (h, s, l) => {
          h /= 360;
          s /= 100;
          l /= 100;
          let r, g, b;
      
          if (s === 0) {
            r = g = b = l; // Achromatic
          } else {
            const hue2rgb = (p, q, t) => {
              if (t < 0) t += 1;
              if (t > 1) t -= 1;
              if (t < 1 / 6) return p + (q - p) * 6 * t;
              if (t < 1 / 2) return q;
              if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
              return p;
            };
      
            const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            const p = 2 * l - q;
            r = hue2rgb(p, q, h + 1 / 3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1 / 3);
          }
      
          const toHex = (x) => {
            const hex = Math.round(x * 255).toString(16);
            return hex.length === 1 ? '0' + hex : hex;
          };
      
          return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
        };
      
        return hslToRgb(hue, saturation, lightness);
      };
      
    const color = getRandomColor(index);

    return (
       <>
            <ul className = "nav align-items-center">
                <li className = "nav-item">
                    <div className = "random-clr" style={{background:color}}>
                        {nameletter}
                    </div>
                </li>
                <li className = "nav-item">
                    <div className = "c-name">
                        {name}
                    </div>
                </li>
            </ul>
       </>
    );
};

export default Username;