import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from 'react-router-dom';

import TemplateListingHeader from "../components/template-listing/templateListing-header";
import TemplateListingFooter from "../components/template-listing/templateListing-footer";
import Template_ic from "../images/icons/template-ic.svg";
import NoData from "../images/icons/no-data.svg";


const TemplateEmpty = (props) => {
    return (
        <>
            <section className="main inner-main templates-body-empty container" style={{ gap: "18px" }} >
                <Container>
                    <Row className="align-items-center">
                        <Col xs={12}>
                            <div className="tb-empty-content text-center" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }} >
                                {
                                    props?.isEmpty ?
                                        <>
                                            <img src={NoData} alt="icon" />
                                            <p style={{ fontWeight: 500, fontSize: "20px", marginTop: "10px", fontFamily: "Segoe UI", color: "#000000" }} >{props?.emptyText ? props?.emptyText : "No Data Found"}</p>
                                        </>
                                        :
                                        <>
                                            <img src={Template_ic} alt="icon" />
                                            <div className="tb-page-title" style={{ marginTop: "10px" }}>
                                                No Templates to display.
                                            </div>
                                            <p style={{ fontWeight: 500, fontSize: "20px", fontFamily: "Segoe UI", color: "#000000", marginTop: "10px" }}>
                                                You can create a new Tag by clicking the button below
                                            </p>
                                            <Link to="/createTemplate" className="btn btn-main text-decoration-none" style={{ marginTop: "23px" }} >
                                                <i className="bi bi-plus"></i> Create New
                                            </Link>
                                        </>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    );
};

export default TemplateEmpty;