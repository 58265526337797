import "./broadcast.css";

const BroadcastcardLoading = ({title}) => {
    return (
        <>
            <div className = "broadcast-card">
                <div className = "card-inner">
                    <div className = "broadcast-header">
                        <span></span>{title}
                    </div>
                    <div className = "broadcast-body placeholder-glow">
                        <div className = "placeholder w-75 py-2 mx-auto d-block"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BroadcastcardLoading;