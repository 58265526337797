import { useState } from "react";
import { Form } from "react-bootstrap";
import Select, { components } from "react-select";
import { Box, Tooltip, Typography, Zoom } from "@mui/material";
import dropdownIcon from "../../images/icons/select-field-icon.svg";
import InfoIcon from "../../images/icons/infoIcon.svg";

export const BasicTemplate = ({
  isEdit,
  formData,
  handleChange,
  handleLanguage,
  tempData,
}) => {
  const [show, setShow] = useState(false);
  const [showCategory, setShowCategory] = useState(false);

  const languageOptions = [
    { value: "en_US", label: "English (US)" },
    { value: "en_GB", label: "English (UK)" },
  ];
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={dropdownIcon} />
      </components.DropdownIndicator>
    );
  };

  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      color: "#000000",
      fontWeight: 500,
      fontSize: "14px",
      cursor: "pointer",
      "&:hover": { backgroundColor: "#E2FFF0", color: "#000000" },
    }),
  };

  return (
    <>
      <Box className="flex justify-start items-start flex-column w-100 gap-4">
        <Box className="flex justify-start items-start flex-column border-[1px] border-dashed rounded-md border-[#77777780] p-3 bg-[#FFFFFF] w-100">
          <Form.Group>
            <h4
              style={{ fontFamily: "SEGOE UI", display: "flex" }}
              className="text-[16px] font-medium leading-[22px] text-[#000000] gap-2"
            >
              Template Category
              <Tooltip
                arrow
                TransitionComponent={Zoom}
                title={
                  <p>
                    <strong style={{ fontWeight: "600" }}> Utility: </strong>For
                    Transactional messages e.g. Payment confirmation,Bill
                    Payment, Opt-In, Order Management, Account Alerts or Updates{" "}
                    <br />
                    <strong style={{ fontWeight: "600" }}>
                      {" "}
                      Marketing:
                    </strong>{" "}
                    For promotional content and offers e.g. Awareness ,Sales
                    ,Retargeting , App Promotion ,Build Customer,Relationships{" "}
                    <br />
                    <strong style={{ fontWeight: "600" }}>
                      {" "}
                      Authentication:{" "}
                    </strong>{" "}
                    For Authentication code.
                  </p>
                }
                placement={"top"}
                sx={{ cursor: "pointer" }}
                open={showCategory}
                onMouseEnter={() => setShowCategory(true)}
                onMouseLeave={() => setShowCategory(false)}
              >
                <img
                  src={InfoIcon}
                  style={{ cursor: "pointer" }}
                  className="mt-1"
                  width={16}
                  height={16}
                />
              </Tooltip>
            </h4>
            <h4
              style={{ fontFamily: "SEGOE UI" }}
              className="text-[15px] font-normal leading-[18px] text-[#494949] mt-[8px] "
            >
              Choose a category below.
            </h4>
            <div className="button-select mt-[16px]">
              <div className="form-check form-check-inline" onClick={() => {}}>
                <input
                  disabled={formData.isApproved}
                  className="form-check-input create-template-btn"
                  type="radio"
                  id="inline-radio-1"
                  name="category"
                  value="UTILITY"
                  onChange={handleChange}
                  checked={formData.category === "UTILITY"}
                />
                <label
                  className="form-label"
                  style={{
                    color:
                      formData.category === "UTILITY"
                        ? "#165E5B"
                        : formData.isApproved
                        ? "rgb(153, 153, 153)"
                        : "#4B4A4A",
                    fontSize: "14px",
                    fontFamily: "Segoe UI",
                    fontWeight: 500,
                  }}
                  htmlFor="inline-radio-1"
                >
                  Utility
                </label>
              </div>
              <div className="form-check form-check-inline" onClick={() => {}}>
                <input
                  disabled={formData.isApproved}
                  className="form-check-input create-template-btn"
                  type="radio"
                  id="inline-radio-2"
                  name="category"
                  value="MARKETING"
                  onChange={handleChange}
                  checked={formData.category === "MARKETING"}
                />
                <label
                  className="form-label"
                  htmlFor="inline-radio-2"
                  style={{
                    color:
                      formData.category === "MARKETING"
                        ? "#165E5B"
                        : formData.isApproved
                        ? "rgb(153, 153, 153)"
                        : "#4B4A4A",
                    fontSize: "14px",
                    fontFamily: "Segoe UI",
                    fontWeight: 500,
                  }}
                >
                  Marketing
                </label>
              </div>
              <div className="form-check form-check-inline" onClick={() => {}}>
                <input
                  disabled={formData.isApproved}
                  className="form-check-input create-template-btn"
                  type="radio"
                  id="inline-radio-3"
                  name="category"
                  value="AUTHENTICATION"
                  onChange={handleChange}
                  checked={formData.category === "AUTHENTICATION"}
                />
                <label
                  className="form-label"
                  htmlFor="inline-radio-3"
                  style={{
                    color:
                      formData.category === "AUTHENTICATION"
                        ? "#165E5B"
                        : formData.isApproved
                        ? "rgb(153, 153, 153)"
                        : "#4B4A4A",
                    fontSize: "14px",
                    fontFamily: "Segoe UI",
                    fontWeight: 500,
                  }}
                >
                  Authentication
                </label>
              </div>
            </div>
          </Form.Group>
        </Box>
        <Box className="flex justify-start items-start flex-column border-[1px] border-dashed rounded-md border-[#77777780] p-3 bg-[#FFFFFF] create-template relative w-100 ">
          <Form.Group
            className="form-group w-full mt-[12px]"
            style={{ width: "100%" }}
          >
            <h4
              style={{ fontFamily: "SEGOE UI", display: "flex" }}
              className="text-[16px] font-medium leading-[22px] text-[#000000] gap-2"
            >
              Template Name{" "}
              <Tooltip
                arrow
                TransitionComponent={Zoom}
                title={"The name should be in lowercase without space"}
                placement={"top"}
                sx={{ cursor: "pointer" }}
                open={show}
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
              >
                <img
                  src={InfoIcon}
                  style={{ cursor: "pointer" }}
                  className="mt-1"
                  width={16}
                  height={16}
                />
              </Tooltip>
            </h4>
            <h4
              style={{ fontFamily: "SEGOE UI" }}
              className="text-[15px] font-normal leading-[18px] text-[#494949] mt-[8px] mb-[12px] "
            >
              Give a unique name to your template
            </h4>
            <Form.Control
              readOnly={isEdit}
              style={{
                marginBottom: "12px",
                width: "100%",
                justifyContent: "space-between",
                display: "flex",
                height: "40px",
                backgroundColor: "#F3F7F7",
                color: isEdit ? "rgb(153, 153, 153)" : "#494949",
              }}
              type="text"
              placeholder="mytemplate"
              isInvalid={
                formData.name.length > 60 ||
                (formData.name && !/^(?! )[a-z_][a-z_]*$/g.test(formData.name))
              }
              onChange={handleChange}
              name="name"
              value={formData.name}
            />
            <Form.Control.Feedback
              style={{
                marginBottom: "0px",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                display: "flex",
              }}
              type="invalid"
            >
              <p style={{ fontSize: "12px", textAlign: "right" }}>
                {!/^(?! )[a-z_][a-z_]*$/g.test(formData.name) &&
                formData.name.length > 0
                  ? "Name can only have lowercase and underscore"
                  : formData.name.length > 60
                  ? "Not more than 60 character allowed"
                  : ""}
              </p>
              <span style={{ color: "black", textAlign: "right" }}>
                {Array.from(formData?.name).length}/60
              </span>
            </Form.Control.Feedback>
          </Form.Group>

          <h4
            style={{ fontFamily: "SEGOE UI" }}
            className="text-[16px] font-medium leading-[22px] text-[#000000]"
          >
            Languages
          </h4>
          <h4
            style={{ fontFamily: "SEGOE UI" }}
            className="text-[15px] font-normal leading-[18px] text-[#494949] mt-[8px] "
          >
            Select the language for your message template.
          </h4>
          <Select
            styles={colourStyles}
            components={{ DropdownIndicator }}
            isDisabled={isEdit}
            className="c-select mt-[12px] w-100 h-[40px] border-[1px] rounded-md"
            value={tempData.language}
            onChange={handleLanguage}
            options={languageOptions}
            placeholder="Select a language"
          />
        </Box>
      </Box>
    </>
  );
};
