import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import Wtable from "../table/table";
import Useraction from "./useraction";
import { generateColorHash } from ".././../utils/utils";
import Username from "./userName/username";
import UsertableStatus from "./user-status";

import "../table/table.css";

const UserListingtable = (props) => {
  const badgeStyel = {
    color: "#000",
    fontWeight: "600",
    lineHeight: "16px",
    fontSize: "12px",
    borderRadius: "5px",
    padding: "5px 6px",
    display: "inline-block",
    margin: "3px 3px",
    whiteSpace: "nowrap",
  };

  const theadData = [
    "Name",
    "Tags",
    "Role",
    "Status",
    "Created Date",
    "Action",
  ];

  const [allUsers, setAllUsers] = useState([]);

  // get selected tags values
  const getSelectedTagsValues = (tags) => {
    if (!tags) {
      return [];
    }
    let temp = [];
    for (let i = 0; i != tags?.length; i++) {
      temp.push({ label: tags[i]?.title, value: tags[i]?._id });
    }
    return temp;
  };

  // function setUserData() {
  //     let tempUsers = []
  //     for (let i = 0; i != props?.allUsers?.length; i++) {
  //         tempUsers?.push({
  //             id: props?.allUsers[i]?._id,
  //             items: [<Username name={props?.allUsers[i]?.firstname + " " + props?.allUsers[i]?.lastname} Useremail={props?.allUsers[i]?.email} />,
  //             <>
  //                 {
  //                     getSelectedTagsValues(props?.allUsers[i]?.tags)?.map((item, index) => {
  //                         return <span style={{ ...badgeStyel, backgroundColor: generateColorHash(item?.value?.substring(0, 10)) }} >{item?.label}</span>
  //                     })
  //                 }
  //             </>,
  //             props?.allUsers[i]?.role,
  //             <UsertableStatus statusTitle={props?.allUsers[i]?.isapproved ? "Active" : "Inactive"} statusClass={props?.allUsers[i]?.isapproved ? 'active' : 'disactivated'} />,
  //             moment(props?.allUsers[i]?.createdAt).format("MMM-DD-YYYY"),
  //             <Useraction isOwner={props?.allUsers[i]?.role == "owner"} handleClickEditUser={() => props?.handleClickEditUser(props?.allUsers[i])} handleClickDeleteUser={() => props?.handleClickDeleteUser(props?.allUsers[i])} />]
  //         })
  //     }

  //     setAllUsers(tempUsers)
  // }
  const setUserData = () => {
    const tempUsers = props?.allUsers?.map((user) => ({
      id: user?._id,
      items: [
        <Username
          key={user._id}
          name={`${user?.firstname} ${user?.lastname}`}
          Useremail={user?.email}
        />,
        <>
          {getSelectedTagsValues(user?.tags)?.map((item, index) => (
            <span
              key={index}
              style={{
                ...badgeStyel,
                backgroundColor: generateColorHash(
                  item?.value?.substring(0, 10)
                ),
              }}
            >
              {item?.label}
            </span>
          ))}
        </>,
        user?.role,
        <UsertableStatus
          key={`status-${user._id}`}
          statusTitle={user?.isapproved ? "Active" : "Inactive"}
          statusClass={user?.isapproved ? "active" : "disactivated"}
        />,
        moment(user?.createdAt).format("MMM-DD-YYYY"),
        <Useraction
          key={`action-${user._id}`}
          isOwner={user?.role === "owner"}
          handleClickEditUser={() => props?.handleClickEditUser(user)}
          handleClickDeleteUser={() => props?.handleClickDeleteUser(user)}
        />,
      ],
    }));

    setAllUsers(tempUsers);
  };

  useEffect(() => {
    setUserData();
  }, [props?.allUsers]);

  return (
    <>
      <div className="cg-custom-table">
        <Wtable theadData={theadData} tbodyData={allUsers} />
      </div>
    </>
  );
};

export default UserListingtable;
