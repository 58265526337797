import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "./App.css";
import { store } from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/main-style.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "react-toastify/dist/ReactToastify.css";
import io from "socket.io-client";
import Muzamil_img from "./images/muzumil.png";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import BellIcon from "./images/icons/Bell-icon.svg";

import Router from "./Router";
import { getInbox } from "./services/Inbox";
import getconfig from "./config";
import {
  removeAllConversationsData,
  removeAllChatsData,
  addNewMessageToChat,
  addNewChatToChatList,
  setConversationsData,
} from "./reduxSlice/chats";
import {
  setBroadcastUpdate,
  setMessageFailed,
  setMessageStatus,
  setNewMessage,
  updateUnreadChats,
} from "./reduxSlice/messageNotificationSlice";
function App() {
  const [socket, setSocket] = useState(null);
  const dispatch = useDispatch();
  const companyId = localStorage.getItem("companyId");
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    if (socket == null)
      setSocket(io(getconfig.backend, { autoConnect: true }).connect());
  }, []);

  useEffect(() => {
    getAllUnReadChatsCount();
  }, []);

  document.addEventListener("userLoggedIn", (data) => getAllUnReadChatsCount());

  // get user unread messages count
  const getAllUnReadChatsCount = async () => {
    const companyId = localStorage.getItem("companyId");
    const { data } = await axios.get(
      `${getconfig.backend}user/getNewConversationCount/${companyId}`
    );
    let temp = [];
    if (data?.success) {
      if (data?.data?.length > 0) {
        const allData = store.getState();
        const { allConversations } = allData?.ChatsReducer;

        // sort conversations
        let givenData = [...allConversations];
        let sortedData = givenData.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        dispatch(setConversationsData(sortedData));
      }

      for (let i = 0; i != data?.data?.length; i++) {
        temp.push({
          contact: data?.data[i]?.contact?._id,
        });
      }
      console.log("going to push : ", temp);
      dispatch(updateUnreadChats({ users: temp, contact: null }));
    }

    console.log("data of unread chats count", data);
  };

  // update user lists on receiving new message
  // const updateUserLists = (chatId, newMsg, updatedInbox) => {
  //   let allConvers = allConversations;
  //   const selectedConversation = allConvers.findIndex(
  //     (item) => item?._id == chatId
  //   );
  //   if (selectedConversation) {
  //     const newMsgReceivedEvent = new Event("newMessageReceivedEvent", {
  //       detail: { chatId: chatId, msg: newMsg },
  //     });
  //     document.dispatchEvent(newMsgReceivedEvent);
  //   } else {
  //     // if not found, adding chat to chat list in redux store
  //     dispatch(addNewChatToChatList(updatedInbox));
  //   }
  // };
  // get latest conversations on reload any page
  const getLatestConversations = async () => {
    const response = await getInbox();
    if (response?.success) {
      let givenData = response?.data;
      let sortedData = givenData.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
      dispatch(setConversationsData(sortedData));
      console.log("sortedData", sortedData);
    }
  };

  // get all conversations on reload
  useEffect(() => {
    getLatestConversations();
  }, []);

  useEffect(() => {
    if (!socket) return;
    function onConnect() {
      console.log("connect");
    }

    function onDisconnect() {
      console.log("Disconnect");
    }

    function EventListenerFunc(event) {
      const action = event.action;
      const data = event.data;
      console.log("action ::::", action);
      switch (action) {
        case "newMessage":
          console.log("newMessage data received : ", data);
          if (companyId == data?.newMsgData?.company) {
            const newMsgReceivedEvent = new CustomEvent(
              "newMessageReceivedEvent",
              {
                detail: {
                  chatId: data?.newMsgData?._id,
                  msg: data?.newMsgData,
                  updatedInbox: data?.userChatData,
                  assignedTag: data?.assignedTag?._id,
                },
              }
            );
            document.dispatchEvent(newMsgReceivedEvent);
          }
          break;
        case "newMessageSent":
          console.log("newMessageSent data received : ", data);
          if (
            companyId == data?.companyId &&
            data?.newMessage?.from?._id != userId
          ) {
            const newMsgSentEvent = new CustomEvent("newMsgSentEvent", {
              detail: {
                chatId: data?.chatId,
                msg: data?.newMessage,
              },
            });
            document.dispatchEvent(newMsgSentEvent);
          }

          break;
        case "agentChanged":
          console.log("agentChanged data received : ", data, ":", userId);
          // if (companyId == data?.companyId && data?.senderId != userId) {
          if (companyId == data?.companyId) {
            const chatAgentChangedEvent = new CustomEvent(
              "chatAgentChangedEvent",
              {
                detail: {
                  chatId: data?.inboxId,
                  newAgents: data?.userIds,
                },
              }
            );
            document.dispatchEvent(chatAgentChangedEvent);
          }

          break;
        case "tagChanged":
          console.log("tagChanged data received : ", data);
          if (companyId == data?.companyId && data?.senderId != userId) {
            const chatTagChangedEvent = new CustomEvent("chatTagChangedEvent", {
              detail: {
                chatId: data?.inboxId,
                tagId: data?.tagId,
              },
            });
            document.dispatchEvent(chatTagChangedEvent);
          }

          break;
        case "noteUpdate":
          console.log(
            "noteUpdate data received : ",
            data,
            userId,
            ":",
            companyId == data?.companyId && data?.senderId != userId
          );
          if (companyId == data?.companyId && data?.senderId != userId) {
            console.log("going to send ");
            const notesEvent = new CustomEvent("notesEvent", {
              detail: {
                chatId: data?.inboxId,
              },
            });
            document.dispatchEvent(notesEvent);
          }

          break;
        case "newChatAdded":
          console.log("newChatAdded data received : ", data);
          if (companyId == data?.companyId && data?.creatorId != userId) {
            const newChatAdded = new CustomEvent("newChatAdded", {
              detail: {
                chatId: data?.chatId,
                newChat: data?.newChat,
              },
            });
            document.dispatchEvent(newChatAdded);
          }

          break;
        case "chatDeleted":
          console.log("chatDeleted data received : ", data);
          if (companyId == data?.companyId && data?.creatorId != userId) {
            const chatDeleted = new CustomEvent("chatDeleted", {
              detail: {
                chatId: data?.chatId,
              },
            });
            document.dispatchEvent(chatDeleted);
          }

          break;
        case "chatMuted":
          console.log("chatMuted data received : ", data, ":", userId);
          if (companyId == data?.companyId && data?.creatorId != userId) {
            const chatMuteChangedEvent = new CustomEvent(
              "chatMuteChangedEvent",
              {
                detail: {
                  chatId: data?.chatId,
                },
              }
            );
            document.dispatchEvent(chatMuteChangedEvent);
          }

          break;
        case "chatBlock":
          console.log("chatBlock data received : ", data, ":", userId);
          if (companyId == data?.companyId && data?.creatorId != userId) {
            const chatBlockChangedEvent = new CustomEvent(
              "chatBlockChangedEvent",
              {
                detail: {
                  chatId: data?.chatId,
                },
              }
            );
            document.dispatchEvent(chatBlockChangedEvent);
          }

          break;
        case "status":
          console.log("status changed ====", data, data.messageId);
          const msgReadStatusChanged = new CustomEvent("msgReadStatusChanged", {
            detail: {
              msgData: data,
            },
          });
          document.dispatchEvent(msgReadStatusChanged);
          //dispatch(setMessageStatus(data));
          break;
        case "failed":
          console.log("status changed failed ====");
          const msgFailedStatusChanged = new CustomEvent(
            "msgFailedStatusChanged",
            {
              detail: {
                msgData: data,
              },
            }
          );
          document.dispatchEvent(msgFailedStatusChanged);
          // dispatch(setMessageFailed(data));
          break;
        case "broadcastUpdate":
          dispatch(setBroadcastUpdate(true));
          break;
        default:
          console.log("No Socket Action Matched  ");
          break;
      }
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("message", EventListenerFunc);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("message", EventListenerFunc);
    };
  }, [socket]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (socket.connected) {
        socket.emit("ping"); // Send a ping message to keep the connection alive
      }
    }, 30000); // Ping every 30 seconds

    return () => clearInterval(interval); // Clean up on unmount
  }, [socket]);

  return (
    <>
      <Router />
      <ToastContainer className={"toast-position"} />
    </>
  );
}

export default App;
