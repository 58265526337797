import React , {useState, useEffect, ReactNode} from 'react'
import { Box , IconButton, Pagination ,  } from '@mui/material';
import { Table } from 'antd';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import windowHeightDetector from "../../components/customHooks/DeviceHeightDetector"
import moment from 'moment/moment';
import { generateColorHash} from "../../utils/utils"
import editIcon from "../../images/icons/edit-icon.svg"
import deleteIcon from "../../images/icons/delete-icon.svg"

interface TagType {
    _id: string;
    title: string;
    company: {
        _id: string;
        firstname: string;
        lastname: string;
        email: string;
    }
}

interface tableRowType {
    name: ReactNode
    date?: React.ReactNode
    status: ReactNode
}
interface propsTypes {
    handleClickEditTag?: (tagData: TagType) => void,
    handleClickDeleteTag?: (tagData: TagType) => void,
    handleClickViewTag?:() => void,
    allTags: TagType[]
}

const AllTags: React.FC<propsTypes> = (props) => {
    const [ tags , setTags ] = useState<tableRowType[]>([])
    const windowHeight = windowHeightDetector()
    const [currPage , setCurrPage ] = useState(1)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update screenWidth state
        const updateScreenWidth = () => {
          setScreenWidth(window.innerWidth);
        };
    
        // Add event listener for window resize
        window.addEventListener("resize", updateScreenWidth);
    
        // Remove event listener on component unmount
        return () => {
          window.removeEventListener("resize", updateScreenWidth);
        };
      }, []);

    const handleShowEditTagPopup = (itemData: TagType) => {
        if(props?.handleClickEditTag != undefined){
            props?.handleClickEditTag(itemData)
        }
    }

    const handleShowDeleteTagPopup = (itemData: TagType) => {
        if(props?.handleClickDeleteTag != undefined){
            props?.handleClickDeleteTag(itemData)
        }
    }

    useEffect(() => {
        if(screenWidth>=768){

            makeTableData(0,10)
        }
        else{
            makeResponsiveTableData(0,10)
        }
    },[props?.allTags,screenWidth])

    const ActionButtonGroup = (item: TagType) => {
        return (
            <Box className="flex justify-end items-end gap-[10px] pr-6" >
                <IconButton sx={{padding: "5px", borderRadius : "5px", border : "1px solid #0000001A"}} onClick={() => handleShowEditTagPopup(item)} >
                    {/* <EditIcon sx={{fontSize : "20px", padding: 0}} /> */}
                    <img src={editIcon} style={{width : "18px", height : "18px"}} />
                </IconButton>
                <IconButton sx={{padding: "5px", borderRadius : "5px", border : "1px solid #0000001A"}} onClick={() => handleShowDeleteTagPopup(item)} >
                    {/* <DeleteIcon sx={{fontSize : "20px", padding: 0}} /> */}
                    <img src={deleteIcon} style={{width : "18px", height : "18px"}} />
                </IconButton>
            </Box>
        )
    }

    const columns = [
        {
            title: <div className="flex justify-start items-start pl-3" ><p style={{color : "#FFFFFF", fontFamily : "Segoe UI", fontSize : "15px", fontWeight : 500 }} >Tag Names</p></div>,
            dataIndex: 'name',
            width : "25%"
        },
        {
            title: <div className="flex justify-start items-start pl-3" ><p style={{color : "#FFFFFF", fontFamily : "Segoe UI", fontSize : "15px", fontWeight : 500 }} >Tag Key</p></div>,
            dataIndex: 'tagKey',
            width : "25%"
        },
        {
            title:     <div className="flex justify-center items-center  pr-6" ><p style={{color : "#FFFFFF", fontFamily : "Segoe UI", fontSize : "15px", fontWeight : 500 }} >Created At</p></div>,
            dataIndex: 'date',
            width : "25%"
        },
        {
            title: <div className="flex justify-end items-end pr-10" ><p style={{color : "#FFFFFF", fontFamily : "Segoe UI", fontSize : "15px", fontWeight : 500 }} >Action</p></div>,
            dataIndex: 'status',
            width : "25%",
        },
    ];
    const responsiveColumns = [
        {
            title: <div className="flex justify-start items-start pl-3" ><p style={{color : "#FFFFFF", fontFamily : "Segoe UI", fontSize : "15px", fontWeight : 500 }} >Tag Names</p></div>,
            dataIndex: 'name',
            width : "33.33%"
        },
        // {
        //     title: <div className="flex justify-start items-start pl-3" ><p style={{color : "#FFFFFF", fontFamily : "Segoe UI", fontSize : "15px", fontWeight : 500 }} >Tag Key</p></div>,
        //     dataIndex: 'tagKey',
        //     width : "33.33%"
        // },
        {
            title: <div className="flex justify-end items-end pr-10" ><p style={{color : "#FFFFFF", fontFamily : "Segoe UI", fontSize : "15px", fontWeight : 500 }} >Action</p></div>,
            dataIndex: 'status',
            width : "33.33%",
        },
    ];


    const makeTableData = (start:number = 0, end:number = 1) => {
        let tempData: tableRowType[] = []
        for(let i = start ; i != end; i++){
            if(props?.allTags[i]?.title){
                console.log("props?.allTags[i]",props?.allTags[i])
                tempData.push({
                    date :  <div className="flex justify-center items-center pr-6" > <p style={{color : "#000000", fontFamily : "Segoe UI", fontSize : "14px", fontWeight : 400  }} >{moment(props?.allTags[i]?.createdAt).format("MMM-DD-YYYY")}</p></div>,
                    name : <div style={{display : "flex", justifyContent : "flex-start", alignItems : "flex-start"}} className="pl-3" ><p style={{backgroundColor : generateColorHash(props?.allTags[i]?._id?.substring(0,10)), color : "#000000", fontFamily : "Segoe UI",  fontSize : "14px", padding : "5px", borderRadius : "5px", fontWeight : 500 }} >{props?.allTags[i]?.title}</p></div>,
                    tagKey : <div style={{display : "flex", justifyContent : "flex-start", alignItems : "flex-start"}} className="pl-3" ><p style={{ color : "#000000", fontFamily : "Segoe UI",  fontSize : "14px", padding : "5px", borderRadius : "5px", fontWeight : 500 }} >{props?.allTags[i]?.tagKey}</p></div>,
                    status : <ActionButtonGroup item={props?.allTags[i]} />
                })
            }
        }

        setTags(tempData)
    }


    
    const makeResponsiveTableData = (start:number = 0, end:number = 1) => {
        let tempData: tableRowType[] = []
        for(let i = start ; i != end; i++){
            if(props?.allTags[i]?.title){
                tempData.push({
                    name : <div style={{display : "flex", justifyContent : "flex-start", alignItems : "flex-start",flexDirection:"column"}} className="" ><p style={{backgroundColor : generateColorHash(props?.allTags[i]?._id?.substring(0,10)), color : "#000000", fontFamily : "Segoe UI",  fontSize : "14px", padding : "5px", borderRadius : "5px", fontWeight : 500 }} >{props?.allTags[i]?.title}</p>  


                     <div style={{display : "flex", justifyContent : "flex-start", alignItems : "flex-start",flexDirection:"column"}} className="" ><p style={{ color : "#000000", fontFamily : "Segoe UI",  fontSize : "14px", padding : "5px", borderRadius : "5px", fontWeight : 500 }} >{props?.allTags[i]?.tagKey}</p> </div>
                    
                    <div className="flex justify-center items-center pl-1  mt-1" > <p style={{color : "#000000", fontFamily : "Segoe UI", fontSize : "14px", fontWeight : 400  }} >{moment(props?.allTags[i]?.createdAt).format("MMM-DD-YYYY")}</p></div> 
                    
                    
                    </div>,
                    // tagKey : <div style={{display : "flex", justifyContent : "flex-start", alignItems : "flex-start",flexDirection:"column"}} className="pl-3" ><p style={{ color : "#000000", fontFamily : "Segoe UI",  fontSize : "14px", padding : "5px", borderRadius : "5px", fontWeight : 500 }} >{props?.allTags[i]?.tagKey}</p> </div>,
                    status : <ActionButtonGroup item={props?.allTags[i]} />
                })
            }
        }

        setTags(tempData)
    }

    const handlePaginationChange = (event, value) => {
        console.log("value of paginatipn ", value)
        setCurrPage(value)
        if(value == 1){
            makeTableData(0, 10)
        }else{
            makeTableData((value - 1) * 10, (value * 10) )
        }
    }

    return (
        <>
            <Box className="bg-[#ecf1f9] flex justify-start align-items-start w-100 flex-column h-100"  >
                <Box className="flex justify-start align-items-start w-100 mt-[30px] rounded-md container all-tags mb-8 testing" sx={{padding: 0, height : "calc(100vh  - 250px)", }} >
                    <Table
                        columns={screenWidth>=768 ?columns:responsiveColumns}
                        dataSource={tags}
                        pagination={{ pageSize: props?.allTags?.length > 10 ? 10 : props?.allTags?.length }}
                        style={{width : "100%", background : "#FFFFFF",}}
                        size="middle"
                    />
                </Box>
                <Box className="bg-[#FFFFFF] flex justify-center align-items-center w-100 all-tags" sx={{height : "10%"}} >
                    <Pagination
                        count={Math.ceil((props?.allTags?.length/10) % 10 )}
                        variant="outlined"
                        shape="rounded"
                        siblingCount={0}
                        page={currPage}
                        onChange={handlePaginationChange}
                    />
                </Box>
            </Box>
        </>
    )
}

export default AllTags
