import axios from 'axios'
import config from '../../config'

const baseUrl = `${config.backend}company/`

export const registerCompany = async (data) => {
    const response = await axios.post(`${baseUrl}register`, data)
    console.log(response.data)
    return response.data
}

export const addCompanyToken = async (data) => {
    const response = await axios.post(`${baseUrl}addcompanytoken`, data)
    console.log(response.data)
    return response.data
}

export const getPhoneNumber = async(data) => {
    const response = await axios.get(`${baseUrl}getnumber?companyId=${data}`)
    return response.data
}
