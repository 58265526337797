import { Col, Form, Row } from "react-bootstrap"
import { Box, Typography } from "@mui/material"

export const AuthenticationTemplate = ({ formData, handleChange }) => {
    return (
        <>
            <div style={{ marginBottom: "20px" }}>
                <Box className="flex justify-start items-start flex-column border-[1px] border-dashed rounded-md border-[#77777780] p-3 bg-[#FFFFFF] w-100 mt-[18px]">
                    <Form.Group className="form-group fg-body w-100">
                        <Form.Label>Body <span style={{ opacity: '60%', fontSize: '14px' }}>(optional)</span></Form.Label>
                        <h4 style={{ fontFamily: "SEGOE UI" }} className="text-[16px] font-medium leading-[22px] text-[#000000]" >Enter code expiration time in minutes between 1-90</h4>
                        <Form.Control style={{ marginTop: "12px", width: "100%", justifyContent: "space-between", display: "flex", height: "40px", backgroundColor: "#F3F7F7" }} min={1} type="number" placeholder="10" onChange={handleChange} name="codeExpiry" value={formData.codeExpiry} />
                        <div style={{ marginTop: "20px" }}>
                            <h4 style={{ fontFamily: "SEGOE UI" }} className="text-[15px] font-thin leading-[18px] text-[#494949] mt-[8px] " >Add security recommendation.</h4>
                            <Form.Check style={{ marginBottom: "0px", marginTop: "12px" }} checked={formData.security} type="checkbox" placeholder="10" onChange={handleChange} name="security" label={"For your security, do not share this code"} value={formData.security} />
                        </div>
                    </Form.Group>
                </Box>
            </div>
            <Box className="flex justify-start items-start flex-column border-[1px] border-dashed rounded-md border-[#77777780] p-3 bg-[#FFFFFF] w-100 create-template">
                <Form.Group className="form-group w-100">
                    <h4 style={{ fontFamily: "SEGOE UI" }} className="text-[16px] font-medium leading-[22px] text-[#000000]" >Button <span style={{ opacity: '60%', fontSize: '14px' }}>(optional)</span></h4>
                    <h4 style={{ fontFamily: "SEGOE UI" }} className="text-[15px] font-normal leading-[18px] text-[#494949] mt-[8px] " >Add button text</h4>
                    <Form.Control placeholder="copy code" style={{ marginTop: "12px", width: "100%", justifyContent: "space-between", alignItems: "center", display: "flex", height: "40px", backgroundColor: "#F3F7F7", }} onChange={handleChange} name="copyButton" value={formData.copyButton} />
                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }} >
                        <span style={{ textAlign: "right", width: "100%" }} >{formData?.copyButton ? Array.from(formData?.copyButton)?.length ? Array.from(formData?.copyButton)?.length : 0 : 0}/60</span>
                    </div>
                </Form.Group>
            </Box>
        </>
    )
}