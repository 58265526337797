import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Select, { components } from "react-select";
import Form from "react-bootstrap/Form";
import Closeic from "../../images/icons/close-ic-1.svg";
import useDeviceWidthDetector from "../customHooks/DeviceWidthDetector";
import dropdownIcon from "../../images/icons/select-field-icon.svg";

const CallToAction = ({
  handleCallToAction,
  data,
  dataKey,
  deleteCallToAction,
  toggleAction,
}) => {
  // const country = [
  //     { value: '+92', label: 'PK +92' },
  //     { value: '+44', label: 'GB +44' }
  // ]

  // const urlOptions = [
  //     { value: 'Static', label: 'Static' },
  //     { value: 'Dynamic', label: 'Dynamic' }
  // ]

  const calloption = [
    { value: "PHONE_NUMBER", label: "Call phone number" },
    { value: "visitwebsite", label: "Visit Website" },
  ];

  const [tempData, setTempData] = useState({
    option: {
      value: data.action,
      label:
        data.action === "PHONE_NUMBER" ? "Call phone number" : "Visit Website",
    },
    country: null,
    urlType: null,
  });

  useEffect(() => {
    setTempData((prevState) => ({
      ...prevState,
      option: {
        ...prevState.option,
        value: data.action,
        label:
          data.action === "PHONE_NUMBER"
            ? "Call phone number"
            : "Visit Website",
      },
    }));
  }, [data.action]);

  function handleUrlType(selectedOption) {
    setTempData({ ...tempData, urlType: selectedOption });
    const event = { target: { name: "urltype", value: selectedOption.value } };
    handleCallToAction(event, dataKey);
  }

  function handleOption(selectedOption) {
    setTempData({ ...tempData, option: selectedOption });
    const event = { target: { name: "action", value: selectedOption.value } };
    tempfunc(event);
  }

  function handleCountry(selectedOption) {
    setTempData({ ...tempData, country: selectedOption });
    const event = { target: { name: "country", value: selectedOption.value } };
    handleCallToAction(event, dataKey);
  }

  function handleChange(event) {
    handleCallToAction(event, dataKey);
  }

  const tempfunc = (event) => {
    handleChange(event);
    toggleAction(dataKey);
  };

  const width = useDeviceWidthDetector();

  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      color: "#000000",
      fontWeight: 500,
      fontSize: "14px",
      cursor: "pointer",
      "&:hover": { backgroundColor: "#E2FFF0", color: "#000000" },
    }),
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={dropdownIcon} />
      </components.DropdownIndicator>
    );
  };

  return (
    <div
      className="button-text"
      style={{
        display: "flex",
        alignItems: "center",
        marginTop: "12px",
        marginBottom: "15px",
        width: "100%",
      }}
    >
      <Row style={{ width: "100%", columnGap: "0px" }}>
        <Col xs={12} sm={4} style={{ paddingRight: width > 540 ? "0px" : "" }}>
          <Select
            components={{ DropdownIndicator }}
            styles={colourStyles}
            className="c-select create-template create-template-header min-w-[100%]"
            style={{ width: "100%" }}
            options={calloption}
            value={tempData.option}
            onChange={handleOption}
          />
        </Col>
        <Col
          xs={12}
          sm={4}
          style={{
            paddingLeft: width > 540 ? "2px" : "10px",
            paddingRight: width > 540 ? "0" : "18px",
          }}
        >
          <Form.Control
            style={{
              minWidth: "100%",
              height: "40px",
              backgroundColor: "#F3F7F7",
            }}
            type="text"
            placeholder="button text"
            onChange={(event) => handleCallToAction(event, dataKey)}
            name="text"
            value={data.text}
          />
        </Col>
        <Col
          xs={12}
          sm={4}
          style={{ padding: "0px", paddingLeft: width > 540 ? "" : "10px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            {data.action === "PHONE_NUMBER" && (
              <Form.Control
                style={{
                  width: "99%",
                  height: "40px",
                  backgroundColor: "#F3F7F7",
                }}
                type="text"
                placeholder="Ex: 447700900123"
                onChange={(event) => handleCallToAction(event, dataKey)}
                name="number"
                value={data.number}
              />
            )}
            {data.action === "visitwebsite" && (
              <Form.Control
                style={{
                  width: "99%",
                  height: "40px",
                  backgroundColor: "#F3F7F7",
                }}
                placeholder="https://www.example.com"
                onChange={(event) => handleCallToAction(event, dataKey)}
                name="websiteurl"
                value={data.websiteurl}
              />
            )}
            <div
              style={{
                height: "100%",
                cursor: "pointer",
                display: "flex",
                justifyContent: width > 768 ? "flex-end" : "flex-end",
                alignItems: width > 768 ? "center" : "flex-start",
              }}
            >
              <img
                src={Closeic}
                alt="close-icon"
                onClick={() => deleteCallToAction(dataKey)}
              />
            </div>
          </div>
        </Col>
      </Row>
      {/* <Form.Control placeholder="button text" onChange={(event) => handleCallToAction(event, dataKey)} name="text" value={data.text} /> */}
      {/* {data.action==="PHONE_NUMBER" && (<Select className = "c-select" options={country} placeholder = {'Country'} value={tempData.country} onChange={handleCountry} />)} */}
      {/* {data.action === "visitwebsite" && (<Select className="c-select" options={urlOptions} placeholder={'URL type'} value={tempData.urlType} onChange={handleUrlType} />)} */}
    </div>
  );
};

export default CallToAction;
