import React from 'react'
import { Box , Typography ,IconButton } from '@mui/material';
import CustomButton from '../../components/customButton/index.tsx';
import AddIcon from '@mui/icons-material/Add';
import tagIcon from "../../images/icons/tag_page_icons.svg"

interface propsTypes {
    handleClickAddNewTag?:() => void
}


const NoTagsFound: React.FC<propsTypes> = (props) => {
    const handleShowCreateNewTagPopup = () => {
        if(props?.handleClickAddNewTag != undefined){
            props?.handleClickAddNewTag()
        }
    }
    return (
        <>
            <Box className="bg-[#ecf1f9] flex justify-start align-items-start w-100" sx={{height : "83vh"}} >
                <Box className="flex bg-[#FFFFFF] justify-center align-items-center w-100 mt-[100px] md:mt-[55px] rounded-md container " >
                    <Box className="flex justify-center align-items-center m-[20px] md:m-[50px] lg:m-[100px] p-2 flex-column " >
                        <img className="no-tags-icon-style" src={tagIcon} alt="tags icon" />
                        <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[15px] lg:text-[20px] xl:text-[23px] mt-[10px] font-medium leading-[36px] text-[#000000] text-center " > No Tags to display.</Typography>
                        <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[13px] lg:text-[16px] xl:text-[18px] mt-[20px] mb-[30px] font-normal leading-[20px] text-[#000000] text-center " > You can create a new Tag by clicking the button below</Typography>
                        <CustomButton
                            text="Create New"
                            handleOnClick={handleShowCreateNewTagPopup}
                        >
                            <IconButton sx={{padding: 0}}>
                                <AddIcon sx={{ width : "15px", height : "20px", color : "#FFFFFF", marginRight : "5px" }} />
                            </IconButton>
                        </CustomButton>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default NoTagsFound
