import React from "react";
import TableRow from "./tableRow";
import TableHeadItem from "./tableHead";
import Table from "react-bootstrap/Table"

const Wtable = ({ theadData, tbodyData, customClass, fileUploaded = false, clickRow, isCsvTable }) => {
    return (
        <Table className={customClass} responsive>
            <thead>
                <tr>
                    {theadData?.map((h, i) => {
                        return <TableHeadItem key={i} item={h} />;
                    })}
                </tr>
            </thead>
            <tbody>
                {tbodyData?.map((item) => {
                    return <TableRow style={item?.style} isCsvTable={isCsvTable} key={item.id} id={item.id} data={item.items} clickRow={clickRow} rowid={item.id} />;
                })}
            </tbody>
        </Table>
    );
};

export default Wtable;