import React, { useEffect, useState } from "react";
import Wtable from "../table/table";
import Actionbutton from "../tableAction/tableactionBtn1";
import Placeholdertable from "../loader/loading-table";
import { Button, Row, Col } from "react-bootstrap";

const Grouptable = ({
  groups,
  searchQuery,
  handleDelete,
  Contactlist,
  handleSelected,
  selectedContacts,
  editGroup,
  loading,
}) => {
  const theadData = ["No#", "Group Name", "Created Date", "Members", "Action"];

  const convertDate = (dateStr) => {
    const date = new Date(dateStr);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return formattedDate;
  };

  // Pagination states and logic
  const [currentPage, setCurrentPage] = useState(1);
  const [groupsPerPage] = useState(5);

  useEffect(() => {
    // Whenever the searchQuery changes, reset the currentPage to 1
    setCurrentPage(1);
  }, [searchQuery]);

  const filteredGroups = groups.filter((group) =>
    group.groupName.toLowerCase()?.includes(searchQuery.toLowerCase())
  );

  const totalPages = Math.ceil(filteredGroups.length / groupsPerPage);
  if (currentPage > totalPages && totalPages !== 0) setCurrentPage(totalPages);
  const indexOfLastGroup = currentPage * groupsPerPage;
  const indexOfFirstGroup = indexOfLastGroup - groupsPerPage;
  const currentGroups = filteredGroups.slice(
    indexOfFirstGroup,
    indexOfLastGroup
  );

  const tbodyData1 = currentGroups.map((group, index) => {
    const overallIndex = indexOfFirstGroup + index + 1;
    return {
      id: group._id,
      items: [
        overallIndex,
        group.groupName,
        convertDate(group.createdAt),
        group.contacts.length,
        <Actionbutton
          handleDelete={handleDelete}
          group={group}
          Contactlist={Contactlist}
          handleSelected={handleSelected}
          selectedContacts={selectedContacts}
          editGroup={editGroup}
        />,
      ],
    };
  });

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = () => {
    return pageNumbers.map((number) => {
      return (
        <li
          key={number}
          id={number}
          className={currentPage === number ? "active" : null}
          onClick={(e) => setCurrentPage(Number(e.target.id))}
        >
          {number}
        </li>
      );
    });
  };

  return (
    <div>
      {!loading ? (
        <>
          {filteredGroups.length > 0 ? (
            <>
              <div className="cg-custom-table">
                <Wtable theadData={theadData} tbodyData={tbodyData1} />
              </div>

              <Row className="py-3 py-md-4">
                <Col sm={12}>
                  <ul className="nav justify-content-center align-items-center">
                    <li className="nav-item">
                      <Button
                        className="table-btn"
                        onClick={() =>
                          setCurrentPage((prevPage) =>
                            prevPage === 1 ? prevPage : prevPage - 1
                          )
                        }
                      >
                        <i className="bi bi-chevron-left"></i>
                      </Button>
                    </li>
                    {Array.from({ length: totalPages }, (_, i) => (
                      <li className="nav-item" key={i}>
                        <Button
                          className={`table-btn ${
                            i + 1 === currentPage ? "active" : ""
                          }`}
                          onClick={() => setCurrentPage(i + 1)}
                        >
                          {i + 1}
                        </Button>
                      </li>
                    ))}
                    <li className="nav-item">
                      <Button
                        className="table-btn"
                        onClick={() =>
                          setCurrentPage((prevPage) =>
                            prevPage === totalPages ? prevPage : prevPage + 1
                          )
                        }
                      >
                        <i className="bi bi-chevron-right"></i>
                      </Button>
                    </li>
                  </ul>
                </Col>
              </Row>
            </>
          ) : groups.length > 0 ? (
            <div className="cg-custom-table">
              <Wtable theadData={theadData} tbodyData={tbodyData1} />
              <p
                style={{
                  textAlign: "center",
                  paddingTop: "20px",
                  paddingBottom: "10px",
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                No groups match your search
              </p>
            </div>
          ) : (
            <div className="cg-custom-table">
              <Wtable theadData={theadData} tbodyData={tbodyData1} />
              <p
                style={{
                  textAlign: "center",
                  paddingTop: "20px",
                  paddingBottom: "10px",
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                No groups exist
              </p>
            </div>
          )}
        </>
      ) : (
        <Placeholdertable theadData={theadData} />
      )}
    </div>
  );
};

export default Grouptable;
