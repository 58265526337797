import axios from "axios";
import config from "../../config";

const baseUrl = `${config.backend}broadcast/`;

export const sendBroadcast = async (data) => {
  const response = await axios.post(`${baseUrl}send`, data);
  return response.data;
};

export const getAllBroadcasts = async (
  page = 1,
  limit = 10,
  startDate,
  endDate
) => {
  const response = await axios.get(
    `${baseUrl}all?page=${page}&limit=${limit}&start_date=${startDate}&end_date=${endDate}`
  );
  return response.data;
};

export const getSingleBroadcasts = async (id) => {
  const response = await axios.get(`${baseUrl}${id}`);
  return response.data;
};
