import axios from 'axios'
import config from '../../config'

export const getAllNotesOfUser = async (userId, chatId) => {
    const response = await axios.get(`${config.backend}notes/get/${userId}/${chatId}`)
    return response.data
}

export const addNewNoteForUser = async (data) => {
    const response = await axios.post(`${config.backend}notes/create`, data)
    return response.data
}

export const updateNoteOfAUser = async (data) => {
    const response = await axios.put(`${config.backend}notes/edit`, data)
    return response.data
}

export const deleteNoteOfAUser = async (data) => {
    const response = await axios.delete(`${config.backend}notes/delete`, data)
    return response.data
}