import { Col, Row } from "react-bootstrap";

export const AuthenticationPreview = ({ formData }) => {
    return (
        <>
            <div className="preview-sec">
                <div className="preview-body">
                    <div className="preview-screen">
                        <div className="content">
                            {`{{1}} is you verification code.`} {formData.security ? "For your security, do not share this code." : ""}
                        </div>
                        {formData.codeExpiry !== undefined && formData.codeExpiry !== "" && (<div className="content" style={{ opacity: '70%', fontSize: '12px' }} >
                            {`This code expires in ${formData.codeExpiry} minutes`}
                        </div>)}
                    </div>
                    <div>
                        <Row className="link-footer1 px-0">
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", paddingBottom: "10px" }}>
                                <p style={{ wordWrap: "anywhere", paddingLeft: "10px", paddingRight: "10px", color: "#0FA6E0", fontWeight: "600", }} >
                                    {formData.copyButton}
                                </p>
                            </div>

                        </Row>
                    </div>
                    <div>
                    </div>

                </div>

            </div>
        </>
    );
}