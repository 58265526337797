import { useState, useContext } from "react";
import { Form, Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { loginUser } from '../../services/User/index'

import UserContext from "../../store/user-context";


import './form.css';

const Whatsapp_form = ({ heading }) => {
  const [isSendingReq, setIsSendingReq] = useState(false)
  const { setUserState } = useContext(UserContext);
  const [inputValues, setInputValues] = useState({ email: '', password: '' })
  const [inputErrors, setInputErrors] = useState({
    email: { hasError: false, errorMessage: '' },
    password: { hasError: false, errorMessage: '' }
  });

  const [btnClicked, setBtnClicked] = useState(false)
  const navigate = useNavigate()

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const errorMessage = validateInput(name, value);
    setInputErrors(prevState => ({
      ...prevState,
      [name]: {
        hasError: Boolean(errorMessage),
        errorMessage: errorMessage || ''
      }
    }));
    setInputValues((prevValues) => ({ ...prevValues, [name]: value }));
  };


  const clearInputValues = () => {
    setInputValues({ email: '', password: '' })
  }

  const validateInput = (name, value) => {
    // perform validation and return error message if there's an error, otherwise return null
    if (name === 'email') {
      if (value.trim() === '') {
        return 'Email is required.';
      }
      else if (!/^\S+@\S+\.\S+$/.test(value)) {
        return 'Email is invalid.';
      }
    }

    if (name === 'password' && value.trim() === '') {
      return 'Password is required.';
    }

    // add more validation checks for other fields here

    return null;
  }

  const handleSubmit = async (e) => {
    e?.preventDefault()
    if (btnClicked)
      return
    setBtnClicked(true)

    const errors = {};
    Object.keys(inputValues).forEach((name) => {
      const error = validateInput(name, inputValues[name]);
      if (error) {
        errors[name] = { hasError: true, errorMessage: error };
      } else {
        errors[name] = { hasError: false, errorMessage: '' };
      }
    });
    setInputErrors(errors);

    let hasError = false;
    Object.keys(errors).forEach((name) => {
      if (errors[name].hasError) {
        hasError = true;
      }
    });

    if (hasError) {
      // There is at least one error
      setBtnClicked(false);
      return;
    }

    try {

      const resp = await loginUser(inputValues)
      console.log(resp)

      if (resp.error == "User not verified yet") {
        console.log("unapproved")
        setInputErrors(prevState => ({
          ...prevState,
          password: {
            ...prevState.password,
            hasError: true,
            errorMessage: "Please check your email to set your password first."
          }
        }))
      }

      if (resp.message == "Wrong password") {
        console.log("wrongpass")
        setInputErrors(prevState => ({
          ...prevState,
          password: {
            ...prevState.password,
            hasError: true,
            errorMessage: "Wrong Password"
          }
        }))
      }
      if (resp.message == "User not approved by Admin") {
        console.log("unapproved")
        setInputErrors(prevState => ({
          ...prevState,
          password: {
            ...prevState.password,
            hasError: true,
            errorMessage: "User not approved by Admin"
          }
        }))
      }
      if (resp.message == "Token doesnt exist" && resp.companyadmin) {
        console.log("Token doesnt exist")
        navigate('/facebook-signup', { state: { companyId: resp.companyId, message: 'Connect your Facebook Business Account' } })
      }
      if (resp.message == "Token expired" && resp.companyadmin) {
        console.log("Token expired")
        navigate('/facebook-signup', { state: { companyId: resp.companyId, message: 'Your Facebook session has expired' } })
      }
      if ((resp.message == "Token doesnt exist" || resp.message == "Token expired") && !resp.companyadmin) {
        console.log(resp)
        setInputErrors(prevState => ({
          ...prevState,
          password: {
            ...prevState.password,
            hasError: true,
            errorMessage: "Your Facebook login is expired or incomplete, ask your company admin to login with facebook again"
          }
        }))
      }
      if (resp.message == "A user with this email could not be found") {
        setInputErrors(prevState => ({
          ...prevState,
          email: {
            ...prevState.email,
            hasError: true,
            errorMessage: "A user with this email doesn't exist"
          }
        }))
      }

      if (resp.success) {
        clearInputValues()
        // toast.success('Logged In Successfully')
        localStorage.setItem('token', resp.token)
        localStorage.setItem('userId', resp.userId)
        localStorage.setItem('superAdmin', resp.superadmin)
        localStorage.setItem('companyAdmin', resp.role == "owner" || resp.role == "admin" ? true : false)
        localStorage.setItem('companyId', resp.companyid)
        localStorage.setItem('mfaCompleted', resp.isMFADone)
        localStorage.setItem('mfaDone', false)
        console.log("setting up local storage ", resp.userId, " ", resp.superadmin, resp.companyadmin, resp.companyid)
        const remainingMilliseconds = 60 * 60 * 1000;
        const expiryDate = new Date(
          new Date().getTime() + remainingMilliseconds
        );
        const userLoggedIn = new CustomEvent('userLoggedIn', {
          detail: { companyId: resp.companyid }
        })
        document.dispatchEvent(userLoggedIn)

        localStorage.setItem('expiryDate', expiryDate.toISOString());
        setUserState({ isAuth: true, token: resp.token, userId: resp.userId, superAdmin: resp.superadmin, companyAdmin: resp.companyadmin, companyId: resp.companyid, mfaCompleted: resp.isMFADone, mfaDone: false })
        // console.log("response: ",resp.isadmin)
        navigate('/')
      }
      else {
        throw new Error(resp.message)
      }

    } catch (error) {
    }
    setBtnClicked(false)
  }
  const onClick = (e) => {
    navigate(e);
  }

  return (
    <div className="form-sec">
      <Form onSubmit={handleSubmit}>
        <div className="form-heading text-center">{heading}</div>
        <Form.Group className="form-group">
          <Form.Label>Email Address*</Form.Label>
          <Form.Control type="email" name="email" value={inputValues.email} onChange={handleInputChange} className={inputErrors.email.hasError ? "is-invalid" : ""} />
          <Form.Control.Feedback type="invalid">
            {inputErrors.email.hasError && inputErrors.email.errorMessage}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>Password*</Form.Label>
          <Form.Control type="password" name="password" value={inputValues.password} onChange={handleInputChange} className={inputErrors.password.hasError ? "is-invalid" : ""} />
          <Form.Control.Feedback type="invalid">
            {inputErrors.password.hasError && inputErrors.password.errorMessage}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="form-group text-end">
          <Link to="/resetpassword">Forgot your password?</Link>
        </Form.Group>
        <Form.Group className="form-group">
          {
            btnClicked ?
              <Button className="btn btn-main">
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{ color: "#FFFFFF" }}
                />
              </Button>
              :
              <Button className="btn btn-main" type="submit">
                Login
              </Button>
          }
        </Form.Group>
        <Form.Group className="text-center last-pra">
          Don't have an account? <Link to="/signup">Sign up</Link>
        </Form.Group>
        <Form.Group className="text-center">
          <div >
            <p className="policy-text"> By Siging in, you agree to our
              <a className='policy-link' onClick={() => {
                onClick("/privacy-policy")
              }} >
                Privacy Policy
              </a>
              &
              <a className='policy-link' onClick={() => {
                onClick("/terms-and-condition")
              }}
              >
                Terms & Conditions
              </a>
            </p>
          </div>
        </Form.Group>
      </Form>
    </div>
  );
}

export default Whatsapp_form;