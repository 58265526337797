import axios from 'axios'
import config from '../../config'

const baseUrl = `${config.backend}user/`

export const loginUser = async (data) => {
  const response = await axios.post(`${baseUrl}login`, data)
  return response.data
}

export const logOutUser = async (data) => {
  const response = await axios.post(`${baseUrl}logout`, data)
  return response.data
}

export const updateUser = async (data) => {
  console.log("data", data)
  const response = await axios.post(`${baseUrl}update`, data)
  return response.data
}

export const updatePfp = async (data) => {
  const response = await axios.post(`${baseUrl}updatepfp`, data)
  return response.data;
};

export const getPfp = async (data) => {
  const response = await axios.get(`${baseUrl}getpfp`, data)
  return response.data
}


export const getUserDetails = async (data) => {
  const response = await axios.get(`${baseUrl}getdetails?userId=${data}`)
  return response.data
}

export const checkValidToken = async (data) => {
  const response = await axios.get(`${baseUrl}velidateUserToken?userId=${data}`)
  return response.data
}
export const getCompanyMessages = async (data) => {
  const response = await axios.get(`${baseUrl}getcompanymessages`, data)
  return response.data
}

export const getAllUsers = async (data) => {
  const response = await axios.get(`${baseUrl}getall`, data)
  return response.data
}

export const getAllMessagesSent = async (data) => {
  const response = await axios.get(`${baseUrl}getallmessages`, data)
  return response.data
}

export const checkIfUser = async (data) => {
  const response = await axios.get(`${baseUrl}checkuser?email=${data}`)
  return response.data
}

export const verifyToken = async (data) => {
  const response = await axios.get(`${baseUrl}verifytoken?token=${data}`)
  return response.data
}

export const getAllCompanyUsers = async (data) => {
  console.log(data)
  const response = await axios.get(`${baseUrl}company-users`, data)
  return response.data
}

export const editApproval = async (data) => {
  console.log(data);
  try {
    const response = await axios.post(`${baseUrl}editapproval`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editUser = async (data) => {
  console.log(data);
  try {
    const response = await axios.post(`${baseUrl}update`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async (data) => {
  console.log(data);
  try {
    const response = await axios.post(`${baseUrl}delete`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reverseDeleteUser = async (data) => {
  console.log(data);
  try {
    const response = await axios.post(`${baseUrl}undelete`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createUser = async (data) => {
  console.log(data);
  try {
    const response = await axios.post(`${baseUrl}register`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const handleForgotPassword = async (data) => {
  console.log(data);
  try {
    const response = await axios.post(`${baseUrl}forgot-password`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (data) => {
  console.log(data);
  try {
    const response = await axios.post(`${baseUrl}resetpassword`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const get2FACredentials = async (data) => {
  const response = await axios.get(`${baseUrl}getcredentials`, data)
  return response.data
}

export const authenticateUser = async (data) => {
  const response = await axios.post(`${baseUrl}authenticateuser`, data)
  return response.data
}