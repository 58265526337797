import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Box, Typography, Tooltip, Zoom } from "@mui/material";
import InfoIcon from "../../images/icons/infoIcon.svg";

export const FooterTemplate = ({ handleChange, formData }) => {
  const [footerShow, setFooterShow] = useState(false);
  return (
    <>
      <Box className="flex justify-start items-start flex-column border-[1px] border-dashed rounded-md border-[#77777780] p-3 bg-[#FFFFFF] mt-[18px] templates">
        <Form.Group className="form-group w-100">
          <h4
            style={{ fontFamily: "SEGOE UI" }}
            className="text-[16px] font-medium leading-[22px] text-[#000000] flex gap-2"
          >
            Footer
            <Tooltip
              arrow
              TransitionComponent={Zoom}
              title={
                <p>
                  Few Examples: <br />
                  - Thank you for shopping with us!
                  <br />
                  - Best Regards, <br />- [Name] or Powered by [Company Name]{" "}
                </p>
              }
              placement={"top"}
              sx={{ cursor: "pointer" }}
              open={footerShow}
              onMouseEnter={() => setFooterShow(true)}
              onMouseLeave={() => setFooterShow(false)}
            >
              <img
                src={InfoIcon}
                style={{ cursor: "pointer" }}
                className="mt-1"
                width={16}
                height={16}
              />
            </Tooltip>
          </h4>
          <h4
            style={{ fontFamily: "SEGOE UI" }}
            className="text-[15px] font-normal leading-[18px] text-[#494949] mt-[8px] "
          >
            (Optional) Add a short line of text to the bottom of your message.
          </h4>
          <div style={{ position: "relative" }}>
            <Form.Control
              isInvalid={Array.from(formData?.footer).length > 60}
              placeholder="Footer text"
              onChange={handleChange}
              name="footer"
              style={{
                height: "40px",
                backgroundColor: "#F3F7F7",
                marginTop: "12px",
              }}
              value={formData.footer}
            />
          </div>
          <Form.Control.Feedback
            style={{
              marginTop: "5px",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
            }}
            type="invalid"
          >
            <p style={{ fontSize: "12px" }}>
              {Array.from(formData?.footer).length > 60
                ? "Not more than 60 character allowed"
                : ""}
            </p>
            <span style={{ color: "black" }}>
              {Array.from(formData?.footer).length}/60
            </span>
          </Form.Control.Feedback>
        </Form.Group>
      </Box>
    </>
  );
};
