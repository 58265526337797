import React, { useState, useEffect } from "react";
import Checkbox from "./checkbox";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Groupcontact from "./groupcontact";
import Hajira from "../../images/hajira.png";

const Allcontacts = ({
  Contactlist,
  handleSelected,
  selectedContacts,
  searchQuery,
}) => {
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState(selectedContacts);
  const [list, setList] = useState([]);
  const [numSelected, setNumSelected] = useState(0);
  console.log("Contactlist in new comp", Contactlist);

  useEffect(() => {
    setList(Contactlist);
  }, [list]);

  useEffect(() => {
    setNumSelected(isCheck.length);
    handleSelected(isCheck);
  }, [isCheck, handleSelected]);

  //   console.log(isCheck)
  useEffect(() => {
    // Check if all checkboxes are selected
    const isAllSelected =
      list.length > 0 && list.every((item) => isCheck.includes(item._id));
    setIsCheckAll(isAllSelected);
  }, [isCheck, list]);

  const handleSelectAll = () => {
    if (isCheckAll) {
      setIsCheck([]);
    } else {
      setIsCheck(list.map((li) => li._id));
    }
    setIsCheckAll(!isCheckAll);
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    if (checked) {
      setIsCheck((prevCheck) => [...prevCheck, id]);
    } else {
      setIsCheck((prevCheck) => prevCheck.filter((item) => item !== id));
    }
  };

  const catalog = list
    .filter((contact) =>
      contact.contactName.toLowerCase()?.includes(searchQuery.toLowerCase())
    )
    .map(({ _id, contactName }, index) => {
      return (
        <div className="row mx-0 user-row" key={index}>
          <div className="user-name user-name-details">
            <Form.Label htmlFor={_id}>
              <Groupcontact
                Image={Hajira}
                name={contactName}
                id={_id}
                index={index + 1}
              />
            </Form.Label>
            <Checkbox
              type="checkbox"
              id={_id}
              handleClick={handleClick}
              isChecked={isCheck.includes(_id)}
            />
          </div>
        </div>
      );
    });

  return (
    <>
      {Contactlist.length > 0 ? (
        <>
          {catalog.length > 0 ? (
            <>
              <Row className="mb-0">
                <Col sm={12} className="d-none">
                  <p className="text-end slect-indicator">
                    <span className="select-numb">{numSelected}</span> Selected
                  </p>
                </Col>
              </Row>
              <div className="row">
                <div className="user-name">
                  <label htmlFor="selectAll">
                    <ul className="nav align-items-center">
                      <li className="nav-item">
                        <div className="u-name">
                          Select All <span>({isCheck?.length})</span>{" "}
                        </div>
                      </li>
                    </ul>
                  </label>
                  <Checkbox
                    type="checkbox"
                    name="selectAll"
                    id="selectAll"
                    handleClick={handleSelectAll}
                    isChecked={isCheckAll}
                  />
                </div>
              </div>
              {catalog}
            </>
          ) : (
            <p style={{ fontWeight: "500", fontSize: "16px" }}>
              No contacts match your search
            </p>
          )}
        </>
      ) : (
        <p style={{ fontWeight: "500", fontSize: "16px" }}>No contacts found</p>
      )}
    </>
  );
};

export default Allcontacts;
