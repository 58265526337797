import { useState, useEffect } from "react";
import Countdown from "react-countdown";
import { useTimer } from "react-timer-hook";
const UserBasicInfo = (props) => {
  console.log("props?.expiryTime", props?.expiryTime);

  const [timeLeft, setTimeLeft] = useState(
    calculateTimeLeft(props?.expiryTime)
  );
  const [isExpired, setIsExpired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      const remainingTime = calculateTimeLeft(props?.expiryTime);
      setTimeLeft(remainingTime);
      setIsLoading(false);
      if (
        remainingTime.hours === 0 &&
        remainingTime.minutes === 0 &&
        remainingTime.seconds === 0
      ) {
        setIsExpired(true);
      } else {
        setIsExpired(false);
      }
    }, 1000);

    // Clear the timer on unmount
    return () => clearInterval(timer);
  }, [props?.expiryTime]);

  function calculateTimeLeft(expiryTime) {
    const difference = expiryTime - Date.now();

    if (difference <= 0) {
      return { hours: 0, minutes: 0, seconds: 0 };
    }

    return {
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return (
    <>
      <div className="user-info-data">
        <div className="user-personal-info">
          <div className="info-row">
            <div className="title">Phone: </div>
            <div className="description">{props?.phoneNo}</div>
          </div>
          <div className="info-row">
            <div className="title">Conversation info:</div>
            <div className="description">
              {isExpired ? "Expired" : "Active"}
            </div>
          </div>
          <div className="info-row">
            <div className="title">Remaining Times:</div>
            <div className="description">
              <i class="bi bi-alarm me-2"></i>
              {/* <Countdown date={props.expiryTime} daysInHours /> */}
              {String(timeLeft.hours).padStart(2, "0")}:
              {String(timeLeft.minutes).padStart(2, "0")}:
              {String(timeLeft.seconds).padStart(2, "0")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserBasicInfo;
