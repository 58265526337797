import { useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Modal,
  Spinner,
} from "react-bootstrap";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { IconButton } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Tooltip,
  Zoom,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Close_icon from "../../images/icons/close-ic.svg";
import ToolTopComp from "../tooltip";
import InfoIcon from "../../images/icons/infoIcon.svg";

import "./styles.css";

const WidgetForm = (props) => {
  const [showNumber, setShowNumber] = useState(false);
  const [showColor, setShowColor] = useState(false);
  const [showBtnText, setShowBtnText] = useState(false);
  const [showRadius, setShowRadius] = useState(false);
  const [showPosition, setShowPosition] = useState(false);
  const [showMarginLeft, setShowMarginLeft] = useState(false);
  const [showMarginRight, setShowMarginRight] = useState(false);
  const [showMarginBottom, setShowMarginBottom] = useState(false);
  const [showWidgetHeader, setShowWidgetHeader] = useState(false);
  const [showWidgetTitle, setShowWidgetTitle] = useState(false);
  const [showShort, setShowShort] = useState(false);

  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false); // State to track if color picker is open
  const [snippet, setSnippet] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const colorPickerRef = useRef(null);
  // Handle click on color picker field to toggle color picker open/close
  const handleColorPickerToggle = () => {
    setIsColorPickerOpen(!isColorPickerOpen);
    if (!isColorPickerOpen) {
      colorPickerRef.current.click(); // Programmatically open color picker
    }
  };
  const [snippetshow, setSnippetShow] = useState(false);

  // handle button text change in widget
  const handleCTABtnDataChange = (index, label = "", tag = "") => {
    let temp = props?.widgetStyles?.widgetDetails?.CTAs;
    if (tag == "") {
      if (label.length <= 30) {
        temp[index].text = label;
      }
    } else {
      temp[index].tag = tag;
    }

    props?.setWidgetStyles((prev) => ({
      ...prev,
      widgetDetails: { ...prev.widgetDetails, CTAs: [...temp] },
    }));
  };

  const getMatchedTag = (id) => {
    let isFound = props?.allOrgTags?.find(
      (item) => item?._id == id || item?.tagKey == id
    );
    console.log("isFound in funccc", isFound);
    return isFound;
  };

  console.log(
    "props?.widgetStyles?.widgetDetails?.CTAs",
    props?.widgetStyles?.widgetDetails?.CTAs
  );

  // remove CTA button
  const handleRemoveCTABtn = (index) => {
    let temp = props?.widgetStyles?.widgetDetails?.CTAs;
    let filteredCTAs = temp?.filter((item, ind) => ind != index);
    props?.setWidgetStyles((prev) => ({
      ...prev,
      widgetDetails: { ...prev.widgetDetails, CTAs: [...filteredCTAs] },
    }));
  };

  // add new CTA button
  const handleAddNewCTABtn = () => {
    let temp = props?.widgetStyles?.widgetDetails?.CTAs;
    temp.push({ text: "", tag: "" });
    props?.setWidgetStyles((prev) => ({
      ...prev,
      widgetDetails: { ...prev.widgetDetails, CTAs: [...temp] },
    }));
  };

  // removes paces from script tag but not from inside of script tag so that spaces remain extact same
  const removeLeadingWhitespace = (str) => {
    const lines = str.split("\n");
    // Remove any empty lines at the start and end
    while (lines.length > 0 && lines[0].trim() === "") {
      lines.shift();
    }
    while (lines.length > 0 && lines[lines.length - 1].trim() === "") {
      lines.pop();
    }

    // Determine the minimum indentation
    const minIndent = Math.min(
      ...lines
        .filter((line) => line.trim() !== "")
        .map((line) => line.match(/^(\s*)/)[0].length)
    );

    // Remove the minimum indentation from each line
    const trimmedLines = lines.map((line) => line.substring(minIndent));
    return trimmedLines.join("\n");
  };

  const scriptUrl = window.location.origin + "/script.js";

  // generate snippet for widget
  const handleGenerateBtnClick = async () => {
    let response = null;
    if (props?.isEdit) {
      //response = await props?.updateWidget();
      response = await props?.createWidget();
      console.log("resp of update snippet", response);
    } else {
      response = await props?.createWidget();
      console.log("resp of create snippet", response);
    }

    if (response?.success) {
      props?.setIsOpen(false);
      const scriptCode = removeLeadingWhitespace(`
            <script
                type="text/javascript"
                src=${scriptUrl}
                widget-id="${response?.widgetId}"
                id="chat-widget-script"
            ></script>
                `);
      setSnippet(scriptCode);
      setTimeout(() => setSnippetShow(true), 800);
    }
  };

  const markAsCopied = (index) => {
    setIsCopied(true);
    removeCopiedData();
  };

  const removeCopiedData = () => {
    setTimeout(() => {
      setIsCopied(false);
    }, 800);
  };

  return (
    <>
      <section className="main inner-main">
        <section className="contact-group-top">
          <Container>
            <Row>
              <Col md={12} lg={8} className="mb-3 mb-lg-0">
                <div className="create-template-form widget-form mb-3">
                  <Row>
                    <div className="widget-heading">Chat Button</div>
                    <div className="widget-pra">
                      Easily add a customizable chat button to your website.
                      When clicked, it opens a WhatsApp chat for instant
                      support.
                    </div>
                  </Row>
                  <Row>
                    <Col sm={12} md={6}>
                      <Form.Group className="form-group">
                        <Form.Label className="flex gap-2 ">
                          WhatsApp Phone Number{" "}
                          <Tooltip
                            arrow
                            TransitionComponent={Zoom}
                            title={
                              "WhatsApp Phone number on which the user will message"
                            }
                            placement={"top"}
                            sx={{ cursor: "pointer" }}
                            open={showNumber}
                            onMouseEnter={() => setShowNumber(true)}
                            onMouseLeave={() => setShowNumber(false)}
                          >
                            <img src={InfoIcon} style={{ cursor: "pointer" }} />
                          </Tooltip>
                        </Form.Label>
                        <Form.Control
                          style={{
                            cursor: "disabled",
                            backgroundColor: "lightGrey",
                          }}
                          disabled
                          placeholder="923015675524"
                          value={props?.widgetStyles?.chatButton?.whatsAppNo}
                        />
                      </Form.Group>
                    </Col>

                    <Col sm={12} md={6}>
                      <Form.Group className="form-group">
                        <Form.Label className="flex gap-2">
                          Button Color{" "}
                          <Tooltip
                            arrow
                            TransitionComponent={Zoom}
                            title={"Color to use for WhatsApp Button "}
                            placement={"top"}
                            sx={{ cursor: "pointer" }}
                            open={showColor}
                            onMouseEnter={() => setShowColor(true)}
                            onMouseLeave={() => setShowColor(false)}
                          >
                            <img src={InfoIcon} style={{ cursor: "pointer" }} />
                          </Tooltip>
                        </Form.Label>
                        <div
                          className="color-picker-field"
                          onClick={handleColorPickerToggle}
                        >
                          <div className="color-picker-inner d-flex align-items-center gap-2">
                            {/* Left side: Color picker */}
                            <div className="color-sec">
                              <input
                                ref={colorPickerRef}
                                type="color"
                                className="form-control form-control-color mb-0 border-0 bg-transparent"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "50%",
                                  backgroundColor:
                                    props?.widgetStyles?.chatButton?.bgColor,
                                  display: "block",
                                  padding: "0",
                                  overflow: "hidden",
                                }}
                                value={props?.widgetStyles?.chatButton?.bgColor}
                                onChange={(e) =>
                                  props?.setWidgetStyles((prev) => ({
                                    ...prev,
                                    chatButton: {
                                      ...prev.chatButton,
                                      bgColor: e.target.value,
                                    },
                                  }))
                                }
                              />
                            </div>

                            {/* Right side: Color code display */}
                            <div className="color-code-sec">
                              <span>
                                {props?.widgetStyles?.chatButton?.bgColor}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6}>
                      <Form.Group className="form-group">
                        <Form.Label className="flex gap-2">
                          Button Text
                          <Tooltip
                            arrow
                            TransitionComponent={Zoom}
                            title={
                              "Text to display on WhatsApp Button, eg, Chat with Us"
                            }
                            placement={"top"}
                            sx={{ cursor: "pointer" }}
                            open={showBtnText}
                            onMouseEnter={() => setShowBtnText(true)}
                            onMouseLeave={() => setShowBtnText(false)}
                          >
                            <img src={InfoIcon} style={{ cursor: "pointer" }} />
                          </Tooltip>
                        </Form.Label>
                        <Form.Control
                          placeholder="Chat with us"
                          style={{
                            marginBottom:
                              props?.widgetStyles?.chatButton?.text?.length > 40
                                ? "5px"
                                : "",
                          }}
                          value={props?.widgetStyles?.chatButton?.text}
                          onChange={(e) =>
                            props?.setWidgetStyles((prev) => ({
                              ...prev,
                              chatButton: {
                                ...prev.chatButton,
                                text: e.target.value,
                              },
                            }))
                          }
                        />
                        {props?.widgetStyles?.chatButton?.text?.length > 40 ? (
                          <p
                            style={{
                              fontSize: "12px",
                              color: "red",
                              fontWeight: 400,
                              marginBottom: "10px",
                            }}
                          >
                            Button text can't be greater than 40
                          </p>
                        ) : null}
                      </Form.Group>
                    </Col>

                    <Col sm={12} md={6}>
                      <Form.Group className="form-group">
                        <Form.Label className="flex gap-2">
                          Border Radius
                          <Tooltip
                            arrow
                            TransitionComponent={Zoom}
                            title={
                              "It controls the rounded corners on buttons, with higher values resulting in greater roundness. Max supported value is 30."
                            }
                            placement={"top"}
                            sx={{ cursor: "pointer" }}
                            open={showRadius}
                            onMouseEnter={() => setShowRadius(true)}
                            onMouseLeave={() => setShowRadius(false)}
                          >
                            <img src={InfoIcon} style={{ cursor: "pointer" }} />
                          </Tooltip>
                        </Form.Label>
                        <Form.Control
                          placeholder="20"
                          value={props?.widgetStyles?.chatButton?.borderRadius}
                          onChange={(e) =>
                            e.target.value.replace(/[^0-9]/g, "") <= 30 &&
                            props?.setWidgetStyles((prev) => ({
                              ...prev,
                              chatButton: {
                                ...prev.chatButton,
                                borderRadius: e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                ),
                              },
                            }))
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "10px" }}>
                    <Col xs={12}>
                      <FormControl>
                        <FormLabel
                          className="flex gap-2"
                          id="demo-row-radio-buttons-group-label "
                          style={{
                            fontSize: "16px",
                            fontFamily: "Segoe UI",
                            fontWeight: 500,
                            color: "#000000",
                          }}
                        >
                          Position
                          <Tooltip
                            arrow
                            TransitionComponent={Zoom}
                            title={" Position of button/widget on the screen"}
                            placement={"top"}
                            sx={{ cursor: "pointer" }}
                            open={showPosition}
                            onMouseEnter={() => setShowPosition(true)}
                            onMouseLeave={() => setShowPosition(false)}
                          >
                            <img src={InfoIcon} style={{ cursor: "pointer" }} />
                          </Tooltip>
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                sx={{
                                  color: "#4B4A4A",
                                  "&.Mui-checked": {
                                    color: "#165E5B",
                                  },
                                }}
                              />
                            }
                            label="Bottom Left"
                            checked={props?.widgetStyles?.position == "left"}
                            onChange={(e) =>
                              props?.setWidgetStyles((prev) => ({
                                ...prev,
                                position: "left",
                                marginRight: null,
                              }))
                            }
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                sx={{
                                  color: "#4B4A4A",
                                  "&.Mui-checked": {
                                    color: "#165E5B",
                                  },
                                }}
                              />
                            }
                            checked={props?.widgetStyles?.position == "right"}
                            label="Bottom Right"
                            onChange={(e) =>
                              props?.setWidgetStyles((prev) => ({
                                ...prev,
                                position: "right",
                                marginLeft: null,
                              }))
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </Col>
                  </Row>
                  <Row>
                    {props?.widgetStyles?.position == "left" ? (
                      <Col sm={12} md={6}>
                        <Form.Group className="form-group">
                          <Form.Label className="flex gap-2">
                            Margin Left(px)
                            <Tooltip
                              arrow
                              TransitionComponent={Zoom}
                              title={"Margin from left side, Default is 30px"}
                              placement={"top"}
                              sx={{ cursor: "pointer" }}
                              open={showMarginLeft}
                              onMouseEnter={() => setShowMarginLeft(true)}
                              onMouseLeave={() => setShowMarginLeft(false)}
                            >
                              <img
                                src={InfoIcon}
                                style={{ cursor: "pointer" }}
                              />
                            </Tooltip>
                          </Form.Label>
                          <Form.Control
                            placeholder="10"
                            value={props?.widgetStyles?.chatButton?.marginLeft}
                            onChange={(e) =>
                              props?.setWidgetStyles((prev) => ({
                                ...prev,
                                chatButton: {
                                  ...prev.chatButton,
                                  marginLeft: e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  ),
                                },
                              }))
                            }
                          />
                        </Form.Group>
                      </Col>
                    ) : (
                      <Col sm={12} md={6}>
                        <Form.Group className="form-group">
                          <Form.Label className="flex gap-2">
                            Margin Right(px)
                            <Tooltip
                              arrow
                              TransitionComponent={Zoom}
                              title={"Margin from right side, Default is 30px"}
                              placement={"top"}
                              sx={{ cursor: "pointer" }}
                              open={showMarginRight}
                              onMouseEnter={() => setShowMarginRight(true)}
                              onMouseLeave={() => setShowMarginRight(false)}
                            >
                              <img
                                src={InfoIcon}
                                style={{ cursor: "pointer" }}
                              />
                            </Tooltip>
                          </Form.Label>
                          <Form.Control
                            placeholder="10"
                            value={props?.widgetStyles?.chatButton?.marginRight}
                            onChange={(e) =>
                              props?.setWidgetStyles((prev) => ({
                                ...prev,
                                chatButton: {
                                  ...prev.chatButton,
                                  marginRight: e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  ),
                                },
                              }))
                            }
                          />
                        </Form.Group>
                      </Col>
                    )}
                    <Col sm={12} md={6}>
                      <Form.Group className="form-group">
                        <Form.Label className="flex gap-2">
                          Margin Bottom(px)
                          <Tooltip
                            arrow
                            TransitionComponent={Zoom}
                            title={"Margin from bottom side, Default is 30px"}
                            placement={"top"}
                            sx={{ cursor: "pointer" }}
                            open={showMarginBottom}
                            onMouseEnter={() => setShowMarginBottom(true)}
                            onMouseLeave={() => setShowMarginBottom(false)}
                          >
                            <img src={InfoIcon} style={{ cursor: "pointer" }} />
                          </Tooltip>
                        </Form.Label>
                        <Form.Control
                          placeholder="10"
                          value={props?.widgetStyles?.chatButton?.marginBottom}
                          onChange={(e) =>
                            props?.setWidgetStyles((prev) => ({
                              ...prev,
                              chatButton: {
                                ...prev.chatButton,
                                marginBottom: e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                ),
                              },
                            }))
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
                <div className="create-template-form widget-form mb-3">
                  <Row>
                    <div className="widget-heading">Chat Widget</div>
                    <div className="widget-pra">
                      Design a branded chat widget for your site. Customize the
                      header, title, and description, and add buttons for
                      specific support topics. Generate the snippet code to
                      integrate the widget seamlessly, providing quick and
                      tailored assistance to your visitors.
                    </div>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group className="form-group">
                        <Form.Label className="flex gap-2">
                          Widget Header Background
                          <Tooltip
                            arrow
                            TransitionComponent={Zoom}
                            title={"Choose color for the Widget Header "}
                            placement={"top"}
                            sx={{ cursor: "pointer" }}
                            open={showWidgetHeader}
                            onMouseEnter={() => setShowWidgetHeader(true)}
                            onMouseLeave={() => setShowWidgetHeader(false)}
                          >
                            <img src={InfoIcon} style={{ cursor: "pointer" }} />
                          </Tooltip>
                        </Form.Label>
                        <div className="color-picker-field">
                          <div className="color-picker-inner d-flex align-items-center gap-2">
                            {/* Left side: Color picker */}
                            <div className="color-sec">
                              <input
                                type="color"
                                className="form-control form-control-color mb-0 border-0 bg-transparent"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "50%",
                                  backgroundColor:
                                    props?.widgetStyles?.widgetDetails
                                      ?.headerBgColor,
                                  display: "block",
                                  padding: "0",
                                  overflow: "hidden",
                                }}
                                value={
                                  props?.widgetStyles?.widgetDetails
                                    ?.headerBgColor
                                }
                                onChange={(e) =>
                                  props?.setWidgetStyles((prev) => ({
                                    ...prev,
                                    widgetDetails: {
                                      ...prev.widgetDetails,
                                      headerBgColor: e.target.value,
                                    },
                                  }))
                                }
                              />
                            </div>

                            {/* Right side: Color code display */}
                            <div className="color-code-sec">
                              <span>
                                {
                                  props?.widgetStyles?.widgetDetails
                                    ?.headerBgColor
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      <Form.Group className="form-group">
                        <Form.Label className="flex gap-2">
                          Widget Title
                          <Tooltip
                            arrow
                            TransitionComponent={Zoom}
                            title={
                              "The title to be displayed on the once Widget is open"
                            }
                            placement={"top"}
                            sx={{ cursor: "pointer" }}
                            open={showWidgetTitle}
                            onMouseEnter={() => setShowWidgetTitle(true)}
                            onMouseLeave={() => setShowWidgetTitle(false)}
                          >
                            <img src={InfoIcon} style={{ cursor: "pointer" }} />
                          </Tooltip>
                        </Form.Label>
                        <div className="input-body position-relative">
                          <Form.Control
                            placeholder=""
                            style={{
                              marginBottom:
                                props?.widgetStyles?.widgetDetails?.title
                                  ?.length > 40
                                  ? "5px"
                                  : "5PX",
                              marginLeft: 0,
                              marginRight: 0,
                              border:
                                props?.widgetStyles?.widgetDetails?.title
                                  ?.length > 59
                                  ? "1px solid red"
                                  : "",
                            }}
                            value={props?.widgetStyles?.widgetDetails?.title}
                            onChange={(e) =>
                              e.target.value?.length <= 60 &&
                              props?.setWidgetStyles((prev) => ({
                                ...prev,
                                widgetDetails: {
                                  ...prev.widgetDetails,
                                  title: e.target.value,
                                },
                              }))
                            }
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent:
                                props?.widgetStyles?.widgetDetails?.title
                                  ?.length > 60
                                  ? "space-between"
                                  : "flex-end",
                              alignItems: "center",
                            }}
                          >
                            {props?.widgetStyles?.widgetDetails?.title?.length >
                            60 ? (
                              <p
                                style={{
                                  fontSize: "12px",
                                  color: "red",
                                  fontWeight: 400,
                                  marginBottom: "10px",
                                }}
                              >
                                Title can't be greater than 60
                              </p>
                            ) : (
                              <p
                                style={{
                                  fontSize: "12px",
                                  color: "red",
                                  fontWeight: 400,
                                  marginBottom: "10px",
                                }}
                              ></p>
                            )}
                            <span
                              className="word-limit"
                              style={{
                                bottom:
                                  props?.widgetStyles?.widgetDetails?.title
                                    ?.length > 60
                                    ? 0
                                    : -5,
                              }}
                            >
                              {
                                props?.widgetStyles?.widgetDetails?.title
                                  ?.length
                              }
                              /60
                            </span>
                          </div>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      <Form.Group className="form-group">
                        <Form.Label className="flex gap-2">
                          Short Description
                          <Tooltip
                            arrow
                            TransitionComponent={Zoom}
                            title={
                              "This placeholder text clearly indicates where users can input their custom text description for the chat widget."
                            }
                            placement={"top"}
                            sx={{ cursor: "pointer" }}
                            open={showShort}
                            onMouseEnter={() => setShowShort(true)}
                            onMouseLeave={() => setShowShort(false)}
                          >
                            <img src={InfoIcon} style={{ cursor: "pointer" }} />
                          </Tooltip>
                        </Form.Label>
                        <div className="textarea-body position-relative">
                          <textarea
                            className="form-control"
                            style={{
                              marginBottom:
                                props?.widgetStyles?.widgetDetails?.desc
                                  ?.length > 100
                                  ? "5px"
                                  : "5px",
                              border:
                                props?.widgetStyles?.widgetDetails?.desc
                                  ?.length > 99
                                  ? "1px solid red"
                                  : "",
                              marginLeft: 0,
                              marginRight: 0,
                              width: "100%",
                            }}
                            placeholder=""
                            value={props?.widgetStyles?.widgetDetails?.desc}
                            onChange={(e) =>
                              e.target.value?.length <= 100 &&
                              props?.setWidgetStyles((prev) => ({
                                ...prev,
                                widgetDetails: {
                                  ...prev.widgetDetails,
                                  desc: e.target.value,
                                },
                              }))
                            }
                            rows="4"
                          ></textarea>
                          <div
                            style={{
                              display: "flex",
                              justifyContent:
                                props?.widgetStyles?.widgetDetails?.desc
                                  ?.length > 100
                                  ? "space-between"
                                  : "flex-end",
                              alignItems: "center",
                            }}
                          >
                            {props?.widgetStyles?.widgetDetails?.desc?.length >
                            100 ? (
                              <p
                                style={{
                                  fontSize: "12px",
                                  color: "red",
                                  fontWeight: 400,
                                  marginBottom: "10px",
                                }}
                              >
                                Description can't be greater than 100
                              </p>
                            ) : null}
                            <span
                              className="word-limit"
                              style={{
                                bottom:
                                  props?.widgetStyles?.widgetDetails?.desc
                                    ?.length > 100
                                    ? 0
                                    : 0,
                              }}
                            >
                              {props?.widgetStyles?.widgetDetails?.desc?.length}
                              /100
                            </span>
                          </div>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  {props?.widgetStyles?.widgetDetails?.CTAs?.length > 0
                    ? props?.widgetStyles?.widgetDetails?.CTAs?.map(
                        (item, ind) => (
                          <Row className="align-items-end newBtn-row">
                            <Col xs={12} md={6}>
                              <Form.Group className="form-group">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    columnGap: "10px",
                                  }}
                                >
                                  <Form.Label>Button Label</Form.Label>
                                  {ind == 0 ? (
                                    <ToolTopComp text="Add the text to the Button which will redirect the user to WhatsApp, allowing them to start a conversation instantly" />
                                  ) : null}
                                </div>
                                <Form.Control
                                  disabled={item?.text?.length > 30}
                                  placeholder="Text"
                                  style={{
                                    marginBottom:
                                      item?.text?.length > 30 ? "5px" : "",
                                  }}
                                  value={item?.text}
                                  onChange={(e) =>
                                    handleCTABtnDataChange(ind, e.target.value)
                                  }
                                />
                                {item?.text?.length >= 30 ? (
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      color: "red",
                                      fontWeight: 400,
                                      marginBottom: "10px",
                                    }}
                                  >
                                    Label can't be greater than 30
                                  </p>
                                ) : null}
                              </Form.Group>
                            </Col>
                            <Col xs={12} md={5}>
                              <Form.Group className="form-group">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    columnGap: "10px",
                                  }}
                                >
                                  <Form.Label className="flex">
                                    Assign Tag
                                  </Form.Label>
                                  {ind == 0 ? (
                                    <ToolTopComp text=" Select from dropdown" />
                                  ) : null}
                                </div>

                                <Select
                                  className="c-select"
                                  options={props?.allTags}
                                  placeholder={"Supports"}
                                  value={{
                                    label: getMatchedTag(item?.tag)?.title,
                                    value: getMatchedTag(item?.tag)?.tagKey,
                                  }}
                                  onChange={(val) =>
                                    handleCTABtnDataChange(ind, "", val?.value)
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col xs={12} md={1} className="d-none d-md-block">
                              {props?.widgetStyles?.widgetDetails?.CTAs
                                ?.length > 1 ? (
                                <Button
                                  onClick={() => handleRemoveCTABtn(ind)}
                                  className="bg-transparent border-none p-2 button-close"
                                >
                                  <img src={Close_icon} alt="close-ic" />
                                </Button>
                              ) : null}
                            </Col>
                            {props?.widgetStyles?.widgetDetails?.CTAs?.length >
                            1 ? (
                              <Button
                                onClick={() => handleRemoveCTABtn(ind)}
                                className="bg-transparent border-none p-2 button-close d-md-none"
                              >
                                <img src={Close_icon} alt="close-ic" />
                              </Button>
                            ) : null}
                          </Row>
                        )
                      )
                    : null}

                  <Row>
                    <Col sm={12}>
                      <Button
                        onClick={handleAddNewCTABtn}
                        className="btn btn-simple"
                      >
                        <i class="bi bi-plus"></i> New Button
                      </Button>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col sm={12}>
                    <button
                      className="btn btn-main d-block w-100"
                      style={{
                        backgroundColor: props?.isApiCalling ? "#165e5b" : "",
                      }}
                      onClick={handleGenerateBtnClick}
                    >
                      {props?.isApiCalling ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          style={{ color: "#FFFFFF" }}
                        />
                      ) : (
                        "Save and Generate Snippet"
                      )}
                    </button>
                  </Col>
                </Row>
              </Col>

              <Col md={12} lg={4}>
                <div className="create-template-form widget-form mb-3">
                  <Row>
                    <div className="widget-heading">Create WhatsApp Button</div>
                    <div className="widget-pra">
                      Install the button on your website via simple code. Click
                      on Generate Snippet to get the installation code for your
                      website. Then place this snippet at bottom of body tag in
                      your main html file. Check live preview of your WhatsApp
                      Button on your website then.
                    </div>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </section>

      <Modal
        show={snippetshow}
        onHide={() => setSnippetShow(false)}
        className="whatsapp-modal modal-contact widget-modal"
        centered
      >
        <Modal.Header className="p-0" closeButton>
          <Modal.Title>Code Snippet</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col sm={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column-reverse",
                  width: "100%",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px",
                  }}
                >
                  <pre>
                    <code>{snippet}</code>
                  </pre>
                </div>
                <div
                  style={{
                    paddingRight: "10px",
                    paddingTop: "10px",
                    display: "flex",
                    justifyContent: "flex-end",
                    backgroundColor: "transparent",
                  }}
                >
                  <CopyToClipboard text={`${snippet}`} onCopy={markAsCopied}>
                    <IconButton sx={{ padding: "0px" }}>
                      {isCopied ? (
                        <CheckCircleIcon
                          sx={{ fontSize: "20px", color: "#000000" }}
                        />
                      ) : (
                        <ContentCopyIcon
                          sx={{ fontSize: "20px", color: "#000000" }}
                        />
                      )}
                    </IconButton>
                  </CopyToClipboard>
                </div>
              </div>
              {/* <Tabs className="code-tabs">

                                <TabList>
                                    <Tab id="#react-tab">React Code</Tab>
                                    <Tab id="html-tab">HTML Code</Tab>
                                </TabList>

                                <TabPanel style={{ padding: 0 }} >
                                </TabPanel>

                                <TabPanel style={{ padding: 0 }} >
                                    <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column-reverse", width: "100%", backgroundColor: "red" }} >
                                        <pre>
                                            {snippet}
                                        </pre>
                                        <div style={{ paddingRight: "10px", paddingTop: "10px", display: "flex", justifyContent: "flex-end", backgroundColor: "transparent" }} >
                                            <CopyToClipboard text={`${snippet}`} onCopy={markAsCopied} >
                                                <IconButton sx={{ padding: "0px" }} >
                                                    {
                                                        isCopied ?
                                                            <CheckCircleIcon sx={{ fontSize: "20px", color: "#000000", }} />
                                                            :
                                                            <ContentCopyIcon sx={{ fontSize: "20px", color: "#000000", }} />
                                                    }
                                                </IconButton>
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                </TabPanel>
                            </Tabs> */}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WidgetForm;
