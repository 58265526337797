
import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useLocation } from 'react-router-dom';


import Resetform from '../components/wahtsapp_form/form-reset1';

import '../components/wahtsapp_form/form.css';
import { verifyToken } from '../services/User';
import { resetPassword } from '../services/User';


const Login = () => {

    const [token, setToken] = useState('')
    const [userId, setUserId] = useState('')
    const [tokenStatus, setTokenStatus] = useState('')
    const [passwordUpdated, setPasswordUpdate] = useState(false)

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);


    useEffect(() => {
        setToken(searchParams.get('token'))
    }, [])

    const verify = async () => {
        const res = await verifyToken(token)
        if (res.token == 'valid') {
            setUserId(res.userId)
        }
        setTokenStatus(res.token)
    }

    useEffect(() => {
        if (token && token.length > 0) {
            verify()
        }
    }, [token])

    const reset = async (password) => {
        const res1 = await resetPassword({ userId, password })
        if (res1.updated) {
            setPasswordUpdate(true)
        }
    }



    return (
        <section className="wahtsapp_form">
            <Container>
                <Row>
                    <Col sm={12}>
                        <Resetform heading={'Reset Password'} tokenStatus={tokenStatus} reset={reset} updated={passwordUpdated} />
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Login;