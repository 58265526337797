import axios from "axios";
import config from "../../config";

const baseUrl = `${config.backend}inbox/`;
const baseUrlTag = `${config.backend}`;

export const getInbox = async () => {
  const response = await axios.get(`${baseUrl}all`);
  return response.data;
};

export const getAllChats = async () => {
  const response = await axios.get(
    `${baseUrl}chat/all?page=${1}&limit=${1000}`
  );
  return response.data;
};

export const getSingleChat = async (chatId, limit, msgId) => {
  //const response = await axios.get(`${baseUrl}chat/${chatId}/${limit}`);
  const response = await axios.get(
    `${baseUrl}chat/${chatId}/${limit}/${msgId}`
  );

  return response.data;
};

export const createChat = async (data) => {
  const response = await axios.post(`${baseUrl}chat/create`, data);
  return response.data;
};

export const deleteChat = async (data) => {
  const response = await axios.post(`${baseUrl}chat/delete`, data);
  return response.data;
};

export const deleteSingleMsg = async (data) => {
  const response = await axios.post(`${baseUrl}chat/message/delete`, data);
  return response.data;
};

export const editSingleMsg = async (data) => {
  const response = await axios.post(`${baseUrl}chat/message/edit`, data);
  return response.data;
};

export const blockChat = async ({ chatId, block }) => {
  const response = await axios.post(`${baseUrl}chat/block`, { chatId, block });
  return response.data;
};

export const muteChat = async ({ chatId, duration, mute }) => {
  const requestData = {
    chatId,
    mute,
  };

  // Include the duration key only if it's provided
  if (duration !== undefined) {
    requestData.duration = duration;
  }

  const response = await axios.post(`${baseUrl}chat/mute`, requestData);
  return response.data;
};

export const sendChatMessage = async (data) => {
  const response = await axios.post(`${baseUrl}chat/send`, data);
  return response.data;
};

export const markMessageAsRead = async (data) => {
  const response = await axios.post(`${baseUrl}chat/read`, data);
  return response.data;
};

export const assignTagToChat = async (data) => {
  const response = await axios.post(
    `${baseUrlTag}tags/assingTagToConversation`,
    data
  );
  return response.data;
};

export const assignChatToUser = async (data) => {
  const response = await axios.post(
    `${baseUrlTag}tags/assignChatToUsers`,
    data
  );
  return response.data;
};
