import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

const Placeholdertable = ({ theadData }) => {
  return (
    <section className="main inner-main">
      <section className="contact-group-table">
        <Container>
          <Row>
            <Col xs={12}>
              <Table className="cg-custom-table" responsive>
                <thead>
                  <tr className="placeholder-glow">
                    {theadData.map((data, index) => {
                      return <th key={index}>{data}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <tr key={index} className="placeholder-glow">
                      {theadData.map((data) => (
                        <td key={data}>
                          <span className="placeholder w-100"></span>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </section>
    </section>
  );
};

export default Placeholdertable;
