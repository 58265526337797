const TemplateDetails = ({ Eventname, EventCreatedBy }) => {
  return (
    <>
      <div className="eventName">{Eventname}</div>
      <div className="eventCreator">
        Created by {EventCreatedBy ? EventCreatedBy : "_"}
      </div>
    </>
  );
};

export default TemplateDetails;
