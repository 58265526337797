
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState } from "react"
import { Box, IconButton, Pagination, } from '@mui/material';

import './css/templateListing-footer.css';

const TemplateListingFooter = (props) => {
    const handlePaginationChange = (event, value) => {
        console.log("value got :", value, ((value - 1) * 10))
        props?.hideView()
        props?.setCurrPage(value)
        if (value == 1) {
            props?.setStartIndexTable(0)
        } else {
            props?.setStartIndexTable(((value - 1) * 10))
        }
    }

    return (
        <>
            <section className="listingfooter">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <Box className="bg-[#FFFFFF] flex justify-center align-items-center w-100 all-tags"  >
                                {
                                    props?.templates?.length > 0 ?
                                        <Pagination
                                            count={Math.ceil((props?.templates?.length / 10) % 10)}
                                            variant="outlined"
                                            shape="rounded"
                                            siblingCount={0}
                                            page={props?.currPage}
                                            onChange={handlePaginationChange}
                                        />
                                        : null
                                }
                            </Box>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default TemplateListingFooter;