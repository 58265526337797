

import React from "react";
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Broadcastprogressbar = ({color,percentage}) => {

    return (
        <>
            <div style={{ width: 60, height: 60 }}>
                <CircularProgressbar value={percentage} text={`${percentage}%`} styles={color} />
            </div>
        </>
    )
}

export default Broadcastprogressbar;