import { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Form from "react-bootstrap/Form";
import Closeic from "../../images/icons/close-ic-1.svg"


const QuickReply = ({ dataKey, handleQuickReply, data, deleteQuickReply }) => {
    return (
        <div className="button-text" style={{ display: "flex", columnGap: "10px", alignItems: "center", justifyContent: "space-between", marginBottom: "5px", width: "100%" }}>
            <Form.Control style={{ minWidth: "90%", height: "40px", backgroundColor: "#F3F7F7", }} placeholder="Add button text" onChange={(event) => handleQuickReply(event, dataKey)} name="quickreply" value={data} />
            {/* <Form.Control placeholder="Add button text" onChange={(event) => handleQuickReply(event, dataKey)} name="quickreply" value={data} /> */}
            <div style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }} >
                <img src={Closeic} alt="close-icon" onClick={() => deleteQuickReply(dataKey)} />
            </div>
        </div>
    )
}

export default QuickReply