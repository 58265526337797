import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";
import { Table } from "antd";
import { buildStyles } from "react-circular-progressbar";
import moment from "moment";
import messagesend from "../images/icons/b-tick.svg";
import delivered from "../images/icons/delivery.svg";
import readmessage from "../images/icons/read.svg";
import failed from "../images/icons/failed.svg";
import pending from "../images/icons/pending.svg";
import Replied from "../images/icons/replied.svg";
import chat from "../images/icons/chat.svg";
import Closeic from "../images/icons/close-ic.svg";

import Broadcastcard from "../components/broadcast/broadcast-card";
import Broadcastprogressbar from "../components/broadcast/b-progressbar";
import Broadcasttable from "../components/broadcast/broadcast-table";
import BroadcastcardLoading from "../components/broadcast/broadcast-loading";

import { getAllBroadcasts } from "../services/Broadcast/index";
import { getAllTemplates } from "../services/WhatsappAPI/index";
import { getAllGroups } from "../services/Group/index";
import { sendBroadcast } from "../services/Broadcast/index";
import { toast } from "react-toastify";
import SelectTemplateModal from "../components/chat/SelectTemplateModal";
import DataContext from "../store/data-context";
import { DatePicker, TimePicker } from "antd";
import useDeviceWidthChecker from "../components/customHooks/DeviceWidthDetector";
import dayjs from "dayjs";
import "./style.css";
import { useSelector } from "react-redux";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { BroadCastStates } from "../utils/broadcastUtil";
import {
  isDateLessThanOrEqual,
  isDateGreaterThanOrEqual,
} from "../utils/utils";
import TableSkeleton from "../components/skeletons/TableLoader";
dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;
const rangePresets = [
  { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
  { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
  { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
  { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
  { label: "All", value: [] },
];

const Broadcast = () => {
  const width = useDeviceWidthChecker();
  const [ctshow, setCtShow] = useState(false);
  // const { socket } = useContext(DataContext)
  const [groups, setGroups] = useState([]);
  const [broadcasts, setBroadcasts] = useState([]);
  const [allData, setAllData] = useState([]);
  const [filteredBroadCasts, setFilteredBroadcast] = useState([]);
  const [title, setTitle] = useState("");
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(undefined);
  const [selectedgroups, setSelectedGroups] = useState([]);
  const [isNowTimeSelected, setIsNowTimeSelected] = useState(true);
  const [count, setCount] = useState({
    total: 0,
    completed: 0,
    delivered: 0,
    read: 0,
    failed: 0,
    pending: 0,
  });
  const [groupOptions, setGroupOptions] = useState([]);
  const [titleError, setTitleError] = useState(false);
  const [groupError, setGroupError] = useState(false);
  const [TemplateError, setTemplateError] = useState(false);
  const [broadcastAll, setBroadcastAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateUi, setUpdateUi] = useState(false);
  const [show, setShow] = useState(false);
  const handleCloseTemplate = () => setShow(false);
  const [variable, setVariable] = useState([]);
  const [sending, setSending] = useState(false);
  const [showFailed, setShowFailed] = useState(false);
  const [contacts, setContacts] = useState(null);
  const [dateFilter, setDateFilter] = useState({ startDate: "", endDate: "" });
  const broadcastUpate = useSelector(
    (state) => state.MessageNotification.broadcastUpdate
  );
  const [selDateForSchedule, setSelDateForSchedule] = useState(null);
  const [selTimeForSchedule, setselTimeForSchedule] = useState(null);
  const calculateDefaultDate = () => {
    const end = new Date();
    const start = new Date();
    start.setDate(start.getDate() - 29);
    end.setDate(end.getDate());
    let ata_utc_start = `${start?.getFullYear()}-${
      start?.getMonth() + 1 < 10
        ? "0" + (start?.getMonth() + 1)
        : start?.getMonth() + 1
    }-${start?.getDate() < 10 ? "0" + start?.getDate() : start?.getDate()}`;
    let ata_utc_end = `${end?.getFullYear()}-${
      end?.getMonth() + 1 < 10 ? "0" + (end.getMonth() + 1) : end.getMonth() + 1
    }-${end?.getDate() < 10 ? "0" + end?.getDate() : end?.getDate()}`;
    return [ata_utc_start, ata_utc_end];
  };

  function stripTime(date) {
    let gotDate = new Date(date);
    const strippedDate = new Date(
      gotDate.getFullYear(),
      gotDate.getMonth(),
      gotDate.getDate()
    );
    return strippedDate;
  }

  useEffect(() => {
    let filteredBroad = [];
    const defaultFilter = calculateDefaultDate();
    const stateDate =
      dateFilter.startDate == ""
        ? stripTime(defaultFilter[0])
        : stripTime(dateFilter.startDate);
    const endDate =
      dateFilter.endDate == ""
        ? stripTime(defaultFilter[1])
        : stripTime(dateFilter.endDate);
    console.log("broadcasts all ", broadcasts);
    filteredBroad = broadcasts.filter((broadcast) => {
      // const createDate = new Date(broadcast.scheduledDate.slice(0, 10));
      const createDate = stripTime(broadcast.scheduledDate?.slice(0, 10));
      console.log(
        "sliced date: ",
        broadcast.BroadcastName,
        stateDate >= createDate,
        ":",
        createDate <= endDate,
        createDate,
        ":::",
        "Dates: ",
        stateDate,
        "::",
        endDate
      );
      if (stateDate <= createDate && createDate <= endDate) {
        return broadcast;
      }
    });
    console.log("allData above ", allData);
    let alData =
      allData?.length > 0 &&
      allData?.filter((broadcast) => {
        // const createDate = new Date(broadcast?.scheduleDate?.slice(0, 10));
        const createDate = stripTime(broadcast.scheduleDate?.slice(0, 10));
        console.log(
          "sliced date in lower ",
          createDate,
          stateDate <= createDate,
          "::",
          createDate <= endDate
        );
        if (stateDate <= createDate && createDate <= endDate) {
          return broadcast;
        }
      });

    setFilteredBroadcast(filteredBroad);

    console.log("alData : ", alData);
    getCountOfMessages(alData);
  }, [dateFilter, broadcasts]);

  console.log("allData", allData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const resBroadcast = await getAllBroadcasts();
        console.log(resBroadcast);
        if (resBroadcast.success) {
          const sortedBroadcasts = resBroadcast.data.sort((a, b) => {
            return new Date(b.scheduleDate) - new Date(a.scheduleDate);
          });
          console.log("sortedBroadcasts", sortedBroadcasts);
          setAllData(sortedBroadcasts);
          setBroadcasts(handleDataForTable(sortedBroadcasts));
          setLoading(false);
          const firstBroadCastDate =
            new Date(sortedBroadcasts[sortedBroadcasts.length - 1].createdAt) -
            new Date(sortedBroadcasts[0].createdAt);
          console.log("firstBroadCastDate", sortedBroadcasts);
          rangePresets[4].value = [
            dayjs(sortedBroadcasts[sortedBroadcasts?.length - 1]?.scheduleDate),
            dayjs(sortedBroadcasts[0]?.scheduleDate),
          ];
          setDateFilter({
            startDate: dayjs(
              sortedBroadcasts[sortedBroadcasts?.length - 1]?.scheduleDate
            ),
            endDate: dayjs(sortedBroadcasts[0]?.scheduleDate),
          });
          console.log("rangePresets", rangePresets);
          getCountOfMessages(sortedBroadcasts);
        } else throw new Error(resBroadcast.error);
      } catch (error) {
        console.log("Failed to fetch Data!");
      }
    };
    fetchData();
  }, [updateUi]);

  useEffect(() => {
    setUpdateUi((prevData) => {
      return !prevData;
    });
  }, [broadcastUpate, setUpdateUi]);

  const fetchData = async () => {
    try {
      const resGroups = await getAllGroups();
      console.log("calling ====", resGroups);
      if (resGroups.success) {
        setGroups(resGroups.data);
      } else throw new Error(resGroups.error);
    } catch (error) {
      console.log("Failed to fetch Data!");
    }
  };

  const getCountOfMessages = (data) => {
    const newCount = {
      total: 0,
      completed: 0,
      delivered: 0,
      read: 0,
      failed: 0,
      pending: 0,
    };
    console.log("data of broadcast", data);

    if (data) {
      data?.forEach((broadcast) => {
        broadcast?.messages?.forEach(({ message }) => {
          newCount.total++;
          if (message?.read) {
            newCount.read++;
            newCount.delivered++;
            newCount.completed++;
          } else if (message?.delivered) {
            newCount.delivered++;
            newCount.completed++;
          } else if (message?.sent) newCount.completed++;
          else if (message?.failed) newCount.failed++;
          else newCount.pending++;
        });
      });

      console.log("newCount====", newCount);

      setCount(newCount);
    }
  };

  // useEffect(() => {
  //     const newCount = {
  //         'total': 0,
  //         'completed': 0,
  //         'delivered': 0,
  //         'read': 0,
  //         'failed': 0,
  //         'pending': 0,
  //     }

  //     allData?.forEach((broadcast) => {
  //         broadcast?.messages?.forEach(({ message }) => {
  //             newCount.total++;
  //             if (message?.read) {
  //                 newCount.read++;
  //                 newCount.delivered++;
  //                 newCount.completed++;
  //             }
  //             else if (message?.delivered) {
  //                 newCount.delivered++
  //                 newCount.completed++
  //             }
  //             else if (message?.sent) newCount.completed++;
  //             else if (message?.failed) newCount.failed++;
  //             else newCount.pending++;
  //         });
  //     });

  //     setCount(newCount);
  // }, [filteredBroadCasts, allData, updateUi]);

  useEffect(() => {
    let nonEmptyGroups = groups.filter((item) => item?.contacts?.length > 0);
    const newOptions = [
      { label: "All contacts", value: "all" },
      ...nonEmptyGroups.map((group) => ({
        value: group._id,
        label: group.groupName,
      })),
    ];

    setGroupOptions(newOptions);
  }, [groups]);

  const handleInputChange = (e) => {
    setTitle(e.target.value);
    if (e.target.value.length > 0 && !/^\ /.test(e.target.value)) {
      setTitleError(false);
    } else setTitleError(true);
  };

  const handleSelectChange = (selected) => {
    setSelectedGroups(selected);
    if (selected.length > 0) {
      setGroupError(false);
    }
  };

  const handleSelectChange1 = (selected) => {
    setSelectedTemplate(selected);
    if (selected !== undefined) {
      setTemplateError(false);
    }
  };

  const HandleSendBroadcast = async () => {
    let dateObj = new Date();
    let scheduleTimeTemp = Date.UTC(
      dateObj.getUTCFullYear(),
      dateObj.getUTCMonth(),
      dateObj.getUTCDate(),
      dateObj.getUTCHours(),
      dateObj.getUTCMinutes(),
      dateObj.getUTCSeconds()
    );
    let scheduleTime = new Date(scheduleTimeTemp);

    console.log(
      "date of shcheule",
      selDateForSchedule,
      ":::",
      selTimeForSchedule,
      ":::",
      scheduleTime.toISOString()
    );
    if (!isNowTimeSelected) {
      if (selDateForSchedule == null) {
        toast.error("Date is required for schedule broadcast");
        return;
      }
      if (selTimeForSchedule == null) {
        toast.error("Time is required for schedule broadcast");
        return;
      }

      let scheduleDate = new Date(selDateForSchedule);
      console.log("new Date : ", scheduleDate);
      const splittedDate = selTimeForSchedule.split(":");
      scheduleDate.setHours(
        splittedDate[0],
        splittedDate[1],
        splittedDate[2],
        0
      );
      console.log("new Date two : ", scheduleDate);

      let scheduleTimeTemp = Date.UTC(
        scheduleDate.getUTCFullYear(),
        scheduleDate.getUTCMonth(),
        scheduleDate.getUTCDate(),
        scheduleDate.getUTCHours(),
        scheduleDate.getUTCMinutes(),
        scheduleDate.getUTCSeconds()
      );
      scheduleTime = new Date(scheduleTimeTemp);
      console.log(
        "scheduleTime ===",
        scheduleDate,
        ":::",
        scheduleTime.toISOString()
      );
    }

    if (title === "") {
      setTitleError(true);
      return;
    }
    if (selectedTemplate == undefined) {
      setTemplateError(true);
      return;
    }
    if (selectedgroups.length == 0) {
      setGroupError(true);
      return;
    }

    let broadcastall = false;

    selectedgroups.map((group) => {
      if (group.value == "all") {
        setBroadcastAll(true);
        broadcastall = true;
      }
    });
    // const d = new Date();
    // d.setMinutes(d.getMinutes() + 5);
    let data = {
      broadcastName: title,
      ...(broadcastall
        ? { broadcastAll: true }
        : { groups: selectedgroups.map((group) => group.value) }),
      templateId: selectedTemplate.id,
      variables: [
        { type: "header", parameters: [] },
        { type: "body", parameters: [] },
      ],
      scheduleDate: !isNowTimeSelected ? scheduleTime.toISOString() : null,
      // "variables": selectedTemplate.variables
      // "variables": []
    };
    console.log("data", data);

    // return
    try {
      setSending(true);
      const res = await sendBroadcast(data);
      if (res.success) {
        toast.success("Broadcast Successful");
        setUpdateUi((prevData) => {
          return !prevData;
        });
      } else if (res.error) {
        toast.error(res.error);
      } else throw new Error(res.error);
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
    setSending(false);
    setCtShow(false);
  };

  useEffect(() => {
    if (ctshow === false) {
      setTitle("");
      setSelectedTemplate(undefined);
      setSelectedGroups([]);
    }
  }, [ctshow]);

  const onChangeDate = (date, dateArray) => {
    console.log(
      "dateArray",
      dateArray,
      dateArray[0] == "" && dateArray[1] == "",
      rangePresets[4]
    );
    if (dateArray[0] == "" && dateArray[1] == "") {
      setDateFilter({
        startDate: dayjs(allData[allData?.length - 1]?.scheduleDate),
        endDate: dayjs(allData[0]?.scheduleDate),
      });
    } else {
      if (dateArray.length == 2) {
        dateFilter.startDate = dateArray[0];
        dateFilter.endDate = dateArray[1];
        setDateFilter({
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,
        });
      }
    }
  };
  const startDate =
    dateFilter.startDate !== ""
      ? dateFilter.startDate
      : calculateDefaultDate()?.[0];
  const endDate =
    dateFilter.endDate !== ""
      ? dateFilter.endDate
      : calculateDefaultDate()?.[1];

  console.log("dates : ", startDate, ":::", endDate);

  const columns = [
    {
      title: "Broadcast Name",
      dataIndex: "BroadcastName",
    },
    {
      title: "Created Date",
      dataIndex: "Date",
    },
    {
      title: "Successful",
      dataIndex: "Successful",
    },
    {
      title: "Pending",
      dataIndex: "Pending",
    },
    {
      title: "Failed",
      dataIndex: "Failed",
    },
    {
      title: "Members",
      dataIndex: "Members",
    },
    {
      title: "Failed",
      dataIndex: "Failed1",
    },
    {
      title: "Created By",
      dataIndex: "CreatedBy",
    },
    {
      title: "Schedule Date/Time",
      dataIndex: "schedule",
    },
    {
      title: "Status",
      dataIndex: "Status",
    },
  ];

  const getFailedMessageIndices = (broadcast) => {
    const failedIndices = [];
    const success = [];
    const pending = [];

    console.log("broadcast.messages", broadcast);

    broadcast.messages?.forEach(({ message, contact }, index) => {
      if (message?.failed) {
        if (contact != null) failedIndices.push(contact);
      } else if (contact != null) {
        if (message != null) success.push(contact);
        else pending.push(contact);
      }
    });

    return broadcast.state == 0
      ? { failed: failedIndices, success: success, schedulepending: pending }
      : { failed: failedIndices, success: success, pending: pending };
  };

  const clickRow = (id) => {
    const matchingBroadcast = allData.find((broadcast) => broadcast._id === id);
    if (matchingBroadcast) {
      const contacts = getFailedMessageIndices(matchingBroadcast);
      if (
        contacts.failed.length > 0 ||
        contacts.success.length > 0 ||
        contacts?.pending?.length > 0 ||
        contacts?.schedulepending?.length > 0
      ) {
        setShowFailed(true);
        setContacts(contacts);
      }
    } else {
      console.log("No matching broadcast found");
    }
  };

  const countStatus = (broadcast) => {
    const count = {
      total: broadcast?.messages?.length,
      completed: 0,
      failed: 0,
      pending: 0,
    };
    broadcast?.messages?.forEach(({ message }) => {
      if (message?.failed) count.failed++;
      else if (message?.success) count.completed++;
      else count.pending++;
    });

    console.log("count ====", count);
    return count;
  };

  const formatPercentage = (percentage) => {
    return percentage % 1 === 0 ? percentage.toFixed(0) : percentage.toFixed(1);
  };

  // function to check if same broadcast created and schedule time
  function checkIfScheduleBroadcast(createdAt, scheduleDate, name) {
    const isSameYear = moment(createdAt).isSame(scheduleDate, "year");
    const isSameMonth = moment(createdAt).isSame(scheduleDate, "month");
    const isSameDate = moment(createdAt).isSame(scheduleDate, "date");
    const isSameHour = moment(createdAt).isSame(scheduleDate, "hour");
    //const isSameMinute = moment(createdAt).isSame(scheduleDate, "minute")
    const momentCreated = moment(createdAt);
    const momentScheduled = moment(scheduleDate);
    const differenceInMinutes = momentScheduled.diff(momentCreated, "seconds");

    console.log(
      "check: ",
      name,
      isSameYear,
      ":",
      isSameMonth,
      ":",
      isSameYear,
      ":",
      isSameDate,
      ":",
      isSameHour,
      "differenceInMinutes",
      differenceInMinutes
    );

    let isSameMinute = true;
    if (differenceInMinutes > 60) {
      isSameMinute = false;
    }

    if (
      isSameYear &&
      isSameMonth &&
      isSameYear &&
      isSameDate &&
      isSameHour &&
      isSameMinute
    ) {
      return true;
    }

    return false;
  }

  // making data for showing rows in table
  const handleDataForTable = (data) => {
    let finalData = [];
    for (let i = 0; i != data.length; i++) {
      const count = countStatus(data[i]);
      let empty = "-";
      console.log(
        "date matching ; ",
        data[i]?.broadcastName,
        checkIfScheduleBroadcast(
          data[i]?.createdAt,
          data[i]?.scheduleDate,
          data[i]?.broadcastName
        )
      );
      finalData.push({
        key: i,
        id: data[i]._id,
        BroadcastName: data[i]?.broadcastName,
        Date: moment(data[i]?.createdAt).format("YYYY-MM-DD HH:mm"),
        Successful: (
          <Broadcastprogressbar
            percentage={formatPercentage((count.completed * 100) / count.total)}
            color={buildStyles({
              textColor: "#000000",
              trailColor: "#E8EFEF",
              pathColor: "lime",
              textsize: "14px",
            })}
          />
        ),
        Pending: (
          <Broadcastprogressbar
            percentage={formatPercentage((count.pending * 100) / count.total)}
            color={buildStyles({
              textColor: "#000000",
              trailColor: "#E8EFEF",
              pathColor: "orange",
              textsize: "14px",
            })}
          />
        ),
        Failed: (
          <Broadcastprogressbar
            percentage={formatPercentage((count.failed * 100) / count.total)}
            color={buildStyles({
              textColor: "#000000",
              trailColor: "#E8EFEF",
              pathColor: "red",
              textsize: "14px",
            })}
          />
        ),
        Members: data[i]?.messages.length,
        Failed1: count.failed > 0 ? count.failed + "Contacts" : "None",
        CreatedBy: data[i].madeBy?.firstname + " " + data[i].madeBy?.lastname,
        schedule: checkIfScheduleBroadcast(
          data[i]?.createdAt,
          data[i]?.scheduleDate
        ) ? (
          <div style={{ paddingLeft: "50px" }}>
            <div
              style={{ backgroundColor: "black", width: "10px", height: "2px" }}
            ></div>
          </div>
        ) : (
          moment(data[i].scheduleDate).format("YYYY-MM-DD HH:mm")
        ),
        scheduledDate: moment(data[i].scheduleDate).format("YYYY-MM-DD HH:mm"),
        Status: isNaN(data[i].state)
          ? data[i].completed
            ? "Completed"
            : "Pending"
          : BroadCastStates[data[i].state],
      });
    }
    console.log("finalData before", finalData);

    finalData.sort((a, b) => {
      return new Date(b.Date) - new Date(a.Date);
    });

    console.log("finalData after", finalData);

    return finalData;
  };

  // diable previouse dates
  const disabledDate = (current) => {
    let date = new Date();
    date.setDate(date.getDate() - 1);
    const newDate = new Date(date);

    return current && current.valueOf() <= newDate;
  };

  const currentTime = moment();

  const disabledHours = () => {
    const hours = [];
    if (selDateForSchedule == moment().format("YYYY-MM-DD")) {
      for (let i = 0; i < 24; i++) {
        if (i < currentTime.hour()) {
          hours.push(i);
        }
      }
    }
    return hours;
  };

  const disabledMinutes = (selectedHour) => {
    const minutes = [];
    if (selDateForSchedule == moment().format("YYYY-MM-DD")) {
      if (selectedHour === currentTime.hour()) {
        for (let i = 0; i < 60; i++) {
          if (i < currentTime.minute()) {
            minutes.push(i);
          }
        }
      }
    }
    return minutes;
  };

  const onChangeDateForAddBroadcast = (date, dateString) => {
    console.log(dateString);
    setSelDateForSchedule(dateString ? dateString : null);
    setselTimeForSchedule(null);
  };

  const onChangeTimeForAddBroadcast = (time, timeString) => {
    console.log(timeString);
    setselTimeForSchedule(timeString ? timeString : null);
  };

  return (
    <>
      <section className="main inner-main broadcast-sec">
        <section className="contact-group-top">
          <Container fluid>
            <Row className="pb-3 pb-lg-4">
              <Col sm={12} className="text-end">
                <div className="d-flex gap-2 w-100 d-md-flex justify-content-md-end flex-md-row flex-column-reverse ">
                  <RangePicker
                    dropdownClassName="createDateRangePicker"
                    className="dateRange"
                    presets={rangePresets}
                    value={[
                      dayjs(startDate, "YYYY-MM-DD"),
                      dayjs(endDate, "YYYY-MM-DD"),
                    ]}
                    format={"YYYY-MM-DD"}
                    onChange={onChangeDate}
                  />
                  <Button
                    className="btn-main"
                    onClick={() => {
                      if (!loading) {
                        setCtShow(true);
                        fetchData();
                      }
                    }}
                  >
                    <i className="bi bi-plus"></i> Send Broadcast
                  </Button>
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <div className="overview-heading">Overview</div>
              </Col>
            </Row>

            <Row className="pb-3">
              <Col md="4" lg={3} xl={2}>
                <div className="replied">
                  <Broadcastcard
                    title={"Total Messages"}
                    imageicon={chat}
                    number={count.total}
                    loading={loading}
                    id={"chaticon"}
                  />
                </div>
              </Col>
              <Col md="4" lg={3} xl={2}>
                <div className="message-send">
                  <Broadcastcard
                    title={"Messages Sent"}
                    imageicon={messagesend}
                    number={count.completed}
                    loading={loading}
                  />
                </div>
              </Col>
              <Col md="4" lg={3} xl={2}>
                <div className="delivered-message">
                  <Broadcastcard
                    title={"Delivered Messages"}
                    imageicon={delivered}
                    number={count.delivered}
                    loading={loading}
                  />
                </div>
              </Col>
              <Col md="4" lg={3} xl={2}>
                <div className="read-message">
                  <Broadcastcard
                    title={"Read Messages"}
                    imageicon={readmessage}
                    number={count.read}
                    loading={loading}
                  />
                </div>
              </Col>
              <Col md="4" lg={3} xl={2}>
                <div className="failed">
                  <Broadcastcard
                    title={"Failed Messages"}
                    imageicon={failed}
                    number={count.failed}
                    loading={loading}
                  />
                </div>
              </Col>
              <Col md="4" lg={3} xl={2}>
                <div className="pending">
                  <Broadcastcard
                    title={"Pending Messages"}
                    imageicon={pending}
                    number={count.pending}
                    loading={loading}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="contact-group-table">
          <Container fluid>
            <Row className="py-3">
              <Col sm={12}>
                <div className="overview-heading">Broadcast Summary</div>
              </Col>
            </Row>
            {loading ? (
              <TableSkeleton width="100%" />
            ) : (
              <Row>
                <Col xs={12}>
                  <Table
                    columns={columns}
                    dataSource={filteredBroadCasts}
                    scroll={{
                      x: width < 600 && 1200,
                    }}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: () => clickRow(record.id),
                      };
                    }}
                  />
                </Col>
              </Row>
            )}
          </Container>
        </section>
      </section>

      <Modal
        show={ctshow}
        onHide={() => {
          setCtShow(false);
          setSelDateForSchedule(null);
          setselTimeForSchedule(null);
          setIsNowTimeSelected(true);
        }}
        className="whatsapp-modal modal-contact"
        centered
      >
        <Modal.Header className="p-0" closeButton>
          <Modal.Title>Send Broadcast</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Enter Broadcast Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Set broadcast Name i.e Offer Alert"
                  name="name"
                  value={title}
                  onChange={handleInputChange}
                />
              </Form.Group>
              {titleError && (
                <span
                  style={{ color: "red", fontWeight: "500", fontSize: "12px" }}
                >
                  Broadcast name cannot be empty *
                </span>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Select Template</Form.Label>
                <Button className="template-btn" onClick={() => setShow(true)}>
                  {!selectedTemplate
                    ? "Select a template"
                    : selectedTemplate.name}
                </Button>
              </Form.Group>
              {TemplateError && (
                <span
                  style={{ color: "red", fontWeight: "500", fontSize: "12px" }}
                >
                  Please select a Template *
                </span>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Select Group</Form.Label>
                <Select
                  className="c-select"
                  options={groupOptions}
                  isMulti // allow multiple selection
                  value={selectedgroups} // set selected options
                  onChange={handleSelectChange} // update selected options
                  placeholder="Select group(s)"
                />
              </Form.Group>
              {groupError && (
                <span
                  style={{ color: "red", fontWeight: "500", fontSize: "12px" }}
                >
                  Please select atleast 1 group *
                </span>
              )}
            </Col>
          </Row>
          <Row style={{ marginBottom: 0 }}>
            <Col sm={12}>
              <Form.Group>
                <Form.Label
                  style={{
                    fontFamily: "",
                    fontSize: "clamp(12px, 12px + 1vw , 14px)",
                    lineHeight: "normal",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  Schedule Broadcast:
                </Form.Label>
              </Form.Group>
            </Col>
          </Row>
          <Row
            style={{ maxWidth: "150px", marginBottom: "10px", marginTop: 0 }}
          >
            <Col xs={6}>
              <Form.Check
                checked={isNowTimeSelected == true}
                type={"radio"}
                label={`Now`}
                id={`1`}
                onChange={() => setIsNowTimeSelected(true)}
              />
            </Col>
            <Col xs={6}>
              <Form.Check
                checked={isNowTimeSelected == false}
                type={"radio"}
                label={`Later`}
                id={`2`}
                onChange={() => setIsNowTimeSelected(false)}
              />
            </Col>
          </Row>
          {!isNowTimeSelected ? (
            <Row className="justify-content-start" style={{ width: "300px" }}>
              <Col xs={12} sm={6} className=" d-flex justify-content-start p-0">
                <Form.Group
                  controlId="timePicker"
                  className="date-time-picker date-class-new"
                >
                  <DatePicker
                    onChange={onChangeDateForAddBroadcast}
                    disabledDate={disabledDate}
                    showNow={false}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} className=" d-flex justify-content-start p-0">
                <Form.Group
                  controlId="timePicker"
                  className="date-time-picker date-class-new"
                >
                  <TimePicker
                    showNow={false}
                    disabled={selDateForSchedule == null}
                    onChange={onChangeTimeForAddBroadcast}
                    defaultOpenValue={dayjs("00:00:00", "HH:mm:ss")}
                    disabledHours={disabledHours}
                    disabledMinutes={disabledMinutes}
                  />
                </Form.Group>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col sm={12}>
              <Button
                className="btn-main w-100"
                onClick={() => HandleSendBroadcast()}
              >
                {sending ? (
                  <div>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                ) : !isNowTimeSelected ? (
                  "Schedule Broadcast "
                ) : (
                  "Send Broadcast"
                )}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {show && (
        <SelectTemplateModal
          show={show}
          setShow={setShow}
          handleCloseTemplate={handleCloseTemplate}
          setSelectedTemplate={handleSelectChange1}
          setVariable={setVariable}
        />
      )}

      <Modal
        show={showFailed}
        onHide={() => {
          setShowFailed(false);
          setContacts(null);
        }}
        className="whatsapp-modal modal-csv"
        centered
      >
        <Modal.Header className="p-0" closeButton>
          <Modal.Title>Broadcast Recipiants</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Row>
            <Col sm={12}>
              <div className="modal-table">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Number</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contacts?.success?.map((contact) => {
                        return (
                          <tr>
                            <td>{contact.contactName}</td>
                            <td>{"+" + contact.number}</td>
                            <td>Successful</td>
                          </tr>
                        );
                      })}
                      {contacts?.failed?.map((contact) => {
                        return (
                          <tr>
                            <td>{contact.contactName}</td>
                            <td>{"+" + contact.number}</td>
                            <td>Failed</td>
                          </tr>
                        );
                      })}
                      {contacts?.pending?.map((contact) => {
                        return (
                          <tr>
                            <td>{contact.contactName}</td>
                            <td>{"+" + contact.number}</td>
                            <td>Pending</td>
                          </tr>
                        );
                      })}
                      {contacts?.schedulepending?.map((contact) => {
                        return (
                          <tr>
                            <td>{contact.contactName}</td>
                            <td>{"+" + contact.number}</td>
                            <td>Pending-Schedule</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Broadcast;
