import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom"
import { UserContextProvider } from './store/user-context'
import axios from 'axios'
import { handleApiError } from './services/apiErrorHandler';
import { store } from './store';
import { Provider } from 'react-redux'

axios.interceptors.request.use(request => {
  const token = localStorage.getItem('token')
  request.headers.Authorization = `Bearer ${token}`
  return request
})

axios.interceptors.response.use(
  (response) => response, // Do nothing for successful responses
  (error) => {
    handleApiError(error); // Handle the error globally
    return Promise.reject(error); // Reject the promise to propagate the error
  }
);
console.log("Envirnoment", process.env.NODE_ENV)
if (process.env.NODE_ENV !== "development")
  console.log = () => { };

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <UserContextProvider>
    <BrowserRouter>
      <Provider store={store} >
        <App />
      </Provider>
    </BrowserRouter>
  </UserContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
