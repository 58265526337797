import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Modaltable from "../components/modal-table/mTable";
import { Table } from "antd";
import {
  getAllContacts,
  addContact,
  addMultipleContacts,
  deleteContacts,
} from "../services/Contacts/index";
import { updatetheContact } from "../services/Contacts/index";
import Companyactionbtn from "../components/company-listing/compnay-actionbtn";
import Papa from "papaparse";
import WindowWidthDetector from "../components/customHooks/DeviceWidthDetector";
import moment from "moment";
import ContactgroupTable from "../components/contact-table/c-table";
import Search from "../components/search/search";
import { getUserDetails } from "../services/User";
import { toast } from "react-toastify";
import Select from "react-select";
import { getAllGroups } from "../services/Group";
import { exportToExcel } from "../utils/exportToCsv";
import { countryCodeList } from "../utils/countryCodeList";
import TableSkeleton from "../components/skeletons/TableLoader";

const Contact = () => {
  const width = WindowWidthDetector();
  const [ctshow, setCtShow] = useState(false);
  const [cvsshow, setCvsShow] = useState(false);
  const [newContactData, setNewContactData] = useState({
    contactName: "",
    number: "",
    canMessage: true,
    canBroadcast: true,
  });
  const [fileUploaded, setFileUploaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [contactsGroup, setContactsGroup] = useState([]);
  const [isNewContactAdded, setIsNewContactAdded] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [duplicateNames, setDuplicateNames] = useState([]);
  const [duplicateNumbers, setDuplicateNumbers] = useState([]);
  const [inserted, setInserted] = useState([]);
  const [allOrgnlRecords, setOrgRecords] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [updateContacts, setUpdateContacts] = useState(false);
  const [editContact, setEditContact] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [inputErrors, setInputErrors] = useState({
    contactName: { hasError: false, errorMessage: "" },
    number: { hasError: false, errorMessage: "" },
  });
  const numericRegex = /^[0-9]+$/;
  const nameRegex = /^[A-Za-z0-9]+(?:[-' ][A-Za-z0-9]+)*$/;
  const [searchQuery, setSearchQuery] = useState("");
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const inputRef = useRef(null);

  const handleClick = () => {
    inputRef.current.click();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // setLoading(true)
        const resp = await getAllGroups();
        const groupData = [];
        if (resp.success) {
          resp.data.map((data) => {
            groupData.push({ value: data._id, label: data.groupName });
          });
        }
        setGroups(groupData);
      } catch (error) {
        console.log("Failed to fetch Groups!");
      }
    };
    fetchData();
  }, []);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputErrors((prevState) => ({
      ...prevState,
      [name]: { hasError: false, errorMessage: "" },
    }));
    setNewContactData((prevValues) => ({ ...prevValues, [name]: value }));

    setSelectedContact((prevValues) => ({ ...prevValues, [name]: value }));
  };

  useEffect(() => {
    if (!ctshow) {
      setNewContactData({
        contactName: "",
        number: "",
        canMessage: true,
        canBroadcast: true,
      });
      setInputErrors({
        contactName: { hasError: false, errorMessage: "" },
        number: { hasError: false, errorMessage: "" },
      });
    }
  }, [ctshow]);

  useEffect(() => {
    if (!cvsshow) {
      setContactsGroup([]);
    }
  }, [cvsshow]);

  useEffect(() => {
    // const fetchData = async () => {
    //     setLoading(true)
    //     try {
    //         const resp = await getAllContacts()
    //         if (resp.success) {
    //             // console.log(resp.data)
    //             setContacts(handleDataForTable(resp.data))
    //             setOrgRecords(handleDataForTable(resp.data))
    //         }
    //         else
    //             throw new Error(resp.error)
    //     } catch (error) {
    //         console.log(error)
    //     }
    //     setLoading(false)

    // }

    fetchAllContacts();
  }, [updateContacts, isNewContactAdded]);

  const fetchAllContacts = async () => {
    setLoading(true);
    try {
      const resp = await getAllContacts();
      if (resp.success) {
        // console.log(resp.data)
        setOrgData(resp.data);
        setContacts(handleDataForTable(resp.data));
        setOrgRecords(handleDataForTable(resp.data));
      } else throw new Error(resp.error);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!cvsshow) setFileUploaded(false);
  }, [cvsshow]);

  const handleClose = () => {
    setContactsGroup([]);
    setInserted([]);
    setSelectedContacts([]);
    setDuplicateNumbers([]);
    setDuplicateNames([]);
    setCtShow(false);
  };

  const handleDelete = async (id) => {
    const isDeleted = await deleteContacts(id);
    if (isDeleted?.success) {
      setUpdateContacts((prevUpdateContacts) => !prevUpdateContacts);
      console.log(updateContacts);
      console.log("Contact Deleted Successfully!");
    } else {
      console.log("Something went wrong");
    }
  };

  const isPhoneNumValid = () => {
    const num = newContactData.number;
    const name = newContactData.contactName;
    if (!nameRegex.test(name)) {
      setInputErrors((prevState) => ({
        ...prevState,
        contactName: {
          ...prevState.contactName,
          hasError: true,
          errorMessage: "Enter a valid name",
        },
      }));
      return false;
    } else if (!numericRegex.test(num)) {
      setInputErrors((prevState) => ({
        ...prevState,
        number: {
          ...prevState.number,
          hasError: true,
          errorMessage: "Enter a valid contact number",
        },
      }));
      return false;
    } else {
      const countryNumberDetail = countryCodeList.filter((code) => {
        if (num.startsWith(code?.phone)) return code;
      });
      console.log("countryNumberDetail", countryNumberDetail);
      if (countryNumberDetail.length <= 0) {
        setInputErrors((prevState) => ({
          ...prevState,
          number: {
            ...prevState.number,
            hasError: true,
            errorMessage: `Invalid country code.`,
          },
        }));
        return false;
      }
      const totalLength =
        countryNumberDetail[0]?.phone.length +
        countryNumberDetail[0]?.phoneLength;
      if (countryNumberDetail?.length > 0) {
        console.log("thiss");

        if (num.length === totalLength) {
          return true;
        }
      }
      setInputErrors((prevState) => ({
        ...prevState,
        number: {
          ...prevState.number,
          hasError: true,
          errorMessage: `Phone number length for ${countryNumberDetail[0]?.label} is ${totalLength} `,
        },
      }));
      return false;
    }
    // else if (num.length < 7) {
    //     setInputErrors(prevState => ({
    //         ...prevState,
    //         number: {
    //             ...prevState.number,
    //             hasError: true,
    //             errorMessage: "Phone number cannot be less than 7 digits"
    //         }
    //     }));
    //     return false
    // }
    // else if (num.length > 15) {
    //     setInputErrors(prevState => ({
    //         ...prevState,
    //         number: {
    //             ...prevState.number,
    //             hasError: true,
    //             errorMessage: "Phone number cannot be greater than 15 digits"
    //         }
    //     }));
    //     return false
    // }
    // return true
  };

  const isPhoneNumValidForUpdate = () => {
    console.log("selectedContact", selectedContact);
    const num = selectedContact.number;
    const name = selectedContact.contactName;
    if (!nameRegex.test(name)) {
      setInputErrors((prevState) => ({
        ...prevState,
        contactName: {
          ...prevState.contactName,
          hasError: true,
          errorMessage: "Enter a valid name",
        },
      }));
      return false;
    } else if (!numericRegex.test(num)) {
      setInputErrors((prevState) => ({
        ...prevState,
        number: {
          ...prevState.number,
          hasError: true,
          errorMessage: "Enter a valid contact number",
        },
      }));
      return false;
    } else {
      const countryNumberDetail = countryCodeList.filter((code) => {
        if (num.startsWith(code.phone)) return code;
      });
      const totalLength =
        countryNumberDetail[0].phone.length +
        countryNumberDetail[0].phoneLength;
      if (countryNumberDetail.length > 0) {
        if (num.length === totalLength) {
          return true;
        }
      }
      setInputErrors((prevState) => ({
        ...prevState,
        number: {
          ...prevState.number,
          hasError: true,
          errorMessage: `Phone number length for ${countryNumberDetail[0].label} is ${totalLength} `,
        },
      }));
      return false;
    }
  };

  const handleAddContact = async (e) => {
    e?.preventDefault();
    console.log("true");

    if (selectedContacts.length > 0) {
      console.log("true dewd");
      try {
        for (let index = 0; index < selectedContacts.length; index++) {
          const element = selectedContacts[index];
          if (
            !numericRegex.test(element.number) ||
            !nameRegex.test(element.contactName) ||
            element.invalid
          ) {
            // toast.error(`Invalid contact ${element.contactName} | ${element.number} `)
            toast.error(
              "Please address the highlighted contacts or deselect them to proceed."
            );
            return;
          }
        }
        const resp = await addMultipleContacts({
          contacts: selectedContacts,
          groups: selectedGroups,
        });
        console.log("resp ===", resp);
        if (resp.success) {
          setCvsShow(false);
          handleClose();
          setContacts((prevContact) => [...resp.data.inserted, ...prevContact]);
          fetchAllContacts();
          setUpdateContacts((prev) => !prev);
          toast.success(
            `${
              resp.data.inserted.length + resp.data.updateContacts.length
            } new contacts added & ${
              resp.data.duplicateNumbers.length
            } duplicate contacts`
          );

          // if (resp.data.duplicateNames.length > 0 || resp.data.duplicateNumbers.length > 0) {
          //     console.log('Some Contacts Were not Added Successfully')
          //     setDuplicateNumbers(resp.data.duplicateNumbers)
          //     setDuplicateNames(resp.data.duplicateNames)
          //     setInserted(resp.data.inserted)
          //     if (resp.data.inserted.length === 0) {
          //         if (resp.data.duplicateNames.length === 0) {
          //             toast.error("None of the selected contacts could be added as those numbers already exist in contacts")
          //         }
          //         else if (resp.data.duplicateNumbers.length === 0) {
          //             toast.error("None of the selected contacts could be added as those names already exist in contacts")
          //         }
          //         else {
          //             toast.error("None of the selected contacts could be added as those names and numbers already exist in contacts")
          //         }
          //     }
          //     else {
          //         if (resp.data.duplicateNames.length === 0) {
          //             toast.error("Some of the selected contacts could not be added as those numbers already exist in contacts")
          //         }
          //         else if (resp.data.duplicateNumbers.length === 0) {
          //             toast.error("Some of the selected contacts could not be added as those names already exist in contacts")
          //         }
          //         else {
          //             toast.error("Some of the selected contacts could not be added as those names and numbers already exist in contacts")
          //         }
          //     }
          // }
          // else {
          //     console.log('Contacts Added successfully')
          //     handleClose()
          // }
          setUpdateContacts((prevUpdateContacts) => !prevUpdateContacts);
        } else throw new Error(resp.error);
      } catch (error) {
        toast.error("Somthing went wrong. Please fix contact from csv file");
        console.log(error.message);
      }
    } else
      try {
        const check = isPhoneNumValid();
        console.log("in else condition");
        if (!check) return;
        const resp = await addContact(newContactData);
        if (resp.success) {
          console.log("Contact Added successfully");
          //setContacts(prevContact => [resp.data, ...prevContact])
          handleClose();
          toast.success("Contact Added successfully");
          fetchAllContacts();
        } else throw new Error(resp.error);
      } catch (error) {
        console.log(error.message);
        if (error.message.includes("Enter a valid contact name")) {
          setInputErrors((prevState) => ({
            ...prevState,
            contactName: {
              ...prevState.contactName,
              hasError: true,
              errorMessage: "Enter a valid contact name",
            },
          }));
        }
        if (error.message.includes("A contact with this name already exists")) {
          setInputErrors((prevState) => ({
            ...prevState,
            contactName: {
              ...prevState.contactName,
              hasError: true,
              errorMessage: "A contact with this name already exists",
            },
          }));
        }
        if (
          error.message.includes("A contact with this number already exists")
        ) {
          setInputErrors((prevState) => ({
            ...prevState,
            number: {
              ...prevState.number,
              hasError: true,
              errorMessage: "A contact with this number already exists",
            },
          }));
        }
        if (error.message.includes("Enter a valid phone number")) {
          setInputErrors((prevState) => ({
            ...prevState,
            number: {
              ...prevState.number,
              hasError: true,
              errorMessage: "Enter a valid contact number",
            },
          }));
        }
      }
  };

  const handleEdit = async () => {
    const check = isPhoneNumValidForUpdate();
    console.log("updatedContact: ", selectedContact);
    console.log("check", check);
    if (!check) return;

    const isContactUpdated = await updatetheContact(selectedContact);
    if (isContactUpdated?.success) {
      console.log("Contact Successfully Updated!");
      setUpdateContacts((prevUpdateContacts) => !prevUpdateContacts);

      setEditContact(false);
      setSelectedContact(null);

      toast.success("Contact Updated Successfully");
    } else {
      console.log("Something went wrong");
      toast.error(isContactUpdated?.error);
      return isContactUpdated;
    }
    console.log(isContactUpdated);
  };

  const changeHandler = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        console.log(results);
        setFileUploaded(true);
        let contacts = [];
        results.data.map(({ name, number }) => {
          let element = {
            contactName: name,
            number: number,
            invalid: false,
          };
          const num = element.number.toString();
          if (
            !numericRegex.test(element.number) ||
            !nameRegex.test(element.contactName)
          ) {
            element.invalid = true;
          }
          const countryNumberDetail = countryCodeList.filter((code) => {
            if (num.startsWith(code.phone)) return code;
          });
          if (countryNumberDetail.length === 0) element.invalid = true;
          else {
            const totalLength =
              countryNumberDetail[0].phone.length +
              countryNumberDetail[0].phoneLength;
            if (countryNumberDetail.length > 0) {
              if (num.length !== totalLength) {
                element.invalid = true;
              }
            }
          }
          contacts.push(element);
        });

        setContactsGroup(contacts);
      },
    });
  };
  const exportContact = () => {
    const result = orgData.map((contact) => ({
      number: contact.number,
      name: contact.contactName,
    }));
    exportToExcel(result);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
    },
    {
      title: "Actions",
      dataIndex: "Actions",
    },
  ];

  const getRandomColor = (index) => {
    // Calculate hue value based on the index to get distinct colors
    const hue = (index * 50) % 360; // You can adjust the multiplier (50) to change the color range

    // Set constant saturation and lightness values
    const saturation = 50;
    const lightness = 50;

    // Convert HSL to RGB values
    const hslToRgb = (h, s, l) => {
      h /= 360;
      s /= 100;
      l /= 100;
      let r, g, b;

      if (s === 0) {
        r = g = b = l; // Achromatic
      } else {
        const hue2rgb = (p, q, t) => {
          if (t < 0) t += 1;
          if (t > 1) t -= 1;
          if (t < 1 / 6) return p + (q - p) * 6 * t;
          if (t < 1 / 2) return q;
          if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
          return p;
        };

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
      }

      const toHex = (x) => {
        const hex = Math.round(x * 255).toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      };

      return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    };

    return hslToRgb(hue, saturation, lightness);
  };

  // making data for showing rows in table
  const handleDataForTable = (data) => {
    // return []
    let finalData = [];
    for (let i = 0; i != data.length; i++) {
      const color = getRandomColor(i);
      finalData.push({
        key: data[i]._id,
        name: data[i]?.contactName,
        number: data[i]?.number,
        Name: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              className="random-clr"
              style={{ background: color, marginRight: "10px" }}
            >
              {data[i]?.contactName?.substring(0, 1)}
            </div>
            <p
              style={{
                fontFamily: "Segoe UI",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "22px",
              }}
            >
              {data[i]?.contactName}
            </p>
          </div>
        ),
        createdAt: (
          <p
            style={{
              fontFamily: "Segoe UI",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "22px",
            }}
          >
            {moment(data[i]?.createdAt).format("DD MMM, YYYY")}
          </p>
        ),
        phone: (
          <p
            style={{
              fontFamily: "Segoe UI",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "22px",
            }}
          >
            {"+" + data[i]?.number}
          </p>
        ),
        Actions: (
          <Companyactionbtn
            data={data[i]}
            isComingFromContact
            submitDelete={handleDelete}
            submitEdit={() => {
              setEditContact(true);
              setSelectedContact(data[i]);
              setInputErrors({
                contactName: { hasError: false, errorMessage: "" },
                number: { hasError: false, errorMessage: "" },
              });
            }}
          />
        ),
      });
    }

    return finalData;
  };

  console.log("contacts", contacts);

  // search any record
  const getSearchedRecords = (value) => {
    console.log("value ===", value, allOrgnlRecords, ":", orgData);
    if (value == "" || !value) {
      setContacts(allOrgnlRecords);
    } else {
      let matched = allOrgnlRecords.filter(
        (item) =>
          item?.name?.toLowerCase()?.includes(value.toLowerCase()) ||
          item?.number?.toLowerCase()?.includes(value.toLowerCase())
      );
      console.log("matched", matched);
      setContacts(matched);
    }
  };
  // if (loading) {
  //   return <TableSkeleton />;
  // }
  return (
    <section className="main inner-main">
      <section className="contact-group-top">
        <Container>
          <Row className="flex-column-reverse flex-md-row">
            <Col sm={6} className="mb-3 mb-sm-0 mt-md-0 mt-3">
              <Form.Group className="input-search">
                <Form.Control
                  type="text"
                  placeholder="Search...."
                  // onChange={(e) =>  setSearchQuery(e.target.value.trimStart())}
                  onChange={(e) =>
                    getSearchedRecords(e.target.value.trimStart())
                  }
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <div className="d-grid  gap-2 d-md-flex justify-content-md-end">
                <Button className="btn-main" onClick={exportContact}>
                  <i
                    className="bi bi-cloud-arrow-up"
                    style={{ marginRight: "10px" }}
                  ></i>{" "}
                  Export All Contacts
                </Button>
                <Button className="btn-main" onClick={() => setCtShow(true)}>
                  <i className="bi bi-plus"></i> Add Contact
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {loading ? (
        <TableSkeleton />
      ) : (
        <section className="contact-group-table">
          <Container>
            <Row>
              <Col xs={12}>
                {/* <ContactgroupTable contacts={contacts} handleDelete={handleDelete} searchQuery={searchQuery} handleEdit={handleEdit} loading={loading} /> */}
                <Table
                  columns={columns}
                  dataSource={contacts}
                  scroll={{
                    x: width < 600 && 1200,
                  }}
                />
              </Col>
            </Row>
          </Container>
        </section>
      )}

      <Modal
        show={ctshow}
        onHide={() => setCtShow(false)}
        className="whatsapp-modal modal-contact"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title>Add Contact</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  name="contactName"
                  value={newContactData.contactName}
                  onChange={handleInputChange}
                  className={
                    inputErrors.contactName.hasError ? "is-invalid" : ""
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {inputErrors.contactName.hasError &&
                    inputErrors.contactName.errorMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Phone No</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ex: 447700900123 "
                  name="number"
                  value={newContactData.number}
                  onChange={handleInputChange}
                  className={inputErrors.number.hasError ? "is-invalid" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  {inputErrors.number.hasError &&
                    inputErrors.number.errorMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Button
                className="import-cvs"
                onClick={() => {
                  setCtShow(false);
                  setCvsShow(true);
                }}
              >
                Import Contacts From CSV File
              </Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button className="btn-main-default" onClick={() => setCtShow(false)}>
            Cancel
          </Button>
          <Button className="btn-main" onClick={handleAddContact}>
            Add Contact
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={cvsshow}
        onHide={() => setCvsShow(false)}
        className="whatsapp-modal modal-csv"
        centered
      >
        <Modal.Header className="p-0 border-0" closeButton>
          <Modal.Title className="w-100 text-center">
            <div
              className="back-btn"
              onClick={() => {
                setCvsShow(false);
                setCtShow(true);
              }}
            >
              <i
                className="bi bi-chevron-left"
                style={{ fontSize: width < 600 ? "13px" : "" }}
              ></i>
            </div>
            Import contacts from CSV File
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          {contactsGroup.length > 0 ? (
            <p style={{ fontSize: "14px" }}>
              Please select the contacts you want to import.
            </p>
          ) : (
            <>
              <p
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "#165e5b",
                  marginBottom: "0",
                }}
              >
                Instructions:
              </p>
              <ul>
                <li style={{ fontSize: "14px" }}>
                  Select and upload a .csv file
                </li>
                <li style={{ fontSize: "14px" }}>
                  The header for the column of contact names should be "name"
                </li>
                <li style={{ fontSize: "14px" }}>
                  The header for the column of contact numbers should be
                  "number" and format should be Ex:447700900123
                </li>
              </ul>
            </>
          )}
          <Row className="mb-1">
            <Col sm={12}>
              <Form.Group controlId="formFile" className="position-relative">
                <Form.Control
                  className="file-up"
                  type="file"
                  ref={inputRef}
                  onChange={changeHandler}
                  accept=".csv"
                />
                <div className="input-label" onClick={handleClick}>
                  Upload
                </div>
              </Form.Group>
            </Col>
          </Row>
          {contactsGroup.length > 0 && (
            <Row>
              <Col sm={12}>
                <div className="modal-table">
                  <Modaltable
                    contacts={contactsGroup}
                    setSelectedContacts={setSelectedContacts}
                    fileUploaded={fileUploaded}
                  />
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col sm={12}>
              <Form.Group className="contact-m-form">
                <Form.Label>Add to Groups (Optional)</Form.Label>
                <Select
                  isMulti
                  className="c-select basic-multi-select"
                  options={groups}
                  placeholder={"Select Group(s)"}
                  classNamePrefix="select"
                  onChange={(e) => {
                    setSelectedGroups(e);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button
            className="btn-main-default"
            onClick={() => setCvsShow(false)}
          >
            Cancel
          </Button>
          <Button
            className="btn-main"
            onClick={() => handleAddContact()}
            style={{ background: selectedContacts.length === 0 && "#165e5b" }}
            disabled={selectedContacts.length === 0}
          >
            Import Contact
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={editContact}
        onHide={() => {
          setEditContact(false);
          setSelectedContact(null);
        }}
        className="whatsapp-modal modal-contact"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title>Edit Contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={selectedContact?.contactName}
                  name="contactName"
                  value={selectedContact?.contactName}
                  onChange={handleInputChange}
                  className={
                    inputErrors.contactName.hasError ? "is-invalid" : ""
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {inputErrors.contactName.hasError &&
                    inputErrors.contactName.errorMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Phone No</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={selectedContact?.number}
                  name="number"
                  value={selectedContact?.number}
                  onChange={handleInputChange}
                  className={inputErrors.number.hasError ? "is-invalid" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  {inputErrors.number.hasError &&
                    inputErrors.number.errorMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button
            className="btn-main-default"
            onClick={() => {
              setEditContact(false);
              setSelectedContact(null);
            }}
          >
            Cancel
          </Button>
          <Button className="btn-main" onClick={handleEdit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default Contact;
