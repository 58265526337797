import { useEffect } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { addCompanyToken } from '../services/Company';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Accessfb from "../images/connect_img.png";


function FacebookSignup() {

  const navigate = useNavigate()
  const location = useLocation();
  const companyId = location.state?.companyId;
  const message = location.state?.message;
  console.log(companyId)

  const AddCompanyToken = async (accessToken) => {

    const data = { companyId: companyId, accessToken: accessToken }

    try {
      const resp = await addCompanyToken(data)
      if (resp.success) {
        console.log('Company token added successfully')
        console.log(resp)
        setTimeout(() => {
          navigate('/login')
        }, 1500)
      }
      else
        throw new Error(resp.error)
    } catch (error) {
      console.log(error.message)
    }


  }

  useEffect(() => {
    // Load the JavaScript SDK asynchronously
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '1061102704514632', // Facebook App ID
        // appId: '719081352898093',
        cookie: true, // enable cookies
        xfbml: true, // parse social plugins on this page
        version: 'v16.0', // Graph API version
      });
    };
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    // Cleanup function to remove the global variable added by the SDK
    return function cleanup() {
      delete window.fbAsyncInit;
      delete window.FB;
    };
  }, []);

  // Facebook Login with JavaScript SDK
  function launchWhatsAppSignup() {
    // Conversion tracking code
    // fbq && fbq('trackCustom', 'WhatsAppOnboardingStart', {appId: '719081352898093', feature: 'whatsapp_embedded_signup'});

    // Check the login status and launch the login dialog if needed
    window.FB.getLoginStatus(function (response) {
      if (response.status === 'connected') {
        const accessToken = response.authResponse.accessToken;
        console.log(accessToken)
        AddCompanyToken(accessToken)
        //Use this token to call the debug_token API and get the shared WABA's ID
      } else {
        window.FB.login(function (response) {
          if (response.authResponse) {
            const accessToken = response.authResponse.accessToken;
            console.log(accessToken)
            AddCompanyToken(accessToken)
            //Use this token to call the debug_token API and get the shared WABA's ID
          } else {
            console.log('User cancelled login or did not fully authorize.');
          }
        }, {
          scope: 'business_management,whatsapp_business_management,whatsapp_business_messaging',
          extras: {
            feature: 'whatsapp_embedded_signup',
            // always new embedded Sign-up flow regardless of rollout
            version: 2
          }
        });
      }
    });
  }

  return (
    <>
      <section className="wahtsapp_form pt-4">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} xl={7} xxl={6}>
              <div className="accessfacebook-content bg-white">
                <div className="text-center access-img mb-3 mb-lg-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                  <img src={Accessfb} alt="access-img" />
                </div>
                <h2 className="text-center">
                  {message}
                </h2>
                <p className="top">
                  To continue, you will need to access your company's Facebook business account.By clicking the '<span style={{ color: "rgba(53, 120, 228, 1)", fontSize: "14px", fontFamily: "Segoe UI", fontWeight: 600 }} >Continue with Facebook</span>' button below you can:
                </p>
                <ul style={{ listStyle: "disc", paddingLeft: "3%" }}>
                  <li>
                    Create new or select existing Facebook and WhatsApp Business accounts.
                  </li>
                  <li>
                    Provide a display name and description for your WhatsApp business profile.
                  </li>
                  <li>
                    Connect and verify your phone number to use for the WhatsApp API access.
                  </li>
                </ul>
                <br />
                <div className="highlighted-pra">
                  <p>
                    Do not use a number that is currently connected to an existing WhatsApp account. This includes numbers that are connected to the <b>WhatsApp Business App</b>. You will not be able to finish the registration process.
                    <br /><br />
                    {/* <b>For more information, please visit <a href="https://developers.facebook.com/docs/whatsapp/embedded-signup/" target="_blank">here</a></b> */}
                    <b><a style={{ textDecoration: "underline", color: "#494949", fontFamily: "Segoe UI", fontSize: "14px" }} href="https://developers.facebook.com/docs/whatsapp/embedded-signup/" target="_blank">Learn more about your Whatsapp phone Number</a></b>

                  </p>
                </div>
                <button className="btn" onClick={launchWhatsAppSignup}>
                  Continue with Facebook
                </button>

              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default FacebookSignup;