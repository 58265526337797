

const TemplateStatus = ({ statustext, statusClass }) => {
    let finalClass = statustext == "PENDING" ? "in-review" : statustext == "APPROVED" ? "approved" : statustext == "REJECTED" ? "rejected" : statustext == "REJECTED" ? "rejected" : null
    return (
        <>
            <span className={`status-badge ${finalClass}`}>
                {statustext.charAt(0).toUpperCase() + statustext.slice(1).toLowerCase()}
            </span>
        </>
    );
};

export default TemplateStatus;