import React, { useEffect, useState, useContext } from 'react'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import { authenticateUser, get2FACredentials } from '../services/User'
import Authform from '../components/wahtsapp_form/form-auth'
import UserContext from '../store/user-context'
import { useNavigate } from 'react-router'


const Register2FA = () => {
  const [isSendingReq, setIsReqSending] = useState(false)
  const [qrCode, setQrCode] = useState(null)
  const [secretKey, setSecretKey] = useState(null)
  const [codeGenerated, setCodeGenerated] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setError] = useState('')
  const { userState, setUserState } = useContext(UserContext)
  const navigate = useNavigate()
  console.log(userState)

  useEffect(() => {
    const getData = async () => {
      const resp = await get2FACredentials()
      if (resp.success) {
        setQrCode(resp.qrcodeUrl)
        setSecretKey(resp.secretKey)
      }
    }

    getData()
  }, [])

  const goback = () => {
    setCodeGenerated(false)
  }

  const setErrorMessage = (msg) => {
    setError(msg)
  }

  const checkAuth = async (code) => {
    setIsReqSending(true)
    const resp = await authenticateUser({ authCode: code })
    console.log("response of 2FA", resp, ":::", resp.userToken)
    if (resp.success) {
      //localStorage.removeItem('token')
      setSuccessMessage('User authentication successful ✔')
      localStorage.setItem('mfaCompleted', true)
      localStorage.setItem('mfaDone', true)
      localStorage.setItem('token', resp.userToken)
      setUserState(prevState => ({
        ...prevState,
        mfaDone: true,
        mfaCompleted: true
      }));
      setTimeout(() => {
        navigate('/')
      }, 1000);
    }
    else {
      setErrorMessage('User authentication failed')
    }
    setIsReqSending(false)
  }

  return (
    <>
      {!codeGenerated ? <section className="wahtsapp_form pt-4">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} xl={7} xxl={6}>
              <div className="accessfacebook-content bg-white">
                <div className="text-center access-img mb-3 mb-lg-4"></div>
                <h2 className="text-center">
                  YOU NEED TO COMPLETE THE 2FA PROCESS TO CONTINUE
                </h2>
                <p className="top">
                  To start, you will need to folow the all the Instructions below, after completing all steps you can click the '<a href="#">Continue</a>' button to advance

                </p>
                <p className="top" style={{ marginBottom: '10px' }}>
                  Instructions for setup:
                </p>
                <ul style={{ marginBottom: '20px' }}>
                  <li style={{ marginBottom: '5px' }}>
                    <p className="top" style={{ marginBottom: '2px' }}>Download an authentication app</p>
                    <p style={{ marginLeft: '10px' }}>We recommend downloading Duo Mobile or Google Authenticator if you don't have one installed.</p>
                  </li>
                  <li style={{ marginBottom: '5px' }}>
                    <p className="top" style={{ marginBottom: '2px' }}>Scan this barcode/QR code or copy the key</p>
                    <p style={{ marginLeft: '10px' }}>Scan this barcode/QR code in the authentication app or copy the key and paste it in the authentication app.</p>

                    {(qrCode !== null && secretKey !== null) &&
                      <div className="two-FA-code-section">
                        <img src={qrCode} />
                        <div style={{ maxWidth: '125px', wordBreak: 'break-all' }}>
                          <p className='top'>{secretKey}</p>
                        </div>

                      </div>}

                  </li>
                  <li style={{ marginBottom: '5px' }}>
                    <p className="top" style={{ marginBottom: '2px' }}>Copy and enter 6-digit code</p>
                    <p style={{ marginLeft: '10px' }}>After the barcode/QR code is scanned or the key is entered, your authentication app will generate a 6-digit code. Copy it and continue.</p>
                  </li>
                </ul>
                <button className="btn" onClick={() => { setCodeGenerated(true) }}>
                  CONTINUE
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </section> :
        <section className="wahtsapp_form">
          <Container>
            <Row>
              <Col sm={12}>
                <Authform isSendingReq={isSendingReq} goback={goback} checkAuth={checkAuth} successMessage={successMessage} errorMessage={errorMessage} setErrorMessage={setErrorMessage} showback={true} />

              </Col>
            </Row>
          </Container>
        </section>
      }

    </>
  )
}

export default Register2FA