import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link, Navigate, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import WindowWidthDetector from "../components/customHooks/DeviceWidthDetector";

import Template_ic from "../images/icons/template-ic.svg";
import { getAllTemplates, deleteTemplate } from "../services/WhatsappAPI/index";
import { UrlPhoneNumber } from "../components/template-preview/UrlPhoneNumber";
import { QuickReplyPreview } from "../components/template-preview/QuickReplyPreview";
import { AuthTemplate } from "../components/template-preview/AutheTemplate";
import { toast } from "react-toastify";

const Template = () => {
  const width = WindowWidthDetector();
  const [dtshow, setDtShow] = useState(false);
  const [templateshow, setTemplateShow] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [templatetoShow, setTemplatetoShow] = useState(undefined);
  const [templatetoDelete, setTemplatetoDelete] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [selectedTemplates, setSelectedTemplates] = useState({
    type: "",
    templates: [],
  });
  const [templateData, setTemplateData] = useState({
    name: "",
    language: "",
    header: "",
    body: "",
    footer: "",
    buttons: [],
  });
  const [reload, setReload] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const templatesPerPage = 5;
  const totalPages = Math.ceil(
    selectedTemplates.templates.length / templatesPerPage
  );

  const handlePagination = (page) => {
    setCurrentPage(page);
  };
  if (currentPage > totalPages && totalPages !== 0) setCurrentPage(totalPages);
  const startIndex = (currentPage - 1) * templatesPerPage;
  const endIndex = startIndex + templatesPerPage;
  const templatesToDisplay = selectedTemplates.templates.slice(
    startIndex,
    endIndex
  );

  console.log("templatesToDisplay : ", templatesToDisplay);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const resp = await getAllTemplates();
        if (!resp.success) throw new Error(resp.error);
        setTemplates(resp.data);
        setSelectedTemplates({ type: "all", templates: resp.data });
      } catch (error) {
        console.log("Failed to fetch templates!");
      }
      setLoading(false);
    };
    fetchData();
  }, [reload]);

  useEffect(() => {
    if (templatetoShow) {
      const headerComponent = templatetoShow.components.find(
        (c) => c.type === "HEADER"
      );
      const bodyComponent = templatetoShow.components.find(
        (c) => c.type === "BODY"
      );
      const footerComponent = templatetoShow.components.find(
        (c) => c.type === "FOOTER"
      );
      const buttonsComponent = templatetoShow.components.find(
        (c) => c.type === "BUTTONS"
      );

      setTemplateData({
        name: templatetoShow.name,
        language: templatetoShow.language,
        header: headerComponent ? headerComponent.text : "",
        body: bodyComponent ? bodyComponent.text : "",
        footer: footerComponent ? footerComponent.text : "",
        buttons: buttonsComponent
          ? buttonsComponent.buttons.map((btn) => ({
              type: btn.type,
              text: btn.text,
            }))
          : [],
      });
    }
  }, [templatetoShow]);

  // console.log(templateData)
  // console.log(templatetoShow)
  // console.log(loading)

  const templateList = templatesToDisplay.map((template) => {
    return (
      <div className="single-template">
        <div className="content-info">
          <div className="template-name">{template.name}</div>
          <div className="template-info">
            <div className="templage-date-time">
              Language: {template.language}
            </div>
            <div className="templage-date-time mt-1">
              Approval status: {template.status}
            </div>
          </div>
        </div>
        <div className="action">
          <ul className="nav responsive-actions">
            <li className="nav-item">
              <button
                className="action-b"
                onClick={() => {
                  {
                    setTemplateShow(true);
                    setTemplatetoShow(template);
                  }
                }}
              >
                <svg
                  width="16"
                  height="10"
                  viewBox="0 0 16 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.99984 1.33333C9.22566 1.32926 10.4278 1.67119 11.4679 2.31982C12.5081 2.96845 13.3441 3.89744 13.8798 5C12.7798 7.24667 10.5332 8.66667 7.99984 8.66667C5.4665 8.66667 3.21984 7.24667 2.11984 5C2.65558 3.89744 3.49159 2.96845 4.53176 2.31982C5.57192 1.67119 6.77401 1.32926 7.99984 1.33333ZM7.99984 0C4.6665 0 1.81984 2.07333 0.666504 5C1.81984 7.92667 4.6665 10 7.99984 10C11.3332 10 14.1798 7.92667 15.3332 5C14.1798 2.07333 11.3332 0 7.99984 0ZM7.99984 3.33333C8.44186 3.33333 8.86579 3.50893 9.17835 3.82149C9.49091 4.13405 9.6665 4.55797 9.6665 5C9.6665 5.44203 9.49091 5.86595 9.17835 6.17851C8.86579 6.49107 8.44186 6.66667 7.99984 6.66667C7.55781 6.66667 7.13389 6.49107 6.82133 6.17851C6.50877 5.86595 6.33317 5.44203 6.33317 5C6.33317 4.55797 6.50877 4.13405 6.82133 3.82149C7.13389 3.50893 7.55781 3.33333 7.99984 3.33333ZM7.99984 2C6.3465 2 4.99984 3.34667 4.99984 5C4.99984 6.65333 6.3465 8 7.99984 8C9.65317 8 10.9998 6.65333 10.9998 5C10.9998 3.34667 9.65317 2 7.99984 2Z"
                    fill="black"
                    fillOpacity="0.6"
                  />
                </svg>
              </button>
            </li>
            <li className="nav-item">
              <button
                className="action-b"
                onClick={() => {
                  if (template?.status == "PENDING") {
                    toast.error("You can not edit pending templates");
                  } else {
                    navigate("/createTemplate", {
                      state: { isEdit: true, template: template },
                    });
                  }
                }}
              >
                <i class="bi bi-pencil"></i>
              </button>
            </li>
            <li className="nav-item">
              <button
                className="action-b"
                onClick={() => {
                  {
                    setDtShow(true);
                    setTemplatetoDelete(template);
                  }
                }}
              >
                <svg
                  width="12"
                  height="14"
                  viewBox="0 0 12 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 13.75C1.8375 13.75 1.4845 13.6033 1.191 13.3097C0.897 13.0157 0.75 12.6625 0.75 12.25V2.5H0V1H3.75V0.25H8.25V1H12V2.5H11.25V12.25C11.25 12.6625 11.1033 13.0157 10.8097 13.3097C10.5157 13.6033 10.1625 13.75 9.75 13.75H2.25ZM9.75 2.5H2.25V12.25H9.75V2.5ZM3.75 10.75H5.25V4H3.75V10.75ZM6.75 10.75H8.25V4H6.75V10.75Z"
                    fill="black"
                    fillOpacity={"0.6"}
                  />
                </svg>
              </button>
            </li>
          </ul>
        </div>
      </div>
    );
  });

  const loadingList = Array.from({ length: 5 }).map((_, index) => (
    <div className="single-template1">
      <div className="placeholder-glow">
        <span
          className="col-12 placeholder"
          style={{ height: "70px", borderRadius: "5px" }}
        ></span>
      </div>
    </div>
  ));

  const deleteTemp = async (name) => {
    setLoading(true);
    const res = await deleteTemplate({ name: name });
    if (res.success) {
      setReload((prevReload) => !prevReload);
    }
    setLoading(false);
  };

  const handleTemplateSelection = (e) => {
    let list;
    if (e == "all") {
      setSelectedTemplates({ type: e, templates: templates });
    }
    if (e == "approved") {
      list = templates.filter((temp) => temp.status === "APPROVED");
      setSelectedTemplates({ type: e, templates: list });
    }
    if (e == "pending") {
      list = templates.filter((temp) => temp.status === "PENDING");
      setSelectedTemplates({ type: e, templates: list });
    }
    if (e == "rejected") {
      list = templates.filter((temp) => temp.status === "REJECTED");
      setSelectedTemplates({ type: e, templates: list });
    }
  };

  return (
    <>
      <section className="main inner-main template-list-sec">
        <section className="contact-group-top">
          <Container className="px-md-0">
            <Row className="align-items-center">
              <Col sm={6} className="mb-3 mb-sm-0">
                <div className="template-heading flex gap-2">
                  <img src={Template_ic} alt="icon" /> Templates{" "}
                  <span className="badge">{templates.length}</span>
                </div>
              </Col>
              <Col sm={6} className="text-sm-end">
                <div className="btn-sec text-sm-end">
                  {/* <Link to="/createTemplate" className="btn-main text-decoration-none">
                                        <i className="bi bi-plus"></i> New Template
                                    </Link> */}
                  <Button
                    className="btn-main"
                    style={{ width: width < 600 ? "100%" : "" }}
                    onClick={() => navigate("/createTemplate")}
                  >
                    <i className="bi bi-plus"></i> New Template
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="template-lsit">
          <Container className="nav-container px-0">
            <Row>
              <Col sm={12}>
                <div className="template-nav">
                  <ul className="nav nav-fill">
                    <li
                      className={`nav-item ${
                        selectedTemplates.type == "all" ? "active" : ""
                      } `}
                      onClick={() => handleTemplateSelection("all")}
                    >
                      All{" "}
                      <span className="notification-number">
                        ({templates.length})
                      </span>
                    </li>
                    <li
                      className={`nav-item ${
                        selectedTemplates.type == "approved" ? "active" : ""
                      } `}
                      onClick={() => handleTemplateSelection("approved")}
                    >
                      Approved{" "}
                      <span className="notification-number">
                        (
                        {
                          templates.filter((temp) => temp.status === "APPROVED")
                            .length
                        }
                        )
                      </span>
                    </li>
                    <li
                      className={`nav-item ${
                        selectedTemplates.type == "pending" ? "active" : ""
                      } `}
                      onClick={() => handleTemplateSelection("pending")}
                    >
                      Pending{" "}
                      <span className="notification-number">
                        (
                        {
                          templates.filter((temp) => temp.status === "PENDING")
                            .length
                        }
                        )
                      </span>
                    </li>
                    <li
                      className={`nav-item ${
                        selectedTemplates.type == "rejected" ? "active" : ""
                      } `}
                      onClick={() => handleTemplateSelection("rejected")}
                    >
                      Rejected{" "}
                      <span className="notification-number">
                        (
                        {
                          templates.filter((temp) => temp.status === "REJECTED")
                            .length
                        }
                        )
                      </span>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col sm={12}>
                {loading ? (
                  loadingList
                ) : templateList.length > 0 ? (
                  templateList
                ) : (
                  <p
                    style={{
                      textAlign: "center",
                      paddingTop: "10px",
                      paddingBottom: "0px",
                      fontWeight: "500",
                      fontSize: "20px",
                    }}
                  >
                    No Templates Found
                    <br />
                    <br />
                    Create a new template by clicking{" "}
                    <Link to="/createTemplate" style={{ color: "#165E5B" }}>
                      Create New Template
                    </Link>
                  </p>
                )}
              </Col>
            </Row>
            {/* Custom-styled Pagination */}

            {/* End of Custom-styled Pagination */}
          </Container>
        </section>
        {templateList.length > 0 && (
          <Row className="py-3 py-md-4">
            <Col sm={12}>
              <ul className="nav justify-content-center align-items-center">
                <li className="nav-item">
                  <Button
                    className="table-btn"
                    onClick={() =>
                      setCurrentPage((prevPage) =>
                        prevPage === 1 ? prevPage : prevPage - 1
                      )
                    }
                  >
                    <i className="bi bi-chevron-left"></i>
                  </Button>
                </li>
                {Array.from({ length: totalPages }, (_, i) => (
                  <li className="nav-item" key={i}>
                    <Button
                      className={`table-btn ${
                        i + 1 === currentPage ? "active" : ""
                      }`}
                      onClick={() => setCurrentPage(i + 1)}
                    >
                      {i + 1}
                    </Button>
                  </li>
                ))}
                <li className="nav-item">
                  <Button
                    className="table-btn"
                    onClick={() =>
                      setCurrentPage((prevPage) =>
                        prevPage === totalPages ? prevPage : prevPage + 1
                      )
                    }
                  >
                    <i className="bi bi-chevron-right"></i>
                  </Button>
                </li>
              </ul>
            </Col>
          </Row>
        )}
      </section>

      {templatetoDelete && (
        <Modal
          show={dtshow}
          onHide={() => setDtShow(false)}
          className="whatsapp-modal confirm-modal modal-contact"
          centered
        >
          <Modal.Header className="p-0">
            <Modal.Title>Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-content">
              You are about to delete{" "}
              <span className="u-name">
                <b>{` “${templatetoDelete.name.trim()}”`}</b>
              </span>{" "}
              from your templates. This process cannot be reversed.
            </div>
          </Modal.Body>
          <Modal.Footer className="px-0">
            <Button
              variant="danger-light"
              onClick={() => {
                deleteTemp(templatetoDelete.name);
                setDtShow(false);
              }}
            >
              Yes, Delete it
            </Button>
            <Button variant="danger" onClick={() => setDtShow(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {templateshow && (
        <Modal
          show={templateshow}
          onHide={() => setTemplateShow(false)}
          className="whatsapp-modal modal-contact preview-template"
          centered
          // backdrop="static"
          style={{ backdropFilter: "blur(10px)" }}
        >
          <Modal.Header className="p-0" closeButton></Modal.Header>
          <Modal.Body className="pb-5">
            <div className="preview-sec px-3 pt-0">
              <div className="preview-body">
                <div className="preview-screen">
                  {templatetoShow.category == "AUTHENTICATION" ? (
                    <AuthTemplate components={templatetoShow.components} />
                  ) : (
                    <UrlPhoneNumber components={templatetoShow.components} />
                  )}
                </div>
              </div>
              <QuickReplyPreview components={templatetoShow.components} />
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default Template;
