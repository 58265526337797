
import React from 'react';
import { useState } from 'react';
import Wtable from '../table/table';
import Row from "react-bootstrap/Row";
import { Modal } from 'react-bootstrap';
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Broadcastprogressbar from './b-progressbar';
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import Placeholdertable from '../loader/loading-table';
import "../table/table.css";


const Broadcasttable = ({ broadcasts, loading }) => {

  const theadData = ["Broadcast Name", "Date", "Successful", "Pending", "Failed", "Members", "Failed", "Created By", "Status",];
  const [currentPage, setCurrentPage] = useState(1);
  const [broadcastsPerPage] = useState(5);
  const [showFailed, setShowFailed] = useState(false)
  const [contacts, setContacts] = useState(null)

  const indexOfLastBroadcast = currentPage * broadcastsPerPage;
  const indexOfFirstBroadcast = indexOfLastBroadcast - broadcastsPerPage;
  const currentBroadcasts = broadcasts.slice(
    indexOfFirstBroadcast,
    indexOfLastBroadcast
  );
  const totalPages = Math.ceil(broadcasts.length / broadcastsPerPage);

  const countStatus = (broadcast) => {
    const count = {
      total: broadcast.messages.length,
      completed: 0,
      failed: 0,
      pending: 0,
    };
    broadcast.messages.forEach(({ message }) => {
      if (message?.failed) count.failed++;
      else if (message?.sent) count.completed++;
      else count.pending++;
    });
    return count;
  };

  const renderBroadcasts = () => {
    return currentBroadcasts.map((broadcast) => {
      const count = countStatus(broadcast);

      const formatPercentage = (percentage) => {
        return percentage % 1 === 0 ? percentage.toFixed(0) : percentage.toFixed(1);
      };

      return {
        id: broadcast._id,
        items: [
          broadcast.broadcastName,
          broadcast.createdAt.substring(0, 10),
          <Broadcastprogressbar
            percentage={formatPercentage(count.completed * 100 / count.total)}
            color={buildStyles({
              textColor: "#000000",
              trailColor: "#E8EFEF",
              pathColor: "lime",
              textsize: "14px",
            })}
          />,
          <Broadcastprogressbar
            percentage={formatPercentage(count.pending * 100 / count.total)}
            color={buildStyles({
              textColor: "#000000",
              trailColor: "#E8EFEF",
              pathColor: "orange",
              textsize: "14px",
            })}
          />,
          <Broadcastprogressbar
            percentage={formatPercentage(count.failed * 100 / count.total)}
            color={buildStyles({
              textColor: "#000000",
              trailColor: "#E8EFEF",
              pathColor: "red",
              textsize: "14px",
            })}
          />,
          broadcast.messages.length,
          count.failed > 0 ? count.failed + " Contacts" : "None",
          broadcast.madeBy?.firstname + " " + broadcast.madeBy?.lastname,
          broadcast.completed ? "Completed" : "Pending",
        ],
      };
    });
  };



  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(broadcasts.length / broadcastsPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = () => {
    return pageNumbers.map((number) => {
      return (
        <li
          key={number}
          id={number}
          className={currentPage === number ? "active" : null}
          onClick={(e) => setCurrentPage(Number(e.target.id))}
        >
          {number}
        </li>
      );
    });
  };

  const getFailedMessageIndices = (broadcast) => {
    const failedIndices = [];
    const success = []
    const pending = []

    broadcast.messages.forEach(({ message, contact }, index) => {
      if (message?.failed) {
        if (contact != null)
          failedIndices.push(contact);
      }
      else
        if (contact != null) {
          if (message != null)
            success.push(contact)
          else
            pending.push(contact)
        }
    });

    return { failed: failedIndices, success: success, pending: pending };
  };

  const clickRow = (id) => {
    console.log(id);

    // Assuming currentBroadcasts is an array of objects
    const matchingBroadcast = currentBroadcasts.find((broadcast) => broadcast._id === id);

    if (matchingBroadcast) {
      console.log(matchingBroadcast);
      const contacts = getFailedMessageIndices(matchingBroadcast)
      console.log(contacts)
      if (contacts.failed.length > 0 || contacts.success.length > 0 || contacts.pending.length > 0) {
        setShowFailed(true)
        setContacts(contacts)
      }
      // Do something with the matching broadcast object
    } else {
      console.log("No matching broadcast found");
    }
  };

  return (

    <>
      {!loading ?
        <div className="cg-custom-table">
          <Wtable theadData={theadData} tbodyData={renderBroadcasts()} clickRow={clickRow} />
          {broadcasts.length === 0 && <p style={{ textAlign: 'center', paddingTop: '20px', paddingBottom: '10px', fontWeight: '500', fontSize: '20px' }}>No Broadcasts Found</p>}
        </div> :
        <Placeholdertable theadData={theadData} />}


      {broadcasts.length > 0 && <Row className="py-3 py-md-4">
        <Col sm={12}>
          <ul className="nav justify-content-center align-items-center">
            <li className="nav-item">
              <Button
                className="table-btn"
                onClick={() =>
                  setCurrentPage(
                    (prevPage) => (prevPage === 1 ? prevPage : prevPage - 1)
                  )
                }
              >
                <i className="bi bi-chevron-left"></i>
              </Button>
            </li>
            {Array.from({ length: totalPages }, (_, i) => (
              <li className="nav-item" key={i}>
                <Button
                  className={`table-btn ${i + 1 === currentPage ? "active" : ""}`}
                  onClick={() => setCurrentPage(i + 1)}
                >
                  {i + 1}
                </Button>
              </li>
            ))}
            <li className="nav-item">
              <Button
                className="table-btn"
                onClick={() =>
                  setCurrentPage(
                    (prevPage) =>
                      prevPage === totalPages ? prevPage : prevPage + 1
                  )
                }
              >
                <i className="bi bi-chevron-right"></i>
              </Button>
            </li>
          </ul>
        </Col>
      </Row>}

      <Modal show={showFailed} onHide={() => {
        setShowFailed(false)
        setContacts(null)
      }} className="whatsapp-modal modal-csv" centered>
        <Modal.Header className="p-0" closeButton>
          <Modal.Title>
            Broadcast Recipiants
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Row>
            <Col sm={12}>
              <div className="modal-table">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Number</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contacts?.success
                        ?.map((contact) => {
                          return (
                            <tr>
                              <td>
                                {contact.contactName}
                              </td>
                              <td>
                                {'+' + contact.number}
                              </td>
                              <td>
                                Successful
                              </td>
                            </tr>)
                        })}
                      {contacts?.failed
                        ?.map((contact) => {
                          return (
                            <tr>
                              <td>
                                {contact.contactName}
                              </td>
                              <td>
                                {'+' + contact.number}
                              </td>
                              <td>
                                Failed
                              </td>
                            </tr>)
                        })}
                      {contacts?.pending
                        ?.map((contact) => {
                          return (
                            <tr>
                              <td>
                                {contact.contactName}
                              </td>
                              <td>
                                {'+' + contact.number}
                              </td>
                              <td>
                                Pending
                              </td>
                            </tr>)
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>

  );
}

export default Broadcasttable;