import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allConversations: [],
  allChatsData: [],
  currentConversation: null,
  singleChatData: null,
};

export const ChatsSlices = createSlice({
  name: "Chats",
  initialState,
  reducers: {
    // get all redux states
    getCurrentState: (state, action) => {
      return {
        allConversations: state.allConversations,
        allChatsData: state.allChatsData,
        singleChatData: state.singleChatData,
      };
    },

    // set all conversations
    setConversationsData: (state, action) => {
      state.allConversations = action.payload;
    },
    // empty all conversations
    removeAllConversationsData: (state, action) => {
      state.allConversations = [];
    },
    setSingleChatData: (state, action) => {
      state.singleChatData = action.payload;
    },
    // add new chat to chat list
    addNewChatToChatList: (state, action) => {
      let allChats = state.allConversations;
      allChats.push(action.payload);
      console.log(
        "new chat adding in list",
        action.payload,
        ":",
        allChats?.reverse()
      );

      state.allConversations = allChats?.reverse();
    },
    // update unread count of any chat
    updateUnReadCountOfAnyConversation: (state, action) => {
      let allChats = state.allConversations;
      console.log(
        "updated chat list in redux",
        action.payload,
        ":::",
        allChats
      );
      if (allChats?.length > 0) {
        allChats[0].unreadMessageCount = 0;
        state.allConversations = allChats;
      }
    },

    // set current conversation
    setCurrConversationsData: (state, action) => {
      // const uniqueMessages = (arr) => {
      //     const seen = {};
      //     const uniqueItems = [];

      //     if (arr?.messages?.length > 0) {
      //         arr?.messages.forEach(item => {
      //             if (!seen[item.whatsappMessageId]) {
      //                 seen[item.whatsappMessageId] = true;
      //                 uniqueItems.push(item);
      //             }
      //         });
      //     }

      //     return uniqueItems;
      // };

      // const filteredMsgs = uniqueMessages(action.payload && action.payload)
      state.currentConversation = action.payload;
    },
    // remove current conversation
    removeCurrConversationsData: (state, action) => {
      state.currentConversation = null;
    },

    // set chat data
    setChatsData: (state, action) => {
      let allChats = state.allChatsData;
      let matchedIndex = allChats.findIndex(
        (item) => item?.conversationId == action.payload.conversationId
      );
      if (matchedIndex < 0) {
        allChats.push({
          conversationId: action.payload.conversationId,
          messages: action.payload.messages,
          id: action.payload?.id,
          tags: action.payload?.tags,
          contact: action.payload?.contact,
          generalInfo: action.payload?.generalInfo,
        });
      } else {
        allChats[matchedIndex].messages = action.payload.messages;
      }

      state.allChatsData = allChats;
    },
    // empty all chats data
    removeAllChatsData: (state, action) => {
      state.allChatsData = [];
    },
    // add new message in messages array
    addNewMessageToChat: (state, action) => {
      let allChats = state.allChatsData;
      let matchedIndex = allChats.findIndex(
        (item) => item?.id == action.payload.conversationId
      );
      if (matchedIndex > -1) {
        // const uniqueMessages = (arr) => {
        //     const seen = {};
        //     const uniqueItems = [];

        //     arr.forEach(item => {
        //         if (!seen[item.whatsappMessageId]) {
        //             seen[item.whatsappMessageId] = true;
        //             uniqueItems.push(item);
        //         }
        //     });

        //     return uniqueItems;
        // };
        // const filteredMsgs = uniqueMessages(action.payload.newMessages)

        // if (filteredMsgs?.length > 0) {
        //     console.log("reached redux filtered", filteredMsgs)
        // }
        allChats[matchedIndex].messages = action.payload.newMessages;
        allChats[matchedIndex].tagsAssigned = action.payload.tag;
        allChats[matchedIndex].generalInfo.remaining =
          action.payload.conversationExpiry;
      }
      console.log("reached redux", matchedIndex, ":", action.payload);

      state.allChatsData = allChats;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setConversationsData,
  removeAllConversationsData,
  setSingleChatData,
  setChatsData,
  removeAllChatsData,
  addNewMessageToChat,
  addNewChatToChatList,
  updateUnReadCountOfAnyConversation,
  getCurrentState,
  setCurrConversationsData,
  removeCurrConversationsData,
} = ChatsSlices.actions;

export default ChatsSlices.reducer;
