import React , {useState,useEffect} from 'react'
import "./styles.css"
import { Box , IconButton, Typography } from '@mui/material';
import tagIcon from "../../images/icons/tag_page_icons.svg"
import InputField from '../../components/inputField/index.tsx';
import AddIcon from '@mui/icons-material/Add';
import CustomButton from "../../components/customButton/index.tsx"

interface propsTypes {
    handleClickAddNewTag?:() => void,
    getSearchedTags?:(val: string) => void
}

const TagsHeader: React.FC<propsTypes> = (props) => {
    const [ searchText , setSearchText ] = useState("")
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update screenWidth state
        const updateScreenWidth = () => {
          setScreenWidth(window.innerWidth);
        };
    
        // Add event listener for window resize
        window.addEventListener("resize", updateScreenWidth);
    
        // Remove event listener on component unmount
        return () => {
          window.removeEventListener("resize", updateScreenWidth);
        };
      }, []);

    const handleOnChangeTagSearch = (value: string) => {
        console.log("value got : ", value)
        setSearchText(value)
        if(props?.getSearchedTags != undefined){
            props?.getSearchedTags(value)
        }
    }

    const handleShowCreateNewTagPopup = () => {
        if(props?.handleClickAddNewTag != undefined){
            props?.handleClickAddNewTag()
        }
    }
    return (
        <>
        {
            screenWidth >= 768 ?  <Box className="bg-[#FFFFFF] flex justify-center align-items-center h-[70px] w-100 " >
            <Box className="flex justify-between align-items-center h-[70px] h-100 w-100 pl-2 template-heading container " >
                <Box className="flex justify-start align-items-center" >
                    <Box className="flex justify-start align-items-center gap-2 mr-[30px]" >
                        <img className="tags-icon-style" src={tagIcon} alt="tags icon" />
                        <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[17px] lg:text-[22px] xl:text-[25px] font-bold leading-[30px] text-[#000000] text-center " > Tags</Typography>
                    </Box>
                    <Box className="flex justify-start align-items-center" >
                        <InputField
                            value={searchText}
                            placeholder="Search Tag name"
                            handleOnChange={(val : string) => handleOnChangeTagSearch(val)}
                        />
                    </Box>
                </Box>
                <CustomButton
                    text="Create New"
                    handleOnClick={handleShowCreateNewTagPopup}
                >
                    <IconButton sx={{padding: 0}}>
                        <AddIcon sx={{ width : "15px", height : "20px", color : "#FFFFFF", marginRight : "5px" }} />
                    </IconButton>
                </CustomButton>
            </Box>
        </Box>:  
        <>
        <Box className="bg-[#FFFFFF] flex justify-center align-items-center h-[70px] w-100 py-2 " >
                <Box className="flex justify-between align-items-center h-[70px] h-100 w-100 pl-2 template-heading container " >
                    <Box className="flex justify-start align-items-center" >
                        <Box className="flex justify-start align-items-center gap-2 mr-[30px]" >
                            <img className="tags-icon-style" src={tagIcon} alt="tags icon" />
                            <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[17px] lg:text-[22px] xl:text-[25px] font-bold leading-[30px] text-[#000000] text-center " > Tags</Typography>
                        </Box>
                        
                    </Box>
                    <CustomButton
                        text="Create New"
                        handleOnClick={handleShowCreateNewTagPopup}
                    >
                        <IconButton sx={{padding: 0}}>
                            <AddIcon sx={{ width : "15px", height : "20px", color : "#FFFFFF", marginRight : "5px" }} />
                        </IconButton>
                    </CustomButton>
                </Box>
            </Box>
            <Box className="flex justify-center mt-2 w-full align-items-center" >
                            <InputField
                                value={searchText}
                                placeholder="Search Tag name"
                                handleOnChange={(val : string) => handleOnChangeTagSearch(val)}
                               
                            />
                        </Box>
            </>
        }
          
        </>
    )
}

export default TagsHeader
