import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
export const QuickReplyPreview = ({ components, isSending }) => {
  return (
    <>
      {components?.map((component, index) => {
        if (component.type === "BUTTONS") {
          if (component.buttons[0].type === "QUICK_REPLY") {
            return (
              <Row className="event-time">
                {component?.buttons?.length === 1 ? (
                  <Col xs={12} className="pe-1">
                    <div className="e-time start-time">
                      {component.buttons[0].text}
                    </div>
                  </Col>
                ) : (
                  <Col xs={6} className="pe-1">
                    <div className="e-time start-time">
                      {component.buttons[0].text}
                    </div>
                  </Col>
                )}

                {component.buttons.length > 1 && (
                  <Col xs={6} className="ps-1">
                    <div className="e-time end-time">
                      {component.buttons[1].text}
                    </div>
                  </Col>
                )}
                {component.buttons.length > 2 && (
                  <div className="event-time">
                    <Col xs={12}>
                      <div className="e-time">{component.buttons[2].text}</div>
                    </Col>
                  </div>
                )}
              </Row>
            );
          }
        }

        return null;
      })}
    </>
  );
};
