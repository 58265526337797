import React from 'react'
import {Row,Col} from "react-bootstrap"
import { Box, Typography } from "@mui/material"
import dummyImgForHeader from "../../../images/Temp-Image-Placeholder.svg"
import dummyDocumentForHeader from "../../../images/document-upload.svg"
import dummyLocatImgForHeader from "../../../images/location-upload.svg"
import dummyVideoImgForHeader from "../../../images/video-upload.svg"
import Linkforward from "../../../images/icons/link-forward.svg";

// used when no header is selected (default)
export const TemplateWithVariablesInHeader = (props) => {
    return (
        <>
            <Box className="flex justify-start items-start flex-column p-[15px] rounded-md bg-[#FFFFFF] mt-[10px] mb-[30px] pb-[25px] w-[95%]">
                <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[14px] lg:text-[16px] font-medium leading-[22px] text-[#000000]" >Hey, [Eda]</Typography>
                <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-thin leading-[22px] text-[#000000] mt-[12px]" >
                    We are excited to inform you about our upcoming event, [GCC eHEALTH WORKFORCE DEVELOPMENT CONFERENCE]! This is a great opportunity for you to connect with industry experts, learn about the latest trends, and expand your network. <br />  <br /> We are offering an exclusive discount code for our valued participants. Use the code [GCC20] to get a special discount on your registration.
                </Typography>
                {/* <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-medium leading-[22px] text-[#000000] mt-[27px]" >Best Regards</Typography> */}
            </Box>
        </>
    )
}

// when image uploaded in header
export const TemplateWithHeaderImage = (props) => {
    return (
        <>
            <Box className="flex justify-start items-start flex-column mt-[10px] mb-[30px] min-w-[95%]">
                <Box className="flex justify-start items-start flex-column bg-[#FFFFFF] p-[10px] rounded-md w-100">
                    <img src={props?.image || dummyImgForHeader} style={{ width: "100%", height: "150px", objectFit: props?.image ? "contain" : "cover" }} />
                    <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-thin leading-[22px] text-[#000000] mt-[12px]" >
                        {props?.formData?.body}
                    </Typography>
                    {/* <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-medium leading-[22px] text-[#000000] mt-[27px]" >Best Regards</Typography> */}
                </Box>
                {
                    props?.buttonType == "calltoaction" && (<div className="link-footer px-0">
                        {props?.callToActionData[0].action === "PHONE_NUMBER" && (
                            <div>
                                {props?.callToActionData[0].text !== '' && <a href="#"><img src={Reciver} alt="icon" />{props?.callToActionData[0].text}</a>}
                                {props?.numcallToActionButtons > 1 && props?.callToActionData[1].text !== '' && (<a href="#"><img src={Linkforward} alt="icon" />{props?.callToActionData[1].text}</a>)}
                            </div>
                        )}

                    </div>)
                }
                {
                    props?.buttonType == "quickreply" && (<Row className="event-time">
                    {props?.quickReplyData[0].data !== "" && <Col xs={6} className="pe-1">
                        <div className="e-time start-time">
                            {props?.quickReplyData[0].data}
                        </div>
                    </Col>}

                    {props?.numQuickReplyButtons > 1 && props?.quickReplyData[1].data !== "" && <Col xs={6} className="ps-1">
                        <div className="e-time end-time">
                            {props?.quickReplyData[1].data}
                        </div>
                    </Col>}

                    {props?.numQuickReplyButtons > 2 && props?.quickReplyData[2].data !== "" && <div className="event-time">
                        <Col xs={12} className="pe-0">
                            <div className="e-time">
                                {props?.quickReplyData[2].data}
                            </div>
                        </Col>
                    </div>}

                </Row>)
                }
                <Box className="flex justify-between items-center gap-2 mt-[5px] w-100">
                    <Box className="bg-[#FFFFFF] flex justify-center items-center p-[12px] rounded-md w-100" >
                        <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px]  font-medium leading-[18px] text-[#0C7FD1]" >12:00 PM</Typography>
                    </Box>
                    <Box className="bg-[#FFFFFF] flex justify-center items-center p-[12px] rounded-md w-100" >
                        <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-medium leading-[18px] text-[#0C7FD1]" >2:00 PM</Typography>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

// when any document uploaded in header
export const TemplateWithHeaderDocument = (props) => {
    return (
        <>
            <Box className="flex justify-start items-start flex-column mt-[10px] mb-[30px] w-[95%]">
                <Box className="flex justify-start items-start flex-column bg-[#FFFFFF] p-[10px] rounded-md w-100">
                    <img src={dummyDocumentForHeader} style={{ width: "100%", height: "150px", objectFit: "cover" }} />
                    <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-thin leading-[22px] text-[#000000] mt-[12px]" >{props?.formData?.body}</Typography>
                    {/* <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-medium leading-[22px] text-[#000000] mt-[27px]" >Best Regards</Typography> */}
                </Box>
                <Box className="flex justify-between items-center gap-2 mt-[5px] w-100">
                    <Box className="bg-[#FFFFFF] flex justify-center items-center p-[12px] rounded-md w-100" >
                        <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px]  font-medium leading-[18px] text-[#0C7FD1]" >12:00 PM</Typography>
                    </Box>
                    <Box className="bg-[#FFFFFF] flex justify-center items-center p-[12px] rounded-md w-100" >
                        <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-medium leading-[18px] text-[#0C7FD1]" >2:00 PM</Typography>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

// when any video uploaded in header
export const TemplateWithHeaderVideo = (props) => {
    return (
        <>
            <Box className="flex justify-start items-start flex-column mt-[10px] mb-[30px] w-[95%]">
                <Box className="flex justify-start items-start flex-column bg-[#FFFFFF] p-[10px] rounded-md w-100">
                    <img src={dummyVideoImgForHeader} style={{ width: "100%", height: "150px", objectFit: "cover" }} />
                    <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-thin leading-[22px] text-[#000000] mt-[12px]" >{props?.formData?.body}</Typography>
                    {/* <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-medium leading-[22px] text-[#000000] mt-[27px]" >Best Regards</Typography> */}
                </Box>
                <Box className="flex justify-between items-center gap-2 mt-[5px] w-100">
                    <Box className="bg-[#FFFFFF] flex justify-center items-center p-[12px] rounded-md w-100" >
                        <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px]  font-medium leading-[18px] text-[#0C7FD1]" >12:00 PM</Typography>
                    </Box>
                    <Box className="bg-[#FFFFFF] flex justify-center items-center p-[12px] rounded-md w-100" >
                        <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-medium leading-[18px] text-[#0C7FD1]" >2:00 PM</Typography>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

// when location is selected in header
export const TemplateWithHeaderLocation = (props) => {
    return (
        <>
            <Box className="flex justify-start items-start flex-column mt-[10px] mb-[30px] w-[95%]">
                <Box className="flex justify-start items-start flex-column bg-[#FFFFFF] p-[10px] rounded-md w-100">
                    <img src={dummyLocatImgForHeader} style={{ width: "100%", height: "150px", objectFit: "cover" }} />
                    <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-thin leading-[22px] text-[#000000] mt-[12px]" >{props?.formData?.body}</Typography>
                    {/* <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-medium leading-[22px] text-[#000000] mt-[27px]" >Best Regards</Typography> */}
                </Box>
                <Box className="flex justify-between items-center gap-2 mt-[5px] w-100">
                    <Box className="bg-[#FFFFFF] flex justify-center items-center p-[12px] rounded-md w-100" >
                        <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px]  font-medium leading-[18px] text-[#0C7FD1]" >12:00 PM</Typography>
                    </Box>
                    <Box className="bg-[#FFFFFF] flex justify-center items-center p-[12px] rounded-md w-100" >
                        <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-medium leading-[18px] text-[#0C7FD1]" >2:00 PM</Typography>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

// used when simple text is added in header
export const TemplateSimple = (props) => {
    return (
        <>
            <Box className="flex justify-start items-start flex-column mt-[10px] mb-[30px] w-[95%]">
                <Box className="flex justify-start items-start flex-column bg-[#FFFFFF] p-[10px] rounded-md w-100">
                    <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[14px] lg:text-[16px] font-medium leading-[22px] text-[#000000]" >{props?.formData?.header}</Typography>
                    <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-thin leading-[22px] text-[#000000] mt-[12px]" >{props?.formData?.body}</Typography>
                    {/* <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-medium leading-[22px] text-[#000000] mt-[27px]" >Best Regards</Typography> */}
                </Box>
                <Box className="flex justify-between items-center gap-2 mt-[5px] w-100">
                    <Box className="bg-[#FFFFFF] flex justify-center items-center p-[12px] rounded-md w-100" >
                        <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px]  font-medium leading-[18px] text-[#0C7FD1]" >12:00 PM</Typography>
                    </Box>
                    <Box className="bg-[#FFFFFF] flex justify-center items-center p-[12px] rounded-md w-100" >
                        <Typography variant="h4" sx={{ fontFamily: "SEGOE UI" }} className="text-[12px] lg:text-[14px] font-medium leading-[18px] text-[#0C7FD1]" >2:00 PM</Typography>
                    </Box>
                </Box>
            </Box>
        </>
    )
}