import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Electric_icon from "../images/integrations/electric-plugin-icon.svg";
import hubspot_logo from "../images/integrations/hubspot.svg";
import Header from "../components/widgets/header";
import Card from "../components/widgets/WidgetCard";
import { getAllWidgets } from "../services/widget";

const Widgets = () => {
  const [allWidgets, setAllWidgets] = useState([]);
  const [allOrgWidgets, setAllOrgWidgets] = useState([]);
  const [isGetWidgets, setIsGetWidgets] = useState(false);
  const navigate = useNavigate();
  const isSuperAdmin = localStorage.getItem("superAdmin");
  const isAdmin = localStorage.getItem("companyAdmin");

  const isSimpleUser = isSuperAdmin == "false" && isAdmin == "false";
  console.log(
    "isSimpleUser",
    isSuperAdmin == "false",
    isAdmin == "false",
    ":",
    isSimpleUser
  );

  // get selected widget styles
  const getSelectedWidgetData = (type) => {
    if (isSimpleUser) {
      return null;
    }

    let data = null;

    if (type != "") {
      data = allWidgets?.find((item) => item?.type == type);
    }

    const widgetData = {
      chatButton: {
        whatsAppNo: data?.button?.phone_number,
        bgColor: data?.button?.color,
        text: data?.button?.btn_text,
        borderRadius: data?.button?.radius,
        marginLeft: data?.button?.marginBottom?.left || 20,
        marginRight: data?.button?.margin?.right || 20,
        marginBottom: data?.button?.margin?.bottom || 20,
      },
      widgetDetails: {
        headerBgColor: data?.widget?.color,
        title: data?.widget?.name,
        desc: data?.widget?.desc,
        CTAs: data?.widget?.CTA,
      },
      id: data?._id,
      type: data?.type,
      position: data?.position,
    };

    console.log("going to pass", widgetData);

    if (data) {
      setTimeout(
        () =>
          navigate("/widget-creation", {
            state: { widgetData: widgetData, type: type },
          }),
        500
      );
    } else {
      navigate("/widget-creation", { state: { type: "utter-wa-widget" } });
      //navigate("/widget-creation")
    }
  };

  // get all widgets
  const getWidgets = async () => {
    setIsGetWidgets(true);
    const resp = await getAllWidgets();
    console.log("resp of all widgets ", resp);
    if (resp?.success) {
      setAllWidgets(resp?.data);
      setAllOrgWidgets(resp?.data);
    }
    setIsGetWidgets(false);
  };

  useEffect(() => {
    getWidgets();
  }, []);

  return (
    <>
      <Header Pagename={"Integrations"} />

      <section className="main inner-main">
        <Container>
          <Row className="py-1 py-md-3">
            {isGetWidgets ? (
              <div
                style={{
                  display: "flex",
                  width: "40%",
                  maxHeight: "234px",
                  //   marginLeft: "10%",
                  marginTop: "30px",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <div className="user-personal-info" style={{ width: "100%" }}>
                  <div className="info-row">
                    <div
                      className="placeholder-glow"
                      style={{ maxWidth: "100%" }}
                    >
                      <span
                        className="col-12 placeholder mb-2 py-2"
                        style={{ height: "234px" }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <Col md={6} lg={4} className="mb-3 mb-md-0">
                  <Card
                    Iconimg={Electric_icon}
                    Websitename={"WhatsApp Website Widget"}
                    WebsiteTitle={
                      "Boost WhatsApp sales by implementing personalized CTAs."
                    }
                    WebsiteLink={"/widget-creation"}
                    allWidgets={allWidgets}
                    isSimpleUser={isSimpleUser}
                    widgetData={
                      allWidgets?.find(
                        (item) => item?.type == "utter-wa-widget"
                      ) || allWidgets[0]
                    }
                    getSelectedWidgetData={() =>
                      getSelectedWidgetData(
                        allWidgets?.length > 0
                          ? "utter-wa-widget" || ""
                          : allWidgets[0]
                      )
                    }
                    // widgetData={allWidgets?.length > 0 ? allWidgets[0] : null}
                    // getSelectedWidgetData={() => getSelectedWidgetData(allWidgets?.length > 0 ? allWidgets[0] : null)}
                  />
                </Col>
                {/* <Col md={6} lg={4}>
                                        <Card Iconimg={hubspot_logo} Websitename={'WhatsApp Website Widget'} widgetData={null}
                                            WebsiteTitle={'Boost WhatsApp sales by implementing personalized CTAs.'} WebsiteLink={'/widget-creation'} />
                                    </Col> */}
              </>
            )}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Widgets;
