import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allBroadCastsData: [],
  totalRecords: 0,
  pickerLoader: false,
};

export const GroupsSlices = createSlice({
  name: "Broadcasts",
  initialState,
  reducers: {
    // set all broadcasts
    setAllBroadCastsData: (state, action) => {
      console.log("action.payload type", action.payload);
      state.allBroadCastsData = Object.values(action.payload.data);
      state.totalRecords = action.payload.totalRecords;
    },
    setPickerLoader: (state, action) => {
      state.pickerLoader = action.payload;
    },
    // empty all broadcasts
    removeAllBroadCastsData: (state, action) => {
      state.allBroadCastsData = [];
      state.totalRecords = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAllBroadCastsData,
  removeAllBroadCastsData,
  setPickerLoader,
} = GroupsSlices.actions;

export default GroupsSlices.reducer;
