import React, { useState } from "react";
import { Box, IconButton, Tooltip, Zoom } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Form } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import InfoIcon from "../../images/icons/infoIcon.svg";

const TemplateVariables = (props) => {
  const [isCopied, setIsCopied] = useState({
    copied: false,
    index: null,
  });
  const [showDynamic, setShowDynamic] = useState(false);

  const markAsCopied = (index) => {
    setIsCopied({
      copied: true,
      index: index,
    });
    removeCopiedData();
  };

  const removeCopiedData = () => {
    setTimeout(() => {
      setIsCopied({
        copied: false,
        index: null,
      });
    }, 800);
  };

  return (
    <>
      <Box className="flex justify-start items-start flex-column border-[1px] border-dashed rounded-md border-[#77777780] p-[10px] lg:p-[20px] bg-[#FFFFFF] mt-[18px] templates">
        <h4
          style={{ fontFamily: "SEGOE UI" }}
          className="text-[14px] lg:text-[16px] font-medium leading-[22px] text-[#000000] flex gap-2"
        >
          Add Variables
          <Tooltip
            arrow
            TransitionComponent={Zoom}
            title={
              <p>
                To add the dynamic content click on "
                <strong style={{ fontWeight: "600" }}>+add variables</strong>"
                button <br />
                when the variable is added, it would display field {"{{1}}"} -
                either use the pre-defined options( "Full_Name" ,
                "Phone_Number") OR add the variable manually in the text field.
                <br />
                <br />
                Click on the copy icon against the variable and add it in the
                Message Text Body, This will add the dynamic content while the
                template is being sent.{" "}
                <strong style={{ fontWeight: "600" }}> Full_Name </strong>fill
                Contact name of the person from the database. <br /> <br /> Hey{" "}
                {"{{1}}"}, get 10% OFF using code {"{{2}}"}.
                <br /> <br /> Variables Text Added would be as below: <br />
                {"{{1}}"} - Full_Name
                <br />
                {"{{2}}"} - Promo10
                <br />
                <br />
                Message Delivered to end user with name Eda would be: <br />
                Hey Eda, get 10% OFF using code Promo10.
              </p>
            }
            placement={"top"}
            sx={{ cursor: "pointer" }}
            open={showDynamic}
            onMouseEnter={() => setShowDynamic(true)}
            onMouseLeave={() => setShowDynamic(false)}
          >
            <img
              src={InfoIcon}
              style={{ cursor: "pointer" }}
              className="mt-1"
              width={16}
              height={16}
            />
          </Tooltip>
        </h4>
        <h4
          style={{ fontFamily: "SEGOE UI" }}
          className="text-[12px] lg:text-[15px] font-normal leading-[18px] text-[#494949] mt-[12px] mb-[12px]"
        >
          Add dynamic content to the message by adding Full_Name, Phone_Number,
          or custom text.
        </h4>

        {/* already existing variables */}
        <Box className="flex justify-start items-center gap-1">
          <Box className="flex justify-center items-center bg-[#D6F8E5] p-[6px] rounded-[5px] gap-2 ">
            <h4
              style={{ fontFamily: "SEGOE UI" }}
              className="text-[12px] lg:text-[14px] font-[400] leading-[18px] text-[#000000] "
            >{`Full_Name`}</h4>
            <CopyToClipboard
              text={`Full_Name`}
              onCopy={() => markAsCopied(1000)}
            >
              <IconButton sx={{ padding: "0px" }}>
                {isCopied?.copied && isCopied?.index == 1000 ? (
                  <CheckCircleIcon
                    sx={{ fontSize: "20px", color: "#000000" }}
                  />
                ) : (
                  <ContentCopyIcon
                    sx={{ fontSize: "20px", color: "#000000" }}
                  />
                )}
              </IconButton>
            </CopyToClipboard>
          </Box>
          <Box className="flex justify-center items-center bg-[#D6F8E5] p-[6px] rounded-[5px] gap-2 ">
            <h4
              style={{ fontFamily: "SEGOE UI" }}
              className="text-[12px] lg:text-[14px] font-[400] leading-[18px] text-[#000000] "
            >{`Phone_Number`}</h4>
            <CopyToClipboard
              text={`Phone_Number`}
              onCopy={() => markAsCopied(1001)}
            >
              <IconButton sx={{ padding: "0px" }}>
                {isCopied?.copied && isCopied?.index == 1001 ? (
                  <CheckCircleIcon
                    sx={{ fontSize: "20px", color: "#000000" }}
                  />
                ) : (
                  <ContentCopyIcon
                    sx={{ fontSize: "20px", color: "#000000" }}
                  />
                )}
              </IconButton>
            </CopyToClipboard>
          </Box>
        </Box>

        <h4
          style={{ fontFamily: "SEGOE UI" }}
          className="text-[12px] lg:text-[15px] font-normal leading-[18px] text-[#494949] mt-[20px]"
        >
          {props?.bodyVarCount == 0
            ? "Click below button to create variables"
            : "Use the input box below to create variables, which you can apply in the Template’s “Body” "}
        </h4>
        {props?.bodyVarCount > 0
          ? props?.bodySample?.map((bodyVar, index) => (
              <Box className="flex justify-start items-center gap-1 flex-column mt-[10px] w-100">
                <Box className="flex justify-start items-center gap-2 w-100">
                  <h4
                    style={{ fontFamily: "SEGOE UI" }}
                    className="text-[14px] lg:text-[16px] font-[400] leading-[18px] text-[#494949]"
                  >
                    {"{{" + (index + 1) + "}}"}
                  </h4>
                  <CopyToClipboard
                    text={`{{${index + 1}}}`}
                    onCopy={() => markAsCopied(index)}
                  >
                    <IconButton sx={{ padding: "0px", marginLeft: "5px" }}>
                      {isCopied?.copied && isCopied?.index == index ? (
                        <CheckCircleIcon
                          sx={{ fontSize: "20px", color: "#000000" }}
                        />
                      ) : (
                        <ContentCopyIcon
                          sx={{ fontSize: "20px", color: "#000000" }}
                        />
                      )}
                    </IconButton>
                  </CopyToClipboard>
                  <Box
                    className="flex justify-between items-center bg-[#F3F7F7] border-[1px] border-[#F3F7F7] rounded-[5px] h-[40px] p-[10px]"
                    sx={{ width: "100%" }}
                  >
                    <Form.Control
                      placeholder="Address"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        paddingLeft: "0px",
                      }}
                      onChange={(event) =>
                        props?.handleBodySample(event, index)
                      }
                      value={bodyVar}
                    />
                    <IconButton
                      sx={{ padding: "0px", marginLeft: "5px" }}
                      onClick={() => props?.removeVariable(index)}
                    >
                      <CloseIcon sx={{ fontSize: "20px", color: "#7D7D7D" }} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            ))
          : null}

        {props?.bodyVarCount > 0 ? (
          <Box className="flex justify-end items-center gap-2 w-100 mt-[15px]">
            <IconButton
              sx={{ padding: "0px", cursor: "pointer" }}
              onClick={props?.handlebodyVarCount}
            >
              <AddIcon sx={{ fontSize: "20px", color: "#000000" }} />
            </IconButton>
            <h4
              style={{ fontFamily: "SEGOE UI", cursor: "pointer" }}
              className="text-[12px] lg:text-[14px] font-[500] leading-[18px] text-[#000000] "
              onClick={props?.handlebodyVarCount}
            >
              Add more
            </h4>
          </Box>
        ) : (
          <Box className="flex justify-start items-center gap-2 w-100 mt-[15px]">
            <IconButton
              sx={{ padding: "0px", cursor: "pointer" }}
              onClick={props?.handlebodyVarCount}
            >
              <AddIcon sx={{ fontSize: "20px", color: "#000000" }} />
            </IconButton>
            <h4
              style={{ fontFamily: "SEGOE UI", cursor: "pointer" }}
              className="text-[12px] lg:text-[14px] font-[500] leading-[18px] text-[#000000] "
              onClick={props?.handlebodyVarCount}
            >
              Add Variables
            </h4>
          </Box>
        )}
      </Box>
    </>
  );
};

export default TemplateVariables;
