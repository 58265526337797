import React from 'react'
import {Box } from "@mui/material"
import leftArrow from "../../images/icons/left-arrow.svg"
import {useNavigate} from "react-router-dom"

const Header = (props) => {
  const navigate = useNavigate()
  return (
    <>
      <Box className="bg-[#FFFFFF] flex justify-center align-items-center min-h-[70px] w-100 shadow-[#00000014] shadow-sm " >
        <Box className="flex justify-between align-items-center h-[70px] h-[70px] w-100 pl-2  container " >
          <Box className="flex justify-start align-items-center d-none d-md-block" >
            <h4 style={{ fontFamily: "SEGOE UI" }} className="text-[17px] lg:text-[22px] font-semibold leading-[30px] text-[#000000] text-center " >{props?.isEdit == true ? "Update " : "Create New " } Template</h4>
          </Box>
          <Box className="flex justify-end items-center gap-1">
            <img src={leftArrow} alt="left arrow icon" style={{cursor : "pointer" }} onClick={() => navigate("/template")} />
            <h4 onClick={() => navigate("/template")} style={{ fontFamily: "SEGOE UI" ,cursor : "pointer" }} className="text-[16px] font-medium leading-[18px] text-[#000000] text-center" >Templates</h4>
            <h4 style={{ fontFamily: "SEGOE UI" }} className="text-[16px] font-medium leading-[18px] text-[#4B4A4A] text-center " >/</h4>
            <h4 style={{ fontFamily: "SEGOE UI" }} className="text-[16px] font-normal leading-[18px] text-[#4B4A4A] text-center " >{props?.isEdit == true ? "Update " : "Create " } Template</h4>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Header
