import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Spinner,
} from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";

import config from "../config.js";
import NoData from "./template-listing-empty.js";
import { generateColorHash } from "../utils/utils";
import {
  getAllCompanyUsers,
  deleteUser,
  createUser,
  editUser,
} from "../services/User";
import Header from "../components/users-module/userheader.js";
import DesktopUserListing from "../components/users-module/DesktopListing.jsx";
import ManageUsermob from "../components/users-module/MobileListing.jsx";
import Footer from "../components/template-listing/templateListing-footer.js";
import TableSkeleton from "../components/skeletons/TableLoader.jsx";

const baseUrl = `${config.backend}tags/`;

const ManageUser = () => {
  const [addNewShow, setAddNewShow] = useState(false);
  const [isShoweditUserModal, setIsShowEditUserModal] = useState(false);
  const [isDeleteUserModal, setIsShowDeleteUserModal] = useState(false);
  const [isUserAdding, setIsUserAdding] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [startIndexTable, setStartIndexTable] = useState(0);
  const [isUserDeleting, setIsUserDeleting] = useState(false);
  const [userToDel, setUserToDel] = useState(null);
  const [searchedText, setSearchedText] = useState("");
  const [selTag, setSelCategory] = useState("All");
  const [selStatus, setSelStatus] = useState(null);
  const [selTags, setSelTags] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [allNotHeaderTags, setAllNotHeaderTags] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const companyId = localStorage.getItem("companyId");

  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    companyId: companyId,
    userRole: "",
    tagIds: [],
    status: true,
    userId: null,
    password: "123123123",
  });
  const [userDataErrors, setUserDataErrors] = useState({
    firstname: { hasError: false, errorMessage: "" },
    lastname: { hasError: false, errorMessage: "" },
    email: { hasError: false, errorMessage: "" },
    userRole: { hasError: false, errorMessage: "" },
    tagIds: { hasError: false, errorMessage: "" },
    status: { hasError: false, errorMessage: "" },
  });

  const role = [
    { value: "user", label: "User" },
    { value: "admin", label: "Admin" },
    // { value: 'superadmin', label: 'Super Admin' }
  ];
  const status = [
    { value: true, label: "Active" },
    { value: false, label: "Inactive" },
  ];
  const headerStatus = [
    { value: "all", label: "All" },
    { value: true, label: "Active" },
    { value: false, label: "Inactive" },
  ];

  // get selected tags values
  const getSelectedTagsValues = (tags) => {
    if (!tags) {
      return [];
    }
    let temp = [];
    for (let i = 0; i != tags?.length; i++) {
      temp.push({ label: tags[i]?.title, value: tags[i]?._id });
    }
    return temp;
  };

  // get selected tags ids
  const getSelectedTagsIds = (tags) => {
    if (!tags) {
      return [];
    }
    let temp = [];
    for (let i = 0; i != tags?.length; i++) {
      temp.push(tags[i]?._id);
    }
    return temp;
  };

  const handleClickAddNew = () => {
    setAddNewShow(true);
  };

  const handleClickEditUser = (userData) => {
    setUserData({
      firstname: userData?.firstname,
      lastname: userData?.lastname,
      email: userData?.email,
      companyId: companyId,
      userRole: userData?.role,
      tagIds: getSelectedTagsIds(userData?.tags),
      userId: userData?._id,
      status: userData?.isapproved,
    });
    setSelTags(getSelectedTagsValues(userData?.tags));
    setIsShowEditUserModal(true);
  };

  const handleClickDeleteUser = (userData) => {
    setIsShowDeleteUserModal(true);
    setUserToDel({
      id: userData?._id,
      name: userData?.firstname + " " + userData?.lastname,
    });
  };

  // getting all data
  const getUsers = async () => {
    setIsFetching(true);
    setCurrPage(1);
    setStartIndexTable(0);
    const resp = await getAllCompanyUsers();
    console.log("data of all users", resp);
    if (resp?.success) {
      setOrgData(resp?.users);
      setAllUsers(resp?.users);
    } else {
      setOrgData([]);
      setAllUsers([]);
    }
    setIsFetching(false);
  };

  // get all tags
  const getAllTags = async () => {
    const { data } = await axios.get(`${baseUrl}`);
    if (data?.success) {
      let temp = [{ value: "all", label: "All" }];
      let _temp = [];
      for (let i = 0; i != data?.allTags?.length; i++) {
        temp.push({
          value: data?.allTags[i]?._id,
          label: data?.allTags[i]?.title,
        });
        _temp.push({
          value: data?.allTags[i]?._id,
          label: data?.allTags[i]?.title,
        });
      }
      setAllTags(temp);
      setAllNotHeaderTags(_temp);
    }
  };

  useEffect(() => {
    getUsers();
    getAllTags();
  }, []);

  // validate userFields while adding/editing user
  const isValidated = () => {
    if (userData.firstname === "") {
      toast.error("First name cannot be empty.");
      return false;
    }
    if (userData.lastname === "") {
      toast.error("last name cannot be empty.");
      return false;
    }
    if (userData.email === "") {
      toast.error("Email cannot be empty.");
      return false;
    }
    if (userData.userRole === "") {
      toast.error("role cannot be empty.");
      return false;
    }
    // if (userData.tagIds?.length == 0) {
    //     toast.error('tags cannot be empty.')
    //     return false
    // }
    // if (userData.status) {
    //     toast.error('status cannot be empty.')
    //     return false
    // }
    return true;
  };

  const validateInput = (name, value) => {
    if (name === "firstname" && value !== "" && !/^[a-zA-Z ]+$/.test(value)) {
      return "First name should only contain letters and spaces.";
    }
    if (name === "firstname" && value == "") {
      return "First name is required";
    }

    if (name === "lastname" && value !== "" && !/^[a-zA-Z ]+$/.test(value)) {
      return "Last name should only contain letters and spaces.";
    }
    if (name === "lastname" && value == "") {
      return "Last name is required";
    }
    if (name === "email") {
      if (value.trim() === "") {
        return "Email is required.";
      } else if (!/^\S+@\S+\.\S+$/.test(value)) {
        return "Email is invalid.";
      }
    }
    if (name == "userRole" && value === "") {
      console.log("inside of rolesss");
      return "Role is required.";
    }
    // if (name === 'tagIds' && value.length == 0) {
    //     return 'Tag is required.';
    // }
    // if (name === 'status' && value === '') {
    //     return 'Status is required.';
    // }

    return null;
  };

  // handle input change
  const inputChangeHandler = (e) => {
    const { name, value } = e.target || e;

    const errorMessage = validateInput(name, value);
    setUserDataErrors((prevState) => ({
      ...prevState,
      [name]: {
        hasError: Boolean(errorMessage),
        errorMessage: errorMessage || "",
      },
    }));

    setUserData({
      ...userData,
      [name]:
        typeof value == "object" || typeof value == "boolean"
          ? value
          : value?.trimStart(),
    });
  };

  console.log("userdata updated ", userData);

  // add/update user
  const addOrUpdateUser = async () => {
    const errors = {};
    Object.keys(userData).forEach((name) => {
      const error = validateInput(name, userData[name]);
      console.log("error check ", error, "::", name, "::", userData[name]);
      if (error) {
        errors[name] = { hasError: true, errorMessage: error };
      } else {
        errors[name] = { hasError: false, errorMessage: "" };
      }
    });
    setUserDataErrors(errors);

    let hasError = false;
    Object.keys(errors).forEach((name) => {
      if (errors[name].hasError) {
        hasError = true;
      }
    });

    if (hasError) {
      return;
    }

    console.log("errors", errors, "::", hasError);

    if (isValidated() === true) {
      try {
        setIsUserAdding(true);
        let resp = null;
        if (isShoweditUserModal) {
          resp = await editUser({
            ...userData,
            status: Boolean(userData?.status),
          });
        } else {
          resp = await createUser({
            ...userData,
            status: Boolean(userData?.status),
          });
        }
        if (resp.success) {
          toast.success(
            isShoweditUserModal
              ? "User updated successfully"
              : "User added successfully"
          );
          console.log("User registered successfully");
          handleCloseModal();
          getUsers();
        } else throw new Error(resp.error);
      } catch (error) {
        console.log("Error Signing Up. Please try again.");
        console.log(error.message);
        if (error.message.includes("A user with this email already exists")) {
          setUserDataErrors((prevState) => ({
            ...prevState,
            email: {
              ...prevState.email,
              hasError: true,
              errorMessage: error.message,
            },
          }));
        } else if (
          error.message.includes("E11000 duplicate key error collection:")
        ) {
          setUserDataErrors((prevState) => ({
            ...prevState,
            email: {
              ...prevState.email,
              hasError: true,
              errorMessage: "A user with this email already exists",
            },
          }));
        } else {
          toast.error(error.message);
        }
      }
      setIsUserAdding(false);
    }
  };

  // delete user
  const handleDeleteUser = async (id) => {
    setIsUserDeleting(true);

    const resp = await deleteUser(id);
    console.log("response of delete : ", resp);
    if (resp) {
      toast.success("User Deleted successfully");
      getUsers();
      setIsShowDeleteUserModal(false);
    }
    setIsUserDeleting(false);
  };

  const getTagsValues = (data) => {
    let allValues = [];
    for (let i = 0; i != data?.length; i++) {
      allValues.push(data[i]?.value);
    }
    return allValues;
  };

  // close modal action
  const handleCloseModal = () => {
    if (addNewShow) {
      setAddNewShow(false);
    }
    if (isShoweditUserModal) {
      setIsShowEditUserModal(false);
    }
    if (isDeleteUserModal) {
      setIsShowDeleteUserModal(false);
      setIsUserDeleting(false);
    }
    setUserData({
      firstname: "",
      lastname: "",
      email: "",
      companyId: companyId,
      userRole: "",
      tags: [],
      status: true,
    });
    setUserDataErrors({
      firstname: { hasError: false, errorMessage: "" },
      lastname: { hasError: false, errorMessage: "" },
      email: { hasError: false, errorMessage: "" },
      userRole: { hasError: false, errorMessage: "" },
      tags: { hasError: false, errorMessage: "" },
      status: { hasError: false, errorMessage: "" },
    });
    setSelTags([]);
    setUserToDel(null);
  };

  // get all users while search
  const getSearchedTemplates = (value) => {
    let finalTemplates = orgData;
    let matchedRecords = [];
    //if (value?.length > 0) {
    matchedRecords = finalTemplates?.filter(
      (item) =>
        item?.firstname?.toLowerCase()?.includes(value?.toLowerCase()) ||
        item?.lastname?.toLowerCase()?.includes(value?.toLowerCase()) ||
        item?.email?.toLowerCase()?.includes(value?.toLowerCase())
    );
    console.log(
      "matchedRecords without",
      matchedRecords,
      ":",
      selTag,
      "::",
      selStatus
    );
    if (selStatus != null) {
      matchedRecords = matchedRecords?.filter(
        (item) => item?.isapproved == selStatus
      );
    }

    if (selTag != "All") {
      let temp = [];
      for (let i = 0; i != matchedRecords?.length; i++) {
        let tagsArr = matchedRecords[i]?.tags?.map((item) =>
          item?.title?.toLowerCase()
        );
        temp.push({ ...matchedRecords[i], finalTags: tagsArr });
      }
      matchedRecords = temp;
      matchedRecords = matchedRecords?.filter((item) =>
        item?.finalTags?.includes(selTag?.toLowerCase())
      );
    }

    setAllUsers(matchedRecords);
    setSearchedText(value);
    setCurrPage(1);
    setStartIndexTable(0);
  };

  // get all users tags wise
  const getTagsWiseTemplates = (value) => {
    setSelCategory(value);
    let matchedRecords = [];
    let finalText = value == "All" ? "" : value;
    let finalUsers = orgData;
    if (searchedText?.length > 0) {
      finalUsers = orgData?.filter(
        (item) =>
          item?.firstname
            ?.toLowerCase()
            ?.includes(searchedText?.toLowerCase()) ||
          item?.lastname
            ?.toLowerCase()
            ?.includes(searchedText?.toLowerCase()) ||
          item?.email?.toLowerCase()?.includes(searchedText?.toLowerCase())
      );
    }
    console.log("finalUsers ", finalUsers);
    let temp = [];
    for (let i = 0; i != finalUsers?.length; i++) {
      let tagsArr = finalUsers[i]?.tags?.map((item) =>
        item?.title?.toLowerCase()
      );
      temp.push({ ...finalUsers[i], finalTags: tagsArr });
    }
    finalUsers = temp;

    if (finalText != "") {
      matchedRecords = finalUsers?.filter((item) =>
        item?.finalTags?.includes(finalText?.toLowerCase())
      );
    } else {
      matchedRecords = finalUsers;
    }

    if (selStatus != null) {
      matchedRecords = matchedRecords?.filter(
        (item) => item?.isapproved == selStatus
      );
    }
    setAllUsers(matchedRecords);
    setCurrPage(1);
    setStartIndexTable(0);
  };

  // get all users status wise
  const getStatusWiseTemplates = (value) => {
    setSelStatus(value == "all" ? null : value);
    let matchedRecords = [];
    let finalText = value == "all" ? null : value;
    let finalUsers = orgData;
    if (searchedText?.length > 0) {
      finalUsers = orgData?.filter(
        (item) =>
          item?.firstname
            ?.toLowerCase()
            ?.includes(searchedText?.toLowerCase()) ||
          item?.lastname
            ?.toLowerCase()
            ?.includes(searchedText?.toLowerCase()) ||
          item?.email?.toLowerCase()?.includes(searchedText?.toLowerCase())
      );
    }
    if (finalText != null) {
      matchedRecords = finalUsers?.filter((item) => item?.isapproved == value);
    } else {
      matchedRecords = finalUsers;
    }

    if (selTag != "All") {
      let temp = [];
      for (let i = 0; i != matchedRecords?.length; i++) {
        let tagsArr = matchedRecords[i]?.tags?.map((item) =>
          item?.title?.toLowerCase()
        );
        temp.push({ ...matchedRecords[i], finalTags: tagsArr });
      }
      matchedRecords = temp;
      matchedRecords = matchedRecords?.filter((item) =>
        item?.finalTags?.includes(selTag?.toLowerCase())
      );
    }

    setAllUsers(matchedRecords);
    setCurrPage(1);
    setStartIndexTable(0);
  };

  const colorStyles = {
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        fontWeight: 600,
        color: " #000000",
        fontSize: "14px",
        backgroundColor: generateColorHash(data?.value?.substring(0, 10)),
      };
    },
  };

  const currUserId = localStorage.getItem("userId");

  console.log("userData", userData);

  return (
    <>
      <Header
        handleClickAddNew={handleClickAddNew}
        roles={role}
        status={headerStatus}
        tags={allTags}
        getSearchedTemplates={(value) => getSearchedTemplates(value)}
        getTagsWiseTemplates={(value) => getTagsWiseTemplates(value)}
        getStatusWiseTemplates={(value) => getStatusWiseTemplates(value)}
      />

      <section
        className="contact-group-table py-3 d-none d-lg-block"
        style={{ marginBottom: "80px" }}
      >
        <Container>
          <Row>
            <Col xs={12}>
              {isFetching ? (
                <TableSkeleton width="100%" />
              ) : allUsers?.length > 0 ? (
                <DesktopUserListing
                  allUsers={allUsers?.slice(
                    startIndexTable,
                    startIndexTable + 10
                  )}
                  allTags={allTags}
                  handleClickEditUser={(userData) =>
                    handleClickEditUser(userData)
                  }
                  handleClickDeleteUser={(id) => handleClickDeleteUser(id)}
                />
              ) : (
                <NoData isEmpty={true} />
              )}
            </Col>
          </Row>
        </Container>
      </section>

      <section className="main inner-main template-listing py-3 d-lg-none">
        <ManageUsermob
          allUsers={allUsers?.slice(startIndexTable, startIndexTable + 10)}
          allTags={allTags}
          handleClickEditUser={(userData) => handleClickEditUser(userData)}
          handleClickDeleteUser={(id) => handleClickDeleteUser(id)}
        />
      </section>

      {/* add/edit user modal */}
      <Modal
        show={addNewShow || isShoweditUserModal}
        onHide={handleCloseModal}
        className="whatsapp-modal modal-contact manage-users"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title>
            {isShoweditUserModal ? "Edit User" : "Add User"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  disabled={
                    isShoweditUserModal &&
                    userData?.userRole == "owner" &&
                    currUserId != userData?.userId
                  }
                  placeholder="Adam"
                  name="firstname"
                  onChange={inputChangeHandler}
                  value={userData?.firstname}
                  className={
                    userDataErrors.firstname.hasError ? "is-invalid" : ""
                  }
                  required={true}
                />
                <Form.Control.Feedback type="invalid">
                  {userDataErrors.firstname.hasError &&
                    userDataErrors.firstname.errorMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  disabled={
                    isShoweditUserModal &&
                    userData?.userRole == "owner" &&
                    currUserId != userData?.userId
                  }
                  placeholder="Smith"
                  name="lastname"
                  onChange={inputChangeHandler}
                  value={userData?.lastname}
                  className={
                    userDataErrors.lastname.hasError ? "is-invalid" : ""
                  }
                  required={true}
                />
                <Form.Control.Feedback type="invalid">
                  {userDataErrors.lastname.hasError &&
                    userDataErrors.lastname.errorMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  disabled={isShoweditUserModal}
                  style={{
                    backgroundColor: isShoweditUserModal ? "#e9e9e9" : "",
                  }}
                  placeholder="email@example.com"
                  name="email"
                  onChange={inputChangeHandler}
                  value={userData?.email}
                  className={userDataErrors.email.hasError ? "is-invalid" : ""}
                  required={true}
                />
                <Form.Control.Feedback type="invalid">
                  {userDataErrors.email.hasError &&
                    userDataErrors.email.errorMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Role</Form.Label>
                <Select
                  className="c-select basic-multi-select"
                  isDisabled={
                    isShoweditUserModal && userData?.userRole == "owner"
                  }
                  options={role}
                  placeholder={"Select role"}
                  classNamePrefix={`select`}
                  onChange={(value) =>
                    inputChangeHandler({
                      name: "userRole",
                      value: value?.value,
                    })
                  }
                  value={{
                    label: userData?.userRole,
                    value: userData?.userRole,
                  }}
                  required={true}
                />
                <p style={{ color: "#dc3545", fontSize: "13px" }}>
                  {(userDataErrors?.userRole?.hasError ||
                    userDataErrors?.role?.hasError) &&
                    (userDataErrors?.userRole?.errorMessage ||
                      userDataErrors?.role?.errorMessage)}
                </p>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Assign Tags</Form.Label>
                <Select
                  styles={colorStyles}
                  isMulti
                  isDisabled={
                    isShoweditUserModal &&
                    userData?.userRole == "owner" &&
                    currUserId != userData?.userId
                  }
                  className="c-select basic-multi-select"
                  options={allNotHeaderTags}
                  placeholder={"Select Tags"}
                  classNamePrefix={`select ${userDataErrors?.tagIds?.hasError} ? "is-invalid" : ""`}
                  onChange={(value) => {
                    inputChangeHandler({
                      name: "tagIds",
                      value: getTagsValues(value),
                    });
                    setSelTags(value);
                  }}
                  value={selTags}
                  required={true}
                />
                <p style={{ color: "#dc3545", fontSize: "13px" }}>
                  {userDataErrors?.tagIds?.hasError &&
                    userDataErrors?.tagIds?.errorMessage}
                </p>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Select
                  className="c-select basic-multi-select"
                  isDisabled={
                    isShoweditUserModal && userData?.userRole == "owner"
                  }
                  options={status}
                  placeholder={"Select status"}
                  classNamePrefix={`select ${userDataErrors.status.hasError} ? "is-invalid" : ""`}
                  onChange={(value) =>
                    inputChangeHandler({ name: "status", value: value?.value })
                  }
                  value={{
                    label: userData?.status ? "Active" : "Inactive",
                    value: userData?.status,
                  }}
                  required={true}
                />
                <p style={{ color: "#dc3545", fontSize: "13px" }}>
                  {userDataErrors.status.hasError &&
                    userDataErrors.status.errorMessage}
                </p>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button className="btn-main-default" onClick={handleCloseModal}>
            Cancel
          </Button>
          {isUserAdding ? (
            <Button
              className="btn-main"
              style={{ backgroundColor: "#165E5B", minWidth: "133px" }}
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ color: "#FFFFFF" }}
              />
            </Button>
          ) : (
            <Button
              className="btn-main"
              onClick={
                !isUserAdding
                  ? isShoweditUserModal &&
                    userData?.userRole == "owner" &&
                    currUserId != userData?.userId
                    ? null
                    : addOrUpdateUser
                  : null
              }
            >
              {isShoweditUserModal ? "Edit User" : "Add User"}
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      {/* delete modal */}
      <Modal
        show={isDeleteUserModal}
        onHide={handleCloseModal}
        className="whatsapp-modal confirm-modal modal-contact manage-users"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title>Delete?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-content">
            You are about to delete User{" "}
            <span className="u-name text-600" style={{ fontWeight: 600 }}>
              “{userToDel?.name}“ ?
            </span>{" "}
            This process cannot be reversed.
          </div>
        </Modal.Body>
        <Modal.Footer className="px-0">
          {isUserDeleting ? (
            <Button
              variant="danger-light"
              style={{ width: "160px" }}
              onClick={() => handleDeleteUser(userToDel?.id)}
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ color: "red" }}
              />
            </Button>
          ) : null}
          {!isUserDeleting ? (
            <Button
              variant="danger-light"
              onClick={() => handleDeleteUser(userToDel?.id)}
            >
              Yes, Delete it
            </Button>
          ) : null}
          <Button variant="danger" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Footer
        hideView={handleCloseModal}
        setCurrPage={setCurrPage}
        templates={allUsers}
        currPage={currPage}
        setStartIndexTable={setStartIndexTable}
      />
    </>
  );
};

export default ManageUser;
