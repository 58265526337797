import axios from 'axios'
import config from '../../config'

export const getAllWidgets = async (userId, chatId) => {
    const response = await axios.get(`${config.backend}widget/get`)
    return response.data
}

export const addNewWidget = async (data) => {
    const response = await axios.post(`${config.backend}widget/create`, data)
    return response.data
}

export const updateWidget = async (data) => {
    const response = await axios.put(`${config.backend}widget/edit`, data)
    return response.data
}