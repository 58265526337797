import { configureStore } from '@reduxjs/toolkit'
import counterReucer from './reduxSlice/counterSlice'
import MessageNotificationReducer from './reduxSlice/messageNotificationSlice'
import ChatsReducer from "./reduxSlice/chats"


export const store = configureStore({
    reducer: {
        counter: counterReucer,
        MessageNotification: MessageNotificationReducer,
        ChatsReducer: ChatsReducer
    },
})