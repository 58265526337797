
import React, { useState } from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Wtable from '../table/table';
import Useractionbtn from './user-actionbtn';
import Placeholdertable from '../loader/loading-table';


const Usertable = ({ data, submitDelete, submitEdit, loading }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5);

  const theadData = ["Name", "Email", "Role", "Status", "Actions"];

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = data.filter(d => !d.isdeleted).slice(indexOfFirstUser, indexOfLastUser);

  const totalPages = Math.ceil(data.filter(d => !d.isdeleted).length / usersPerPage);
  if (currentPage > totalPages && totalPages !== 0)
    setCurrentPage(totalPages);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = () =>
    pageNumbers.map((number) => (
      <li className="nav-item" key={number}>
        <Button
          className={`table-btn ${currentPage === number ? 'active' : ''}`}
          onClick={() => setCurrentPage(number)}
        >
          {number}
        </Button>
      </li>
    ));

  const tbodyData1 = currentUsers.map((d, index) => ({
    id: index,
    items: [d.firstname + " " + d.lastname, d.email, d.companyadmin ? "Admin" : "User", d.isapproved ? <span><span className="dot active"></span>Active</span> : <span><span className="dot inactive"></span>Inactive</span>, !d.companyadmin && <Useractionbtn data={d} submitDelete={submitDelete} submitEdit={submitEdit} />],
  }));

  return (
    <>
      {!loading ? <div className="cg-custom-table">
        <Wtable theadData={theadData} tbodyData={tbodyData1} />
      </div> :
        <Placeholdertable theadData={theadData} />
      }

      {data.length > 0 && <Row className="py-3 py-md-4">
        <Col sm={12}>
          <ul className="nav justify-content-center align-items-center">
            <li className="nav-item">
              <Button
                className="table-btn"
                onClick={() =>
                  setCurrentPage(
                    (prevPage) => (prevPage === 1 ? prevPage : prevPage - 1)
                  )
                }
              >
                <i className="bi bi-chevron-left"></i>
              </Button>
            </li>
            {renderPageNumbers()}
            <li className="nav-item">
              <Button
                className="table-btn"
                onClick={() =>
                  setCurrentPage(
                    (prevPage) =>
                      prevPage === totalPages
                        ? prevPage
                        : prevPage + 1
                  )
                }
              >
                <i className="bi bi-chevron-right"></i>
              </Button>
            </li>
          </ul>
        </Col>
      </Row>}
    </>
  )
}

export default Usertable;