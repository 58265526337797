import { toast } from "react-toastify";

export const handleApiError = (error) => {
  console.error("An error occurred:", error);

  if (error.response) {
    console.log("Server responded with:", error.response.status);
    toast.error(error.response.data.message);
    return error.response;
  } else if (error.request) {
    console.log("Request was made, but no response received");
    toast.error("Server overflow detected, please hold on or refresh");
  } else {
    console.log("Error occurred during request setup:", error.message);
    toast.error("There was an error in the system, please refresh");
  }
};
