
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import './form.css';

const Reset_form = ({ heading, tokenStatus, reset, updated }) => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/resetpassword');
    };

    const [password, setPassword] = useState({ pass1: "", pass2: "" })


    const handleSubmit = (event) => {
        event.preventDefault();

        if (password.pass1 !== password.pass2) {
            alert("Passwords do not match");
            return;
        }
        if (password.pass1 == "") {
            alert("Passwords cannot be empty");
            return;
        }
        if (password.pass1.length < 8) {
            alert("Passwords cannot be less than 8 digit");
            return;
        }
        reset(password.pass1)

    };


    return (
        <div className="form-sec">
            <Form>
                <div className="form-heading text-center">
                    {heading}
                </div>
                {tokenStatus == "valid" && (<><Form.Group className="form-group">
                    <Form.Label>Enter password*</Form.Label>
                    <Form.Control type="password" placeholder="" value={password.pass1} onChange={(e) => setPassword({ ...password, pass1: e.target.value })} />
                </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Label>Confirm password*</Form.Label>
                        <Form.Control type="password" placeholder="" value={password.pass2} onChange={(e) => setPassword({ ...password, pass2: e.target.value })} />
                    </Form.Group>
                    {updated === true && <Form.Group className="text-center last-pra" style={{ marginBottom: '10px', color: 'green' }}>
                        Password updated Successfully ✔
                    </Form.Group>}
                    <Form.Group className="form-group">
                        {updated === false ? <Button className="btn btn-main" onClick={handleSubmit}>
                            Reset Password
                        </Button> : <></>}
                    </Form.Group></>)}
                {tokenStatus == "expired" && (
                    <>
                        <Form.Group className="text-center last-pra" style={{ marginBottom: '30px', color: 'orange' }}>
                            Your Link is expired, Click on Reset Password to generate a new link.
                        </Form.Group>
                        <Button className="btn btn-main" onClick={handleClick}>
                            Reset Password
                        </Button>
                    </>
                )}
                {tokenStatus == "invalid" && (
                    <>
                        <Form.Group className="text-center last-pra" style={{ marginBottom: '30px', color: 'red' }}>
                            Your Link is invalid, Click on Reset Password to generate a valid password reset link.
                        </Form.Group>
                        <Button className="btn btn-main" onClick={handleClick}>
                            Reset Password
                        </Button>
                    </>
                )}

                <Form.Group className="text-center last-pra">
                    <Link to="/login">Back to login</Link>
                </Form.Group>
            </Form>
        </div>
    );
}

export default Reset_form;