
import React, { useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import { countryCodeList } from '../../utils/countryCodeList';

const Actionbutton = ({ contact, handleDelete, handleEdit }) => {

    const [dtshow, setDtShow] = useState(false);
    const [ctshow, setCtShow] = useState(false);
    const [newContactData, setNewContactData] = useState(contact)
    const [inputErrors, setInputErrors] = useState({
        contactName: { hasError: false, errorMessage: '' },
        number: { hasError: false, errorMessage: '' }
    });

    useEffect(() => {
        if (!ctshow) {
            setInputErrors({
                contactName: { hasError: false, errorMessage: '' },
                number: { hasError: false, errorMessage: '' }
            })
        }
    }, [ctshow])

    const deleteContact = () => {
        setDtShow(false)
        handleDelete(contact._id)
    }


    const isPhoneNumValid = () => {
        const num = newContactData.number;
        const numericRegex = /^[0-9]+$/;

        if (!numericRegex.test(num)) {
            setInputErrors(prevState => ({
                ...prevState,
                number: {
                    ...prevState.number,
                    hasError: true,
                    errorMessage: "Enter a valid contact number"
                }
            }));
            return false
        }
        else {
            const countryNumberDetail = countryCodeList.filter((code) => {
                if (num.startsWith(code.phone))
                    return code;
            })
            if (countryNumberDetail.length > 0) {
                const totalLength = countryNumberDetail[0].phone.length + countryNumberDetail[0].phoneLength;
                if (countryNumberDetail.length > 0) {
                    if (num.length === totalLength) {
                        return true;
                    }
                }
                setInputErrors(prevState => ({
                    ...prevState,
                    number: {
                        ...prevState.number,
                        hasError: true,
                        errorMessage: `Phone number length for ${countryNumberDetail[0].label} is ${totalLength} `
                    }
                }));
            }

        }

        // else if (num.length < 7) {
        //     setInputErrors(prevState => ({
        //         ...prevState,
        //         number: {
        //             ...prevState.number,
        //             hasError: true,
        //             errorMessage: "Phone number cannot be less than 7 digits"
        //         }
        //     }));
        //     return false
        // }
        // else if (num.length > 15) {
        //     setInputErrors(prevState => ({
        //         ...prevState,
        //         number: {
        //             ...prevState.number,
        //             hasError: true,
        //             errorMessage: "Phone number cannot be greater than 15 digits"
        //         }
        //     }));
        //     return false
        // }
        return false
    }


    const handleEditContact = async () => {
        const check = isPhoneNumValid()
        if (!check)
            return
        const res1 = await handleEdit(newContactData)
        console.log("res1 ===", res1)
        if (!res1) {
            setCtShow(false)
        }
        else {
            if (res1.error.includes("Enter a valid contact name")) {
                setInputErrors(prevState => ({
                    ...prevState,
                    contactName: {
                        ...prevState.contactName,
                        hasError: true,
                        errorMessage: "Enter a valid contact name"
                    }
                }))
            }
            if (res1.error.includes("Enter a valid phone number")) {
                setInputErrors(prevState => ({
                    ...prevState,
                    number: {
                        ...prevState.number,
                        hasError: true,
                        errorMessage: "Enter a valid contact number"
                    }
                }))
            }
            if (res1.error.includes("A contact with this name already exists")) {
                setInputErrors(prevState => ({
                    ...prevState,
                    contactName: {
                        ...prevState.contactName,
                        hasError: true,
                        errorMessage: "A contact with this name already exists"
                    }
                }))
            }
            if (res1.error.includes("A contact with this number already exists")) {
                setInputErrors(prevState => ({
                    ...prevState,
                    number: {
                        ...prevState.number,
                        hasError: true,
                        errorMessage: "A contact with this number already exists"
                    }
                }))
            }
        }
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInputErrors(prevState => ({
            ...prevState,
            [name]: { hasError: false, errorMessage: '' }

        }));
        setNewContactData((prevValues) => ({ ...prevValues, [name]: value }));
    };

    return (
        <>
            <div className='d-flex align-items-center flex-row'>
                <button className="ded-btn" onClick={() => setCtShow(true)}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.75 12.25H2.8L9.26875 5.78125L8.21875 4.73125L1.75 11.2V12.25ZM12.475 4.69375L9.2875 1.54375L10.3375 0.49375C10.625 0.20625 10.9783 0.0625 11.3973 0.0625C11.8158 0.0625 12.1687 0.20625 12.4562 0.49375L13.5062 1.54375C13.7937 1.83125 13.9437 2.17825 13.9562 2.58475C13.9688 2.99075 13.8313 3.3375 13.5438 3.625L12.475 4.69375ZM11.3875 5.8L3.4375 13.75H0.25V10.5625L8.2 2.6125L11.3875 5.8ZM8.74375 5.25625L8.21875 4.73125L9.26875 5.78125L8.74375 5.25625Z" fill="black" fillOpacity={'0.6'} />
                    </svg>
                </button>
                <button className="ded-btn" onClick={() => setDtShow(true)}>
                    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.25 13.75C1.8375 13.75 1.4845 13.6033 1.191 13.3097C0.897 13.0157 0.75 12.6625 0.75 12.25V2.5H0V1H3.75V0.25H8.25V1H12V2.5H11.25V12.25C11.25 12.6625 11.1033 13.0157 10.8097 13.3097C10.5157 13.6033 10.1625 13.75 9.75 13.75H2.25ZM9.75 2.5H2.25V12.25H9.75V2.5ZM3.75 10.75H5.25V4H3.75V10.75ZM6.75 10.75H8.25V4H6.75V10.75Z" fill="black" fillOpacity={'0.6'} />
                    </svg>
                </button>
            </div>

            <Modal show={dtshow} onHide={() => setDtShow(false)} className="whatsapp-modal confirm-modal modal-contact" centered>
                <Modal.Header className="p-0">
                    <Modal.Title>Confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-content">
                        You are about to delete <span className="u-name"><b>{` “${contact?.contactName?.trim()}”`}</b></span> from your contacts. This process cannot be reversed.
                    </div>
                </Modal.Body>
                <Modal.Footer className="px-0">
                    <Button variant="danger-light" onClick={() => deleteContact()}>
                        Yes, Delete it
                    </Button>
                    <Button variant="danger" onClick={() => setDtShow(false)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={ctshow} onHide={() => setCtShow(false)} className="whatsapp-modal modal-contact" centered>

                <Modal.Header className="p-0">
                    <Modal.Title>Edit Contact</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row>
                        <Col sm={6}>

                            <Form.Group>
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder={contact.contactName} name="contactName" value={newContactData.contactName} onChange={handleInputChange} className={inputErrors.contactName.hasError ? "is-invalid" : ""} />
                                <Form.Control.Feedback type="invalid">
                                    {inputErrors.contactName.hasError && inputErrors.contactName.errorMessage}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label>Phone No</Form.Label>
                                <Form.Control type="text" placeholder={contact.number} name="number" value={newContactData.number} onChange={handleInputChange} className={inputErrors.number.hasError ? "is-invalid" : ""} />
                                <Form.Control.Feedback type="invalid">
                                    {inputErrors.number.hasError && inputErrors.number.errorMessage}
                                </Form.Control.Feedback>

                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="px-0">
                    <Button className="btn-main-default" onClick={() => setCtShow(false)}>
                        Cancel
                    </Button>
                    <Button className="btn-main" onClick={handleEditContact}>
                        Update
                    </Button>
                </Modal.Footer>

            </Modal>
        </>
    );
};

export default Actionbutton;