import { useState, useEffect } from "react";
import axios from "axios";
import WidgetForm from "../components/widgetCreation/widgetForm";
import PageHeader from "../components/widgetCreation/header";
import ChatUs from "../components/widgetCreation/chatWithUs";
import { addNewWidget, updateWidget } from "../services/widget";
import config from "../config.js";
import { getPhoneNumber } from "../services/Company";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const baseUrl = `${config.backend}tags?page=${1}&limit=${1000}`;

const Websitewidget = (props) => {
  const location = useLocation();
  const [isApiCalling, setIsApiCalling] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [allOrgTags, setAllOrgTags] = useState([]);
  const [widgetStyles, setWidgetStyles] = useState(
    location?.state?.widgetData || {
      chatButton: {
        whatsAppNo: "",
        bgColor: "#4DC247",
        text: "",
        borderRadius: "",
        marginLeft: "20",
        marginRight: "20",
        marginBottom: "20",
      },
      widgetDetails: {
        headerBgColor: "#2ABC65",
        title: "",
        desc: "",
        CTAs: [{ text: "", tag: "" }],
      },
      position: "right",
    }
  );

  // getting all tags
  const getAllTags = async () => {
    const { data } = await axios.get(`${baseUrl}`);
    if (data?.allTags?.length > 0) {
      setAllOrgTags(data?.allTags);
      let temp = [];
      for (let i = 0; i != data?.allTags?.length; i++) {
        temp.push({
          label: data?.allTags[i]?.title,
          value: data?.allTags[i]?.tagKey,
        });
      }

      setAllTags(temp);
    }
  };

  // get company phone no
  const getCompanyPhoneNo = async () => {
    try {
      const companyId = localStorage.getItem("companyId");
      const resp = await getPhoneNumber(companyId);
      if (resp.success) {
        const cleanedNumber = resp.number.replace(/\D/g, "");
        setWidgetStyles((prev) => ({
          ...prev,
          chatButton: { ...prev.chatButton, whatsAppNo: cleanedNumber },
        }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCompanyPhoneNo();
    getAllTags();
  }, []);

  const getMatchedTag = (tagKey) => {
    let isFound = allOrgTags?.find(
      (item) => item?.tagKey == tagKey || item?._id == tagKey
    );
    console.log(" finallll", tagKey, ":::", isFound);
    return isFound?._id;
  };

  // get CTAs for sending
  const getCTAsForSending = () => {
    let CTAs = [];

    for (let i = 0; i != widgetStyles?.widgetDetails?.CTAs?.length; i++) {
      CTAs.push({
        text: widgetStyles?.widgetDetails?.CTAs[i]?.text,
        tag: getMatchedTag(widgetStyles?.widgetDetails?.CTAs[i]?.tag),
      });
    }
    console.log("final VTAs", CTAs);

    const isEmpty = CTAs?.find(
      (item) => item?.text == "" || item?.tag == undefined
    );
    if (isEmpty) {
      toast.error("Please provide all required CTAs");
      return null;
    } else {
      return CTAs;
    }
  };

  // set widget data for creating widget
  const getWidgetDataForCreating = () => {
    const chatButton = {
      phone_number: widgetStyles?.chatButton?.whatsAppNo,
      color: widgetStyles?.chatButton?.bgColor,
      btn_text: widgetStyles?.chatButton?.text,
      radius: widgetStyles?.chatButton?.borderRadius,
      margin: {
        left: widgetStyles?.chatButton?.marginLeft,
        right: widgetStyles?.chatButton?.marginRight,
        bottom: widgetStyles?.chatButton?.marginBottom,
      },
    };

    const chatWidget = {
      name: widgetStyles?.widgetDetails?.title,
      desc: widgetStyles?.widgetDetails?.desc,
      color: widgetStyles?.widgetDetails?.headerBgColor,
      //image_url: String,
      // CTA: widgetStyles?.widgetDetails?.CTAs,
      CTA: getCTAsForSending(),
    };

    if (chatWidget?.CTA == null) {
      return null;
    }

    const finalWidgetData = {
      button: chatButton,
      widget: chatWidget,
      position: widgetStyles?.position,
    };

    console.log("finalWidgetData", finalWidgetData);

    return finalWidgetData;
  };

  // validate widget data
  const checkIfWidgetDataIsValid = () => {
    if (widgetStyles?.chatButton?.whatsAppNo == "") {
      toast.error("Phone is required");
      return false;
    }

    if (widgetStyles?.widgetDetails?.title == "") {
      toast.error("Widget Title is required");
      return false;
    }
    if (widgetStyles?.widgetDetails?.desc == "") {
      toast.error("Widget Description is required");
      return false;
    }

    if (widgetStyles?.chatButton?.text?.length > 40) {
      toast.error("Please see validation errors");
      return false;
    }
    if (widgetStyles?.widgetDetails?.title?.length > 60) {
      toast.error("Please see validation errors");
      return false;
    }
    if (widgetStyles?.widgetDetails?.desc?.length > 100) {
      toast.error("Please see validation errors");
      return false;
    }
    if (
      widgetStyles?.widgetDetails?.CTAs?.find(
        (item) => item?.text == "" || item?.tag == ""
      )
    ) {
      toast.error("Please provide data for all buttons and their tags");
      return false;
    }
    if (
      widgetStyles?.widgetDetails?.CTAs?.find((item) => item?.text?.length > 60)
    ) {
      toast.error("Please see validation errors");
      return false;
    }

    return true;
  };

  // create widget
  const createWidget = async () => {
    if (checkIfWidgetDataIsValid()) {
      let widgetDData = getWidgetDataForCreating();
      if (widgetDData == null) {
        return;
      }
      setIsApiCalling(true);
      const resp = await addNewWidget({
        ...widgetDData,
        type: "utter-wa-widget",
      });
      console.log("resp of create widget", resp);
      if (resp?.success) {
        toast.success("Widget Created Successfully");
        setIsApiCalling(false);
        return { success: true, widgetId: resp?.data };
      } else {
        toast.error(resp?.error);
        setIsApiCalling(false);
        return { success: false };
      }
    }
  };

  // updated widget data
  const updateSingleWidget = async () => {
    if (checkIfWidgetDataIsValid()) {
      let widgetDData = getWidgetDataForCreating();
      if (widgetDData == null) {
        return;
      }
      setIsApiCalling(true);
      const resp = await updateWidget({
        ...widgetDData,
        widgetId: location?.state?.widgetData?.id,
        type: location?.state?.widgetData?.type,
      });
      console.log("resp of update widget", resp);
      if (resp?.success) {
        toast.success("Widget Updated Successfully");
        setIsApiCalling(false);
        return { success: true, widgetId: resp?.data?._id };
      } else {
        toast.error(resp?.error);
        setIsApiCalling(false);
        return { success: false };
      }
    }
  };

  return (
    <>
      <PageHeader
        Pagename={"WhatsApp Website Widget"}
        Backpage={"Integrations"}
        Currentpage={"WhatsApp Website Widget"}
        Linkpage={"/widgets"}
      />

      <WidgetForm
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isApiCalling={isApiCalling}
        updateWidget={updateSingleWidget}
        isEdit={location?.state?.widgetData}
        allTags={allTags}
        allOrgTags={allOrgTags}
        createWidget={createWidget}
        widgetStyles={widgetStyles}
        setWidgetStyles={setWidgetStyles}
      />

      <ChatUs
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        widgetStyles={widgetStyles}
      />
    </>
  );
};

export default Websitewidget;
