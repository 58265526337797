import React from "react";

import '../group-table/group-contact.css';

const ContactGroup = ({Image, name, email, Usertag}) => {
    return (
        <>
            <ul className = "nav align-items-center">
                <li className = "nav-item">
                    <div className = "user-img">
                        <img src = {Image} alt = "user-img"/>
                    </div>
                </li>
                <li className = "nav-item">
                    <div className = "u-name">
                        <div class = "uu-name">
                            {name}
                        </div>
                        <div className = "user-email">
                            {email}
                        </div>
                        <div className = "user-tag">
                            {Usertag}
                        </div>
                    </div>
                </li>
            </ul>
        </>
    )
}

export default ContactGroup;