
import Accordion from "react-bootstrap/Accordion"

import "./accordion.css"

const Faqaccordion = ({title, description,handleAccordionClick,expanded}) => {
    return (
        <>
            <Accordion activeKey={expanded ? "0" : null} onSelect={handleAccordionClick}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        {title}
                    </Accordion.Header>
                    <Accordion.Body>
                        {description}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}

export default Faqaccordion;