import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import "./pricecard.css";

const Pricecard = ({feetitle,amount,buttontext,point_a,point_b,point_c,point_d,selectedOption}) => {
    return (
        <>
            <Card>
                <Card.Body>
                    <div className = "price-header">
                        <div className = "title">
                            {feetitle}
                        </div>
                        <div className = "price">
                           <ul className = "nav align-items-center">
                                <li className = "nav-item">
                                    <div className = "amount">
                                        {amount}
                                    </div>
                                </li>
                                <li className = "nav-item">
                                    Per {selectedOption==="monthly"?"month":"year"}
                                </li>
                           </ul>
                        </div>
                    </div>
                    <div className = "price-body">
                        <div className = "body-title">Free includes:</div>
                        <ul className = "nav flex-column">
                            <li className = "nav-item">
                                {point_a}
                            </li>
                            <li className = "nav-item">
                                {point_b}
                            </li>
                            <li className = "nav-item">
                                {point_c}
                            </li>
                            <li className = "nav-item">
                                {point_d}
                            </li>
                        </ul>
                    </div>
                    <div className = "price-footer">
                        <Button variant = "main">
                            {buttontext}
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}

export default Pricecard;