import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allCompUsers: [],
    totalRecords: 0
};

export const UsersSlices = createSlice({
    name: "Users",
    initialState,
    reducers: {
        // set all allCompUsers
        setAllCompUsersData: (state, action) => {
            state.allCompUsers = Object.values(action.payload.data);
            state.totalRecords = action.payload.totalRecords;
        },
        // empty all allCompUsers
        removeAllCompUsersData: (state, action) => {
            state.allCompUsers = [];
            state.totalRecords = 0;
        }
    },
});

// Action creators are generated for each case reducer function
export const {
    setAllCompUsersData,
    removeAllCompUsersData,
} = UsersSlices.actions;

export default UsersSlices.reducer;
