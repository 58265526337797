

export function PrivacyPolicy() {

    return (
        <div>
            <div style={{
                width: "100%",
                backgroundColor: "#228581",
                height: "234px",
                top: "60px",
            }}>
                <p style={{
                    fontFamily: "Segoe UI",
                    fontSize: "48px",
                    fontWeight: 700,
                    lineHeight: "64px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    marginLeft: "10%",
                    paddingTop: "5%",
                    color: "white"
                }}>Privacy Policy</p>
                <div style={{ width: "53px", top: "203px", marginLeft: "10%", border: "4px solid #5FD495", }}>

                </div>
            </div>

            <div className="privacy-policy">
                <p style={{
                    fontFamily: "Segoe UI",
                    fontSize: "18px",
                    fontWeight: 400,
                    lineHeight: "32px",
                    letterSpacing: "0em",
                    textAlign: "left",
                }}>
                    <h4>Introduction</h4>
                    When utilizing the UTTER app provided by MWAN MOBILE, you entrust us with your information, and we are committed to safeguarding it and ensuring your control over it.

                    This Privacy Policy elucidates the data we collect, the reasons for collection, and measures you can take to protect your information.

                    In this document, the following terms apply:

                    MWAN MOBILE ("UTTER," "we," "us," or "our"): Refers to the entity responsible for collecting and processing Personal Data for the purposes outlined in this Policy.
                    <br />   <br />
                    <h4>What is UTTER</h4>
                    UTTER is a communication tool developed by MWAN MOBILE. The app acts as a Conversational Cloud Platform, specifically tailored for WhatsApp Business, allowing users to manage, analyze, and transfer messages across various messaging channels. MWAN MOBILE is dedicated to safeguarding Personal Data, both for its internal use and on behalf of its users.
                    <br />   <br />
                    <h4>Collection and Use of Personal Data</h4>
                    UTTER collects information, including Personal Data, for the following purposes:
                    <br /> <br />
                    <h5>Providing and managing the Service</h5>
                    Internal business purposes (communication, marketing, personnel management)
                    Collecting payment for the Service
                    Understanding and improving the Service and Website
                    To enhance user experience and deliver our Service, we may request personally identifiable information such as the user's name, email address, and phone number. This information is stored securely on our servers (https://utter.mwancloud.com/) and is solely used for user identification and content delivery within the app.

                    UTTER may utilize third-party services that collect information for user identification. Privacy policies of these third-party service providers are accessible via the following links:

                    Facebook Sign-in Service (Facebook Privacy Policy)
                    This Policy does not impose restrictions on aggregated or de-identified data that is no longer associated with identifiable individuals or UTTER users.
                    <br />  <br />
                    <h5>Cloud API</h5>
                    UTTER communicates with Cloud API to store and transmit messages to the WhatsApp platform.
                    <br /> <br />
                    <h5>Providing and Managing the Service</h5>
                    In the course of providing the Service, UTTER may receive, access, analyze, process, and maintain Personal Data on behalf of its users. Users determine the types of Personal Data collected, used, and disclosed within the Service.
                    <br /> <br />
                    <h5>Service Data Privacy Statement</h5>
                    The privacy practices related to Service Data are detailed in the UTTER Service Data Privacy Statement, explaining how personal data is collected through the website, stored in secure cloud servers, and encrypted to ensure security. Users can request account data deletion by emailing support@mwanmobile.com, and upon termination, all data is permanently deleted unless otherwise requested.
                    <br /> <br />
                    <h4>Account Information</h4>
                    Account Information, including contact details, user profile information, and payment method information, is collected during registration for managing payments, providing support, and facilitating communication. Privacy practices related to Account Information are detailed in this Privacy Policy.
                    <br /><br />
                    <h5>Service Usage Information</h5>
                    Service Usage Information, detailing how users access and utilize the Service, is collected to understand and improve services, as well as investigate and prevent security issues, abuse, and fraud.
                    <br /><br />
                    <h5>Internal Business Purposes</h5>
                    For internal business purposes, UTTER collects information for communicating, marketing, recruiting, and managing personnel. This includes responding to product demo requests, inquiries, and informing users about products and services.
                    <br /><br />
                    <h5>Collecting Payment for the Service</h5>
                    For clients using the paid version, UTTER collects and processes information about Service usage and Account Information for billing purposes.
                    <br /><br />
                    <h5>To Understand and Improve our Services and Website</h5>
                    Understanding Service usage and improving services is facilitated through collecting Service Usage Information. Additionally, IP addresses and related information are collected to monitor and enhance website activity.
                    <br /><br />
                    <h5>Disclosure of Personal Data</h5>
                    UTTER does not disclose, trade, rent, sell, or transfer Personal Data unless required for specific purposes, such as service provider arrangements, changes in business structure, compliance with laws, or enforcing rights, preventing fraud, and ensuring safety.
                    <br /><br />
                    <h4>Your Rights</h4>
                    Users can request access, updating, or correction of Personal Data submitted through the website by sending a written request. UTTER takes steps to protect user data and provides details in the Security Practices document.
                    <br /><br />
                    <h5>Cookie Policy</h5>
                    UTTER uses cookies for recording log data. Both session-based and persistent cookies are employed, with session-based cookies deleted upon closing the browser. Users can manage cookie settings through their browsers, but disabling cookies may limit Service usage.

                    Third-party links on the UTTER website are provided for user convenience, and UTTER is not responsible for the privacy policies of these external websites.
                    <br /><br />
                    <h5>Data Deletion</h5>
                    We respect your privacy and are committed to protecting your personal data. If you have provided us with your personal information through any custom login or social login, you can request data deletion by contacting us at support@mwanevents.com. We will promptly respond to your request and ensure that your personal data is deleted from our records and systems in accordance with any custom login or social login policies and guidelines.
                    <br /><br />
                    <h5>Updates to this Privacy Policy</h5>
                    This Privacy Policy may be periodically updated, and the revised version will be posted on the website. Continued use of the Service or website after changes implies agreement with the revised Policy.
                    <br /><br />
                    <h5>Contact Us</h5>
                    For questions or comments about this Privacy Policy, access requests, or other concerns, contact the UTTER Compliance Officer at support@mwanmobile.com.
                </p>
            </div>
        </div >
    )
}   