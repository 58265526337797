import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Ratio from "react-bootstrap/Ratio";

import Faqaccordion from "../components/accordion/accordion";

const Helpcetner = () => {
  // State to keep track of the currently expanded accordion item
  const [expandedItem, setExpandedItem] = useState(null);

  // Function to handle accordion item click and update the state
  const handleAccordionClick = (index) => {
    if (expandedItem === index) {
      // If the clicked item is already expanded, collapse it
      setExpandedItem(null);
    } else {
      // Otherwise, expand the clicked item
      setExpandedItem(index);
    }
  };



  return (
    <section className="main faq">
      <Container>
        <h1>Frequently Asked Questions</h1>
        <p>Learn what to do if you're having trouble with setting up your account.</p>
        <Row className="justify-content-center">
          <Col sm="12" xl={6}>
            <Faqaccordion
              title={"How do I get started and create my account?"}
              description={
                <>
                  <p>
                    If you are a business and want to register with our Whatsapp client,
                    the best way to do this is with the Whatsapp Embedded Signup Flow.
                  </p>
                  <p>
                    Watch the video down below and follow the steps in order to send as many messages
                    as you want through us.
                  </p>
                  <Row className="py-3">
                    <Col sm={12}>
                      <Ratio aspectRatio="16x9">
                        <iframe src="https://www.youtube.com/embed/fEsfktmuVOg?rel=0" title="YouTube video" allowFullScreen></iframe>
                      </Ratio>
                    </Col>
                  </Row>
                </>
              }
              expanded={expandedItem === 0}
              handleAccordionClick={() => { handleAccordionClick(0) }}
            />
            <Faqaccordion
              title={"Do you provide any subscription plans?"}
              description={
                <>
                  <p>
                    Once you have registered your company with us, you can go into your profile menu and find the subscription plans.
                  </p>
                  <p>
                    We provide a number of diverse subscription plans attending to the needs of businesses of all scales.
                  </p>
                </>
              }
              expanded={expandedItem === 1}
              handleAccordionClick={() => { handleAccordionClick(1) }}
            />
            <Faqaccordion
              title={"Every individual is allowed to send a broadcast message?"}
              description={
                <>
                  <p>
                    Once you register your company on our APP and it's approved by an admin,
                    your primary email, which you used while registering your company,
                    gets an admin dashboard from where you can add more users and give specific permissions to each user.
                  </p>
                </>
              }
              expanded={expandedItem === 2}
              handleAccordionClick={() => { handleAccordionClick(2) }}
            />
            <Faqaccordion
              title={"Is there any template or message limit?"}
              description={
                <>
                  <p>
                    Yes, there are limits to your messages and template creation, and it all depends on your subscription plan.
                  </p>
                  <p>
                    We provide multiple subscription plans which have different limits to the amount of user-initiated conversations you can reply to,
                    the amount of conversations you can initiate, and the number of templates you can create.
                  </p>
                </>
              }
              expanded={expandedItem === 3}
              handleAccordionClick={() => { handleAccordionClick(3) }}
            />
            <Faqaccordion
              title={"Can I renew my subscription plan?"}
              description={
                <>
                  <p>
                    Our Subscription plans are mostly monthly, and you need to renew your subscription every month.
                  </p>
                  <p>
                    In circumstances where you have finished your message limit or want to upgrade your subscription,
                    we allow you an option to do so.
                  </p>
                </>
              }
              expanded={expandedItem === 4}
              handleAccordionClick={() => { handleAccordionClick(4) }}
            />
            <Faqaccordion
              title={"What WhatsApp permissions do we require from Meta?"}
              description={
                <>
                  <div>
                    <h6>Whatsapp Business Management</h6>
                    <p>
                      The whatsapp_business_management permission allows your app to read and/or manage WhatsApp business assets you own or have been granted access to by other businesses through this permission. These business assets include WhatsApp business accounts, phone numbers, message templates, QR codes and their associated messages, and webhook subscriptions.
                    </p>
                  </div>
                  <br />
                  <div>
                    <h6>Whatsapp Business Messaging</h6>
                    <p>
                      The whatsapp_business_messaging permission allows an app to send WhatsApp messages to a specific phone number, upload and retrieve media from messages, manage and get WhatsApp business profile information, and to register those phone numbers with Meta.
                    </p>
                  </div>
                  <br />
                  <div>
                    <p>For more information, please visit <a href="https://developers.facebook.com/docs/permissions" target="_blank">here</a></p>

                  </div>
                  {/* <div>
                    <h6>Business Management</h6>
                    <p>
                      The business_management permission allows your app to read and write with the Business Manager API.
                    </p>
                  </div> */}
                </>
              }
              expanded={expandedItem === 5}
              handleAccordionClick={() => { handleAccordionClick(5) }}
            />
          </Col>
        </Row>
      </Container>
      <Container className="py-5">
        <Row>
          <Col sm={12}>
            <h1 className="mb-3 mb-lg-5">User Guide Videos</h1>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={4} className="mb-3 mb-md-0">
            <Ratio aspectRatio="16x9">
              <iframe src="https://www.youtube.com/embed/ebjs9xBp8kE?rel=0" title="YouTube video" allowFullScreen></iframe>
            </Ratio>
          </Col>
          <Col md={4} className="mb-3 mb-md-0">
            <Ratio aspectRatio="16x9">
              <iframe src="https://www.youtube.com/embed/ou1FcBPohyg?rel=0" title="YouTube video" allowFullScreen></iframe>
            </Ratio>
          </Col>
          <Col md={4} className="mb-3 mb-md-0">
            <Ratio aspectRatio="16x9">
              <iframe src="https://www.youtube.com/embed/19G4dKQJykI?rel=0" title="YouTube video" allowFullScreen></iframe>
            </Ratio>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Helpcetner;
