import React from 'react'
import {Box} from "@mui/material"
import Header from "./header.tsx"
import CreateTemplate from './createTemplate.tsx'

const Templates = () => {
  return (
    <>
        <Box className="bg-[#ecf1f9] flex flex-column justify-start align-items-start" sx={{height : "calc(100vh - 70px)"}} >
            <Header />
            <CreateTemplate />
        </Box>
    </>
  )
}

export default Templates
