import axios from 'axios'
import config from '../../config'

const baseUrl = `${config.backend}broadcast/`

export const sendBroadcast = async(data) => {
    const response = await axios.post(`${baseUrl}send`, data)
    return response.data
}

export const getAllBroadcasts = async() => {
    const response = await axios.get(`${baseUrl}all`)
    return response.data
}

export const getSingleBroadcasts = async(id) => {
    const response = await axios.get(`${baseUrl}${id}`)
    return response.data
}