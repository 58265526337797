

export function RegisterBusinessOnMeta() {

    return (
        <div>

            <div style={{
                width: "100%",
                backgroundColor: "#228581",
                height: "234px",
                top: "60px",
            }}>
                <p style={{
                    fontFamily: "Segoe UI",
                    fontSize: "48px",
                    fontWeight: 700,
                    lineHeight: "64px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    marginLeft: "10%",
                    paddingTop: "5%",
                    color: "white"
                }}>Register Business On Meta</p>
                <div style={{ width: "53px", top: "203px", marginLeft: "10%", border: "4px solid #5FD495", }}>
                </div>
            </div>

            <div className="privacy-policy">
                <br />
                <p style={{
                    fontFamily: "Segoe UI",
                    fontSize: "18px",
                    fontWeight: 400,
                    lineHeight: "32px",
                    letterSpacing: "0em",
                    textAlign: "left",
                }}>
                    MWAN MOBILE Limited (“UTTER,” “MWAN MOBILE,” “we,” “us,” or “our”) provides Software as a Service (SaaS) that enables our customers to store, manipulate, analyze, and transfer messages between their business systems and their customers on various messaging channels provided by MWAN MOBILE and third-party providers (the “Service”). A “Customer” is an entity with whom MWAN MOBILE has an agreement to provide the Service.
                    <br /><br />
                    This MWAN MOBILE Terms of Service (the “Agreement”) is an agreement between the Customer and MWAN MOBILE, collectively referred to as the “Parties,” and each as a “Party.” This Agreement is effective from the date the Customer signs up for a MWAN MOBILE account through the UTTER website or UTTER app (the “Effective Date”).
                    <br /><br />
                    By registering for a UTTER or MWAN MOBILE account, you acknowledge your understanding of these terms and agree to the terms of this Agreement on behalf of the Customer. Please ensure that you have the necessary authority to enter into this Agreement on behalf of the Customer before proceeding.
                    <br /><br />
                    If a Customer violates any of the terms outlined below, MWAN MOBILE reserves the right to cancel accounts or bar access to accounts without notice. If you do not agree to these terms, please refrain from using our Services.
                    <br /><br />
                    MWAN MOBILE may, at its discretion, remove Content and Accounts containing Content that is deemed unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene, or otherwise objectionable, or violates any party’s intellectual property or these Terms of Use.
                    <br /><br />
                    MWAN MOBILE may refuse service to anyone for any reason at any time.
                    <br /><br />
                    BY USING ANY ELEMENT OF THE MWAN MOBILE SOLUTION (INCLUDING THE WEBSITE OR APP), CUSTOMER ACKNOWLEDGES THAT CUSTOMER HAS READ, ACCEPTS AND AGREES TO BE BOUND BY AND COMPLY WITH THE TERMS AND CONDITIONS, SET OUT IN THIS AGREEMENT, AS AMENDED FROM TIME TO TIME. IF CUSTOMER DOES NOT ACCEPT AND AGREE TO BE BOUND BY THIS AGREEMENT, CUSTOMER WILL IMMEDIATELY CEASE ANY FURTHER USE OF THE MWAN MOBILE SOLUTION, INCLUDING USE OF ANY PART THEREOF. CUSTOMER REPRESENTS AND WARRANTS TO MWAN MOBILE THAT CUSTOMER HAS THE CAPACITY TO ENTER INTO THIS LEGALLY BINDING AGREEMENT. IF CUSTOMER IS USING THE MWAN MOBILE SOLUTION ON BEHALF OF ANOTHER PERSON, CUSTOMER HEREBY REPRESENTS AND WARRANTS TO MWAN MOBILE THAT CUSTOMER HAS THE AUTHORITY TO BIND SUCH PERSON TO THIS AGREEMENT.
                    <br /><br />
                    <h4>UTTER Platform</h4>
                    Provisioning of the MWAN MOBILE Platform. Subject to Customer’s compliance with the terms and conditions of this Agreement, MWAN MOBILE will make the MWAN MOBILE Platform available to Customer on the terms and conditions set out in this Agreement, provided that: (i) Customer has read and accepted and is in compliance with its obligations under the applicable terms of use related to all applicable Third-Party Messaging Platforms, Pre-Integrated Third-Party Business Applications, and Custom-Integrated Business Applications; and (ii) has taken all steps as necessary to enable interoperability between the MWAN MOBILE Platform and all applicable Third-Party Messaging Platforms, Pre-Integrated Third-Party Business Applications, and Custom-Integrated Business Applications, including acquiring the necessary approvals and API keys, whether directly or via Customer’s Clients, to access these messaging channels as a business.
                    <br /><br />
                    <h4>Modifications to the Service and Fees</h4>
                    MWAN MOBILE reserves the right to modify, suspend, or discontinue the Service at any time for any reason, with or without notice.
                    <br />
                    MWAN MOBILE reserves the right to change our monthly/annual fees upon 30 days’ notice. The fee change will be notified via email to all our subscribers and will be reflected on our website.
                    <br />
                    MWAN MOBILE reserves the right to update and change the Terms of Service from time to time without notice. Any new features that augment or enhance the current Service, including the release of new tools and resources, shall be subject to the Terms of Service. Should you continue to use the Service after any such modifications have been made, this shall constitute your agreement to such modifications.
                    <br /><br />
                    <h4> Trademark License</h4>

                    During the Term, Customer hereby grants to MWAN MOBILE a worldwide, non-exclusive, non-transferable, and non-sublicensable (other than to affiliates) royalty-free license to use Customer’s trademarks and logos made available to MWAN MOBILE by Customer as part of this Agreement, solely in connection with the marketing, advertising, and promotion of the MWAN MOBILE Solution, including listing the Customer and the Customer Application on the MWAN MOBILE Website; and only in accordance with Customer’s reasonable trademark usage guidelines, as updated by Customer from time to time. Customer may require MWAN MOBILE to cease using Customer’s trademarks and logos if, in the reasonable opinion of Customer, the continued display of Customer’s trademarks and logos would cause a materially adverse effect on Customer’s image and the goodwill associated therewith.
                    <br /><br />
                    <h4>Copyrights & Ownership</h4>

                    MWAN MOBILE expressly reserves all rights, title, and interest in, and Customer will not acquire any right, title, or interest in (i) the MWAN MOBILE Solution (or any part thereof) and any other materials or content provided by MWAN MOBILE under this Agreement, including any and all Modifications to any of the foregoing; and (ii) all intellectual property rights in any of the foregoing (clauses (i) and (ii) are collective, the “MWAN MOBILE Property”), in each case. All right, titles, and interests in the MWAN MOBILE Property will remain with MWAN MOBILE (or MWAN MOBILE’s third-party suppliers, as applicable). Customer may not copy, modify, adapt, reproduce, distribute, reverse engineer, decompile, or disassemble any aspect of the Service which MWAN MOBILE or its suppliers own. The customer agrees not to resell, duplicate, reproduce or exploit any part of the Service without the express written permission of MWAN MOBILE. For greater certainty, the MWAN MOBILE Property is licensed and not “sold” to the Customer.
                    <br /><br />
                    MWAN MOBILE claims no intellectual property rights over the Content you upload or provide to the Service.

                    <br /><br />
                    <h4>MWAN MOBILE’s Right to Use Customer Data</h4>
                    Customer acknowledges and agrees that MWAN MOBILE may store, use, reproduce, modify, and transfer to its subcontractors, Customer Data, including Personal Data, solely in connection with delivering the Services under this Agreement. Customer further acknowledges and agrees that MWAN MOBILE may store, use, reproduce, modify, and transfer data that is not related to an identified or identifiable natural person, including aggregated or de-identified data, without limitation, for its internal business purposes, including but not limited to such purposes as analytics, quality assurance, product and service improvement, and new product and service development. The customer agrees to cause any Administrative User, Customer’s Client and Chat Participant to agree to the terms.
                    <br /><br />
                    <h4>Customer Responsibilities</h4>
                    Customer Accounts. Upon Customer’s request, MWAN MOBILE will issue one or more administrator accounts (the “Administrator Accounts”) to Customer that provides Customer with the capability to create accounts for use by individuals who are an employee or contractor of Customer and that Customer wishes to have access to and use of the MWAN MOBILE Platform (each, an “Administrative User”).
                    <br /><br />
                    <h4>WhatsApp Policies</h4>
                    The Customer agrees to adhere to WhatsApp's terms and policies. MWAN MOBILE shall not be responsible for any violations leading to suspension or additional charges incurred by the Customer.
                    <br /><br />
                    <h4>Support and Communication</h4>
                    Technical support is available during weekdays via email, web chat, or mail. Response times are generally within business hours (10:00 am to 7:00 pm HKT, GMT+8:00).
                    <br /><br />
                    <h4>Fees and Payment</h4>
                    Customer agrees to pay applicable fees described on the UTTER App website. Third-Party Messaging Platform fees are the responsibility of the Customer.
                    <br /><br />
                    <h4>Terminationt</h4>
                    The Agreement is effective until terminated. Either party may terminate with notice. Termination for non-payment may result in account suspension. No refunds for partial months. Upon termination, the Customer may lose access to account information.
                    <br /><br />
                    <h4>Refunds and Termination Charges</h4>
                    No refunds for terminated agreements. If terminated by the Customer, unpaid fees for the remainder of the term are due. Termination for cause is subject to terms outlined in the Agreement.
                    <br /><br />
                    By using the UTTER App, you acknowledge and agree to be bound by the terms and conditions of this Agreement. If you do not accept these terms, please discontinue use of the UTTER App.
                    <br /><br />
                </p>
            </div>
        </div>
    )
}   